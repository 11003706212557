import React, { FunctionComponent, useEffect } from "react";
import {
  TitleSectionHeading,
  TitleDescription,
  TitleContainer,
  TitleSectionContainer,
  TitleSectionVideoContainer,
  TitleVideoGradient,
  VideoWrapper,
  TrustpilotContainer,
  TitleBadgeImage,
  TitleBadges,
  TitleBadgeLink,
} from "./TitleSection.components";
import ScrollTrigger from "../../../common/ScrollTrigger";
import gsap from "gsap";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import Trustpilot from "../../shared/components/Trustpilot/Trustpilot";
import { CompanyRoutes } from "../../../config/routes";
import BackgroundVideo from "./BackgroundVideo/BackgroundVideo";
import ImageCQC from "./image_cqc.png";
import ImageUKAS from "./image_ukas.png";
import urls from "../../../config/urls";

const TitleSection: FunctionComponent = () => {
  useEffect(() => {
    gsap
      .timeline()
      .from(".titleSection", { autoAlpha: 0, duration: 1.0, delay: 0.8 })
      .from(".title", { autoAlpha: 0, stagger: 0.1, duration: 1.0 }, 1.2)
      .from(".scrollDown", { autoAlpha: 0, y: 100, duration: 1 }, 1);

    ScrollTrigger.create({
      trigger: ".titleSection",
      scrub: 1,
      onEnter: () => {
        gsap.to(".scrollDown", { autoAlpha: 1, duration: 1 });
      },
      onEnterBack: () => {
        gsap.to(".scrollDown", { autoAlpha: 1, duration: 1 });
      },
      onLeave: () => {
        gsap.to(".scrollDown", { autoAlpha: 0, duration: 1 });
      },

      toggleActions: "play reverse none reverse",
    });

    const productsTl = gsap.timeline({ paused: true }).from(".product", { autoAlpha: 0, y: 100, duration: 2 });
    const trigger = ScrollTrigger.create({
      trigger: ".products",
      start: "top-=200 center",
      end: "bottom center",
      onEnter: () => productsTl.play(),
    });
    productsTl.add(() => trigger.kill());
  }, []);

  return (
    <>
      <SEOMarker routeInfo={CompanyRoutes.title} />
      <TitleSectionContainer id="titleSection" className="titleSection">
        <TitleContainer>
          <TrustpilotContainer>
            <Trustpilot variant="white" />
          </TrustpilotContainer>
          <TitleSectionHeading className="title">Dedicated</TitleSectionHeading>
          <TitleSectionHeading className="title">medical</TitleSectionHeading>
          <TitleSectionHeading className="title">couriers</TitleSectionHeading>
          <TitleSectionHeading className="title">&amp; services</TitleSectionHeading>
          <TitleDescription className="title">wherever you are</TitleDescription>
          <TitleBadges>
            <TitleBadgeLink href={urls.UKAS} target="_blank" rel="noreferrer">
              <TitleBadgeImage src={ImageUKAS} />
            </TitleBadgeLink>
            <TitleBadgeLink href={urls.CQC} target="_blank" rel="noreferrer">
              <TitleBadgeImage src={ImageCQC} />
            </TitleBadgeLink>
          </TitleBadges>
        </TitleContainer>

        <TitleSectionVideoContainer>
          <VideoWrapper>
            <BackgroundVideo />
            <TitleVideoGradient />
          </VideoWrapper>
        </TitleSectionVideoContainer>
      </TitleSectionContainer>
    </>
  );
};

export default React.memo(TitleSection);
