import styled from "styled-components";
import { media } from "../../../shared/styles/media";

export const GetInTouchFormContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 80px;
  justify-content: center;
  padding-bottom: 100px;

  ${media.down("NEW_HALF_WEB")`
    margin-top: 96px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    margin-top: 52px;
  `}

  ${media.down("NEW_MOBILE")`
    margin-top: 56px;
  `}
`;

export const GetInTouchInputsContainer = styled.div`
  margin-top: 52px;

  ${media.down("NEW_LARGE_MOBILE")`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
  ${media.down("NEW_MOBILE")`
    margin-top: 58px;
  `}
`;

export const GetInTouchInputsWrapper = styled.div`
  margin-top: 64px;

  ${media.down("NEW_HALF_WEB")`
    width: 564px;
  `}

  ${media.down("NEW_TABLET")`
    width: 460px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    width: 324px;
    margin-top: 48px;
  `}

  ${media.down("NEW_MOBILE")`
    width: 264px;
  `}
`;

export const HorizontalInputsWrapper = styled.div`
  display: flex;
  > :last-child {
    margin-left: 16px;
  }

  ${media.down("NEW_LARGE_MOBILE")`
    flex-direction: column;
    align-items: center;
    > :last-child {
      margin-left: 0px;
      margin-top: 48px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 80px;

  ${media.down("NEW_LARGE_MOBILE")`
    margin-top: 50px;
  `}
`;

export const ButtonInnerWrapper = styled.div`
  width: 160px;
  cursor: pointer;

  ${media.up("NEW_LARGE_WEB")`
    width: 220px;
  `}
`;
