import { media } from "./../../styles/media";
import styled from "styled-components";

export const Liquid = styled.div`
  position: absolute;
  top: -80px;
  left: 0;
  width: 230px; //200px;
  height: 200px;
  background: #dd2736;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 0.5s;

  ${media.down("TABLET")`
    width: 205px
  `}
  ${media.down("NEW_HALF_WEB")`
    width: 180px;
  `}

  &:after,
  &:before {
    content: "";
    width: 170%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 50%;
  }

  &:before {
    border-radius: 45%;
    background: #b91d2f;
    animation: animateFlowingButton 5s linear infinite;
  }

  &:after {
    border-radius: 40%;
    background: rgba(149, 20, 27, 0.4);
    animation: animateFlowingButton 10s linear infinite;
  }

  @keyframes animateFlowingButton {
    0% {
      transform: ${`translate(-50%, -75%) rotate(0deg)`};
    }
    100% {
      transform: ${`translate(-50%, -75%) rotate(360deg) `};
    }
  }
`;

export const FlowingButtonContainer = styled.a`
  box-sizing: border-box;
  position: relative;
  padding: 20px 50px;
  display: block;
  text-decoration: none;
  width: 230px;
  overflow: hidden;
  border-radius: 40px;
  font-weight: 700;
  text-align: center;
  transform: translateZ(0);

  ${media.down("TABLET")`
    width: 205px
    padding: 16px 40px;
  `}
  ${media.down("NEW_HALF_WEB")`
    width: 180px;
    padding: 12px 32px;
  `}

  > span {
    position: relative;
    color: #fff;
    z-index: 1;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    ${media.down("TABLET")`
    width: 205px
  `}
    ${media.down("NEW_HALF_WEB")`
    font-size: 18px;
  `}
  }

  :hover {
    ${Liquid} {
      top: -120px;
    }
  }
`;
