import React, { createContext, Dispatch, SetStateAction } from "react";

export interface CompanyLandingContextType {
  scrollerProxyCreated: boolean;
  setScrollerProxyCreated: Dispatch<SetStateAction<boolean>>;
}

const CompanyLandingContext: React.Context<CompanyLandingContextType> = createContext<CompanyLandingContextType>(
  {} as CompanyLandingContextType
);

export default CompanyLandingContext;
