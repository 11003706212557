import React, { useEffect, useState } from "react";
import TitleSection from "../TitleSection/TitleSection";
import OurStory from "../OurStory/OurStory";
import { Scroller, Wrapper, LightSectionsContainer } from "./CompanyLandingLayout.components";
import OurTeam from "../OurTeam/OurTeam";
import GoGreen from "../GoGreen/GoGreen";
import LaboratoriesScene from "../LaboratoriesScene/LaboratoriesScene";
import InNumbers from "../InNumbers/InNumbers";
import ClientOpinions from "../ClientOpinions/ClientOpinions";
import { useInView } from "react-intersection-observer";
import ScrollTrigger from "../../../common/ScrollTrigger";
import ContactUs from "../ContactUs/ContactUs";
import PrivacyPolicy from "../../shared/components/PrivacyAndTerms/PrivacyPolicy";
import Terms from "../../shared/components/PrivacyAndTerms/Terms";
import CookiesBox from "../../shared/components/CookiesBox/CookiesBox";
import { LandingOpinions } from "../ClientOpinions/opinionsConfig";
import ModernSlaveryPolicy from "../../shared/components/PrivacyAndTerms/ModernSlaveryPolicy";
import ProductsSection from "../ProductsSection/ProductsSection";

export enum ViewToShow {
  content,
  privacyPolicy = "privacy-and-cookies-policy",
  terms = "terms-and-conditions",
  modernSlaveryPolicy = "modern-slavery-policy",
}

const CompanyLandingLayout = () => {
  const [inViewRef, inView] = useInView();
  const [viewToShow, setViewToShow] = useState<ViewToShow | undefined>();
  const resizeObserver = new ResizeObserver(() => {
    ScrollTrigger.refresh();
  });
  resizeObserver.observe(document.body);
  useEffect(() => {
    const path = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    switch (path) {
      case ViewToShow.privacyPolicy:
        setViewToShow(ViewToShow.privacyPolicy);
        break;
      case ViewToShow.terms:
        setViewToShow(ViewToShow.terms);
        break;
      case ViewToShow.modernSlaveryPolicy:
        setViewToShow(ViewToShow.modernSlaveryPolicy);
        break;
      default:
        setViewToShow(ViewToShow.content);
    }
  }, []);

  return (
    <Wrapper className="companyLayout">
      {viewToShow === ViewToShow.content && (
        <>
          <Scroller className="scroller">
            <TitleSection />
            <ProductsSection />
            <OurStory />
            <OurTeam />
            <GoGreen />
            <LightSectionsContainer ref={inViewRef} className="lightSections">
              <LaboratoriesScene />
              <InNumbers lightSectionInView={inView} />
              <ClientOpinions opinionsConfig={LandingOpinions.COMPANY} />
            </LightSectionsContainer>
            <ContactUs />
            <CookiesBox />
          </Scroller>
        </>
      )}
      {viewToShow === ViewToShow.privacyPolicy && (
        <>
          <Scroller className="scroller">
            <PrivacyPolicy />
            <div style={{ marginTop: -50 }}></div>
          </Scroller>
        </>
      )}
      {viewToShow === ViewToShow.terms && (
        <>
          <Scroller className="scroller">
            <Terms />
            <div style={{ marginTop: -50 }}></div>
          </Scroller>
        </>
      )}
      {viewToShow === ViewToShow.modernSlaveryPolicy && (
        <>
          <Scroller className="scroller">
            <ModernSlaveryPolicy />
          </Scroller>
        </>
      )}
    </Wrapper>
  );
};
export default CompanyLandingLayout;
