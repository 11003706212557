import React from "react";
import { NoMistakesContainer, NoMistakesBanner, NoMistakesText, FakeSpan } from "./NoMistakes.components";
import ScaledContent from "../../shared/components/ScaledContent/ScaledContent";

const NoMistakes = () => {
  return (
    <NoMistakesContainer className="noMistakes">
      <ScaledContent>
        <NoMistakesBanner isBottom>
          <NoMistakesText isBottom className="bannerBottom">
            <span>No Mistakes - No Delays - No more Lost Packages -&nbsp;</span>
            <FakeSpan isBottom>No Mistakes - No Delays - No more Lost Packages -&nbsp;</FakeSpan>
          </NoMistakesText>
        </NoMistakesBanner>
      </ScaledContent>
    </NoMistakesContainer>
  );
};

export default NoMistakes;
