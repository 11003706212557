import gsap from "gsap";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { CompanyRoutes } from "../../../config/routes";
import ConditionalWrapper from "../../shared/components/ConditionalComponent/ConditionalComponent";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { BREAKPOINTS } from "../../shared/styles/const";
import {
  ClientOpinionBox,
  ClientOpinionBoxAuthor,
  ClientOpinionBoxContent,
  ClientOpinionBoxWrapper,
  ClientOpinionsContainer,
  ClientOpinionsHeader,
  ClientOpinionStarsWrapper,
  ClientOpinionsWrapper,
  ReadMoreButton,
  ReadMoreButtonLine,
  ReadMoreButtonWrapper,
  AnimatedLine,
  ClientOpinionInsideWrapper,
} from "./ClientOpinions.components";
import { getOpinionsConfig, LandingOpinions } from "./opinionsConfig";
import SingleStar from "./Single-Star.png";
import SingleStarMint from "./SingleStarMint.png";

// wylaczyc 130vh x2 i wydzielic te wrappery do osobnych komponentow
// svg nie dzialajo
// gogreen

export interface ClientOpinionsProps {
  opinionsConfig: LandingOpinions;
}

const ClientOpinions: FunctionComponent<ClientOpinionsProps> = props => {
  const { opinionsConfig } = props;
  const [inViewRef, inView] = useInView();
  const [width] = useWindowSize();
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    setShouldAnimate(true);
  }, [width]);
  useEffect(() => {
    if (inView && shouldAnimate) {
      gsap
        .timeline()
        .from(".opinion", { y: 30, duration: 0.6, stagger: 0.4 })
        .from(".opinionsReadMore", { y: 40, autoAlpha: 0, duration: 0.6 }, 0.7)
        .to(".opinionStar", { y: -10, duration: 0.3, stagger: 0.1 }, 0.7)
        .to(".opinionStar", { y: 0, duration: 0.3, stagger: 0.1 }, 1);
      setShouldAnimate(false);
    }
  }, [inView]);

  const generatedStars = useMemo(
    () =>
      ["s", "t", "a", "r", "y"].map(star => (
        <img
          key={star}
          className="opinionStar"
          loading="lazy"
          src={opinionsConfig === LandingOpinions.MEDICAL ? SingleStarMint : SingleStar}
        />
      )),
    []
  );

  const isMobileView = () => {
    return BREAKPOINTS.MOBILE_LARGE.max && width < BREAKPOINTS.MOBILE_LARGE.max;
  };

  return (
    <ClientOpinionsContainer opinionsConfig={opinionsConfig}>
      {opinionsConfig !== LandingOpinions.MEDICAL && <ClientOpinionsHeader>What Our Clients Say</ClientOpinionsHeader>}
      <SEOMarker routeInfo={CompanyRoutes.clientOpinions} offset={-300} />
      <ClientOpinionsWrapper ref={inViewRef}>
        {getOpinionsConfig(opinionsConfig).opinions.map((opinion, index) => (
          <ConditionalWrapper
            key={index}
            condition={!!isMobileView()}
            wrapper={children => <ClientOpinionInsideWrapper key={index}>{children}</ClientOpinionInsideWrapper>}
          >
            {isMobileView() && (
              <ClientOpinionStarsWrapper>{generatedStars.map(star => star)}</ClientOpinionStarsWrapper>
            )}
            <ClientOpinionBoxWrapper className="opinion" key={`${opinion}-${index}`}>
              <AnimatedLine config={opinionsConfig} animationDelay={index} />
              <ClientOpinionBox opinionsConfig={opinionsConfig}>
                {!isMobileView() && (
                  <ClientOpinionStarsWrapper>{generatedStars.map(star => star)}</ClientOpinionStarsWrapper>
                )}
                <ClientOpinionBoxContent opinionsConfig={opinionsConfig}>{opinion.content}</ClientOpinionBoxContent>
                <ClientOpinionBoxAuthor opinionsConfig={opinionsConfig}>~&nbsp;{opinion.author}</ClientOpinionBoxAuthor>
              </ClientOpinionBox>
            </ClientOpinionBoxWrapper>
          </ConditionalWrapper>
        ))}
      </ClientOpinionsWrapper>

      <ReadMoreButtonWrapper className="opinionsReadMore">
        <ReadMoreButton
          opinionsConfig={opinionsConfig}
          href="https://uk.trustpilot.com/review/medicallogistics.co.uk"
          target="_blank"
        >
          Read More
        </ReadMoreButton>
        <ReadMoreButtonLine opinionsConfig={opinionsConfig} />
      </ReadMoreButtonWrapper>
    </ClientOpinionsContainer>
  );
};

export default React.memo(ClientOpinions);
