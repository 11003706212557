import styled from "styled-components";
import { media } from "../../shared/styles/media";

export const ProdSectionContainer = styled.div`
  margin-top: 64px;

  ${media.down("TABLET")`
    margin-top: 32px;
  `}

  ${media.down("MOBILE")`
    margin-top: 16px;
  `}
`;
