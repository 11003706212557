import { useCallback, useEffect, useState } from "react";
import { Landing } from "../../config";
import { RouteInfo, allRoutes as getAllRoutes, allRoutes } from "../../config/routes";
import { dispatchAppEvent } from "../events/AppEventTarget";
import useEventHandler from "./useEventHandler";

const MINIMAL_TIME_DELTA = 100;
const WAIT_FOR_ELEMENT_TIMEOUT = 3000;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const waitForElement = async (className: string, timeout: number) => {
  for (let i = 0; i < timeout; i += MINIMAL_TIME_DELTA) {
    const elementsWithClassName = document.getElementsByClassName(className);
    if (elementsWithClassName.length > 0) return elementsWithClassName[0];

    await wait(MINIMAL_TIME_DELTA);
  }

  return null;
};

const goToElement = async (className: string) => {
  const marker = await waitForElement(className, WAIT_FOR_ELEMENT_TIMEOUT);

  if (!marker)
    console.error(
      `No element with class ${className} found. Fix routing in useSEO or make sure the SEO Marker is present.`
    );
  else {
    const markerPosition = marker.getBoundingClientRect().top + document.documentElement.scrollTop;
    window.scrollTo(0, markerPosition);
  }
};

const waitForScrollFinish = () => {
  let scrollTimeout: number;
  return new Promise<void>(resolve => {
    window.addEventListener("scroll", () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(resolve, MINIMAL_TIME_DELTA);
    });
  });
};

const initRoutes = (landing: Landing) => {
  const routes = allRoutes();
  const routeMap = routes[landing];

  const replaceState = (routeInfo: RouteInfo) => {
    window.history.replaceState({}, routeInfo.title, `/${routeInfo.url}`);
    dispatchAppEvent("routeChange", { newRoute: routeInfo, initial: false });
  };

  window.addEventListener("scroll", () => {
    const topPosition = window.scrollY;
    const passedMarkers = [];

    const markers = document.querySelectorAll(".seoMarker");
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      if (marker.getBoundingClientRect().top + document.documentElement.scrollTop < topPosition)
        passedMarkers.push(marker);
    }

    if (passedMarkers.length == 0) return;

    const lastMarker = passedMarkers[passedMarkers.length - 1];
    const lastMarkerClass = lastMarker.className.replace("seoMarker", "").trim();

    const routeKey = Object.keys(routeMap).find(key => routeMap[key].className == lastMarkerClass);

    if (routeKey) {
      const route = routeMap[routeKey];
      if (window.location.pathname.substr(1) != route.url) replaceState(route);
    }
  });
};

export const getCurrentRoute = (landing: Landing) => {
  const routes = getAllRoutes();
  const initialRoute = window.location.pathname.substr(1);

  const routeKeys = Object.keys(routes[landing]);
  const initialRouteInfoKey = routeKeys.find(key => routes[landing][key].url == initialRoute);

  return initialRouteInfoKey ? routes[landing][initialRouteInfoKey] : undefined;
};

const init = async (landing: Landing) => {
  const currentRoute = getCurrentRoute(landing);

  if (currentRoute) {
    dispatchAppEvent("routeChange", { newRoute: currentRoute, initial: true });
    if (currentRoute.url != "" && currentRoute.className) {
      await goToElement(currentRoute.className);
      await waitForScrollFinish();
    }
  }

  initRoutes(landing);
};

const useRouting = (landing: Landing) => {
  const [lastRoute, setLastRoute] = useState<RouteInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(getCurrentRoute(landing)?.url !== "");

  const initRouting = async () => {
    await init(landing);
    setIsLoading(false);
  };
  useEffect(() => {
    initRouting();
  }, []);

  useEventHandler(
    "routeChange",
    useCallback(ev => {
      const { newRoute } = ev.detail;
      setLastRoute(newRoute);
    }, [])
  );

  window.addEventListener("unload", () => {
    if (lastRoute && lastRoute.className) goToElement(lastRoute.className);
  });
  return isLoading;
};

export default useRouting;
