import React, { Dispatch, FunctionComponent, SetStateAction, useCallback } from "react";
import { Formik } from "formik";
import { GetInTouchFormValues, getInTouchInitialValues, getInTouchValidationSchema, subjects } from "./form";
import GetInTouchFormContent from "./GetInTouchFormContent";

const sendContactFormEmailUrl =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? ""
    : "https://europe-west2-medlog-landing-mails.cloudfunctions.net/sendContactFormEmail";

type GetInTouchFormProps = {
  setSent: Dispatch<SetStateAction<boolean>>;
};

const GetInTouchForm: FunctionComponent<GetInTouchFormProps> = ({ setSent }) => {
  const postData = async (data: GetInTouchFormValues) => {
    await fetch(sendContactFormEmailUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "no-cors",
      body: JSON.stringify({
        data,
      }),
    });
  };

  const handleSubmit = useCallback(async (values: GetInTouchFormValues) => {
    try {
      const formData = { ...values, subject: subjects.find(s => s.cardName === values.subject)?.label };
      postData(formData);
      setSent(true);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Formik
      initialValues={getInTouchInitialValues}
      validateOnMount={true}
      validationSchema={getInTouchValidationSchema}
      onSubmit={handleSubmit}
    >
      {formProps => <GetInTouchFormContent {...formProps} />}
    </Formik>
  );
};

export default GetInTouchForm;
