// @ts-nocheck
import React, { FunctionComponent, Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  GoGreenContainer,
  GoGreenHeading,
  GoGreenHeadingWrapper,
  GoGreenCanvasWrapper,
  GoGreenDescription,
  LeftPointerWrapper,
  CircleWrapper,
  LeftArrowWrapper,
  RightPointerWrapper,
  RightArrowWrapper,
  GoGreenInnerContainer,
  EcoBadge,
  BGBottom,
  BGTop,
} from "./GoGreen.components";
import WaveEffect from "./WaveEffect";
import { ReactComponent as CirclePointer } from "./icons/CirclePointer.svg";
import { ReactComponent as LeftArrow } from "./icons/ArrowLeft.svg";
import { ReactComponent as RightArrow } from "./icons/ArrowRight.svg";
import { useIsMobileLargeDown } from "../../../common/hooks/useMedia";
import GoGreenMobile from "./GoGreenMobile/GoGreenMobile";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import EcoBadgeImage from "./EcoBadge.svg";
import { useInView } from "react-intersection-observer";
import useDpr from "../../../common/hooks/useDpr";
import SEOMarker from "../../../modules/shared/components/SEO/SEOMarker";
import { CompanyRoutes } from "../../../config/routes";

const CANVAS_RATIO = 1.49;
const GoGreen: FunctionComponent = () => {
  const isMobileLargeDown = useIsMobileLargeDown();
  const [width] = useWindowSize();
  const [inViewRef, inView] = useInView({ threshold: 0.3 });

  const getCanvasWidth = () => Math.min(width * 0.6, 809);

  return (
    <section>
      {!isMobileLargeDown ? (
        <GoGreenContainer ref={inViewRef}>
          <GoGreenInnerContainer maxWidth={getCanvasWidth()} height={getCanvasWidth() / CANVAS_RATIO}>
            <SEOMarker routeInfo={CompanyRoutes.goGreen} offset={-100} />
            <GoGreenHeadingWrapper>
              <GoGreenHeading>Go Green!</GoGreenHeading>
              <GoGreenDescription>By 2023 all of our cars will be: </GoGreenDescription>
            </GoGreenHeadingWrapper>
            <LeftPointerWrapper>
              <LeftArrowWrapper>
                <span>100% ELECTRIC</span>
                <LeftArrow />
                <CircleWrapper>
                  <CirclePointer />
                </CircleWrapper>
              </LeftArrowWrapper>
            </LeftPointerWrapper>
            <GoGreenCanvasWrapper width={getCanvasWidth()} height={getCanvasWidth() / CANVAS_RATIO}>
              <EcoBadge load="lazy" alt="Eco Badge" src={EcoBadgeImage} />
              <Canvas dpr={useDpr()}>
                <Suspense fallback={null}>
                  <WaveEffect inView={inView} />
                </Suspense>
              </Canvas>
            </GoGreenCanvasWrapper>
            <RightPointerWrapper>
              <RightArrowWrapper>
                <RightArrow />
                <span>Emission-free</span>
                <CircleWrapper>
                  <CirclePointer />
                </CircleWrapper>
              </RightArrowWrapper>
            </RightPointerWrapper>
          </GoGreenInnerContainer>
          <BGBottom />
          <BGTop />
          <SEOMarker routeInfo={CompanyRoutes.laboratories} offset={500} />
        </GoGreenContainer>
      ) : (
        <GoGreenMobile />
      )}
    </section>
  );
};

export default React.memo(GoGreen);
