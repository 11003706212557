import React, { useEffect, useRef, useState } from "react";
import { CouriersRoutes } from "../../../config/routes";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import {
  ButtonLightBackground,
  HeroButtonWrapper,
  HeroContainer,
  HeroDescription,
  HeroHeading,
  HeroVideoContainer,
  HeroVideoPlayer,
  VideoInnerWrapper,
  VideoOuterWrapper,
} from "./HeroVideo.components";
// @ts-ignore
import MapVideo from "./Map.webm";
// @ts-ignore
import MapVideoMac from "./Map.mov";
// @ts-ignore
import MapVideoVertical from "./map_v.webm";
// @ts-ignore
import MapVideoMacVertical from "./map_v.mov";

import Trustpilot from "../../shared/components/Trustpilot/Trustpilot";
import gsap from "gsap";
import Button from "../../shared/components/Button";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { useIsHeroVideoMobileDown } from "../../../common/hooks/useMedia";

const getVideoStyle = (windowHeight: number, windowWidth: number, isVertical: boolean) => {
  //landscape orientation
  if (isVertical) {
    const height = Math.max(windowWidth * 1.125, windowHeight, 700);
    return {
      width: windowWidth,
      height: height,
      transform: `translate(0px, ${
        windowHeight / windowWidth < 1.25 ? 80 : Math.min(windowWidth > 450 ? 260 : 400, height - 400)
      }px)`,
      bottom: "0",
      top: "unset",
    };
  }

  return {
    width: windowWidth,
    height: Math.max((windowWidth * 9) / 16, windowHeight),
    top: windowHeight + 1 <= (windowWidth * 9) / 16 ? "100px" : "30px",
    transform: `translate(-${((windowHeight * 16) / 9 - windowWidth) / 2}px, -${
      ((windowWidth * 9) / 16 - windowHeight) / 2
    }px)`,
  };
};

const HeroVideo = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const windowHeight = useRef(window.innerHeight);
  const windowWidth = useRef(window.innerWidth);
  const [width] = useWindowSize();
  const [rerender, setRerender] = useState(true);

  const isMobileLargeDown = useIsHeroVideoMobileDown();

  useEffect(() => {
    if (!isMobile && width !== windowWidth.current) {
      windowWidth.current = width;
      setRerender(!rerender);
    }
  }, [width]);

  useEffect(() => {
    const startingTl = gsap.timeline();
    startingTl.fromTo(
      ".heroHeading",
      { x: -100, opacity: 0, duration: 1 },
      { x: 0, stagger: 0.6, opacity: 1, duration: 1 },
      1.2
    );
  }, []);

  return (
    <HeroVideoContainer
      style={{
        height: isMobileLargeDown
          ? Math.max(700, Math.max(windowHeight.current, windowWidth.current * 1.125))
          : Math.max(windowHeight.current, (windowWidth.current * 9) / 16) + 90,
        marginBottom: isMobileLargeDown || window.innerHeight / window.innerWidth < 9 / 16 ? 0 : "80px",
      }}
      className="heroVideo"
    >
      <SEOMarker routeInfo={CouriersRoutes.title} />
      <HeroContainer>
        <Trustpilot variant="red" />
        <HeroHeading className="heroHeading">
          <span>The Only Dedicated Medical Couriers </span>
          <span>in London</span>
        </HeroHeading>
        <HeroDescription className="heroHeading">
          A highly personalised and exclusively medical courier service you can count on.
          <strong> Always on-time</strong> and available 24/7.
        </HeroDescription>
        <HeroButtonWrapper className="heroHeading">
          <ButtonLightBackground></ButtonLightBackground>
          <Button onClick={() => window.open("https://booking.medicallogistics.co.uk/", "_self")} size="extraLarge">
            Check availability
          </Button>
        </HeroButtonWrapper>
      </HeroContainer>
      <VideoOuterWrapper>
        <VideoInnerWrapper landscapeOrientation={window.innerHeight < (window.innerWidth * 9) / 16}>
          <HeroVideoPlayer
            ref={videoRef}
            style={getVideoStyle(windowHeight.current, windowWidth.current, isMobileLargeDown)}
            playsInline
            autoPlay
            onEnded={() => {
              videoRef.current!.currentTime = 2.2;
              videoRef.current!.play();
            }}
            muted
            key={"heroVideo" + isMobileLargeDown}
          >
            <source src={isMobileLargeDown ? MapVideoMacVertical : MapVideoMac} type="video/quicktime" />
            <source src={isMobileLargeDown ? MapVideoVertical : MapVideo} type="video/webm" />
          </HeroVideoPlayer>
        </VideoInnerWrapper>
      </VideoOuterWrapper>
    </HeroVideoContainer>
  );
};

export default HeroVideo;
