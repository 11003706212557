import styled from "styled-components";

export const BaseEffects = styled.svg`
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
`;

export const HiddenSvg = styled.svg`
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
`;

export const FancyLinkInner = styled.span`
  display: block;
  position: relative;
  outline: 100px solid transparent;
`;

export const FancyLinkDeco = styled.span`
  outline: 120px solid transparent;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const FancyLinkWrapper = styled.a`
  position: relative;
  text-decoration: none;
  font-size: 48px;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    ${FancyLinkDeco} {
      opacity: 1;
    }
  }
`;
