import styled from "styled-components";
import { colors } from "../../shared/styles/colors";
import { media } from "../../shared/styles/media";
import TextEllipse from "./ellipse.png";

export const LabSceneContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-bottom: 50px;
  position: relative;
  /* background-color: rgba(234, 238, 238, 1);
  ${media.down("MOBILE_LARGE")`
      height: 900px; //TODO: remove and add rwd
  `}
  ${media.down("MOBILE")`
      height: 800px; //TODO: remove and add rwd
  `} */
`;

export const LabTestimonial = styled.h1`
  margin: 0;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 104%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1b2d2d;
  max-width: 900px;

  ${media.down("FULL")`
  margin-top: 200px;
  font-size: 72px;
  `}

  ${media.down("MAIN_WEB")`
  font-size: 72px;
  `}

  ${media.down("LAPTOP")`
  font-size: 64px;
  `}

  ${media.down("TABLET_LARGE")`
  font-size: 56px;

  `}

  ${media.down("TABLET_SMALL")`
  margin-top: 160px;
  max-width: 540px;

  font-size: 56px;
  `}

  ${media.down("MOBILE_LARGE")`
  max-width: 400px;
  `}


  ${media.down("MOBILE_MLARGE")`
  margin-top: 128px;
  max-width: 298px;
  `}

  ${media.down("MOBILE")`
  margin-top: 192px;
  `}

  ${media.down("MOBILE_SMALL")`
  margin-top: 136px;
  max-width: 298px;
  font-size: 48px;
  `}

  > span {
    :not(:first-child) {
      color: ${colors.red._500};
    }

    :not(:last-child) {
      background: url(${TextEllipse});
      width: auto;
      height: auto;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      -webkit-background-size: 100% 100%; /*  Safari  */
      -khtml-background-size: 100% 100%; /*  Konqueror  */
      -moz-background-size: 100% 100%; /*  Firefox  */
    }
  }
`;

export const TopRightCell = styled.img`
  position: absolute;
  right: 10%;
  top: 10%;

  ${media.down("FULL")`

  `}

  ${media.down("MAIN_WEB")`
  `}

  ${media.down("LAPTOP")`
  `}

  ${media.down("TABLET_LARGE")`
width: 60px;

  `}

  ${media.down("TABLET_SMALL")`
  width: 70px;
  `}

  ${media.down("MOBILE_LARGE")`
  `}


  ${media.down("MOBILE_MLARGE")`
  width: 60px;

  `}

  ${media.down("MOBILE")`
  `}
`;

export const BottomRightCell = styled.img`
  position: absolute;
  left: 10%;
  bottom: 32%;
  ${media.down("FULL")`

`}

  ${media.down("MAIN_WEB")`
`}

${media.down("LAPTOP")`
`}

${media.down("TABLET_LARGE")`
width: 56px;

`}

${media.down("TABLET_SMALL")`
width: 64px;

`}

${media.down("MOBILE_LARGE")`
width: 58px;

`}


${media.down("MOBILE_MLARGE")`
`}

${media.down("MOBILE")`
`}
`;

export const BottomLeftCell = styled.img`
  position: absolute;
  left: 4%;
  bottom: 52%;
  ${media.down("FULL")`

`}

  ${media.down("MAIN_WEB")`
`}

${media.down("LAPTOP")`
`}

${media.down("TABLET_LARGE")`
`}

${media.down("TABLET_SMALL")`
width: 57px;

`}

${media.down("MOBILE_LARGE")`

`}


${media.down("MOBILE_MLARGE")`
width: 47px;

`}

${media.down("MOBILE_SMALL")`
display: none;
`}
`;

export const AlphaTopLeftCell = styled.img`
  position: absolute;
  left: 25%;
  top: 20%;
  ${media.down("TABLET_SMALL")`
width: 86px;

`}
  ${media.down("MOBILE_MLARGE")`
width: 66px;

`}
`;

export const AlphaTopRightCell = styled.img`
  position: absolute;
  right: 21%;
  top: 7%;
  ${media.down("TABLET_SMALL")`
display: none;
`}
`;

export const AlphaBottomCell = styled.img`
  position: absolute;
  bottom: 25%;
  right: 25%;
  ${media.down("TABLET_SMALL")`
  width: 81px;
`}
  ${media.down("MOBILE_MLARGE")`
width: 61px;

`}
`;
