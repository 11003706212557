import ga from "react-ga";
import { config } from "../../config";

const trackEvent = (category: string, action: string, value?: number) => {
  ga.event({ category, action, value });
};

const trackPageView = (path: string) => {
  ga.pageview(path);
};

const trackEcommerce = (
  transactionId: string,
  productName: string,
  sku: string,
  category: string,
  price: number,
  quantity: number
) => {
  ga.plugin.require("ecommerce");
  ga.plugin.execute("ecommerce", "addItem", {
    id: transactionId,
    name: productName,
    sku,
    category,
    price,
    quantity,
  });
  ga.plugin.execute("ecommerce", "send", null);
};

const initialize = () => {
  const gaTrackingCode = config.gaTrackingCode;
  if (!gaTrackingCode) throw new Error("No GA tracking code provided. Please fill it in the env file.");
  ga.initialize(gaTrackingCode);
  ga.pageview(window.location.pathname);
};

export default () => ({
  trackEvent,
  trackPageView,
  initialize,
  trackEcommerce,
});
