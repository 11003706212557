import React, { FunctionComponent, useEffect, useMemo } from "react";
import { BouncedBox, BouncedBoxTop, BouncedBoxWrapper, BouncedBoxInsideWrapper } from "./BouncedShape.components";
import ScrollTrigger from "../../../../common/ScrollTrigger";
import gsap from "gsap";

//PARENT MUST HAVE POSITION: RELATIVE

const BouncedShape: FunctionComponent<{ trigger: string; topShapeBackground: string; bottomShapeBackground: string }> =
  ({ trigger, topShapeBackground, bottomShapeBackground }) => {
    const topShapeName = useMemo(() => trigger + "topShape", []);
    const bottomShapeName = useMemo(() => trigger + "bottomShape", []);

    useEffect(() => {
      ScrollTrigger.create({
        trigger: `.${trigger}`,
        start: "bottom-=100 bottom",
        end: "bottom-=00 center",

        scrub: 1,
        id: `${bottomShapeName}Trigger`,
        onUpdate: self => {
          gsap.timeline().to(`.${bottomShapeName}`, {
            borderRadius: `0 0 ${(1 - self.progress) * 100}% ${(1 - self.progress) * 100}%`,
            y: (1 - self.progress) * 100,
            duration: 0,
          });
        },
      });

      ScrollTrigger.create({
        trigger: `.${trigger}`,
        scrub: 1,
        start: "bottom 50%",
        end: "bottom 0%",
        id: `${topShapeName}Trigger`,
        onEnter: () => {
          gsap.killTweensOf(`.${topShapeName}`);
          gsap.to(`.${topShapeName}`, { zIndex: 999999, duration: 0 });
        },
        onEnterBack: () => {
          gsap.killTweensOf(`.${topShapeName}`);
          gsap.to(`.${topShapeName}`, { zIndex: 99999, duration: 0 });
        },
        onUpdate: self => {
          gsap.timeline().to(`.${topShapeName}`, {
            display: "block",
            borderRadius: `${self.progress * 100}% ${self.progress * 100}% 0 0`,
            y: self.progress * -100,
            duration: 0,
          });
        },
        onLeave: () => {
          gsap.to(`.${topShapeName}`, { zIndex: -1, duration: 0 });
        },
        onLeaveBack: () => {
          gsap.to(`.${topShapeName}`, { zIndex: -1, duration: 0 });
        },
      });
    }, []);

    return (
      <BouncedBoxWrapper>
        <BouncedBoxInsideWrapper>
          <BouncedBoxTop style={{ backgroundColor: bottomShapeBackground }} className={bottomShapeName} />
          <BouncedBox style={{ backgroundColor: topShapeBackground }} className={topShapeName} />
        </BouncedBoxInsideWrapper>
      </BouncedBoxWrapper>
    );
  };

export default BouncedShape;
