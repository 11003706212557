import { EventDataTypes } from "./EventDataTypes";

const eventTarget = new EventTarget();

export const dispatchAppEvent = <K extends keyof EventDataTypes>(type: K, detail?: EventDataTypes[K]) => {
  const event = new CustomEvent<EventDataTypes[K]>(type, {
    detail,
  });
  eventTarget.dispatchEvent(event);
};

export default eventTarget;
