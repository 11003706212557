import styled from "styled-components";
import {
  FancyLinkDeco,
  FancyLinkInner,
  FancyLinkWrapper,
} from "../../shared/components/FancyLink/FancyLink.components";
import { colors } from "../../shared/styles/colors";
import { media } from "../../shared/styles/media";
import TopShapeSVG from "./contact-us-top-shape.svg";

export const Wrapper = styled.section`
  width: 100%;

  padding-top: 200px;
  position: relative;
`;

export const TopShape = styled.div`
  width: 100%;
  height: 104px;
  background-image: url(${TopShapeSVG});
  position: absolute;
  top: 5px;
  left: 0;
  transform: translateY(-100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  ${media.down("MOBILE_LARGE")`
    height: 40px;
  `}
  ${media.up("FULL")`
    height: 164px;
  `}
`;

export const SeparatorLine = styled.span`
  background: rgba(164, 152, 152, 0.32);
  width: 1px;
  height: 100%;
`;
export const SeparatorText = styled.p`
  margin: 5px 0;
`;

export const ContactUsMenu = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 160px;
  margin: 0 65px;

  ${media.down("FULL")`
  height: 256px;
  `}

  ${media.down("MAIN_WEB")`
  height: 221px;
  `}

  ${media.down("LAPTOP")`
height: 194px;
margin: 0 58px;
  `}

  ${media.down("TABLET_LARGE")`
  height: 155px;
margin: 0 60px;
  `}

  ${media.down("TABLET_SMALL")`
  height: 126px;
margin: 0 41px;
  `}

  ${media.down("MOBILE_LARGE")`
margin: 0 25px;
  `}


  ${media.down("MOBILE_MLARGE")`
flex-direction: column;
height: 289px;
  `}

  ${media.down("MOBILE")`
height: 261px;
  `}

  &:hover {
    ${SeparatorLine} {
      background: ${colors.mint._500};
    }
  }
  ${FancyLinkWrapper} {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    border-top: 1px solid rgba(164, 152, 152, 0.32);
    border-bottom: 1px solid rgba(164, 152, 152, 0.32);

    ${media.down("FULL")`
  font-size: 104px;
  `}

    ${media.down("MAIN_WEB")`
  font-size: 96px;
  `}

  ${media.down("LAPTOP")`
  font-size: 84px;

  `}

  ${media.down("TABLET_LARGE")`
  font-size: 64px;
  `}

  ${media.down("TABLET_SMALL")`
  font-size: 48px;
    width: 100%;
  `}

  ${media.down("MOBILE")`
  `}

  ${media.down("MOBILE_SMALL")`
  `}


    &:hover {
      ${FancyLinkInner} {
        color: ${colors.mint._500};
      }
      ${SeparatorLine} {
        background: ${colors.mint._500};
      }

      .xd {
        background: ${colors.mint._500};
      }

      ${SeparatorText} {
        color: red;
      }
    }
  }
  ${FancyLinkDeco} {
    height: 100%;
    background: none;
    border: 1px solid ${colors.mint._500};
    border-right: 0;
    border-left: 0;
  }
  ${FancyLinkInner} {
    color: #f0f5f5;
    opacity: 0.6;
  }
`;

export const ContactUsMenuSeparator = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50px;
  justify-content: center;
  align-items: center;

  ${media.down("MOBILE_MLARGE")`
display: none;
  `}

  &:hover {
    ${SeparatorLine} {
      background: rgba(209, 220, 220, 0.16);
    }
  }
`;

export const ContactUsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -100px;
  margin-bottom: 100px;
  position: relative;

  ${media.up("LAPTOP")`
    padding-left: calc(((100vw - 130px) / 2 - 300px) / 2) !important;
  `}

  ${media.down("FULL")`
  padding: 0 360px;
  `}

  ${media.down("MAIN_WEB")`
  padding: 0 200px;
  `}

  ${media.down("LAPTOP")`
  padding: 0 144px;

  `}

  ${media.down("TABLET_LARGE")`
  padding: 0 96px;

  `}

  ${media.down("TABLET_SMALL")`
  padding: 0 72px;

  `}

  ${media.down("MOBILE_LARGE")`
  padding: 0;
  flex-direction: column-reverse;
  `}
`;

export const ContactUsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.down("MOBILE_LARGE")`

  margin-top: -140px;
  `}
  ${media.down("MOBILE_SMALL")`

margin-top: -100px;
padding: 0 40px;
`}
`;

export const ContactUsTitle = styled.h1`
  margin: 0;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: 500;
  font-size: 104px;
  line-height: 96%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #f0f5f5;
  ${media.down("FULL")`
  font-size: 136px;
  `}

  ${media.down("MAIN_WEB")`
  font-size: 116px;

  `}

  ${media.down("LAPTOP")`
  font-size: 104px;
  `}

  ${media.down("TABLET_LARGE")`
  font-size: 84px;

  `}

  ${media.down("TABLET_SMALL")`
  font-size: 64px;
  `}

  ${media.down("MOBILE_LARGE")`
  font-size: 72px;
  `}



  ${media.down("MOBILE_SMALL")`
  font-size: 64px;
  `}
`;

export const ContactUsDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 145%;
  gap: 12px;
  margin-top: 8px;

  ${media.down("MOBILE_LARGE")`
    margin-top: 12px;
    gap: 8px;
  `}

  ${media.down("FULL")`
    font-size: 32px;
  `}

  ${media.down("MAIN_WEB")`
    font-size: 28px;
  `}

  ${media.down("LAPTOP")`
    font-size: 24px;
  `}

  ${media.down("TABLET_LARGE")`
    font-size: 20px;
  `}

  ${media.down("TABLET_SMALL")`
    font-size: 16px;
    margin-left: 4px;
  `}

  ${media.down("MOBILE_LARGE")`
    font-size: 18px;
  `}

  ${media.down("MOBILE_SMALL")`
    font-size: 16px;
  `}
`;

export const ContactUsMail = styled.a`
  margin: 0;
  color: ${colors.mint._400};
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-style: inherit;
  transition: transform 200ms ease-in-out;
  width: fit-content;

  :hover {
    transform: scale(1.05);
  }
`;

export const ContactUsSubtitle = styled.h5`
  margin: 0;
  color: #b6c3c3;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-style: inherit;
`;

export const ModelMockContainer = styled.div`
  width: 536px;
  height: 376px;
  position: absolute;
  right: 8%;
  top: 0%;
  transform: translateY(-35%);
  z-index: -1;

  ${media.up("MAIN_WEB")`
    right: 15%;
  `}
  ${media.down("TABLET")`
  right: 3%;
  `};
  ${media.down("TABLET_SMALL")`
  width: 440px;
  height: 322px;
  right: 0;
  `}
  ${media.down("MOBILE_LARGE")`
  width: 100%;
  height: 342px;
  position: static;
  padding-top: 40px;
  `}
  ${media.down("MOBILE_MLARGE")`
 
  height: 282px;
  `}
  ${media.down("MOBILE_SMALL")`
  
  height: 222px;
  `}
`;

export const ModelMockImg = styled.img`
  width: 100%;
  height: 100%;
`;
