import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ButtonContainer,
  VideoContainer,
  ProductCardContainer,
  ProductCardDescription,
  ProductCardFooter,
  ProductCardTitle,
  ProductWrapper,
  TextContentContainer,
  CardWrapper,
} from "./ProductCard.components";
import RedBox from "./BoxShine.svg";
import MintBox from "./BoxMintOnload.svg";
import MintButton from "./btnmint.svg";
import RedButton from "./btnred.svg";
import RedBoxHigh from "./BoxRedHigh.svg";
import MintBoxHigh from "./BoxMintHigh.svg";
import RedBoxWide from "./BoxRedWide.svg";
import MintBoxWide from "./BoxMintWide.svg";
import useMedia from "use-media";
import { useInView } from "react-intersection-observer";
import useGA from "../../../../common/hooks/useGA";

// @ts-ignore
import CrownVideo from "./crown.webm";
// @ts-ignore
import BoxVideo from "./box.webm";
// @ts-ignore
import CrownVideoMac from "./crown.mov";
// @ts-ignore
import BoxVideoMac from "./box.mov";
export interface Props {
  title: string;
  description: string;
  footer?: string;
  isInView: boolean;
  goTo: string;
  isCouriersService?: boolean;
  gaEvent?: { category: string; action: string };
}

const ProductCard: FunctionComponent<Props> = props => {
  const [inViewRef, inView] = useInView();
  const [isHovered, setIsHovered] = useState(false);
  const wideBox = useMedia({ maxWidth: 899, minWidth: 476 });
  const highBox = useMedia({ maxWidth: 475 });
  const { title, description, footer, gaEvent, isCouriersService } = props;

  const { trackEvent } = useGA();

  const renderCardSvg = () => {
    if (highBox) {
      return footer ? RedBoxHigh : MintBoxHigh;
    } else if (wideBox) {
      return footer ? RedBoxWide : MintBoxWide;
    } else {
      return footer ? RedBox : MintBox;
    }
  };

  const triggerBtnAnimation = () => {
    const a = document.getElementById(footer ? "btn-red" : "btn-mint");
    //@ts-ignore
    const b = a?.contentDocument;
    const c = b?.getElementById(footer ? "enrG2uXc1iV1" : "eGQkHviLFW41");
    c?.dispatchEvent(new Event("click"));
  };

  useEffect(() => {
    if (inView) triggerBtnAnimation();
  }, [inView]);

  return (
    <ProductWrapper className="product">
      <VideoContainer
        isHovered={isHovered}
        autoPlay={true}
        muted
        loop
        playsInline={true}
        isCouriersService={isCouriersService}
      >
        <source src={isCouriersService ? BoxVideoMac : CrownVideoMac} type="video/quicktime" />
        <source src={isCouriersService ? BoxVideo : CrownVideo} type="video/webm" />
      </VideoContainer>

      <ProductCardContainer>
        <CardWrapper isHovered={isHovered}>
          <object type="image/svg+xml" data={renderCardSvg()}></object>
        </CardWrapper>
        <TextContentContainer hasFooter={!!footer}>
          <ProductCardTitle>{title}</ProductCardTitle>
          <ProductCardDescription>{description}</ProductCardDescription>
          <ProductCardFooter>{footer ? footer : "\n"}</ProductCardFooter>
        </TextContentContainer>
      </ProductCardContainer>
      <ButtonContainer
        ref={inViewRef}
        onClick={() => {
          gaEvent && trackEvent(gaEvent.category, gaEvent.action);
          window.location.href = props.goTo;
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={{ position: "absolute", height: "100%", width: "100%", top: 0, left: 0 }}></div>
        <object
          id={footer ? "btn-red" : "btn-mint"}
          style={{ zIndex: 2, cursor: "pointer" }}
          type="image/svg+xml"
          data={footer ? RedButton : MintButton}
        ></object>
      </ButtonContainer>
    </ProductWrapper>
  );
};

export default ProductCard;
