import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { CounterTextWrapper, CounterWrapper } from "./Counter.components";
import Line from "./line.svg";
import LineMint from "./lineMint.svg";
import gsap from "gsap";
import { useCountUp } from "react-countup";

type CounterProps = {
  value: number;
  description: string;
  prefix: string;
  suffix: string;
  initialTriggerPassed: boolean;
  inverted?: boolean;
  isMedical?: boolean;
};

const Counter: FunctionComponent<CounterProps> = ({
  value,
  description,
  initialTriggerPassed,
  inverted,
  prefix,
  suffix,
  isMedical,
}) => {
  const countUpRef = useRef(null);
  const [storedCounterData, setStoredCounterData] = useState({
    description: "",
    prefix: "",
    suffix: "",
  });
  const [renderLine, setRenderLine] = useState(false);
  const { update, reset } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 1,
    delay: 1500,
    duration: 1,
    separator: " ",
  });

  const tl = gsap
    .timeline({ paused: true })
    .to(`.counter`, { autoAlpha: 0, y: 40, duration: 0.2 })
    .add(() => setStoredCounterData({ description, prefix, suffix }))
    .add(() => setRenderLine(false))
    .add(() => {
      reset();
      setTimeout(() => update(value), 100);
    })
    .add(() => setRenderLine(true))
    .to(`.counter`, { autoAlpha: 1, y: 0 });

  const initTl = gsap
    .timeline({ paused: true })
    .addLabel("start")
    .to(".counter", {
      autoAlpha: 0,
      y: 50,
    })
    .addLabel("mid")
    .to(".counter", {
      autoAlpha: 1,
      y: 0,
      duration: 0.25,
    })
    .addLabel("end");

  useEffect(() => {
    tl.play();
  }, [value]);

  useEffect(() => {
    if (initialTriggerPassed) {
      setStoredCounterData({ description, prefix, suffix });
      initTl.tweenTo("end");
      setRenderLine(true);
      reset();
      setTimeout(() => update(value), 100);
    } else {
      initTl.tweenTo("mid");
      setTimeout(() => setRenderLine(false), 200);
    }
  }, [initialTriggerPassed]);

  // Hide counters on initial load
  useEffect(() => {
    gsap.to(".counter", {
      autoAlpha: 0,
      y: 150,
    });
  }, []);

  return (
    <CounterWrapper inverted={inverted}>
      <div style={{ width: 56, height: 289 }} className="counter">
        {renderLine && (
          <object type="image/svg+xml" style={{ height: 289, width: 56 }} data={isMedical ? LineMint : Line}></object>
        )}
      </div>
      <CounterTextWrapper inverted={inverted} additionalMarginLeft={storedCounterData.prefix === "+"}>
        <h3 className="counter">
          {storedCounterData.prefix}
          <span ref={countUpRef}></span>
          {storedCounterData.suffix}
        </h3>
        <span className="counter">{storedCounterData.description}</span>
      </CounterTextWrapper>
    </CounterWrapper>
  );
};

export default Counter;
