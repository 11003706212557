import styled from "styled-components";
import { media } from "../../styles/media";

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const CloseButtonWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 102px;
  height: 102px;
  margin-top: -15px;
  z-index: 9999999;
  ${media.up("NEW_LARGE_WEB")`
  width: 168px;
  height: 168px;
  `}

  ${media.down("NEW_TABLET")`
  width: 120px;
  height: 120px;
  margin-top: 50px;
  `}
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
export const PlaceholderImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const CloseButtonOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  display: flex;
  width: 904px;
  height: 504px;

  ${media.down("NEW_SMALL_WEB")`
  width: 768px;
  height: 427px;

  `}

  ${media.down("NEW_HALF_WEB")`
  width: 682px;
  height: 379px;

  `}

  ${media.down("NEW_TABLET")`
  width: 565px;
  height: 319px;

  `}

  ${media.down("NEW_LARGE_MOBILE")`
  width: 366px;
  height: 203px;
  `}

  ${media.down("NEW_MOBILE")`
  width: 328px;
  height: 185px;
  `}
`;

export const BorderElement = styled.div`
  position: absolute;
  margin-top: -5px;
  margin-left: -5px;
  width: 912px;
  height: 512px;
  border: 1px solid white;

  ${media.down("NEW_SMALL_WEB")`
  width: 776px;
  height: 435px;
  `}

  ${media.down("NEW_HALF_WEB")`
  width: 690px;
  height: 387px;
  `}

  ${media.down("NEW_TABLET")`
  width: 573px;
  height: 326px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  margin-top: -3px;
  margin-left: -3px;
  width: 370px;
  height: 208px;
  `}

  ${media.down("NEW_MOBILE")`
  width: 332px;
  height: 189px;
  `}
`;
