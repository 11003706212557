import { useEffect } from "react";
import ScrollTrigger from "../../../common/ScrollTrigger";
import gsap from "gsap";

const useWeAreExpertsAnimations = () => {
  useEffect(() => {
    const headingsTl = gsap.timeline().from(".weAreExpertsHeading", { scale: 0, y: 20, autoAlpha: 0, duration: 1 });
    // Scrolltrigger for heading animation
    ScrollTrigger.create({
      trigger: ".weAreExpertsContainer",
      start: "top bottom-=150px",
      end: "top+=100px bottom-=150px",
      animation: headingsTl,
    });

    const bottomTl = gsap
      .timeline()
      .from(".bottomHeading", { scale: 0, autoAlpha: 0 })
      .to(".animatedHeading", { color: "#0E1B1B", stagger: 0.5, cssText: " -webkit-text-stroke-width: 0px;" });
    // Scrolltrigger for bottom part animation
    ScrollTrigger.create({
      trigger: ".weAreExpertsBottomWrapper",
      start: "top bottom-=150px",
      end: "top+=100px bottom-=150px",
      animation: bottomTl,
    });
  }, []);
};

export default useWeAreExpertsAnimations;
