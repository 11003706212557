import styled, { css } from "styled-components";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import { media } from "../../shared/styles/media";
import Background from "./WhiteBlurBg.png";

export const WeAreExpertsContainer = styled.section<{ hideBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${p =>
    !p.hideBackground &&
    css`
      background-image: url(${Background});
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: 20%;
    `}

  ${media.down("NEW_LARGE_MOBILE")`
  background-position-y: 5%;
  `}

  ${media.down("FULL")`
  background-position-y: 40%;
  background-size: 100% 90%;
  `}
`;

export const WeAreExpertsOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
  position: relative;

  ${media.down("NEW_TABLET")`
  height: 750px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
  height: 600px;
  `}
`;

export const WeAreExpertsLeftLineWrapper = styled.div`
  position: absolute;
  right: 50%;
  top: 20%;
  transform: translateX(-200px);
  width: calc(50vw - 200px);
  svg {
    width: 100%;
  }
`;

export const WeAreExpertsRightLineWrapper = styled(WeAreExpertsLeftLineWrapper)`
  left: 50%;
  transform: translateX(200px);
`;

export const WeAreExpertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 15vh;
  width: 100%;

  ${media.down("LAPTOP")`
    top: 17vh;
  `}
  ${media.down("TABLET")`
    top: 25vh;
  `}
`;

export const WeAreExpertsHeadingLarge = styled.h1`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 112px;
  line-height: 104%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #0e1b1b;
  ${media.down("NEW_SMALL_WEB")`
    font-size: 104px;
   `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 96px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 80px;
  `} 
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 56px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 72px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 64px;
  `}
`;
export const WeAreExpertsHeadingMedium = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 104%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #0e1b1b;
  > span {
    color: #dd2736;
  }
  ${media.down("NEW_SMALL_WEB")`
    font-size: 56px;
   `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 48px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 48px;
  `} 
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 32px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 32px;
    max-width: 300px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 32px;

  `}
`;
export const WeAreExpertsHeadingSmall = styled.h3`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0e1b1b;
  ${media.down("NEW_SMALL_WEB")`
    font-size: 44px;
   `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 38px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 38px;
  `} 
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 28px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 24px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 24px;
  `}
`;

export const WeAreExpersVideoOuterWrapper = styled(SectionWrapper).attrs({ as: "div" })`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
`;

export const WeAreExpertsVideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  border-radius: 40px;
  position: relative;
  z-index: 9;
`;

export const WeAreExpertsVideo = styled.video`
  object-position: top;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 130px;
  margin-top: 64px;
`;

export const AnimatedHeadingsWrapper = styled(SectionWrapper).attrs({ as: "div" })`
  display: flex;
  justify-content: space-between;

  > :not(:first-child) {
    margin-left: 26px;
  }

  ${media.down("NEW_LARGE_MOBILE")`
    flex-direction: column;
    align-items: center;
    > :not(:first-child) {
      margin-left: 0px;
    }
  `}
`;

export const AnimatedHeading = styled.h1`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 120px;
  line-height: 112%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #c2b7b7;

  ${media.down("NEW_SMALL_WEB")`
    font-size: 104px;
   `}
  ${media.down("TABLET_LARGE")`
    font-size: 96px;
   `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 80px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 64px;
  `} 
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 56px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 72px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 72px;
  `}
`;
