import styled, { css } from "styled-components";
import { media } from "../../shared/styles/media";
import { UkasContainer } from "../Ukas/Ukas.components";

export const OurTeamContainer = styled(UkasContainer)`
  overflow: unset;
  display: block;
  padding-top: 0;

  @media all and (min-width: 1640px) {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${media.up("FULL")`
    padding-top: 260px;
  `}
`;

export const OurTeamFloatingText = styled.div`
  position: sticky;
  top: 256px;
  font-family: "Barlow Semi Condensed";
  font-weight: 500;
  font-size: 136px;
  line-height: 96%;
  letter-spacing: -0.02em;

  ${media.down("LAPTOP")`
    font-size: 104px;
    left: 144px;
  `}

  ${media.down("TABLET")`
    font-size: 84px;
  `}

  ${media.down("MOBILE_LARGE")`
    font-size: 70px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    top: unset;
    transform: translateY(100%);
  `}

  ${media.down("MOBILE_LARGE")`
    font-size: 70px;
  `}
`;

export const OurTeamFloatingTextWrapper = styled.div`
  position: relative;
`;

export const OurTeamFloatingTextContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 320px;
  bottom: 900px;
  left: 0px;
  background-color: pink;

  ${media.down("LAPTOP")`
    bottom: 700px;
    top: 280px;
    background: purple;
  `}

  ${media.down("TABLET_LARGE")`
    background: red;
    bottom: 650px;
  `}

  ${media.down("TABLET")`
    background: green;
    bottom: 650px;
  `}

  ${media.down("TABLET_SMALL")`
    background: yellow;
    bottom: 550px;
  `}

  ${media.down("MOBILE_LARGE")`
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    margin-top: 32px;
  `}

  background: transparent !important;
`;

export const OurTeamPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  position: relative;
  ${media.down("LAPTOP")`
      //margin-bottom: 1rem;
    `}
  ${media.down("TABLET_LARGE")`
      //margin-bottom: .5rem;
      margin-left: 0;
    `}
    ${media.down("TABLET")`
      margin-bottom: 0;
    `}

    @media all and (min-width: 1640px) {
    width: 1200px;
  }
`;

export const OurTeamPhotoContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const OurTeamPhoto = styled.div`
  position: relative;
  img {
    border-radius: 10px;
    ${media.down("LAPTOP")`
      width: 272px;
      height: 336px;
    `}

    ${media.down("TABLET_LARGE")`
      width: 240px;
      height: 304px;
    `}
    ${media.down("MOBILE_LARGE")`
      width: 45vw;
      height: auto;
    `}
  }
`;

export const OurTeamAlexPhoto = styled(OurTeamPhoto)`
  margin-top: -300px;
  margin-left: -80px;
  margin-bottom: 30px;
  ${media.down("LAPTOP")`
      margin-top: -170px;
      margin-left: -85px;
      margin-bottom: 0;
    `}

  ${media.down("TABLET_LARGE")`
      margin-left: -15px;
      margin-top: -120px;
    `} 
    ${media.down("TABLET")`
      margin-bottom: 0;
    `}
    ${media.down("TABLET_SMALL")`
      margin-top: -133px;
      margin-left: -173px;
    `}
    ${media.down("MOBILE_LARGE")`
    margin: 0;
      position: absolute;
      top: 8px;
      left: 0;
    `}
`;
export const OurTeamTomaszPhoto = styled(OurTeamPhoto)`
  margin-left: 400px;

  ${media.down("LAPTOP")`
    margin-left: 330px;
    `}
  ${media.down("TABLET_SMALL")`
    margin-left: 270px;
    `}
    ${media.down("MOBILE_LARGE")`
    margin: 0;
      position: absolute;
      right: 0;
      bottom: 0
    `}
`;

export const OurTeamTitle = styled.div<{ videoTitle?: boolean }>`
  margin-top: ${p => (p.videoTitle ? 0 : -180)}px;
  margin-bottom: ${p => (p.videoTitle ? 50 : 150)}px;
  transform: translateX(-20px);
  position: ${p => (p.videoTitle ? "static" : "sticky")};
  top: 40vh;
  z-index: 99;
  left: 200px;
  width: 284px;
  font-family: Barlow Semi Condensed;
  font-weight: 500;
  font-size: 136px;
  line-height: 96%;
  letter-spacing: -0.02em;

  ${p =>
    !p.videoTitle &&
    media.up("FULL")`
      margin-top: -200px;
  `}

  ${p =>
    !p.videoTitle &&
    media.down("LAPTOP")`
    margin-top: -280px;
    margin-bottom: 100px;
  `}

  ${media.down("LAPTOP")`
     font-size: 104px;
     left: 144px;
  `}

  ${media.down("TABLET")`
    font-size: 84px;
    left: 104px;
    margin-top: -150px;
    margin-bottom: 50px;
  `}

  ${media.down("TABLET_SMALL")`
    margin-top: -185px;
    margin-bottom: 20px;
  `}

  ${media.down("MOBILE_LARGE")`
    font-size: 70px;
    position: relative;
    margin: 0;
    left: 0;
    margin-top: -100px;
    margin-bottom: 30px;
    text-align: left;
    transform: translateY(-100%);
  `}

    ${p =>
    p.videoTitle &&
    css`
      ${media.down("TABLET")`
        margin-top: -80px;
        margin-bottom: 40px;
      `}

      ${media.down("TABLET_SMALL")`
        display:none;
      `}
    `}
`;

export const OurTeamText = styled.p`
  margin: 0;
  z-index: 5;
  background-color: #181a1b;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;

  color: rgba(182, 195, 195, 1);
`;

export const OurTeamAlexText = styled(OurTeamText)`
  font-size: 16px;
  width: 330px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  ${media.down("LAPTOP")`
    top: 50px;
    font-size: 14px;
  `};
  ${media.down("TABLET_LARGE")`
    width: 250px;
    left: 0;
  `}
  ${media.down("TABLET")`
    font-size: 12px;
    width: 40%;
    margin-left: 10%;
    top: 0;
  `}
  ${media.down("TABLET_SMALL")`
  width: 50%;
    margin-left: 0;
  `};
  ${media.down("MOBILE_LARGE")`
    background-color: #181a1b;
    margin-left: 0;
    font-size: 14px;
    margin-bottom: 40px;
  `};
`;

export const OurTeamAlexCircle = styled.div`
  position: absolute;
  width: 300px;
  top: 30%;
  left: 0;
  transform: translateX(-85%);
  ${media.down("LAPTOP")`
    top: 20%;
  `}
  ${media.down("TABLET_LARGE")`
    top: 40%;
  `}
  ${media.down("TABLET")`
    top: -5%;
    transform: scale(-1);
    width: 80px;
    left: -60px;
  `}
  ${media.down("MOBILE_LARGE")`
    transform: scale(0.4);
    top: unset;
    bottom: 40%;
    width: 150px;
    right: -85px;
    left: unset;
  `}
  ${media.down("MOBILE_TABLE")`
    bottom: 20%;
  `}
`;

export const TomaszCircle = styled.div`
  position: absolute;
  width: 130px;
  bottom: -50px;
  right: -30px;
  transform: translateX(50%);
  height: 203px;
  ${media.down("TABLET")`
    right: -50px;
    transform: scale(0.8);
  `}
  ${media.down("MOBILE_LARGE")`
    transform: scaleX(-1) scale(0.6);
    left: -65px;
    bottom: -70px;
  `}
  ${media.down("MOBILE_TABLE")`
    bottom: -90px;
  `}
`;

export const OurTeamTomaszText = styled(OurTeamText)`
  position: absolute;
  width: 32%;
  font-size: 16px;
  max-width: 430px;
  bottom: -80px;
  right: 0;
  ${media.down("LAPTOP")`
    font-size: 14px;
    bottom: -20px;
  `}
  ${media.down("TABLET")`
    font-size: 12px;
    width: 240px;
    bottom: -20px;
  `}
  ${media.down("MOBILE_LARGE")`
  font-size: 14px;
  `};
  @media all and (max-width: 950px) {
    width: 220px;
  }
  @media all and (max-width: 850px) {
    width: 200px;
  }

  ${media.down("MOBILE_LARGE")`
    position: static;
    width: 100%;
    margin-top: 8px;
  `}
`;

export const OurTeamTextTitle = styled.span`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  color: #f0f5f5;
  display: block;
  margin-bottom: 8px;
  ${media.down("TABLET")`
    font-size: 16px;
  `}
  ${media.down("MOBILE_LARGE")`
    max-width: 200px;
    font-size: 18px;
  `}
`;

export const OurTeamTextSubtitle = styled.span`
  display: block;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #b6c3c3;
  margin-bottom: 8px;

  ${media.down("TABLET_LARGE")`
    font-size: 14px;
  `}
  ${media.down("TABLET")`
    font-size: 12px;
  `}
  ${media.down("MOBILE_LARGE")`
    font-size: 14px;
  `}
`;

export const OurTeamPhotoCurtain = styled.div`
  position: absolute;
  top: -1%;
  left: -1%;
  height: 102%;
  width: 102%;
  background-color: #181a1b;
`;

export const OurTeamMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const OurTeamMobileTextContainer = styled(OurTeamAlexText)`
  width: 100%;
  position: static;
`;
