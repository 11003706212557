import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { PathOptions } from "../AutoDrawingSVG/AutoDrawingSVG";
import { DrawingSVGOnScrollWrapper } from "./AutoDrawingSVGOnScroll.components";
import ScrollTrigger from "../../../../common/ScrollTrigger";

const DrawingEllipse: FunctionComponent<{ trigger: string; pathOptions: PathOptions }> = ({ trigger, pathOptions }) => {
  const [length, setLength] = useState(0);
  const path = useRef<SVGPathElement>(null);
  const initialLength = useRef<number>(0);
  useEffect(() => {
    if (path && path.current) initialLength.current = path.current.getTotalLength();

    ScrollTrigger.create({
      trigger: trigger,
      start: "bottom bottom",
      end: "bottom top+=400",
      onUpdate: self => {
        setLength(initialLength.current * self.progress);
      },
    });
  }, []);

  return (
    <DrawingSVGOnScrollWrapper pathLength={initialLength.current} dashOffset={initialLength.current - length}>
      <svg
        preserveAspectRatio="none"
        width="498"
        height="149"
        viewBox="0 0 498 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path ref={path} {...pathOptions} />
      </svg>
    </DrawingSVGOnScrollWrapper>
  );
};

export default DrawingEllipse;
