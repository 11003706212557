import React, { useEffect, useState } from "react";
import {
  OurTeamTomaszText,
  OurTeamTextTitle,
  OurTeamTomaszPhoto,
  OurTeamAlexPhoto,
  OurTeamPartContainer,
  OurTeamAlexText,
  OurTeamAlexCircle,
  OurTeamMobileTextContainer,
  OurTeamMobileContainer,
  TomaszCircle,
  OurTeamPhotoCurtain,
} from "./OurTeam.components";
import { useInView } from "react-intersection-observer";

import AlexPhoto from "./assets/alex.png";
import AlexMobilePhoto from "./assets/AlexMobile.png";
import TomaszPhoto from "./assets/tomaszPhoto.png";
import TomaszMobilePhoto from "./assets/TomaszMobile.png";
import CircleTomasz from "./assets/CircleTomasz.svg";
import CircleAlex from "./assets/CircleAlex.svg";
import CircleAlexMobile from "./assets/CircleAlexMobile.svg";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { BREAKPOINTS } from "../../shared/styles/const";
import gsap from "gsap";
import { getImagesHeight } from "./OurTeamHeight.helpers";

const OurTeamAlexTomasz = () => {
  const [inViewRef, inView, entry] = useInView({ threshold: 0.2 });
  const [width] = useWindowSize();
  const [isFirstAnimation, setIsFirstAnimation] = useState(true);

  useEffect(() => {
    setIsFirstAnimation(true);
  }, [width]);

  const isMobileView = () => {
    return BREAKPOINTS.MOBILE_LARGE.max && width < BREAKPOINTS.MOBILE_LARGE.max;
  };
  useEffect(() => {
    if (entry && (isFirstAnimation || entry.boundingClientRect.top > 0)) {
      if (inView) {
        gsap
          .timeline()
          .to(".curtainAlex", { height: 0, duration: 0.5 }, isMobileView() ? 0.3 : 0)
          .to(".curtainTomasz", { height: 0, duration: 0.5 }, isMobileView() ? 0 : 0.3);

        if (!isMobileView()) {
          gsap
            .timeline()
            .to(".OurTeamTomaszText", { y: -30, duration: 1 })
            .to(".OurTeamAlexText", { y: -30, duration: 1 }, 0);
        }
      } else {
        gsap.to(".curtainAlex", { height: "100%", duration: 0.5 });
        gsap.to(".curtainTomasz", { height: "100%", duration: 0.5 });
        if (!isMobileView()) {
          gsap.to(".OurTeamAlexText", { y: 30, duration: 0.5 });
          gsap.to(".OurTeamTomaszText", { y: 30, duration: 0.5 });
        }
      }
    }
    if (isFirstAnimation) setIsFirstAnimation(false);
  }, [inView]);

  const getCircleForAlex = () => {
    if (BREAKPOINTS.TABLET.max && width > BREAKPOINTS.TABLET.max) return CircleAlex;
    else if (!isMobileView()) return CircleTomasz;
    else return CircleAlexMobile;
  };

  return (
    <OurTeamPartContainer style={{ marginTop: 80 }} ref={inViewRef}>
      {!isMobileView() ? (
        <>
          <OurTeamTomaszText className="OurTeamTomaszText">
            <OurTeamTextTitle>Development Director</OurTeamTextTitle>
            Leading sales and client-relationship management. Focused on tracking new markets and emerging trends.
            Recommends new products and services. Developing new strategic partnerships and plans.
          </OurTeamTomaszText>
          <OurTeamTomaszPhoto className="OurTeamTomaszPhoto">
            <img height={getImagesHeight(width)} loading="lazy" src={TomaszPhoto} alt="Tomasz" />
            <OurTeamPhotoCurtain className="curtainTomasz" />
            {inView && (
              <TomaszCircle>
                <object type="image/svg+xml" data={CircleTomasz}></object>
              </TomaszCircle>
            )}
          </OurTeamTomaszPhoto>
          <OurTeamAlexText className="OurTeamAlexText">
            <OurTeamTextTitle>Operations Director</OurTeamTextTitle>
            Business strategies guidelines implementation management and constant evaluation. Ensuring efficient working
            environment and deadlines are met consistently based on evaluation and coordination of internal
            organisation.
            <br />
            <br />
            All based on policies, goals, and objectives - structured.
          </OurTeamAlexText>
          <OurTeamAlexPhoto className="OurTeamAlexPhoto">
            <img height={getImagesHeight(width)} loading="lazy" src={AlexPhoto} alt="Alex" />
            <OurTeamPhotoCurtain className="curtainAlex" />
            {inView && (
              <OurTeamAlexCircle>
                <object type="image/svg+xml" data={getCircleForAlex()}></object>
              </OurTeamAlexCircle>
            )}
          </OurTeamAlexPhoto>
        </>
      ) : (
        <OurTeamMobileContainer>
          <OurTeamMobileTextContainer>
            <OurTeamTextTitle>Operations Director</OurTeamTextTitle>
            Business strategies guidelines implementation management and constant evaluation. Ensuring efficient working
            environment and deadlines are met consistently based on evaluation and coordination of internal
            organisation.
            <br />
            <br />
            All based on policies, goals, and objectives - structured.
          </OurTeamMobileTextContainer>
          <div style={{ position: "relative", width: "100%", height: width * 0.8 }}>
            <OurTeamAlexPhoto className="OurTeamAlexPhoto">
              <img height={getImagesHeight(width)} loading="lazy" src={AlexMobilePhoto} alt="Alex" />
              <OurTeamPhotoCurtain className="curtainAlex" />
              {inView && (
                <OurTeamAlexCircle>
                  <object type="image/svg+xml" data={getCircleForAlex()}></object>
                </OurTeamAlexCircle>
              )}
            </OurTeamAlexPhoto>
            <OurTeamTomaszPhoto>
              <img
                height={getImagesHeight(width)}
                loading="lazy"
                className="OurTeamTomaszPhoto"
                src={TomaszMobilePhoto}
                alt="Tomasz"
              />
              <OurTeamPhotoCurtain className="curtainTomasz" />
              {inView && (
                <TomaszCircle>
                  <object type="image/svg+xml" data={CircleTomasz}></object>
                </TomaszCircle>
              )}
            </OurTeamTomaszPhoto>
          </div>
          <OurTeamTomaszText>
            <OurTeamTextTitle>Development Director</OurTeamTextTitle>
            Leading sales and client-relationship management. Focused on tracking new markets and emerging trends.
            Recommends new products and services. Developing new strategic partnerships and plans.
          </OurTeamTomaszText>
        </OurTeamMobileContainer>
      )}
    </OurTeamPartContainer>
  );
};

export default OurTeamAlexTomasz;
