import styled from "styled-components";
import { media } from "../../shared/styles/media";

export const ServiceAndTimeSectionContainer = styled.section`
  background: #d8e3e3;
  position: relative;

  ${media.up("FULL")`
  max-width: 1200px;
  margin: 0 auto;
  `}
`;

export const ServiceAndTimeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const VideoContainer = styled.div`
  //position: relative;
  flex: 1;
  padding-bottom: 250px;
  position: sticky;
  top: 0;
  ${media.up("NEW_MAIN_WEB")`
    padding-bottom: 320px;
  `}

  ${media.down("TABLET")`
    padding-bottom: 150px
  `}

  ${media.down("NEW_TABLET")`
    padding-bottom: 170px
  `}
  @media all and (max-width: 750px) {
    padding-bottom: 270px;
  }
`;

export const VideoWrapper = styled.div`
  top: 0;
  width: 100%;
  padding-bottom: 350px;
  position: sticky;
  ${media.up("NEW_MAIN_WEB")`
    padding-bottom: 450px;
  `}
`;

export const VideoPlayer = styled.video<{ timeSection?: boolean }>`
  position: absolute;
  width: 530px;
  right: 160px;
  top: 210px;

  ${media.up("NEW_LARGE_WEB")`
    right: 250px;
  `}
  ${media.up("FULL")`
    right: 0px;
  `}

  ${p => media.down("NEW_SMALL_WEB")`
    width: 447px;
    left: unset;
    right: 100px;
    top: ${p.timeSection ? "110px" : "150px"};
  `}

  ${media.down("TABLET")`
    padding-bottom: 150px
  `}


  ${media.down("NEW_HALF_WEB")`
    width: 390px;
    right: 40px;
  `}


  ${media.down("TABLET")`
    right: 80px;
  `}

  ${media.down("TABLET_SMALL")`
    right: 40px;
  `}

  @media all and (max-width: 750px) {
    margin-top: 100px;
    right: 0;
  }
  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    margin-top: 0;
    right: 20px;
  `};
`;

// Base styles for headings & descriptions

export const ServicesBaseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //padding-left: 200px;
  padding-top: 150px;
  ${media.down("FULL")`
    //padding-left: 360px;
  `}

  ${media.down("MAIN_WEB")`
    padding-left: 200px;
  `}

  ${media.down("LAPTOP")`
    padding-left: 144px;
  `}

  ${media.down("TABLET")`
  padding-top: 120px;
  `}

  ${media.down("NEW_HALF_WEB")`
    padding-left: 96px;
    padding-top: 120px;
  `}

  ${media.down("NEW_TABLET")`
    padding-left: 80px;
  `}
  ${media.down("MOBILE_LARGE")`
    padding-left: 48px;
  `}
  
  ${media.down("MOBILE_SMALL")`
    padding-left: 40px;
  `}
`;

export const ServicesBaseHeader = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: #0e1b1b;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 84px;
  `}
  ${media.down("NEW_SMALL_WEB")`
    font-size: 56px;
  `}

  ${media.down("NEW_HALF_WEB")`
    font-size: 48px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    font-size: 56px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 48px;
  `}
  
  ${media.down("NEW_MOBILE")`
    font-size: 36px;
  `}
`;
export const ServicesBaseDescription = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 145%;
  color: #374343;
  margin-top: 12px;
  ${media.up("NEW_LARGE_WEB")`
    font-size: 24px;
    max-width: 640px;
  `}
  ${media.down("NEW_SMALL_WEB")`
    font-size: 16px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    font-size: 18px;
    max-width: 400px;
    white-space: normal;
  `}

  ${media.down("NEW_MOBILE")`
    font-size: 16px;
  `}
`;
