import styled from "styled-components";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import { ClientOpinionsHeader, ClientOpinionsWrapper } from "../ClientOpinions/ClientOpinions.components";

export const CourierClientOpinionsWrapper = styled(SectionWrapper)`
  ${ClientOpinionsHeader} {
    /* Text Dark Second 2 */

    color: #514d4d;
  }

  ${ClientOpinionsWrapper} {
    margin-top: 72px;
  }
`;
