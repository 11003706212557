// @ts-nocheck
import React, { FunctionComponent, useRef, useMemo } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import { OrbitControls, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import PackageTexture from "../Package/PackageTexture.jpg";
import { isMobile } from "react-device-detect";
import useAnimationController from "../Package/animations/useAnimationController";
import { Animation } from "../Package/animations/animation";
type InGoodHandsPackageProps = {
  //animationState: Animation;
  inView: boolean;
  leftBox?: boolean;
};
const InGoodHandsPackage: FunctionComponent<InGoodHandsPackageProps> = ({ inView, leftBox, ...props }) => {
  const crown = useRef();
  const group = useRef();
  const texture = useTexture(PackageTexture);
  const { scene, animations } = useGLTF("/ModelBox.glb", "https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
  const copiedScene = useMemo(() => scene.clone(), [scene]);
  const { actions } = useAnimations(animations, group);

  useAnimationController(actions, Animation.BOX_CLOSE);

  const bakedMaterial = new THREE.MeshBasicMaterial({ map: texture });

  texture.flipY = false;
  copiedScene.traverse(child => {
    child.material = bakedMaterial;
  });

  const DisableRender = () => useFrame(() => null, 1000);

  const AbleRender = () =>
    useFrame((state, delta) => {
      if (group.current) {
        group.current.rotation.y -= 0.6 * delta;
      }
    });

  return (
    <>
      {inView ? <AbleRender /> : <DisableRender />}
      <group {...props} ref={group} dispose={null}>
        <primitive
          ref={crown}
          object={copiedScene}
          scale={1}
          rotation={[leftBox ? -0.2 : 0.3, leftBox ? 0.2 : -0.4, 0]}
        />
        <meshStandardMaterial attach="material" map={texture} />
        {/* <ambientLight color="#FF0000" /> */}
        {!isMobile && (
          <OrbitControls
            enablePan={true}
            enableZoom={false}
            enableRotate={true}
            maxZoom={0.1}
            panSpeed={0.01}
            enableDamping={true}
            zoomSpeed={0.1}
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
          />
        )}
      </group>
    </>
  );
};

useGLTF.preload("/ModelBox.glb", "https://www.gstatic.com/draco/versioned/decoders/1.4.3/");

export default InGoodHandsPackage;
