import styled from "styled-components";
import { media } from "../../styles/media";

export const ScaledContentWrapper = styled.div<{ zoom?: number }>`
  zoom: ${p => p.zoom || 1};
  transform-origin: 0 0;
  ${media.up("FULL")`
        transform-origin: 0 0;
    `}
  ${media.down("MAIN_WEB")`
        zoom: unset;
    `}
`;
