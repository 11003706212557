import styled from "styled-components";
import { media } from "../../shared/styles/media";
import CellsBg from "./CellsBackground.png";
import { Canvas } from "@react-three/fiber";

export const InNumbersContainer = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  height: 2000px;
`;

export const InNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0vh 205px 30vh;
  z-index: 20000;

  ${media.up("NEW_LARGE_WEB")`
  padding-left: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  `}

  ${media.down("TABLET_LARGE")`
    padding: 0px 96px 300px;
  `}

  ${media.down("MOBILE_LARGE")`
    padding:0;
    width: 100%;
  `}
`;

export const CanvasWrapper = styled.div`
  display: -ms-flexbox;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const InNumbersHeading = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: #0e1b1b;
  width: 461px;
  margin-top: 96px;
  position: sticky;
  bottom: 0;

  @media all and (max-height: 800px) {
    margin-top: 30px;
  }

  ${media.down("LAPTOP")`
    font-size: 64px;
    width: 421px;
    
  `}

  ${media.down("TABLET_LARGE")`
    font-size: 56px;
    margin-top:184px;
    width: 381px;
  `}

  ${media.down("MOBILE_LARGE")`
    display: flex;
    justify-content:center;
    text-align: center;
    align-self: center;
    margin-top: 40px;
  `}

  ${media.down("MOBILE")`
    font-size: 48px;
    width: 260px;
  `}
`;

export const CountersWrapper = styled(CanvasWrapper)`
  z-index: 5000;
`;

export const CounterRowWrapper = styled.div<{ isMedical?: boolean }>`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  padding: 20vh 205px 50vh;

  ${media.up("NEW_LARGE_WEB")`
    max-width: 1200px;
    margin: auto;
  `}

  ${media.down("TABLET_LARGE")`
    padding: 30vh 68px 50vh;
  `}
  
  ${media.down("MOBILE_LARGE")`
    padding: 23vh 32px 17vh;
    flex-direction: column-reverse;
  `}

  ${p => media.down("MOBILE_LARGE")`
    padding-top: ${p.isMedical ? 20 : 23}vh;
  `}
`;

export const CellsCanvas = styled(Canvas)`
  width: 100%;
  height: 100%;
  position: sticky;
  top: 0;
  background-image: url(${CellsBg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 110%;
`;
