export enum Animation {
  IDLE = "idle",
  BOX_OPEN = "BoxOpen",
  BOX_CLOSE = "BoxClose",
  BOX_MOVE_X = "BoxMoveX",
  BLOOD_IN = "InBlood",
  BLOOD_OUT = "OutBlood",
  PILLS_IN = "InPills",
  PILLS_OUT = "OutPills",
  SWABS_IN = "InSwabs",
  SWABS_OUT = "OutSwabs",
  XRAY_IN = "InXRay",
  XRAY_OUT = "OutXRay",
  SAMPLES_IN = "InSamples",
  SAMPLES_OUT = "OutSamples",
  TISSUES_IN = "InTissues",
  TISSUES_OUT = "OutTissues",
}
