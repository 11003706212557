import React, { FunctionComponent, useEffect, useMemo } from "react";
import { BouncedBoxInsideWrapper } from "../BouncedShape.components";
import ScrollTrigger from "../../../../../common/ScrollTrigger";
import gsap from "gsap";
import {
  BouncedBoxTopWithTransparency,
  BouncedBoxWithTransparency,
  BouncedShapeWithTransparencyWrapper,
} from "./BouncedShapeWithTransparency.components";

//PARENT MUST HAVE POSITION: RELATIVE

const BouncedShapeWithTransparency: FunctionComponent<{
  trigger: string;
  topShapeBackground: string;
  bottomShapeBackground: string;
  inBottomOfSection?: boolean;
  inTopOfSection?: boolean;
}> = ({ trigger, topShapeBackground, bottomShapeBackground, inBottomOfSection, inTopOfSection }) => {
  const topShapeName = useMemo(() => trigger + "topShape", []);
  const bottomShapeName = useMemo(() => trigger + "bottomShape", []);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: `.${trigger}`,
      start: "bottom-=100 bottom",
      end: "bottom+=4 center",

      scrub: 1,
      id: `${bottomShapeName}Trigger`,
      onUpdate: self => {
        if (self.progress < 1) {
          gsap.timeline().to(`.${bottomShapeName}`, {
            display: "block",
            borderRadius: `0 0 ${(1 - self.progress) * 100}% ${(1 - self.progress) * 100}%`,
            y: (1 - self.progress) * 100,
            duration: 0,
          });
        }
      },
      onLeave: () => {
        gsap.to(`.${bottomShapeName}`, { display: "none", duration: 0 });
      },
      onLeaveBack: () => {
        gsap.to(`.${bottomShapeName}`, { display: "none", duration: 0 });
      },
    });

    ScrollTrigger.create({
      trigger: `.${trigger}`,
      scrub: 1,
      start: "bottom center",
      end: "bottom 0%",
      id: `${topShapeName}Trigger`,
      onEnter: () => {
        gsap.killTweensOf(`.${topShapeName}`);
        gsap.to(`.${topShapeName}`, { zIndex: 999999, duration: 0 });
      },
      onEnterBack: () => {
        gsap.killTweensOf(`.${topShapeName}`);
        gsap.to(`.${topShapeName}`, { zIndex: 99999, duration: 0 });
      },
      onUpdate: self => {
        gsap.timeline().to(`.${topShapeName}`, {
          display: "block",
          opacity: 1,
          borderRadius: `${self.progress * 100}% ${self.progress * 100}% 0 0`,
          y: self.progress * -100,
          duration: 0,
        });
      },
      onLeave: () => {
        gsap.to(`.${topShapeName}`, { opacity: 0, duration: 0 });
      },
      onLeaveBack: () => {
        gsap.to(`.${topShapeName}`, { opacity: 0, duration: 0 });
      },
    });
  }, []);

  return (
    <BouncedShapeWithTransparencyWrapper inTopOfSection={inTopOfSection} inBottomOfSection={inBottomOfSection}>
      <BouncedBoxInsideWrapper className="bouncedBoxWrapper" style={{ overflow: "hidden" }}>
        <BouncedBoxTopWithTransparency
          style={{
            boxShadow: "0 0 0 500px " + topShapeBackground,
            backgroundColor: bottomShapeBackground,
          }}
          className={bottomShapeName}
        />
        <BouncedBoxWithTransparency
          style={{
            boxShadow: "0 0 0 500px " + bottomShapeBackground,
            backgroundColor: topShapeBackground,
          }}
          className={topShapeName}
        />
      </BouncedBoxInsideWrapper>
    </BouncedShapeWithTransparencyWrapper>
  );
};

export default BouncedShapeWithTransparency;
