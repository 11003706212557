import React, { useEffect, useState } from "react";
import {
  OurTeamAlexPhoto,
  OurTeamMobileContainer,
  OurTeamPartContainer,
  OurTeamTextSubtitle,
  OurTeamTextTitle,
  OurTeamTomaszPhoto,
  TomaszCircle,
  OurTeamPhotoCurtain,
} from "./OurTeam.components";
import DilPhoto from "./assets/Dil.png";
import DilMobilePhoto from "./assets/DilMobile.png";
import MagdaPhoto from "./assets/Magda.png";
import MagdaMobilePhoto from "./assets/MagdaMobile.png";
import FaisalPhoto from "./assets/Faisal.png";
import FaisalMobilePhoto from "./assets/FaisalMobile.png";
import CircleFisal from "./assets/CircleFaisal.svg";
import CircleTomasz from "./assets/CircleTomasz.svg";
import CircleAlex from "./assets/CircleAlex.svg";
import CircleMagdaDin from "./assets/CircleMagdaDin.svg";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { BREAKPOINTS } from "../../shared/styles/const";
import gsap from "gsap";
import {
  OurTeamFaisalPhoto,
  OurTeamFaisalDescription,
  OurTeamFaisalCircle,
  OurTeamFaisalTextContainer,
  OurTeamFisalText,
  OurTeamPartMagdaDinContainer,
  OurTeamDilMagdaCircle,
  OurTeamMagdaDinText,
  OurTeamMagdaDilMobileText,
} from "./OurTeamMiddlePart.components";
import { getImagesHeight } from "./OurTeamHeight.helpers";

const OurTeamMiddlePart = () => {
  const [inViewRef, inView, entry] = useInView({ threshold: 0.6 });
  const [inViewMagdaRef, inViewMagda, entryMagda] = useInView({ threshold: 0.3 });
  const [isFirstFaisalAnimation, setIsFirstFaisalAnimation] = useState(true);
  const [isFirstMagdaDilAnimation, setIsFirstMagdaDilAnimation] = useState(true);
  const [width] = useWindowSize();
  const isMobileView = () => {
    return BREAKPOINTS.MOBILE_LARGE.max && width <= BREAKPOINTS.MOBILE_LARGE.max;
  };

  useEffect(() => {
    setIsFirstFaisalAnimation(true);
    setIsFirstMagdaDilAnimation(true);
  }, [width]);

  useEffect(() => {
    if (entry && (isFirstFaisalAnimation || entry.boundingClientRect.top > 0)) {
      if (inView) {
        gsap.timeline().to(".curtainFaisal", { height: 0, duration: 0.7 }).to(".OurTeamFaisalText", { y: -50 }, 0.2);
        if (isFirstFaisalAnimation) setIsFirstFaisalAnimation(false);
      } else {
        gsap.to(".curtainFaisal", { height: "100%" });
        gsap.to(".OurTeamFaisalText", { y: 50, duration: 0 });
      }
    }
  }, [inView]);

  useEffect(() => {
    if (entryMagda && (isFirstMagdaDilAnimation || entryMagda.boundingClientRect.top > 0)) {
      if (inViewMagda) {
        if (isMobileView()) {
          gsap
            .timeline()
            .to(".curtainMagda", { height: 0, duration: 0.5 })
            .to(".curtainDil", { height: 0, duration: 0.5 }, 0.3);
        } else {
          gsap
            .timeline()
            .to(".curtainDil", { height: 0, duration: 0.5 })
            .to(".curtainMagda", { height: 0, duration: 0.5 }, 0.3);
        }
        gsap.to(".OurTeamMagdaDilText", { y: -80 });
        if (isFirstMagdaDilAnimation) setIsFirstMagdaDilAnimation(false);
      } else {
        gsap.to(".curtainMagda", { height: "100%", duration: 0.5 });
        gsap.to(".curtainDil", { height: "100%", duration: 0.5 });
        gsap.to(".OurTeamMagdaDilText", { y: 80, duration: 0.5 });
      }
    }
  }, [inViewMagda]);

  return (
    <>
      <OurTeamPartContainer>
        <OurTeamFaisalPhoto>
          <img
            loading="lazy"
            className="OurTeamFaisalPhoto"
            ref={inViewRef}
            src={isMobileView() ? FaisalMobilePhoto : FaisalPhoto}
            alt="Medical Director"
          />
          <OurTeamPhotoCurtain className="curtainFaisal" />

          <OurTeamFaisalDescription>
            {inView && (
              <OurTeamFaisalCircle>
                <object type="image/svg+xml" data={isMobileView() ? CircleTomasz : CircleFisal}></object>
              </OurTeamFaisalCircle>
            )}
            <OurTeamFaisalTextContainer className="OurTeamFaisalText">
              <OurTeamTextSubtitle>Mission</OurTeamTextSubtitle>
              <OurTeamTextTitle>Enhance patient care &&nbsp;improve healthcare practice.</OurTeamTextTitle>
              <OurTeamFisalText>
                Responsible for participant care, clinical outcomes. The implementation and oversight of the quality
                assessment and performance improvement program.
              </OurTeamFisalText>
            </OurTeamFaisalTextContainer>
          </OurTeamFaisalDescription>
        </OurTeamFaisalPhoto>
      </OurTeamPartContainer>
      {!isMobileView() ? (
        <OurTeamPartMagdaDinContainer ref={inViewMagdaRef}>
          <OurTeamTomaszPhoto>
            <div style={{ position: "relative" }}>
              <img
                height={getImagesHeight(width)}
                loading="lazy"
                className="OurTeamMagdaPhoto"
                src={MagdaPhoto}
                alt="Magda"
              />
              <OurTeamPhotoCurtain className="curtainMagda" />
            </div>
            {inViewMagda && BREAKPOINTS.TABLET_SMALL.max && width <= BREAKPOINTS.TABLET_SMALL.max && (
              <OurTeamDilMagdaCircle>
                <object type="image/svg+xml" data={CircleAlex} />
              </OurTeamDilMagdaCircle>
            )}
            <OurTeamMagdaDinText className="OurTeamMagdaDilText">
              <OurTeamTextSubtitle>Mission</OurTeamTextSubtitle>
              <OurTeamTextTitle>Provide a high-quality service FOR YOU </OurTeamTextTitle>
              <OurTeamFisalText>
                Provides inspired leadership for the organization and manages the day to day operations activity within
                Medical Logistics operations/logistics.
              </OurTeamFisalText>
            </OurTeamMagdaDinText>
          </OurTeamTomaszPhoto>
          <OurTeamAlexPhoto>
            <img height={getImagesHeight(width)} loading="lazy" className="OurTeamDilPhoto" src={DilPhoto} alt="Dil" />
            <OurTeamPhotoCurtain className="curtainDil" />
            {inViewMagda && BREAKPOINTS.TABLET_SMALL.max && width > BREAKPOINTS.TABLET_SMALL.max && (
              <OurTeamDilMagdaCircle>
                <object type="image/svg+xml" data={CircleMagdaDin} />
              </OurTeamDilMagdaCircle>
            )}
          </OurTeamAlexPhoto>
        </OurTeamPartMagdaDinContainer>
      ) : (
        <>
          <OurTeamMobileContainer style={{ marginTop: 0 }}>
            <div style={{ position: "relative", width: "100%", height: width * 0.8 }}>
              <OurTeamAlexPhoto style={{ zIndex: 5555 }} className="OurTeamAlexPhoto">
                <img
                  height={getImagesHeight(width)}
                  loading="lazy"
                  className="OurTeamDilPhoto"
                  src={DilMobilePhoto}
                  alt="Dil"
                />
                <OurTeamPhotoCurtain className="curtainDil" />
              </OurTeamAlexPhoto>
              <OurTeamTomaszPhoto ref={inViewMagdaRef}>
                <img
                  height={getImagesHeight(width)}
                  loading="lazy"
                  className="OurTeamMagdaPhoto"
                  src={MagdaMobilePhoto}
                  alt="Magda"
                />
                <OurTeamPhotoCurtain className="curtainMagda" />
                {inViewMagda && (
                  <TomaszCircle>
                    <object type="image/svg+xml" data={CircleTomasz}></object>
                  </TomaszCircle>
                )}
              </OurTeamTomaszPhoto>
            </div>
            <OurTeamMagdaDilMobileText
              style={{ width: "60%", minWidth: 200, margin: "8px auto" }}
              className="OurTeamMagdaDilText"
            >
              <OurTeamTextSubtitle>Mission</OurTeamTextSubtitle>
              <OurTeamTextTitle>Provide a high-quality service FOR YOU </OurTeamTextTitle>
              Business strategies guidelines implementation management and constant evaluation. Ensuring efficient
              working environment and deadlines are met consistently based on evaluation and coordination of internal
              organisation.
            </OurTeamMagdaDilMobileText>
          </OurTeamMobileContainer>
        </>
      )}
    </>
  );
};

export default OurTeamMiddlePart;
