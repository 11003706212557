import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
		margin: 0;
    padding:0;
    color: white;
    background: #181a1b;
    width: 100vw;
    overflow-x: hidden !important;
    font-family: 'Barlow Semi Condensed', sans-serif;
    -webkit-tap-highlight-color: transparent;
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #181a1b inset !important;
        -webkit-text-fill-color: #f0f5f5 !important;
    }
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            height: 100%;
            width: 100%;
            overflow-x: hidden;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  
`;

export default GlobalStyle;
