import React, { Suspense, useEffect, useState, FunctionComponent } from "react";
import CellsMesh from "./CellsMesh";
import { Canvas } from "@react-three/fiber";
import { CanvasWrapper, InNumbersContainer, CounterRowWrapper, CountersWrapper } from "./InNumbers.components";
import Counter from "./Counter/Counter";
import ScrollTrigger from "../../../common/ScrollTrigger";
import CellsBg from "./CellsBackground.png";
import VirusBg from "./VirusBackground.png";
import { useIsMobileLargeDown } from "../../../common/hooks/useMedia";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CompanyRoutes, CouriersRoutes } from "../../../config/routes";
import { useInView } from "react-intersection-observer";
import { Environment } from "@react-three/drei";
import gsap from "gsap";
// import useDpr from "../../../common/hooks/useDpr";

type InNumbersProps = {
  lightSectionInView: boolean;
  courierLanding?: boolean;
  isMedical?: boolean;
};

const InNumbers: FunctionComponent<InNumbersProps> = ({ lightSectionInView, courierLanding, isMedical }) => {
  const [inViewRef, inView] = useInView();
  const [isHalfway, setIsHalfway] = useState(false);
  const [initialTriggerPassed, setInitialTriggerPassed] = useState(false);
  const isMobileLargeDown = useIsMobileLargeDown();
  let webTrigger: any;

  useEffect(() => {
    webTrigger = ScrollTrigger.create({
      trigger: ".in-numbers",
      start: `top-=5% center`,
      end: `top-=5% center`,
      onEnter: () => setInitialTriggerPassed(true),
      onEnterBack: () => setInitialTriggerPassed(false),
    });

    ScrollTrigger.create({
      trigger: ".in-numbers",
      start: "center center",
      end: "center center",
      id: "switch",
      onEnter: () => setIsHalfway(true),
      onEnterBack: () => setIsHalfway(false),
    });
  }, []);

  useEffect(() => {
    if (webTrigger) {
      webTrigger.refresh();
    }
  }, [isMobileLargeDown]);

  useEffect(() => {
    if (inView) {
      gsap.to(".inNumbersHeading", { scale: 1, duration: 1 });
    } else {
      gsap.to(".inNumbersHeading", { scale: 0.5, duration: 1 });
    }
  }, [inView]);
  return (
    <InNumbersContainer className="in-numbers" ref={inViewRef}>
      {courierLanding ? (
        <SEOMarker routeInfo={CouriersRoutes.ourNumbers} />
      ) : (
        <SEOMarker routeInfo={CompanyRoutes.inNumbers} offset={-250} />
      )}
      <CountersWrapper>
        <CounterRowWrapper isMedical={isMedical}>
          {courierLanding ? (
            <>
              <Counter
                initialTriggerPassed={initialTriggerPassed}
                value={!isHalfway ? 10 : 100}
                suffix={!isHalfway ? "" : "%"}
                prefix={!isHalfway ? "+" : ""}
                description={
                  !isHalfway ? "Years of experience in the medical industry " : "Positive feedback from all our clients"
                }
                inverted={isMobileLargeDown}
              />
              <Counter
                initialTriggerPassed={initialTriggerPassed}
                value={!isHalfway ? 98 : 100000}
                prefix={!isHalfway ? "" : "+"}
                suffix={!isHalfway ? "%" : ""}
                description={!isHalfway ? "On-time collections" : "Shipments delivered on time"}
                inverted={!isMobileLargeDown}
              />
            </>
          ) : (
            <>
              <Counter
                initialTriggerPassed={initialTriggerPassed}
                value={!isHalfway ? 100 : 5000}
                suffix={!isHalfway ? "%" : ""}
                prefix={!isHalfway ? "" : "+"}
                description={!isHalfway ? "Positive feedback from all our clients" : "Home visits"}
                inverted={isMobileLargeDown}
                isMedical={isMedical}
              />
              <Counter
                initialTriggerPassed={initialTriggerPassed}
                value={!isHalfway ? 10 : 100000}
                prefix="+"
                suffix=""
                description={!isHalfway ? "Years of experience in the medical industry" : "Shipments delivered on time"}
                inverted={!isMobileLargeDown}
                isMedical={isMedical}
              />
            </>
          )}
        </CounterRowWrapper>
      </CountersWrapper>
      <CanvasWrapper>
        <Canvas
          id="myCanvas"
          style={{
            width: "100%",
            height: "100vh",
            position: "sticky",
            top: 0,
            backgroundImage: `url(${isMedical ? VirusBg : CellsBg})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPositionY: isMobileLargeDown ? "center" : "100%",
          }}
          // set dpr to 1 to avoid screen blocking
          dpr={1}
        >
          <Suspense fallback={null}>
            <CellsMesh isInView={lightSectionInView} isMedical={isMedical} />
            {lightSectionInView && (
              <>
                <ambientLight color={isMedical ? "#04100c" : "#ecffff"} intensity={0.7} />
                {isMedical && <Environment preset="sunset" />}
              </>
            )}
          </Suspense>
        </Canvas>
      </CanvasWrapper>
    </InNumbersContainer>
  );
};

export default React.memo(InNumbers);
