import styled from "styled-components";
import { media } from "../../shared/styles/media";
import Background from "./bgrect.png";
import BackgroundBottomImage from "./bgbottom.svg";
import BackgroundTopImage from "./bgtop.svg";

export const GoGreenContainer = styled.div`
  height: 120vh;
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: url(${Background});
  background-size: cover;
  background-repeat: no-repeat; //cover;
  background-position: center;
  background-clip: border-box;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 200px 360px 200px 360px;
  ${media.down("FULL")`
    padding: 260px 360px 200px 360px;
  `}

  ${media.down("MAIN_WEB")`
    padding: 200px;
  `}

  ${media.down("LAPTOP")`
    padding: 200px 144px 200px 144px;
  `}
  ${media.down("TABLET")`
    padding: 80px 104px 50px 104px;
  `}
  ${media.down("TABLET_SMALL")`
    padding: 70px 96px 30px 96px;
  `}

  ${media.down("MOBILE_LARGE")`
      padding: 50px 48px 80px 48px;
  `}
  ${media.down("MOBILE")`
      padding: 50px 48px 0 48px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding: 50px 40px 0px 40px;
  `}
`;

export const GoGreenInnerContainer = styled.div<{ maxWidth: number; height: number }>`
  position: relative;
  width: 100%;
  height: ${p => p.height + "px"};
  max-width: ${p => p.maxWidth + 300 + "px"};

  ${media.up("FULL")`
    max-width: 1200px;
  `}
`;

export const GoGreenHeadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  margin-top: -7%;
  z-index: 5;

  ${media.down("TABLET_SMALL")`
   transform: translateY(-100%);
   margin-top: -25px;
  `}
`;

export const GoGreenHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 116px;
  line-height: 96%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  max-width: 100px;
  color: #f0f5f5;

  ${media.down("LAPTOP")`
    font-size: 104px;
  `}

  ${media.down("TABLET")`
    font-size: 84px;
  `}

  ${media.down("TABLET_SMALL")`
    max-width: 600px;
  `}
`;

export const GoGreenDescription = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #b6c3c3;
  margin-left: 9px;
  margin-top: 8px;
`;

export const EcoBadge = styled.img`
  position: absolute;
  width: 30%;
  z-index: 9;
  right: -28px;
  top: -5px;
  transform: translate(7%, -7%);

  ${media.down("TABLET_LARGE")`
  transform: translate(5%, -7%);
  `}

  ${media.down("TABLET")`
  transform: translate(3%, -7%);
  `}
  ${media.down("TABLET_SMALL")`
  transform: translate(1%, -7%);
  `}
  @media all and (max-width: 750px) {
    transform: translate(-2%, -7%);
  }

  ${media.down("MOBILE_LARGE")`
  right: unset;
  left: -38px;
  top: -18px;
  width: 200px;
  transform: unset;
  `}
  ${media.down("MOBILE_TABLE")`
  left: -30px;
  top: -14px;
  width: 160px;
  transform: unset;
  `}
`;

export const GoGreenCanvasWrapper = styled.div<{ width: number; height: number }>`
  position: absolute;
  width: ${p => p.width + "px"};
  height: ${p => p.height + "px"};
  left: 50%;
  transform: translateX(-50%);
`;

export const LeftPointerWrapper = styled.div`
  position: absolute;
  z-index: 99999;
  left: 0;
  bottom: 12%;
`;

export const CircleWrapper = styled.div`
  ${media.down("LAPTOP")`
    >svg {
      width: 80px;
      height: 80px;
    }
  `}

  ${media.down("TABLET")`
    >svg {
      width: 56px;
      height: 56px;
    }
  `}
`;

export const LeftArrowWrapper = styled.div`
  //position: absolute;
  display: flex;
  width: fit-content;
  position: relative;
  //left: -230px;
  //top: -20px;
  ${CircleWrapper} {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(65%, 65%);
  }

  > span {
    white-space: nowrap;
    margin-top: -10px;
    margin-right: 11px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: #b6c3c3;
  }

  ${media.down("LAPTOP")`
    
    > span {
      white-space: normal;
      max-width: 105px;
      margin-top: -35px;
      margin-right: -20px;
    }
  `}

  ${media.down("TABLET")`
  flex-direction: row;

    > span {
      white-space: nowrap;
      margin-top: -10px;
      margin-right: 11px;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: #B6C3C3;
    }
  `}

  ${media.down("TABLET_SMALL")`

    > span {
      white-space: normal;
      max-width: 50px;
      margin-top: -30px;
    }
  `}
`;

export const RightPointerWrapper = styled.div`
  /* position: absolute;
  right: 28px;
  bottom: 134px; */
  position: absolute;
  right: 0;
  top: 50%;
  ${media.down("LAPTOP")`
  `}

  ${media.down("TABLET_SMALL")`
   bottom: -45px;
   top: unset;
    transform: scaleY(-1);
  `}
`;

export const RightArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${CircleWrapper} {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 100%);
  }

  > span {
    white-space: nowrap;
    margin-top: -52px;
    margin-left: 99px;
    margin-right: 11px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: #b6c3c3;

    ${media.down("TABLET_SMALL")`
      white-space: wrap;
      transform: rotate(-180deg) scaleX(-1);
  `}
  }

  ${media.down("TABLET")`
    > span {
      white-space: wrap;
    }
  `}
`;

export const BGBottom = styled.div<{ imageUrl?: string }>`
  height: 146px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(${p => p.imageUrl || BackgroundBottomImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(20%);
  ${media.down("MOBILE_LARGE")`
  height: 40px;
  transform: translateY(1);
  `}
`;

export const BGTop = styled.div<{ imageUrl?: string }>`
  height: 88px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(${p => p.imageUrl || BackgroundTopImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-15%);

  ${media.down("FULL")`
   height: 100px;
  `}

  ${media.down("LAPTOP")`
   height: 62px;
  `}
`;
