import styled, { css } from "styled-components";
import { media } from "../../../shared/styles/media";

interface TextContainerProps {
  hasFooter: boolean;
}

export const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 472px;
  width: 560px;
  justify-content: center;
  align-items: center;

  ${media.down("MAIN_WEB")`
  width: 476px;
  height: 391px;
  
  `}

  ${media.down("LAPTOP")`
  width: 424px;
  height: 360px;
  
  `}

  ${media.down("TABLET_LARGE")`
  width: 338px;
  height: 308px;
  

  `}

  ${media.down("TABLET_SMALL")`
  width: 448px;
  height: 336px;
  

  `}

  ${media.down("MOBILE_MLARGE")`
  width: 448px;
  height: 336px;

  `}

  ${media.down("MOBILE")`
  width: 320px;
  height: 353px;
  

  `}

  ${media.down("MOBILE_SMALL")`
  width: 294px;
  height: 312px;
  

  `}
`;

export const TextContentContainer = styled.div<TextContainerProps>`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 225px;
  margin-bottom: auto;

  ${media.down("MAIN_WEB")`
  font-size: 18px;
  margin-top: 175px;
  `}

  ${media.down("LAPTOP")`
  margin-top: 175px;
  max-width: 320px;

  `}

  ${media.down("TABLET_LARGE")`
  max-width: 272px;

  margin-top: 155px;
  `}

  ${media.down("TABLET_SMALL")`
  max-width: 386px;

  margin-top: 145px;
  `}

  ${media.down("MOBILE")`
  max-width: 290px;

  margin-top: 160px;
  `}

  ${media.down("MOBILE_SMALL")`
  max-width: 270px;
  margin-top: 130px;
  `}
`;

export const ProductCardTitle = styled.h3`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #f0f5f5;
  margin: 0;
  margin-bottom: 16px;

  ${media.down("MAIN_WEB")`
  font-size: 30px;

  `}

  ${media.down("LAPTOP")`
  font-size: 26px;
  `}

  ${media.down("TABLET_LARGE")`
  font-size: 24px;
  `}

  ${media.down("TABLET_SMALL")`
  font-size: 30px;
  `}

  ${media.down("MOBILE")`
  font-size: 24px;
  margin-bottom: 16px;

  
  `}

  ${media.down("MOBILE_SMALL")`
  font-size: 24px;
  margin-bottom: 8px;
  `}
`;

export const ProductCardDescription = styled.h4`
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #b6c3c3;
  margin: 0;
  margin-bottom: 16px;

  ${media.down("FULL")`
  font-size: 19px;
  `}

  ${media.down("MAIN_WEB")`
  font-size: 18px;

  `}

  ${media.down("LAPTOP")`
  font-size: 16px;
  `}

  ${media.down("TABLET_LARGE")`
  font-size: 14px;
  
  `}

  ${media.down("TABLET_SMALL")`
  font-size: 18px;
  
  `}

  ${media.down("MOBILE")`
  font-size: 16px;
  margin-bottom: 16px;

  `}

  ${media.down("MOBILE_SMALL")`
  font-size: 14px;
  margin-bottom: 8px;
  `}
`;

export const ProductCardFooter = styled.h5`
  font-family: Barlow;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #f0f5f5;
  margin: 0;

  ${media.down("FULL")`
  font-size: 19px;
  `}

  ${media.down("MAIN_WEB")`
  font-size: 18px;

  `}

  ${media.down("TABLET_LARGE")`
  font-size: 14px;
  width: 292px;

  `}

  ${media.down("TABLET_SMALL")`
  font-size: 18px;
  max-width: 382px;


  `}

  ${media.down("MOBILE")`
  font-size: 16px;
  max-width: 272px;

  `}

  ${media.down("MOBILE_SMALL")`
  font-size: 14px;
  `}
`;

export const CardWrapper = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transition: transform 0.5s ease-in;
  ${p =>
    p.isHovered &&
    css`
      transform: scale(1.03);
    `}
`;

export const ButtonContainer = styled.div`
  position: relative;
  height: 56px;
  width: 190px;
  margin: 0 auto -15px auto;
  cursor: pointer;
  transition: transform 0.5s ease-in;

  :hover {
    transform: scale(1.03);
  }

  ${media.down("MOBILE_SMALL")`
  width:160px;
  height: 48px;
  `}
`;

export const VideoContainer = styled.video<{ isCouriersService?: boolean; isHovered?: boolean }>`
  top: 0;
  position: absolute;
  transition: transform 0.5s ease-in;
  ${p =>
    p.isHovered &&
    css`
      transform: scale(1.1);
    `}

  ${p =>
    p.isCouriersService
      ? css`
          height: 180px;
          width: 194px;
          top: -7px;
          ${media.down("TABLET_LARGE")`
            height: 145px;
            width: 145px;
          `}

          ${media.down("TABLET_SMALL")`
            height: 170px;
            width: 170px;
          `}
  
          ${media.down("MOBILE_SMALL")`
            height: 145px;
            width: 145px;
          `}
        `
      : css`
          height: 170px;
          width: 184px;

          ${media.down("TABLET_LARGE")`
            height: 135px;
            width: 135px;
          `}

          ${media.down("TABLET_SMALL")`
            height: 159px;
            width: 159px;
          `}
  
          ${media.down("MOBILE_SMALL")`
            height: 135px;
            width: 135px;
          `}
        `}
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-top: 40px;
  ${media.down("TABLET_LARGE")`
  padding-top: 0;
  `}

  ${media.down("TABLET_SMALL")`
  padding-top: 40px;
  `}
  transition: transform 0.5s ease-in;
`;
