import React, { FunctionComponent, useRef, useState } from "react";
import {
  Indicator,
  IndicatorBg,
  IndicatorWrapper,
  TransportButton,
  TransportButtonsWrapper,
  TransportContainer,
  TransportHeading,
  TransportOptionWrapper,
  TransportSlide,
  TransportSliderWrapper,
} from "./Transport.components";
import { ReactComponent as ArrowLeft } from "./ArrowLeft.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";
import Slider from "react-slick";
import sliderElements from "./sliderElements";
import AnimatedText from "../../shared/components/AnimatedText/AnimatedText";
import { isMobile } from "react-device-detect";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CouriersRoutes } from "../../../config/routes";
import useIsSamsungInternet from "../../../common/hooks/useIsSamsungInternet";

const Transport: FunctionComponent = () => {
  const [currentOption, setCurrentOption] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const isSamsungInternet = useIsSamsungInternet();

  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 849,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: `200px`,
        },
      },
      {
        breakpoint: 749,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: `160px`,
        },
      },
      {
        breakpoint: 649,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };

  const goToNextSlide = () => {
    sliderRef.current?.slickNext();
    setCurrentOption(currentOption + 1 < sliderElements.length ? currentOption + 1 : 0);
  };

  const goToPrevSlide = () => {
    sliderRef.current?.slickPrev();
    setCurrentOption(currentOption - 1 < 0 ? 0 : currentOption - 1);
  };

  const TransportHeadingNavigation = (isMobile?: boolean) => (
    <>
      <TransportHeading>What do we transport?</TransportHeading>
      <TransportOptionWrapper>
        <AnimatedText texts={sliderElements.map(el => el.caption)} currentIndex={highlightedIndex} />
      </TransportOptionWrapper>
      <TransportButtonsWrapper isMobile={isMobile}>
        <TransportButton onClick={goToPrevSlide}>
          <ArrowLeft />
        </TransportButton>
        <TransportButton right onClick={goToNextSlide}>
          <ArrowLeft />
        </TransportButton>
      </TransportButtonsWrapper>
    </>
  );

  return (
    <TransportContainer hideBackground={isSamsungInternet}>
      {!isMobile && TransportHeadingNavigation()}
      <SEOMarker routeInfo={CouriersRoutes.whatWeTransport} />
      <TransportSliderWrapper>
        <Slider
          {...settings}
          ref={sliderRef}
          afterChange={index => {
            setHighlightedIndex(index);
          }}
          className="TransportSlider"
        >
          {sliderElements.map((el, index) => (
            <TransportSlide key={`${el.caption}-${index}`} highlighted={index === highlightedIndex} src={el.image} />
          ))}
        </Slider>
      </TransportSliderWrapper>
      {isMobile && TransportHeadingNavigation(true)}
      <IndicatorWrapper>
        <IndicatorBg />
        {sliderElements.map((el, index) => (
          <Indicator key={`${el.caption}-${index}`} highlighted={index === highlightedIndex} />
        ))}
      </IndicatorWrapper>
    </TransportContainer>
  );
};

export default Transport;
