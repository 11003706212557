import { Canvas } from "@react-three/fiber";
import React, { FunctionComponent, Suspense, useEffect, useState } from "react";
import FlowingButton from "../../shared/components/FlowingButton/FlowingButton";
import {
  CanvasWrapper,
  InGoodHandsContainer,
  InGoodHandsImage,
  InGoodHandsInnerContainer,
  InGoodHandsSubtitle,
  InGoodHandsTitle,
} from "./InGoodHands.components";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import InGoodHandsPackage from "./InGoodHandsPackage";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CouriersRoutes } from "../../../config/routes";
import { Environment } from "@react-three/drei";
import GoodHandsBg from "./GoodHandsBackground.png";

const InGoodHands: FunctionComponent<{ inView: boolean }> = ({ inView }) => {
  const [inViewRefContainer, inViewContainer] = useInView();
  const [isFirstAnimation, setIsFirstAnimation] = useState(true);

  useEffect(() => {
    if (inViewContainer && isFirstAnimation) {
      gsap
        .timeline()
        .from(".inGoodHandsTitle", { scale: 0.7, duration: 1.3, delay: 0.2 })
        .from(".inGoodHandsLeftBox", { x: -80, y: 20, duration: 1 }, 0)
        .from(".inGoodHandsRightBox", { x: 80, y: -20, duration: 1 }, 0.3);
      setIsFirstAnimation(false);
    }
  }, [inViewContainer]);
  return (
    <InGoodHandsContainer>
      <SEOMarker routeInfo={CouriersRoutes.yourPackage} />
      <InGoodHandsInnerContainer ref={inViewRefContainer}>
        <InGoodHandsTitle className="inGoodHandsTitle">
          Your Medical Package Is In&nbsp;Good&nbsp;Hands.
        </InGoodHandsTitle>
        <InGoodHandsSubtitle>Let us handle it for you.</InGoodHandsSubtitle>
        <FlowingButton text="Get a quote" />
        <CanvasWrapper left className="inGoodHandsLeftBox">
          <Suspense fallback={null}>
            <Canvas style={{ zIndex: 0, width: "100%", height: "100%" }} dpr={inView ? 2 : 1}>
              <InGoodHandsPackage leftBox inView={inView} />
              <Environment preset="sunset" />
            </Canvas>
          </Suspense>
        </CanvasWrapper>
        <CanvasWrapper className="inGoodHandsRightBox">
          <Suspense fallback={null}>
            <Canvas style={{ zIndex: 0, width: "100%", height: "100%" }} dpr={inView ? 2 : 1}>
              <InGoodHandsPackage inView={inView} />
              <Environment preset="sunset" />
            </Canvas>
          </Suspense>
        </CanvasWrapper>
      </InGoodHandsInnerContainer>
      <InGoodHandsImage src={GoodHandsBg} />
    </InGoodHandsContainer>
  );
};

export default InGoodHands;
