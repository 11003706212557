import styled, { css } from "styled-components";
import { media } from "../../../../shared/styles/media";

export const ServiceBoxWrapper = styled.div`
  width: 152px;
  height: 182px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;

  ${media.up("NEW_LARGE_WEB")`
  width: 168px;
  height: 198px;
  `}
  ${media.down("NEW_SMALL_WEB")`
    width: 136px;
    height: 166px;
  `}

  ${media.down("NEW_HALF_WEB")`
    width: 320px;
    height: 106px;
    border-radius: 24px;
  `}

  ${media.down("NEW_TABLET")`
    width: 268px;
    height: 94px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    width: 320px;
    height: 106px;
  `}

  ${media.down("NEW_MOBILE")`
    width: 312px;
  `}

  ${media.down("MOBILE_TABLE")`
    width: 260px;
  `}
`;

export const ServiceBoxHelper = styled.div`
  position: absolute;
  width: 60px;
  height: 1.5px;
  bottom: 0;
  left: 20%;
  background: rgba(223, 232, 232, 1);
  z-index: 15;

  ${media.down("NEW_HALF_WEB")`
   left: 40%;
   background: #d8e3e3
   height: 2px;
   bottom: -0.1px;
  `}
`;

interface ServiceBoxWrapperInsideProps {
  animationDelay: number;
}

export const ServiceBoxWrapperInside = styled.div<ServiceBoxWrapperInsideProps>`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  transform: rotate(90deg);
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  animation: rotate linear 3.5s infinite;
  ${props =>
    props.animationDelay === 1 &&
    css`
      animation-direction: reverse;
    `};

  @keyframes rotate {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(450deg);
    }
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(-50%, -50%);
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100%;
      background: rgba(252, 252, 252, 0);
    }

    &:last-child {
      background: rgba(252, 252, 252, 0);
      &:after {
        background: linear-gradient(0deg, #5de0de 40%, rgba(252, 252, 252, 0) 61%);
      }
    }
  }
`;

export const ServiceBoxContent = styled.div`
  width: 149px;
  height: 179px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(215, 227, 226, 1);
  box-shadow: inset -1px -2.5px 2.5px rgba(255, 255, 255, 0.8),
    inset 2.64311px 2.64311px 10.5724px rgba(151, 180, 180, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  ${media.up("NEW_LARGE_WEB")`
  width: 165px;
  height: 195px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    width: 133px;
    height: 163px;
  `}

  ${media.down("NEW_HALF_WEB")`
    width: 317px;
    height: 103px;
    border-radius: 24px;
    box-shadow: none;
    background: #E1EAEA;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #eaf1f1;
  `}

  ${media.down("NEW_TABLET")`
    width: 265px;
    height: 91px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    width: 320px;
    height: 106px;
  `}

  ${media.down("NEW_MOBILE")`
    width: 309px;
  `}

  ${media.down("MOBILE_TABLE")`
    width: 257px;
  `}
`;

export const ServiceImageWrapper = styled.div`
  display: none;

  ${media.down("NEW_HALF_WEB")`
    display: flex;
    margin-right: 16px;
    margin-left: 24px;
  `}

  ${media.down("NEW_TABLET")`
    margin-left: 20px;
    margin-right: 8px;
  `}
`;

export const ServiceBoxHeading = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #0e1b1b;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 24px;
  `}

  ${media.down("NEW_HALF_WEB")`
    font-size: 20px;
    text-align: left;
  `}

  ${media.down("NEW_TABLET")`
    font-size: 18px;
  `}
`;

export const ServiceBoxDescription = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #374343;
  margin-top: 10px;
  max-width: 73px;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 19px;
  `}

  ${media.down("NEW_HALF_WEB")`
    max-width: 100%;
    font-size: 18px;
    margin-top: 4px;
  `}

  ${media.down("NEW_TABLET")`
    font-size: 16px;
    margin-top: 0px;
  `}
`;

export const ServiceBoxContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.down("NEW_HALF_WEB")`
    align-items: flex-start;
  `}
`;
