import React, { FunctionComponent } from "react";
import { ServicesImageType } from "../ServicesSection";
import {
  ServiceBoxHeading,
  ServiceBoxWrapper,
  ServiceBoxDescription,
  ServiceBoxContent,
  ServiceBoxWrapperInside,
  ServiceImageWrapper,
  ServiceBoxContentWrapper,
} from "./ServiceBox.components";
import BloodIcon from "./images/BloodIcon.svg";
import CovidIcon from "./images/CovidIcon.svg";
import OtherIcon from "./images/OtherIcon.svg";

type ServiceBoxProps = {
  heading: string;
  description: string;
  imageType: ServicesImageType;
  borderAnimationDelay?: number;
};

const getImageByType = (imageType: ServicesImageType) => {
  return imageType === ServicesImageType.BLOOD_TEST ? (
    <img src={BloodIcon} alt="blood icon" style={{ width: 32 }} />
  ) : imageType === ServicesImageType.COVID_TEST ? (
    <img src={CovidIcon} alt="covid icon" style={{ width: 32 }} />
  ) : (
    <img src={OtherIcon} alt="other services icon" style={{ width: 32 }} />
  );
};

const ServiceBox: FunctionComponent<ServiceBoxProps> = ({ heading, description, imageType, borderAnimationDelay }) => {
  return (
    <ServiceBoxWrapper className="serviceBox">
      <ServiceBoxWrapperInside className="serviceImage" animationDelay={borderAnimationDelay || 0}>
        <span />
        <span />
      </ServiceBoxWrapperInside>
      <ServiceBoxContent>
        <ServiceImageWrapper>{getImageByType(imageType)}</ServiceImageWrapper>
        <ServiceBoxContentWrapper>
          <ServiceBoxHeading>{heading}</ServiceBoxHeading>
          <ServiceBoxDescription>{description}</ServiceBoxDescription>
        </ServiceBoxContentWrapper>
      </ServiceBoxContent>
    </ServiceBoxWrapper>
  );
};

export default ServiceBox;
