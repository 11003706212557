import styled, { css } from "styled-components";
import { media } from "../../../styles/media";
import { BouncedBoxWrapper, BouncedBox } from "../BouncedShape.components";

export const BouncedShapeWithTransparencyWrapper = styled(BouncedBoxWrapper)<{
  inBottomOfSection?: boolean;
  inTopOfSection?: boolean;
}>`
  bottom: ${p => (p.inBottomOfSection ? "-10px" : "-190px")};
  ${p =>
    p.inTopOfSection &&
    css`
      bottom: unset;
      top: 0;
    `}
  height: 200px;
  pointer-events: none;
`;

export const BouncedBoxWithTransparency = styled(BouncedBox)`
  bottom: -100px;
  width: 150%;
  margin-left: -25%;
  height: 200px;
  //${media.up("FULL")`
    //width: 100vw;
    //margin-left: 0;
  //`}
`;

export const BouncedBoxTopWithTransparency = styled(BouncedBoxWithTransparency)`
  bottom: 100px;
  display: none;
`;
