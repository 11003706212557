import styled, { css } from "styled-components";
import { colors } from "../../shared/styles/colors";
import { media } from "../../shared/styles/media";
import zIndexes from "../helpers/zIndexes";
import SimpleBackground from "./DeliveryBackgroundSimple2.jpg";

export const DeliveriesSectionContainer = styled.section`
  overflow: hidden;
  height: 100%;
  position: relative;
  background: url(${SimpleBackground}) no-repeat bottom right;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-position-y: 96%;
  max-width: 1920px;
  box-sizing: border-box;
  padding-bottom: 120px;

  ${media.down("FULL")`
    padding-left: 360px;
    margin: auto;
  `}

  ${media.down("MAIN_WEB")`
    padding-left: 200px;
  `}

  ${media.down("LAPTOP")`
    padding-left: 144px ;
  `}
  ${media.down("TABLET")`
    padding-left: 104px;
  `}
  ${media.down("TABLET_SMALL")`
    padding-left: 96px;
  `}

  ${media.down("MOBILE_LARGE")`
      padding-left: 48px;
      padding-bottom: 80px;
  `} 
  ${media.down("MOBILE")`
      padding-left: 48px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding-left: 40px;
  `}
`;

export const DeliveriesSectionInnerContainer = styled.div`
  position: relative;
  ${media.up("NEW_LARGE_WEB")`
  padding: 0;
  width: 100%;
  /* max-width: 1200px; */
  `}
`;

export const DeliveriesContainer = styled.div`
  padding-top: 90px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;

  /* ${media.up("NEW_LARGE_WEB")`
    padding: 0px !important;
    padding-top: 144px !important;
    overflow: visible;
  `}

  ${media.up("NEW_MAIN_WEB")`
    padding: 0 360px;
  padding-top: 144px;
  `}

  ${media.down("FULL")`
    padding: 0 360px;
  padding-top: 144px;

  `}

  ${media.down("MAIN_WEB")`
    padding: 0 200px;
    padding-top: 100px;
  padding-top: 144px;

  `}

  ${media.down("LAPTOP")`
    padding: 0 160px;
    padding-top: 100px;

  `}

  ${media.down("NEW_HALF_WEB")`
    padding: 0 132px;
  `}
  
  ${media.down("TABLET_SMALL")`
    padding: 0 96px;
  `}
  ${media.down("NEW_TABLET")`
    padding: 0 96px;
    margin-top: 0;
  `}
  ${media.down("MOBILE_LARGE")`
  padding: 0 48px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
  padding: 0 48px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  padding: 0 40px;
  `} */
`;

export const DeliveriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  ${media.down("NEW_LARGE_MOBILE")`
    width: 100%;
  `}
  button {
    padding: 0;
  }
`;

export const DeliveriesTitle = styled.h1`
  margin: 0;
  margin-bottom: 24px;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: #0e1b1b;
  z-index: ${zIndexes.ABOVE_PACKAGE_Z_INDEX};

  > span {
    color: ${colors.red._500};
  }

  max-width: 320px;
  ${media.down("NEW_LARGE_WEB")`
  `}

  ${media.down("NEW_MAIN_WEB")`
  `}

  ${media.down("NEW_SMALL_WEB")`
  font-size: 56px;
  `}

  ${media.down("NEW_HALF_WEB")`
  `}

  ${media.down("NEW_TABLET")`
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  font-size: 48px;
  `}

  ${media.down("NEW_MOBILE")`
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  `}
`;

export const DeliveriesSubTitle = styled.h3`
  margin: 0;
  margin-bottom: 8px;
  //margin for title T letter

  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 120%;
  color: #0e1b1b;

  max-width: 400px;
  z-index: ${zIndexes.ABOVE_PACKAGE_Z_INDEX};

  > span {
    color: #dd2736;
  }
  ${media.down("NEW_LARGE_WEB")`
  `}

  ${media.down("NEW_MAIN_WEB")`
  `}

  ${media.down("NEW_SMALL_WEB")`
  font-size: 28px;
  `}

  ${media.down("NEW_HALF_WEB")`
  `}

  ${media.down("NEW_TABLET")`
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  margin-left: 3px;

  font-size: 26px;
  max-width: 385px;
  `}

  ${media.down("NEW_MOBILE")`
  min-width: 292px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  font-size: 24px;
  `}
`;

export const DeliveriesDescription = styled.p`
  margin: 0;
  margin-bottom: 58px;

  //margin for title T letter
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;
  color: #0e1b1b;
  max-width: 430px;
  z-index: ${zIndexes.ABOVE_PACKAGE_Z_INDEX};

  > span {
    font-weight: 500;
  }
  ${media.down("NEW_LARGE_WEB")`
  `}

  ${media.down("NEW_MAIN_WEB")`
  max-width: 352px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  max-width: 408px;

  `}

  ${media.down("NEW_HALF_WEB")`
  max-width: 365px;
  `}

  ${media.down("NEW_TABLET")`
  max-width: 289px;
  margin-bottom: 24px;

  `}

  ${media.down("NEW_LARGE_MOBILE")`
  margin-left: 3px;
  max-width: 325px;
  `}

  ${media.down("NEW_MOBILE")`
  font-size: 16px;
  max-width: 312px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  `}
`;

export const PackageVideo = styled.div`
  width: 500px;
  height: 520px;
  position: absolute;
  left: 700px;
  bottom: 0;

  ${media.up("NEW_LARGE_WEB")`
  height: 598px;
      width: 575px;
  right: 10%;
  `}

  ${media.down("NEW_MAIN_WEB")`
      height: 598px;
      width: 575px;
      right: 180px;
      left: unset;
  `}

  ${media.down("NEW_SMALL_WEB")`
  height: 520px;
      width: 500px;
  right: 100px;
  left: unset;

  `}

   ${media.down("NEW_HALF_WEB")`
   height: 440px;
      width: 424px;
      right: 5%;
  left: unset;
  bottom: 40px;


   `}

   ${media.down("NEW_TABLET")`
   height: 390px;
      width: 375px;
  /* left: 278px; */
  left: unset;
  right: 4%;


   `}
`;

export const PackageShadowImg = styled.img`
  width: 890px;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: -30px;
  ${media.up("NEW_LARGE_WEB")`
  width: 890px;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: -30px;
  `}

  ${media.up("NEW_MAIN_WEB")`
   
  `}

  ${media.down("NEW_SMALL_WEB")`
  width: 740px;
  left: -20px;
  `}

   ${media.down("NEW_HALF_WEB")`
   width: 650px;
  left: -15px;

   `}

   ${media.down("NEW_TABLET")`
   width: 550px;
  left: -15px;
   `}

   ${media.down("NEW_MOBILE")`
   width: 450px;
  left: -10px;
   `}

   ${media.down("NEW_SMALL_MOBILE")`
   width: 420px;
  left: -10px;
   `}
`;

export const PackageVideoContainerMobile = styled.div`
  height: 364px;
  width: 330px;
  position: relative;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;

  ${media.down("NEW_MOBILE")`
  height: 328px;
  width: 297px;
   `}

  ${media.down("NEW_SMALL_MOBILE")`
  height: 312px;
  width: 287px;
   `}
`;

export const TCDSeparatorLine = styled.div`
  position: absolute;
  height: 339px;
  background: rgba(164, 152, 152, 0.32);
  width: 1px;
  top: 0;
  left: 45%;

  ${media.down("NEW_SMALL_WEB")`
   height: 264px;
  left: 48%;

   `}

  ${media.down("NEW_HALF_WEB")`
   height: 184px;
  left: 50%;

   `}

  ${media.down("NEW_TABLET")`
   display: none;
   `}
`;

export const DeliveriesMobileWrapper = styled.section<{ hideBackground?: boolean }>`
  padding-top: 65px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  ${p =>
    !p.hideBackground &&
    css`
      background: url(${SimpleBackground}) no-repeat bottom right;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-position-y: -20%;
    `}
  /* max-width: 1920px; */
  box-sizing: border-box;
  padding-bottom: 80px;
`;

export const DeliveriesMobileTextContent = styled.div`
  ${media.down("MOBILE_LARGE")`
      padding:0 48px;
  `}
  ${media.down("MOBILE")`
      padding:0 48px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding:0 40px;
  `}
`;
