import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { FancyLinkDeco, FancyLinkInner, FancyLinkWrapper, HiddenSvg } from "./FancyLink.components";
import useGA from "../../../../common/hooks/useGA";
interface Props {
  text: string;
  filterUrl: string;
  additionalClass?: string;
  goTo?: string;
  onClick?: () => void;
  gaEvent?: { category: string; action: string };
  newTab?: boolean;
}
interface State {
  animation: {
    text: boolean;
    line: boolean;
    primitiveValues: {
      turbulence: number;
    };
  };
}
const FancyLink: FunctionComponent<Props> = props => {
  const { trackEvent } = useGA();
  const { text, filterUrl } = props;
  const [options] = useState<State>({
    animation: {
      text: false,
      line: true,
      primitiveValues: {
        turbulence: 0,
      },
    },
  });
  const lineRef = useRef<HTMLElement>(null);
  const textRef = useRef<HTMLElement>(null);
  const turbulenceRef = useRef<SVGFETurbulenceElement>(null);
  const filterRef = useRef<SVGFilterElement>(null);
  //@ts-ignore
  const tlRef: gsap.core.Timeline = useRef<gsap.core.Timeline>(null);
  useEffect(() => {
    tlRef.current = gsap
      .timeline({
        paused: true,
        onStart: () => {
          if (options.animation.line && lineRef.current) {
            lineRef.current.style.filter = `url(#${filterUrl})`;
          }
          if (options.animation.text && textRef.current) {
            textRef.current.style.filter = `url(${filterUrl})`;
          }
        },
        onComplete: () => {
          if (options.animation.line && lineRef.current) {
            lineRef.current.style.filter = "none";
          }
          if (options.animation.text && textRef.current) {
            textRef.current.style.filter = "none";
          }
        },
        onUpdate: () => {
          if (turbulenceRef.current) {
            turbulenceRef.current.setAttribute(
              "baseFrequency",
              options.animation.primitiveValues.turbulence.toString()
            );
          }
        },
      })
      .to(options.animation.primitiveValues, {
        duration: 0.4,
        //ease: "Quint.easeOut",
        startAt: { turbulence: 0.09 },
        turbulence: 0,
      });
  }, []);
  return (
    <FancyLinkWrapper
      onMouseEnter={() => {
        tlRef.current && tlRef.current.restart();
      }}
      onMouseLeave={() => tlRef.current && tlRef.current.progress(1).kill()}
      onClick={e => {
        if (!props.goTo) {
          e.preventDefault();
        }
        tlRef.current && tlRef.current.restart();
        props.onClick && props.onClick();
        props.gaEvent && trackEvent(props.gaEvent.category, props.gaEvent.action);
      }}
      className={props.additionalClass}
      href={props.goTo || "#"}
      target={props.newTab ? "_blank" : "_self"}
    >
      <HiddenSvg>
        <defs>
          <filter ref={filterRef} id={filterUrl}>
            <feTurbulence ref={turbulenceRef} type="fractalNoise" baseFrequency="0" numOctaves="1" result="warp" />
            <feOffset dx="-90" result="warpOffset" />
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="30"
              in="SourceGraphic"
              in2="warpOffset"
            />
          </filter>
        </defs>
      </HiddenSvg>
      <FancyLinkInner ref={textRef} className="menu__link-inner">
        {text}
      </FancyLinkInner>
      <FancyLinkDeco ref={lineRef} className="menu__link-deco" />
    </FancyLinkWrapper>
  );
};
export default FancyLink;
