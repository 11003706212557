import React, { FunctionComponent, useEffect } from "react";
import {
  CrownCanvasWrapper,
  IntroductionContainer,
  IntroductionWrapper,
  IntroductionHeading,
  IntroductionDescription,
  IntroductionOuterContainer,
  TabletBreakLine,
  ButtonWrapper,
} from "./Introduction.components";
import gsap from "gsap";
import Trustpilot from "../../shared/components/Trustpilot/Trustpilot";
import GetQuoteButton from "-!svg-react-loader!./GetAQuoteButton.svg";

// @ts-ignore
import CrownVideo from "./crown.webm";
// @ts-ignore
import CrownVideoMac from "./crown.mov";
import ClientOpinions from "../../CompanyLanding/ClientOpinions/ClientOpinions";
import { LandingOpinions } from "../../CompanyLanding/ClientOpinions/opinionsConfig";
import BouncedShape from "../../shared/components/BouncedShape/BouncedShape";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { MedicalRoutes } from "../../../config/routes";
import urls from "../../../config/urls";
import CrownPoster from "./CrownPoster.png";

export const QuoteButton = GetQuoteButton;

const Introduction: FunctionComponent = () => {
  const introAnimation = () => {
    const startingTl = gsap.timeline();
    startingTl
      .from(".introduction", { autoAlpha: 0, duration: 1.0, delay: 0.8 })
      .from(".introductionHeading", { autoAlpha: 0, stagger: 0.2, x: -100, duration: 1.0 }, 1.2)
      .fromTo(
        ".canvasWrapper",
        { autoAlpha: 0, y: 350, x: -250 },
        { autoAlpha: 1, y: 0, x: 0, rotate: 17, duration: 1 },
        1
      );
  };

  useEffect(() => {
    introAnimation();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <SEOMarker routeInfo={MedicalRoutes.title} />
      <IntroductionOuterContainer className="introduction">
        <IntroductionContainer>
          <IntroductionWrapper>
            <Trustpilot variant="mint" />
            <IntroductionHeading className="introductionHeading">
              Medical <TabletBreakLine />
              Services <TabletBreakLine />
              Wherever You Are
            </IntroductionHeading>
            <IntroductionDescription className="introductionHeading">
              Get professionally tested wherever you are. Our team will happily travel to you, so you don’t have to!
            </IntroductionDescription>
            <ButtonWrapper as="a" className="introductionHeading" href={urls.MEDICAL_LANDING_URL + "get-in-touch"}>
              <GetQuoteButton />
            </ButtonWrapper>
          </IntroductionWrapper>
          <CrownCanvasWrapper
            className="canvasWrapper"
            autoPlay={true}
            muted
            loop
            playsInline={true}
            poster={CrownPoster}
          >
            <source src={CrownVideoMac} type="video/quicktime" />
            <source src={CrownVideo} type="video/webm" />
          </CrownCanvasWrapper>
        </IntroductionContainer>
        <ClientOpinions opinionsConfig={LandingOpinions.MEDICAL} />
      </IntroductionOuterContainer>
      <BouncedShape trigger="introduction" bottomShapeBackground="#181a1b" topShapeBackground="#d8e3e3" />
    </div>
  );
};

export default Introduction;
