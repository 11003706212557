import React, { FunctionComponent, Suspense, useMemo } from "react";
import FancyLink from "../../shared/components/FancyLink/FancyLink";

import {
  ContactUsMenu,
  ContactUsMenuSeparator,
  ContactUsTextWrapper,
  ContactUsTitleContainer,
  SeparatorLine,
  SeparatorText,
  Wrapper,
  ContactUsTitle,
  ContactUsSubtitle,
  ModelMockContainer,
  TopShape,
  ContactUsMail,
  ContactUsDetails,
} from "./ContactUs.components";
import { Canvas } from "@react-three/fiber";
import { isMobile } from "react-device-detect";
import ContactUsScene from "./ContactUsScene";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CompanyRoutes } from "../../../config/routes";
import { useInView } from "react-intersection-observer";
import { config } from "../../../config";

const WHATSAPP_NUMBER = "+447845785571";

const ContactUs: FunctionComponent = () => {
  const [inViewRef, inView] = useInView();
  const WHATSAPP_URL = useMemo(() => {
    return (isMobile ? "https://wa.me/" : "https://web.whatsapp.com/send?phone=") + WHATSAPP_NUMBER;
  }, []);
  return (
    <Wrapper ref={inViewRef} id="contactUs">
      <SEOMarker routeInfo={CompanyRoutes.contactUs} offset={-300} />
      <TopShape />
      <ContactUsTitleContainer>
        <ContactUsTextWrapper>
          <ContactUsTitle>Contact Us</ContactUsTitle>
          <ContactUsDetails>
            <ContactUsSubtitle>Our team will be happy to help.</ContactUsSubtitle>
            <ContactUsMail href={`mailto:${config.infoMail}`}>{config.infoMail}</ContactUsMail>
          </ContactUsDetails>
        </ContactUsTextWrapper>
        <ModelMockContainer>
          <Canvas dpr={Math.max(window.devicePixelRatio, 2)}>
            <Suspense fallback={null}>
              <ContactUsScene isInView={inView} />
            </Suspense>
          </Canvas>
          {/* <ModelMockImg src={ModelImg} /> */}
        </ModelMockContainer>
      </ContactUsTitleContainer>
      <ContactUsMenu>
        <FancyLink
          goTo="https://www.linkedin.com/company/medical-logistics"
          newTab
          filterUrl="link22"
          text="LINKEDIN"
          gaEvent={{ category: "contact", action: "linkedin-click" }}
        />
        <ContactUsMenuSeparator>
          <SeparatorLine className="xd" />
          <SeparatorText>OR</SeparatorText>
          <SeparatorLine />
        </ContactUsMenuSeparator>
        <FancyLink
          goTo={WHATSAPP_URL}
          newTab
          filterUrl="link23"
          text="WHATSAPP"
          gaEvent={{ category: "contact", action: "whatsapp-click" }}
        />
      </ContactUsMenu>
    </Wrapper>
  );
};

export default React.memo(ContactUs);
