import styled from "styled-components";

export const DrawingSVGWrapper = styled.div<{ pathLength: number; draw: boolean; time?: number }>`
  svg {
    max-width: 100%;
  }
  path {
    stroke-dasharray: ${p => p.pathLength};
    stroke-dashoffset: ${p => p.pathLength};
    animation: ${p => (p.draw ? "draw" : "removeDraw")} ${p => (p.time ? p.time : 1)}s ease-in-out forwards;

    @keyframes draw {
      to {
        stroke-dashoffset: 0;
      }
    }

    @keyframes removeDraw {
      to {
        stroke-dashoffset: ${p => p.pathLength};
      }
    }
  }
`;
