import { NavigationUrls } from "@purpledeerstudio/logistics-components/dist/types/NavigationUrls";

interface IUrl {
  MEDICAL_LANDING_URL: string;
  COMPANY_LANDING_URL: string;
  COURIERS_LANDING_URL: string;
  BLOG: string;
}

const developmentUrls: IUrl = {
  BLOG: "https://blog.medicallogistics.co.uk/",
  MEDICAL_LANDING_URL: "https://med-landing-staging--dev-8yaoq791.web.app/",
  COMPANY_LANDING_URL: "https://company-landing-staging--dev-7yq51nmm.web.app/",
  COURIERS_LANDING_URL: "https://couriers-landing-staging--dev-6eh5c1tu.web.app/",
};

const stagingUrls: IUrl = {
  BLOG: "https://blog.medicallogistics.co.uk/",
  MEDICAL_LANDING_URL: "https://med-landing-staging.web.app/",
  COMPANY_LANDING_URL: "https://company-landing-staging.web.app/",
  COURIERS_LANDING_URL: "https://couriers-landing-staging.web.app/",
};

const productionUrls: IUrl = {
  BLOG: "https://blog.medicallogistics.co.uk/",
  MEDICAL_LANDING_URL: "https://medicalservices.medicallogistics.co.uk/",
  COMPANY_LANDING_URL: "https://medicallogistics.co.uk/",
  COURIERS_LANDING_URL: "https://bookcourier.medicallogistics.co.uk/",
};

const urls =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? developmentUrls
    : process.env.REACT_APP_ENVIRONMENT === "STAGING"
    ? stagingUrls
    : productionUrls;

const ISO_URL = `${urls.COMPANY_LANDING_URL}iso_certificate.pdf`;
const PRIVACY_URL = `${urls.COMPANY_LANDING_URL}privacy-and-cookies-policy`;
const TERMS_URL = `${urls.COMPANY_LANDING_URL}terms-and-conditions`;
const CONTACT_URL = `${urls.COMPANY_LANDING_URL}#contact`;
const MODERN_SLAVERY_URL = `${urls.COMPANY_LANDING_URL}modern-slavery-policy`;
const ABOUT_US_URL = `${urls.COMPANY_LANDING_URL}about-us`;
const PRESS_ABOUT_US_URL = `${urls.COMPANY_LANDING_URL}press-about-us`;

export const FooterUrls: NavigationUrls = {
  iso: ISO_URL,
  privacy: PRIVACY_URL,
  terms: TERMS_URL,
  home: urls.COMPANY_LANDING_URL,
  contactUs: CONTACT_URL,
  medicalServices: urls.MEDICAL_LANDING_URL,
  medicalCouriers: urls.COURIERS_LANDING_URL,
  modernSlavery: MODERN_SLAVERY_URL,
  aboutUs: ABOUT_US_URL,
  pressAboutUs: PRESS_ABOUT_US_URL,
};

export default {
  ...urls,
  UKAS: "https://www.ukas.com/find-an-organisation/?q=med+logistics",
  CQC: "https://www.cqc.org.uk/provider/1-10972391656/registration-info",
};
