import React, { FunctionComponent, useEffect, useState } from "react";
import {
  FormCard,
  FormDeck,
  FormInnerWrapper,
  FormSentButtonWrapper,
  FormSentCard,
  FormSentDescription,
  FormSentHeading,
  FormSentWrapper,
  GetInTouchContainer,
  GetInTouchDescription,
  GetInTouchHeadingWrapper,
  GetInTouchWrapper,
} from "./GetInTouch.components";
import GetInTouchForm from "./GetInTouchForm/GetInTouchForm";
import ContactAnimationWeb from "./ContactAnimationWeb.svg";
import ContactAnimationMobile from "./ContactAnimationMobile.svg";
import { isNewLargeMobileDown } from "../../../common/hooks/useMedia";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { MedicalRoutes } from "../../../config/routes";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import FormModel from "./FormModel.png";
import NewRequestButton from "-!svg-react-loader!./ButtonNewRequest.svg";

const GetInTouch: FunctionComponent = () => {
  const isLargeMobileDown = isNewLargeMobileDown();
  const [inViewRef, inView] = useInView();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (inView) gsap.to(".getInTouchHeader", { autoAlpha: 1, scale: 1, duration: 1 });
    else gsap.to(".getInTouchHeader", { autoAlpha: 0, scale: 0 });
  }, [inView]);

  return (
    <GetInTouchContainer id="getInTouch" className="getInTouch" ref={inViewRef}>
      <GetInTouchHeadingWrapper>
        <GetInTouchWrapper>
          <object
            type="image/svg+xml"
            className="getInTouchHeader"
            data={!inView ? "" : isLargeMobileDown ? ContactAnimationMobile : ContactAnimationWeb}
          ></object>
        </GetInTouchWrapper>
        <SEOMarker routeInfo={MedicalRoutes.getInTouch} />
        <GetInTouchDescription>Our team will be happy to help.</GetInTouchDescription>
      </GetInTouchHeadingWrapper>
      <FormDeck>
        <FormInnerWrapper formSent={sent}>
          <FormCard style={{ zIndex: 1 }}>
            <GetInTouchForm setSent={setSent} />
          </FormCard>
          <FormSentCard id="formSentWrapper">
            <FormSentWrapper>
              <img src={FormModel} loading="lazy" alt="Footer image" />
              <FormSentHeading>Your application has been sent successfully!</FormSentHeading>
              <FormSentDescription>
                If your application was sent outside our working hours (8 a.m.—6 p.m.) we will contact you tomorrow.
              </FormSentDescription>
              <FormSentButtonWrapper onClick={() => setSent(false)}>
                <NewRequestButton />
              </FormSentButtonWrapper>
            </FormSentWrapper>
          </FormSentCard>
        </FormInnerWrapper>
      </FormDeck>
    </GetInTouchContainer>
  );
};

export default GetInTouch;
