import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  AnimatedHeading,
  AnimatedHeadingsWrapper,
  BottomWrapper,
  WeAreExpersVideoOuterWrapper,
  WeAreExpertsContainer,
  WeAreExpertsHeadingLarge,
  WeAreExpertsHeadingMedium,
  WeAreExpertsHeadingSmall,
  WeAreExpertsLeftLineWrapper,
  WeAreExpertsOuterWrapper,
  WeAreExpertsRightLineWrapper,
  WeAreExpertsVideo,
  WeAreExpertsVideoWrapper,
  WeAreExpertsWrapper,
} from "./WeAreExperts.components";
import CouriersVideo from "./couriers.mp4";
import ScrollTrigger from "../../../common/ScrollTrigger";
import gsap from "gsap";
import useWeAreExpertsAnimations from "./useWeAreExpertsAnimations";
import BouncedShape from "../../shared/components/BouncedShape/BouncedShape";
import AutoDrawingSVG from "../../shared/components/AutoDrawingSVG/AutoDrawingSVG";
import { useInView } from "react-intersection-observer";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CouriersRoutes } from "../../../config/routes";
import CouriersVideoPoster from "./couriersPoster.jpg";
import useIsSamsungInternet from "../../../common/hooks/useIsSamsungInternet";

const WeAreExperts: FunctionComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [inViewRef, inView] = useInView({ threshold: 0.1 });
  const isSamsungInternet = useIsSamsungInternet();

  useWeAreExpertsAnimations();

  const getHeightForVideo = () => {
    if (videoWrapperRef && videoWrapperRef.current) {
      const styles = getComputedStyle(videoWrapperRef.current);
      if (window.innerWidth >= 650) {
        return (
          ((videoWrapperRef.current.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)) *
            9) /
          16
        );
      } else {
        return (
          ((videoWrapperRef.current.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)) *
            3) /
          4
        );
      }
    } else {
      return 600;
    }
  };

  useEffect(() => {
    const videoTl = gsap.timeline().from(".weAreExpertsVideoWrapper", { width: Math.min(400, window.innerWidth / 2) });
    // Scrolltrigger for video animation
    ScrollTrigger.create({
      trigger: ".weAreExpertsContainer",
      start: "top top",
      end: "bottom-=20% bottom",
      animation: videoTl,
      scrub: 1,
      onEnter: () => videoRef.current?.play(),
    });
  }, []);

  return (
    <WeAreExpertsContainer hideBackground={isSamsungInternet} className="weAreExpertsContainer">
      <SEOMarker routeInfo={CouriersRoutes.weAreExperts} />
      <WeAreExpertsOuterWrapper>
        <WeAreExpertsHeadingMedium className="weAreExpertsHeading">
          We <span>are not</span> just any couriers.
        </WeAreExpertsHeadingMedium>
        <WeAreExpertsHeadingLarge className="weAreExpertsHeading">We are experts</WeAreExpertsHeadingLarge>
        <WeAreExpertsHeadingSmall className="weAreExpertsHeading">
          in time critical deliveries.
        </WeAreExpertsHeadingSmall>
        <WeAreExpertsWrapper>
          <div style={{ position: "relative", width: "100%" }}>
            <WeAreExpertsLeftLineWrapper>
              <AutoDrawingSVG
                drawingDuration={2}
                pathOptions={{
                  d: "M0 73.0253C46.7844 64.4812 93.0548 51.1263 139.989 42.1788C192.274 32.2114 240.752 20.2622 296.192 12.6744C343.74 2.10119 437.645 -7.15364 464.678 12.7824C495.7 41.6077 401.27 61.4497 395.153 63.7287C365.838 74.6505 245.102 94.0798 238.85 136.133C235.172 160.878 268.585 163.454 285.074 165.038C325.154 168.886 479.771 167.938 520 167.938",
                  stroke: "#C2B7B7",
                  strokeLinecap: "round",
                }}
                shouldDraw={inView}
              />
            </WeAreExpertsLeftLineWrapper>
            <WeAreExpersVideoOuterWrapper ref={videoWrapperRef}>
              <WeAreExpertsVideoWrapper
                ref={inViewRef}
                className="weAreExpertsVideoWrapper"
                style={{ height: getHeightForVideo() }}
              >
                <WeAreExpertsVideo
                  ref={videoRef}
                  src={CouriersVideo}
                  loop
                  playsInline
                  muted
                  poster={CouriersVideoPoster}
                />
              </WeAreExpertsVideoWrapper>
            </WeAreExpersVideoOuterWrapper>
            <WeAreExpertsRightLineWrapper>
              <AutoDrawingSVG
                drawingDuration={2}
                pathOptions={{
                  d: "M521 115.041C472.83 115.041 363.429 113.46 311.187 107.141C245.884 99.2413 185.22 96.2029 144.808 75.5416C104.396 54.8802 85.6515 8.6956 131 1.40336C176.349 -5.88887 242.861 99.3519 1 95.043",
                  stroke: "#C2B7B7",
                }}
                shouldDraw={inView}
              />
            </WeAreExpertsRightLineWrapper>
          </div>
        </WeAreExpertsWrapper>
      </WeAreExpertsOuterWrapper>
      <BottomWrapper className="weAreExpertsBottomWrapper">
        <WeAreExpertsHeadingMedium className="bottomHeading">
          <span>On-time</span> delivery.
        </WeAreExpertsHeadingMedium>
        <AnimatedHeadingsWrapper>
          <AnimatedHeading className="animatedHeading">EVERY.</AnimatedHeading>
          <AnimatedHeading className="animatedHeading">SINGLE.</AnimatedHeading>
          <AnimatedHeading className="animatedHeading">TIME.</AnimatedHeading>
        </AnimatedHeadingsWrapper>
      </BottomWrapper>
      <BouncedShape trigger="weAreExpertsContainer" topShapeBackground="#181a1b" bottomShapeBackground="#EFE9E5" />
    </WeAreExpertsContainer>
  );
};

export default WeAreExperts;
