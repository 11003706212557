import styled from "styled-components";

export const TrustpilotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 120px;
  transition: transform 0.5s ease;

  :hover {
    transform: scale(1.05);
  }
`;

export const TruspilotInnerWrapper = styled.div<{ smallerMargin: boolean }>`
  /* Move white starts higher because of missing svg padding. */
  margin-top: ${props => (props.smallerMargin ? 5 : 10)}px;

  > svg {
    margin-right: 3px;
  }
`;
