import styled from "styled-components";
import { media } from "../../shared/styles/media";

export const GetInTouchContainer = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  display: flex;
  background: rgb(22, 23, 24);
  flex-direction: column;
  position: relative;
  z-index: 44;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Spotlight = styled.div`
  position: fixed;
  width: 90vw;
  height: 700px;
  top: 0;
  left: 50%;
  background: rgb(72, 199, 195);
  background: radial-gradient(circle, rgba(72, 199, 195, 0.24413515406162467) 0%, rgba(24, 26, 27, 1) 40%);
`;

export const GetInTouchHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 78px;
`;

export const GetInTouchWrapper = styled.div`
  width: 640px;
  height: 120px;
  ${media.down("NEW_HALF_WEB")`
     width: 530px;
  `}

  ${media.down("NEW_TABLET")`
    width: 460px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    width: 260px;
    height: 140px;
  `}
`;

export const GetInTouchDescription = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 130%;
  color: #b6c3c3;
  margin-top: 12px;
  z-index: 400;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 36px;
  `}

  ${media.down("NEW_HALF_WEB")`
    font-size: 24px;
  `}

  ${media.down("NEW_TABLET")`
    font-size: 20px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    margin-top: 0px;
    font-size: 16px;
  `}
`;

export const FormDeck = styled.div`
  width: 60%;
  height: 800px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  align-self: center;
`;

export const FormInnerWrapper = styled.div<{ formSent?: boolean }>`
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  transform: rotateY(${p => (p.formSent ? -180 : 0)}deg);
`;

export const FormCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
`;

export const FormSentCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  z-index: -1;
  display: block;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
`;

export const FormSentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 164px;
  > img {
    height: 178px;
  }

  ${media.down("NEW_SMALL_WEB")`
   > img {
      height: 158px;
    }
   > h2 {
     font-size: 36px;
   }
   > p {
     font-size: 20px;
   }
  `}

  ${media.down("NEW_TABLET")`
    > img {
        height: 188px;
      }
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    > img {
        height: 148px;
      }
    > h2 {
      font-size: 32px;
    }
    > p {
      font-size: 18px;
    }
  `}

  ${media.down("NEW_MOBILE")`
    > h2 {
      font-size: 30px;
    }
  `}

  ${media.down("NEW_SMALL_MOBILE")`
   > img {
        height: 128px;
      }
    > h2 {
      font-size: 28px;
    }
    > p {
      font-size: 16px;
    }
  `}
`;

export const FormSentHeading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  color: #f0f5f5;
  max-width: 470px;
  margin-top: 16px;
`;

export const FormSentDescription = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  line-height: 145%;
  text-align: center;
  color: #b6c3c3;
  flex: none;
  margin: 16px 0px;
  max-width: 350px;
`;

export const FormSentButtonWrapper = styled.div`
  width: 160px;
  cursor: pointer;
  margin-top: 106px;
  ${media.up("NEW_LARGE_WEB")`
    width: 220px;
  `}
`;
