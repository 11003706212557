import styled from "styled-components";
import { media } from "../../shared/styles/media";

export const TitleBadges = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 56px;

  ${media.down("TABLET_SMALL")`
    margin-top: 48px;
  `}

  ${media.down("MOBILE_LARGE")`
    margin-top: 56px;
  `}
`;

export const TitleBadgeLink = styled.a`
  transition: transform 0.3s ease-in;
  :hover {
    transform: scale(1.1);
  }
`;

export const TitleBadgeImage = styled.img`
  height: 56px;
  width: auto;

  ${media.down("TABLET_SMALL")`
    height: 48px;
  `}
`;

export const TitleVideoGradient = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100px;
  width: 100%;
  background: rgb(24, 26, 27);
  background: linear-gradient(0deg, rgba(24, 26, 27, 1) 33%, rgba(0, 0, 0, 0) 100%);
`;

export const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const TitleSectionVideoContainer = styled.div`
  pointer-events: none;
  position: absolute;
  left: 64px;
  right: 64px;
  top: 0px;
  z-index: -1;
  bottom: 0px;

  ${media.down("LAPTOP")`
    left: 56px;
    right: 56px;
  `}

  ${media.down("TABLET")`
    left: 44px;
    right: 44px;
  `}

  ${media.down("TABLET_SMALL")`
    left: 40px;
    right: 40px;
  `}
  
  ${media.down("MOBILE_LARGE")`
    left: 0px;
    right: 0px;
  `}

  ${media.down("MOBILE")`
    position: static;
    height: 100%;
    margin-left: 40px;
    margin-right: 40px;
  `}

  ${media.down("MOBILE_LARGE")`
    margin-left: 24px;
    margin-right: 24px;
  `}

  ${media.down("MOBILE_SMALL")`
    margin-left: 20px;
    margin-right: 20px;
  `}
`;

export const TitleSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 72px;
  position: relative;
  min-height: 75%;

  ${media.down("LAPTOP")`
    min-height: 550px;
  `}
`;

export const CityImage = styled.div``;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .rain {
    position: absolute;
    left: 0;
    width: 100%;
    height: 150vh;
    z-index: 5;
  }

  .rain.back-row {
    /* display: none; */
    z-index: 1;
    bottom: 60px;
    opacity: 0.5;
  }

  .back-row-toggle .rain.back-row {
    display: block;
  }

  .drop {
    position: absolute;
    bottom: 100%;
    width: 15px;
    height: 120px;
    pointer-events: none;
    animation: drop 0.5s linear infinite;
  }

  @keyframes drop {
    0% {
      transform: translateY(0vh);
    }
    75% {
      transform: translateY(90vh);
    }
    100% {
      transform: translateY(90vh);
    }
  }

  .stem {
    width: 1px;
    height: 60%;
    margin-left: 7px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
    animation: stem 0.5s linear infinite;
  }

  @keyframes stem {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .splat {
    width: 15px;
    height: 10px;
    border-top: 2px dotted rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
    animation: splat 0.5s linear infinite;
    display: none;
  }

  splat-toggle .splat {
    display: block;
  }

  @keyframes splat {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    80% {
      opacity: 1;
      transform: scale(0);
    }
    90% {
      opacity: 0.5;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }

  .toggles {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .toggle {
    position: absolute;
    left: 20px;
    width: 50px;
    height: 50px;
    line-height: 51px;
    box-sizing: border-box;
    text-align: center;
    font-family: sans-serif;
    font-size: 10px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .toggle:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .toggle:active {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .toggle.active {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .splat-toggle {
    top: 20px;
  }

  .back-row-toggle {
    top: 90px;
    line-height: 12px;
    padding-top: 14px;
  }

  .single-toggle {
    top: 160px;
  }

  single-toggle .drop {
    display: none;
  }

  single-toggle .drop:nth-child(10) {
    display: block;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  max-width: 1920px;
  box-sizing: border-box;
  margin-top: 82px;
  margin-bottom: 128px;

  ${media.down("FULL")`
    padding: 0px 360px 0px 360px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.down("MAIN_WEB")`
    padding: 0px 200px 0px 200px;
  `}

  ${media.down("LAPTOP")`
    padding: 0px 144px 0px 144px;
  `}

  ${media.down("TABLET")`
    padding: 0px 104px 0px 104px;
    margin-top: 60px;
  `}

  ${media.down("TABLET_SMALL")`
    padding: 0px 96px 0px 96px;
  `}

  ${media.down("MOBILE_LARGE")`
    padding: 0px 48px 0px 48px;
  `}

  ${media.down("MOBILE")`
    padding: 0px 48px 0 48px;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
  `}
  
  ${media.down("MOBILE_SMALL")`
    padding: 0px 40px 0px 40px;
  `}
`;

export const TrustpilotContainer = styled.div`
  margin-bottom: 12px;
`;

export const TitleSectionHeading = styled.h1`
  margin: 0px;
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 75px;
  line-height: 105%;
  color: #f0f5f5;

  ${media.down("TABLET")`
    font-size: 65px;
  `}

  ${media.down("MOBILE")`
    font-size: 60px;
  `}

  ${media.down("MOBILE_SMALLEST")`
    font-size: 48px;
  `}
`;

export const TitleDescription = styled.h3`
  margin: 12px 0px 0px 0px;
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 47px;
  line-height: 100%;
  color: #f0f5f5;

  ${media.down("TABLET")`
    font-size: 42px;
  `}

  ${media.down("MOBILE")`
    font-size: 39px;
  `}

  ${media.down("MOBILE_SMALLEST")`
    font-size: 32px;
  `}
`;

export const ProductCardsContainer = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.down("FULL")`
  
  > :first-child {
    margin-right: 44px;
  }

  > :last-child {
    margin-left: 44px;
  }
  `}

  ${media.down("MAIN_WEB")`
  
  > :first-child {
    margin-right: 44px;
  }

  > :last-child {
    margin-left: 44px;
  }
  `}

  ${media.down("LAPTOP")`
  
  > :first-child {
    margin-right: 32px;
  }

  > :last-child {
    margin-left: 32px;
  }
  `}

  ${media.down("TABLET_LARGE")`
  
  > :first-child {
    margin-right: 16px;
  }

  > :last-child {
    margin-left: 16px;
  }
  `}
 

  ${media.down("TABLET_SMALL")`
  flex-direction: column;
  > :first-child {
    margin-right: 0;
  }

  > :last-child {
    margin-top: 50px;
    margin-left: 0;
  }

  
  `}
`;
