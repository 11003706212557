import React, { FunctionComponent } from "react";
import { useIsServicesSectionLayoutChange } from "../../../common/hooks/useMedia";
import ServicesDesktop from "./ServicesDesktop/ServicesDesktop";
import ServicesMobile from "./ServicesMobile/ServicesMobile";

const Services: FunctionComponent = () => {
  const isMobileLayout = useIsServicesSectionLayoutChange();
  return isMobileLayout ? <ServicesMobile /> : <ServicesDesktop />;
};

export default Services;
