import styled, { css } from "styled-components";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import { BREAKPOINTS } from "../../shared/styles/const";
import { media } from "../../shared/styles/media";
import Background from "./IconsBackground.png";

export const HighestStandardSection = styled(SectionWrapper)<{ hideBackground?: boolean }>`
  overflow: hidden;
  position: relative;
  z-index: 1;
  ${p =>
    !p.hideBackground &&
    css`
      background-image: url(${Background});
      background-repeat: no-repeat;
      background-position-y: bottom;
      background-size: contain;
    `};
  padding-bottom: 250px !important;
  ${media.down("TABLET_LARGE")`
  background-position-y: 70%;
  `}

  ${media.up("MAIN_WEB")`
  background-position-y: 80%;
  background-position-x: right;
  background-size: 50%;
  `}
  ${media.up("NEW_LARGE_WEB")`
  background-position-y: 85%;
  `}

  ${media.up("FULL")`
  background-position-y: 95%;
  max-width: unset;
  `}
`;

export const HighestStandardTitleWrapper = styled.div`
  position: relative;
  z-index: 9;
`;

export const HighestStandardTitle = styled.h2`
  margin: 0;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 104%;
  letter-spacing: -0.02em;
  color: #0e1b1b;

  ${media.down("NEW_SMALL_WEB")`
    font-size: 56px;
  `}
  ${media.down("TABLET_SMALL")`
    font-size: 54px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 48px;
  `}
  ${media.down("MOBILE")`
    font-size: 44px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 40px;
  `}
`;

export const HighestStandardTitleRed = styled(HighestStandardTitle)`
  font-size: 104px;
  line-height: 108%;
  color: #dd2736;

  ${media.down("NEW_SMALL_WEB")`
    font-size: 96px;
  `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 80px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 64px;
  `}
  ${media.down("MOBILE")`
    font-size: 56px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 52px;
  `}
`;

export const HighestStandardsDrawingLine = styled.div`
  position: absolute;
  top: -4px;
  left: -40px;
  ${media.down("NEW_LARGE_MOBILE")`
    bottom: -10px;
    top: unset;
    width: 320px;
    left: 10px;
  `}

  ${media.down("MOBILE_MLARGE")`
    bottom: 0;
    width: 260px;
  `}

  ${media.down("MOBILE")`
    width: 240px;
  `}
  ${media.down("NEW_MOBILE")`
    width: 220px;
  `}
`;
export const HighestStandardVideoOuterWrapper = styled.div`
  height: calc(100vw * 0.42 - 100px);

  ${media.down("NEW_LARGE_MOBILE")`
  height: calc(100vw * 0.42);
    `}
`;
export const HighestStandardVideoWrapper = styled.div<{ isSamsungInternet?: boolean }>`
  width: 100vw;
  position: absolute;
  left: 0;
  top: 150px;
  video {
    height: 100%;
    width: 100%;
  }
  ${media.down("NEW_LARGE_MOBILE")`
    
    video {
    height: 275px;
    width: 125%;
    object-fit: cover;
    object-position: right;
  }
    `}
  @media all and (max-width: 400px) {
    top: 180px;
  }

  ${p =>
    p.isSamsungInternet &&
    css`
      ${media.down("NEW_LARGE_MOBILE")`
  top: 260px;
`}
      @media all and (min-height: 700px) {
        top: 200px;
      }
    `}
`;

export const HighestStandardVideoGradient = styled.div<{ bottom?: boolean }>`
  position: absolute;
  height: 50px;
  width: 100%;
  background: linear-gradient(0deg, rgba(239, 233, 229, 0) 0%, #efe9e5 52%);
  ${p =>
    p.bottom &&
    css`
      margin-top: -20px;
      transform: rotate(180deg);
    `}
`;

export const StandardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.down("NEW_LARGE_MOBILE")`
  flex-direction: row;
  margin-bottom: 24px;

  `}
`;

export const MedicalStandardsWrapper = styled.div<{ isSamsungInternet?: boolean }>`
  width: 100%;
  height: 100%;
  margin-top: 32px;
  position: relative;
  ${media.down("NEW_LARGE_MOBILE")`
    margin-top: 64px;
  `}
  ${p =>
    p.isSamsungInternet &&
    css`
      margin-top: 180px !important;
    `};
`;

export const MedicalStandardsBackground = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: calc(100vw * 0.649);
  //background-image: url(${Background});

  z-index: -1;
`;

export const MedicalStandardsInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 56px;
  ${media.down("NEW_TABLET")`
    margin-bottom: 40px;
    `}
  ${media.down("NEW_LARGE_MOBILE")`
    flex-direction: column-reverse;
    margin-bottom: 0px;
  `}

  > :not(:last-child) {
    margin-right: 80px;
    ${media.down("NEW_HALF_WEB")`
    margin-right: 42px;
    `}
    ${media.down("NEW_TABLET")`
    margin-right: 40px;
    `}
    ${media.down("NEW_LARGE_MOBILE")`
    margin-right: 0px;
    `}
  }
`;

export const IconWrapper = styled.div<{ animate: boolean; index: number }>`
  position: relative;
  display: flex;
  width: 45px;
  height: 40px;

  ${p =>
    p.animate &&
    css`
      animation: bounce 1.5s ease 2s infinite;
    `}

  animation-delay: ${p => p.index * 0.2}s;

  ${media.down("NEW_SMALL_WEB")`
    > svg {
        width: 45px;
        height: 40px;
    }
  `}
  ${media.down("NEW_LARGE_MOBILE")`
  > svg {
    width: 41px;
    height: 36px;
  }
  margin-right: 20px;
  `}
  ${media.down("MOBILE")`
  > svg {
    width: 36px;
    height: 31px;
  }
  margin-right: 16px;

  `}

  @keyframes animate {
    50% {
      transform: translateY(-205px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

export const LineOnIconWrapper = styled.div<{ index: number }>`
  position: absolute;
  width: 320px;
  top: 40%;
  left: -5px;

  ${media.down("NEW_SMALL_WEB")`
    width: 390px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    top: 10px;
    width: 360px;
  `}
  ${media.down("MOBILE")`
    top: 10px;
    width: 320px;
  `}

  path {
    animation-delay: ${p => p.index * 0.8}s !important;
    @media all and (max-width: ${BREAKPOINTS.NEW_LARGE_MOBILE.max}px) {
      //reverse indexes
      animation-delay: ${p => (p.index % 2 === 0 ? p.index - 1 : p.index + 1) * 0.8}s !important;
    }
  }
`;

export const StandardContentWrapper = styled.div<{ smallerBox?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 360px;
  ${media.down("NEW_HALF_WEB")`
    width: 320px;
  `}
  ${media.down("NEW_TABLET")`
     width: 250px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    width: 288px;
  `}
  ${media.down("MOBILE")`
    width: 260px;
  `}
  ${media.down("NEW_MOBILE")`
    width: 240px;
  `}

  ${p =>
    p.smallerBox &&
    css`
      width: 320px;
      ${media.down("NEW_HALF_WEB")`
    width: 280px;
  `}
      ${media.down("NEW_TABLET")`
     margin-left:0px;
     width: 230px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    width: 288px;
  `}
  ${media.down("MOBILE")`
    width: 260px;
  `}
  ${media.down("NEW_MOBILE")`
    width: 240px;
  `}
    `}
`;

export const TheMarketLine = styled.div`
  display: inline;
  ${media.down("NEW_LARGE_MOBILE")`
    display: block;
    text-align: right;
  `}
`;

export const StandardContentHeader = styled.h4`
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-transform: capitalize;
  color: #0e1b1b;
  white-space: nowrap;

  ${media.down("NEW_TABLET")`
    white-space: normal;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 18px;
    max-width: unset;
  `}
`;

export const StandardContentDescription = styled.p`
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  color: #514d4d;
  margin-top: 8px;

  > span {
    color: #dd2736;
    font-weight: 500;
  }
`;
