import styled from "styled-components";
import { media } from "../../../styles/media";

export const FakeInput = styled.span`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: flex;
  border: 1.5px solid #374343;
  align-items: center;
  justify-content: center;
  margin-left: 37px;
  position: relative;

  ${media.down("NEW_HALF_WEB")`
    margin-left: 16px;
  `}

  ${media.down("NEW_TABLET")`
    margin-left: 0px;
  `}
`;

export const SelectedFakeInput = styled.span`
  display: flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  transition: background-color 0.5s ease;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const WhiteDot = styled.span`
  position: absolute;
  display: flex;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: -10px;
  transition: top 0.5s ease, background-color 0.7s ease, transform 0.6s ease;
  transform: scale(1.5, 0.3);
`;

export const RadioLabel = styled.span`
  display: flex;
  line-height: 1.43;
  font-weight: normal;
  font-size: 20px;
  line-height: 145%;
  color: #b6c3c3;
  margin-left: 16px;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 26px;
  `}

  ${media.down("NEW_HALF_WEB")`
    font-size: 18px;
    margin-left: 12px;
  `}

  ${media.down("NEW_TABLET")`
    margin-left: 0px;
    margin-top: 4px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 16px;
    margin-top: 8px;
  `}
`;

export const RadioButtonCard = styled.label`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 1.5px solid rgba(148, 168, 167, 0.32);
  border-radius: 14px;
  ${media.down("NEW_TABLET")`
    flex-direction: column;
    justify-content: center;
  `}
`;

export const RadioButtonContent = styled.div`
  height: 64px;
  width: 212px;
  position: relative;
  transition: transform 0.4s ease;

  ${media.up("NEW_LARGE_WEB")`
    width: 268px;
    height: 80px;
  `}

  &:hover {
    transform: scale(1.03);
    cursor: pointer;

    ${RadioButtonCard} {
      border-color: #48c7c3;
    }
  }

  ${media.down("NEW_HALF_WEB")`
    width: 174px;
  `}

  ${media.down("NEW_TABLET")`
    width: 142px;
    height: 80px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    width: 118px;
    height: 88px;
  `}

  ${media.down("NEW_MOBILE")`
    width: 101px;
    height: 88px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
    width: 80px;
  `}
`;

export const InvisibleCheckbox = styled.input`
  position: absolute;
  z-index: 1000;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:checked + ${RadioButtonCard} {
    border: 1.5px solid #48c7c3;
    ${FakeInput} {
      border: none;
      margin-left: 40px;
      ${media.down("NEW_HALF_WEB")`
        margin-left: 20px;
      `}

      ${media.down("NEW_TABLET")`
        margin-left: 0px;
        margin-top: 3px;
      `}
      ${SelectedFakeInput} {
        background-color: #48c7c3;
      }
      ${WhiteDot} {
        background-color: #f0f5f5;
        top: 8px;
        transform: scale(1);
      }
    }

    ${RadioLabel} {
      color: #f0f5f5;
    }
  }
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 5000;
  justify-content: center;
  > :nth-child(2) {
    margin: 0 24px;
  }

  ${media.down("NEW_HALF_WEB")`
    > :nth-child(2) {
      margin: 0 20px;
    }
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    > :nth-child(2) {
      margin: 0 8px;
    }
  `}
`;
