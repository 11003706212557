import React, { FunctionComponent, useEffect, useRef } from "react";
import { DrawingSVGWrapper } from "./AutoDrawingSVG.components";

export interface PathOptions {
  d: string;
  strokeWidth?: string;
  stroke: string;
  strokeLinecap?: any;
}

const AutoDrawingSVG: FunctionComponent<{ pathOptions: PathOptions; shouldDraw: boolean; drawingDuration?: number }> =
  ({ pathOptions, shouldDraw, drawingDuration }) => {
    const path = useRef<SVGPathElement>(null);
    const initialLength = useRef<number>(0);
    useEffect(() => {
      if (path && path.current) initialLength.current = path.current.getTotalLength();
    }, []);

    return (
      <DrawingSVGWrapper pathLength={initialLength.current} draw={shouldDraw} time={drawingDuration}>
        <svg
          preserveAspectRatio="none"
          width="520"
          height="197"
          viewBox="0 0 520 197"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path ref={path} {...pathOptions} />
        </svg>
      </DrawingSVGWrapper>
    );
  };

export default AutoDrawingSVG;
