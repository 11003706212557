import styled, { css } from "styled-components";
import { media } from "../../../shared/styles/media";

const VideoImageCommonStyle = () => css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  object-position: top;
  object-fit: cover;
  height: 100%;

  ${media.down("MOBILE")`
    object-fit: contain;
  `}
`;

export const TitleSectionVideo = styled.video`
  ${VideoImageCommonStyle()}
`;

export const TitleSectionImage = styled.img`
  ${VideoImageCommonStyle()}
`;
