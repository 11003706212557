import React, { FunctionComponent } from "react";

const ConditionalWrapper: FunctionComponent<{ condition: boolean; wrapper: FunctionComponent; children: any }> = ({
  condition,
  wrapper,
  children,
}) => {
  return condition ? wrapper(children) : children;
};

export default React.memo(ConditionalWrapper);
