import React from "react";
import ScaledContent from "../../shared/components/ScaledContent/ScaledContent";
import ClientOpinions from "../ClientOpinions/ClientOpinions";
import { LandingOpinions } from "../ClientOpinions/opinionsConfig";
import { CourierClientOpinionsWrapper } from "./CouriersClientOpinions.components";

const CouriersClientOpinions = () => {
  return (
    <CourierClientOpinionsWrapper>
      <ScaledContent>
        <ClientOpinions opinionsConfig={LandingOpinions.COURIERS} />
      </ScaledContent>
    </CourierClientOpinionsWrapper>
  );
};

export default CouriersClientOpinions;
