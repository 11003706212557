import React, { FunctionComponent } from "react";
import { MedicalRoutes } from "../../../config/routes";
import InNumbers from "../../CompanyLanding/InNumbers/InNumbers";
import BouncedShapeWithTransparency from "../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import SEOMarker from "../../shared/components/SEO/SEOMarker";

const InNumbersMedical: FunctionComponent<{ inView: boolean }> = ({ inView }) => {
  return (
    <div className="inNumbersMedical" style={{ position: "relative", backgroundColor: "#D8E3E3" }}>
      <SEOMarker routeInfo={MedicalRoutes.medicalInNumbers} />
      <InNumbers lightSectionInView={inView} isMedical />
      <BouncedShapeWithTransparency
        inBottomOfSection={true}
        trigger="inNumbersMedical"
        topShapeBackground="#161718"
        bottomShapeBackground="transparent"
      />
    </div>
  );
};

export default InNumbersMedical;
