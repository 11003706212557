import React, { useEffect, useRef } from "react";
import {
  OurTeamContainer,
  OurTeamFloatingText,
  OurTeamFloatingTextContainer,
  OurTeamFloatingTextWrapper,
} from "./OurTeam.components";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import OurTeamAlexTomasz from "./OurTeamAlexTomasz";
import OurTeamMiddlePart from "./OurTeamMiddlePart";
import OurTeamVideoSection from "./OurTeamVideoSection";
import OurTeamThirdPart from "./OurTeamThirdPart";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CompanyRoutes } from "../../../config/routes";

const OurTeam = () => {
  const minValue = useRef(0);
  const [width, height] = useWindowSize();

  useEffect(() => {
    minValue.current = 0;
  }, [width, height]);

  return (
    <OurTeamContainer className="ourTeamContainer">
      <OurTeamFloatingTextWrapper>
        <SEOMarker routeInfo={CompanyRoutes.ourTeam} offset={-100} />
        <OurTeamAlexTomasz />
        <OurTeamFloatingTextContainer>
          <OurTeamFloatingText>
            Our
            <br />
            Team
          </OurTeamFloatingText>
        </OurTeamFloatingTextContainer>
        <OurTeamMiddlePart />
      </OurTeamFloatingTextWrapper>
      <OurTeamThirdPart />

      <OurTeamVideoSection />
    </OurTeamContainer>
  );
};

export default React.memo(OurTeam);
