import styled, { css } from "styled-components";
import { media } from "../../shared/styles/media";
import TransportBg from "./TransportBg.png";

export const TransportContainer = styled.div<{ hideBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: black;
  ${p =>
    !p.hideBackground &&
    css`
      background-image: url(${TransportBg});
    `}
  background-size: 100% 100%;
  align-items: center;
  overflow: hidden;
  margin-top: -60px;

  ${media.down("NEW_TABLET")`
    margin-top: -92px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    margin-top: -80px;
  `}
`;

export const TransportHeading = styled.h4`
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 145%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #8a7575;

  ${media.down("NEW_SMALL_WEB")`
    font-size: 16px;
   `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 16px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 20px;
  `} 
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 16px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 16px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 14px;
  `}
`;

export const TransportOptionWrapper = styled.div`
  min-width: 400px;
  text-align: center;
  overflow: hidden;
`;

export const TransportButton = styled.div<{ right?: boolean }>`
  width: 52px;
  height: 52px;
  cursor: pointer;
  ${p =>
    p.right &&
    css`
      transform: scaleX(-1);
      margin-left: 8px;
    `}

  > svg {
    transition: transform 0.5s ease;
  }

  ${media.down("NEW_TABLET")`
    width: 72px;
    height: 72px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    width: 64px;
    height: 64px;
  `}
    @media
    (hover: hover) and (pointer: fine) {
    :hover {
      > svg {
        transform: scale(0.9);
      }
    }
  }
  :active {
    > svg {
      opacity: 0.5;
      transition: opacity 0.1s ease;
    }
  }
`;

export const TransportButtonsWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin-top: 16px;
  z-index: 999;
  ${p =>
    p.isMobile
      ? css`
          margin-bottom: 52px;
          ${TransportButton} {
            width: 72px;
            height: 72px;
          }
        `
      : css``}
`;

export const TransportSliderWrapper = styled.div`
  width: 100vw;
  height: 580px;
  margin-top: -1vh;
  overflow: hidden;
  max-width: 1920px;

  ${media.up("NEW_LARGE_WEB")`
    height: 70vh;
    overflow: visible;
  `}
  ${media.down("NEW_SMALL_WEB")`
      height: 480px;
  `}
  ${media.down("NEW_HALF_WEB")`
    height: 530px;
  `}
  ${media.down("NEW_MOBILE")`
    height: 420px;
  `}
`;

export const TransportSlide = styled.img<{ highlighted?: boolean }>`
  display: flex;
  width: 311px;
  margin: 0 5px;
  object-fit: contain;
  border-radius: 15px;
  transition: transform 0.25s ease-in-out;

  ${p =>
    p.highlighted &&
    css`
      transform: scale(1.2) translateY(15px);
      ${media.down("NEW_LARGE_MOBILE")`transform: scale(0.95) translateY(0);`}
    `}
`;

export const IndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2px;
  box-sizing: border-box;
  ${media.down("FULL")`
    padding:  0 360px;
  `}
  ${media.down("MAIN_WEB")`
    padding: 0 200px;
  `}

  ${media.down("LAPTOP")`
    padding: 0 144px ;
  `}
  ${media.down("TABLET")`
    padding:0 104px;
  `}
  ${media.down("TABLET_SMALL")`
    padding: 0 96px;
  `}

  ${media.down("MOBILE_LARGE")`
      padding: 0 48px;
  `}
  ${media.down("MOBILE")`
      padding: 0 48px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding: 0 40px;
  `}
`;

export const IndicatorBg = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 1px;
  background: rgba(164, 152, 152, 0.32);
`;

export const Indicator = styled.div<{ highlighted?: boolean }>`
  display: flex;
  background: #dd2736;
  height: 2px;
  width: 16.7%;
  transform: scaleX(0);
  transition: transform 0.5s ease;
  transform-origin: right center;

  ${p =>
    p.highlighted &&
    css`
      transform-origin: left center;
      transform: scaleX(1);
    `}
`;
