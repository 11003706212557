import styled, { css } from "styled-components";
import { media } from "../../shared/styles/media";
import GradientBackground from "./Background-Eclipse.svg";

export const UkasContainer = styled.section`
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 1920px;
  box-sizing: border-box;
  padding-bottom: 300px;
  ${media.down("FULL")`
    padding: 360px 360px 100px 360px;
    margin: auto;
  `}

  ${media.down("MAIN_WEB")`
    padding: 100px 200px 0px 200px;
  `}

  ${media.down("LAPTOP")`
    padding: 100px 144px 100px 144px;
  `}
  ${media.down("TABLET")`
    padding: 80px 104px 50px 104px;
  `}
  ${media.down("TABLET_SMALL")`
    padding: 70px 96px 30px 96px;
  `}

  ${media.down("MOBILE_LARGE")`
      padding: 100px 48px 0px 48px;
  `}
  ${media.down("MOBILE")`
      padding: 100px 48px 0 48px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding: 100px 40px 0px 40px;
  `}
`;

export const UkasInnerContainer = styled.div`
  flex: 1;
  //display: flex;
  position: relative;
  padding-bottom: 100px;
`;

export const UkasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const UkasHeading = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 96px;
  line-height: 108%;
  letter-spacing: -0.02em;
  color: #f0f5f5;
  max-width: 549px;
  margin-bottom: 25px;

  ${media.down("LAPTOP")`
    font-size: 84px;
    margin-bottom: 32px;
  `}
  ${media.down("TABLET")`
  font-size: 72px;
  margin-bottom: 40px;
  `}
  ${media.down("TABLET_SMALL")`
  font-size: 96px;
  margin-bottom: 56px;
  max-width: 300px
  `}

  ${media.down("MOBILE_MLARGE")`
  max-width: 200px;
  font-size: 56px;
  margin-bottom: 28px;
  `}
  ${media.down("MOBILE_SMALL")`
  font-size: 56px;
  margin-bottom: 28px;
  `}
`;

export const UkasDescriptionWrapper = styled.div`
  position: relative;
  width: 70%;
  ${media.down("TABLET_SMALL")`
  width: 90%;
  `}
  ${media.down("MOBILE_LARGE")`
  width: 90%;
  `}
  p {
    ${media.down("TABLET_SMALL")`
      max-width: 250px
  `}
  }
`;

export const UkasSeparator = styled.span<{ short?: boolean }>`
  display: inline-block;
  position: relative;
  z-index: 10;
  height: 1px;
  width: ${p => (p.short ? 70 : 100)}%;
  background: rgba(164, 152, 152, 0.32);
  margin-left: -135px;

  ${media.down("LAPTOP")`
  margin-left: -80px;
  
  `}
  ${media.down("TABLET_SMALL")`
  margin-left: -70px;
  `}

  ${media.down("MOBILE")`
  margin-left: -15px;
  `}
  ${media.down("MOBILE_SMALL")`
  margin-left: -7px;
  `}
`;

export const UkasDescription = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 28px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #b6c3c3;
  max-width: 476px;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 34px;

  ${media.down("LAPTOP")`
    font-size: 26px;
    margin-bottom: 43px;
  `}
  ${media.down("TABLET")`
    font-size: 20px;
    margin-bottom: 32px;
  `}

  ${media.down("TABLET_SMALL")`
    margin-top: 35px;
    margin-bottom: 39px;
  `}

  ${media.down("MOBILE")`
  font-size: 20px;
  margin-top: 33px;
  margin-bottom: 40px;
  `}

  ${media.down("MOBILE_SMALL")`
  font-size: 18px;
  margin-top: 32px;
    margin-bottom: 35px;
  `}
`;

export const UkasVideoWrapper = styled.div`
  position: absolute;

  background: url(${GradientBackground});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: -100px;
  right: -10%;
  height: 120%;
  width: 600px;
  text-align: right;
  ${media.down("TABLET_LARGE")`
  width: 500px;
  top: -50px;
  right: -15%;
  `}
  ${media.down("TABLET")`
  width: 450px;
  `}
  ${media.down("TABLET_SMALL")`
  right: -100px;
  width: 400px;
  height: 100%;
  background-size: 100% 100%;
  background-position-y: -100px;
  `}
  ${media.down("MOBILE_LARGE")`
  top: -100px;
  right: -60px;
  `}
  ${media.down("MOBILE_MLARGE")`
  top: -100px;
  right: -60px;
  width: 350px;
  `}
  ${media.down("MOBILE")`
  width: 350px;
  right: -30px;
  top: -80px;
  > div {
    width: 250px;
    margin: 0 0 0 auto;
  }
  `}

  ${media.down("MOBILE_TABLE")`
  
  right: -50px;
  width: 350px;


 
  `}
  ${media.down("MOBILE_SMALL")`
  top: -40px;
  right: -50px;
  width: 300px;
  > div {
    width: 200px;
  }
  `}
`;

interface BadgeWrapperProps {
  isButton?: boolean;
}

export const BadgeWrapper = styled.div<BadgeWrapperProps>`
  position: absolute;
  bottom: 0;
  width: 104px;
  height: 104px;
  cursor: pointer;
  ${p =>
    p.isButton &&
    css`
      > svg {
        transition: 0.4s ease;
        :hover {
          transform: scale(1.2);
          cursor: pointer;
        }
      }
    `}
  ${media.down("TABLET")`
      bottom: -30px;
    `}
    ${media.down("TABLET_SMALL")`
    bottom: 20px;
    `}

  ${media.down("MOBILE")`
  > svg {
  width: 90px;
  height: 90px;
  }
  `}
  ${media.down("MOBILE_SMALL")`
  right: 0px;
  bottom: -30px;
  `}
`;
