import React, { FunctionComponent, useEffect, useRef } from "react";
import { services } from "../ServicesSection/ServicesSection";
import { timeInformations } from "../TimeSection/TimeSection";
import ScrollTrigger from "../../../../common/ScrollTrigger";
import {
  ServicesMobileContainer,
  ServicesSectionMobileWrapper,
  ServicesMobileVideoPlayer,
  ServicesMobileBoxesWrapper,
  ServicesMobileStickyWrapper,
  TimeSectionMobileWrapper,
  TimeMobileVideoPlayer,
  TimeMobileContentWrapper,
  ScrollDownBadge,
  ScrollDownBadgeWrapper,
  TimeInformationMobileOuterWrapper,
  TimeInformationMobileContentWrapper,
} from "./ServicesMobile.components";
import { ServicesDescription, ServicesHeading } from "../ServicesSection/ServicesSection.components";
import ServiceBox from "../ServicesSection/ServiceBox/ServiceBox";
import {
  TimeSectionHeader,
  TimeSectionDescription,
  TimeInformationDescription,
  TimeInformationHeading,
  TimeInformationWrapper,
} from "../TimeSection/TimeSection.components";
import { mobileVideos } from "../videos.config";
// @ts-ignore
import ServicesVideo from "../videos/services.webm";
// @ts-ignore
import ServicesVideoMov from "../videos/services.mov";
import { useIsMobileLargeDown } from "../../../../common/hooks/useMedia";
import BouncedShapeWithTransparency from "../../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import SEOMarker from "../../../shared/components/SEO/SEOMarker";
import { MedicalRoutes } from "../../../../config/routes";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";

const getWidth = () => {
  document.getElementById("timeInformationContentWrapper");
  const wrapper = document.getElementById("timeInformationContentWrapper");
  if (wrapper) {
    return wrapper.getBoundingClientRect().width;
  } else {
    return 0;
  }
};

const ServicesMobile: FunctionComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [inViewRef, inView] = useInView();
  const isMobileLargeDown = useIsMobileLargeDown();
  const width = useRef(0);

  useEffect(() => {
    width.current = getWidth();
    ScrollTrigger.create({
      trigger: ".timeMobileWrapper",
      start: "top top-=140",
      end: "bottom bottom",
      onUpdate: self => {
        //@ts-ignore
        gsap.to(".timeInformationWrapper", { x: self.progress * -720 });
      },
    });
  }, []);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        if (videoRef.current) videoRef.current.play();
      }, 800);
    } else {
      setTimeout(() => {
        if (videoRef.current) videoRef.current.currentTime = 0;
      }, 200);
    }
  }, [inView]);

  return (
    <ServicesMobileContainer className="servicesMobileContainer">
      <SEOMarker routeInfo={MedicalRoutes.services} />

      <ServicesSectionMobileWrapper>
        <ServicesMobileStickyWrapper>
          <ServicesHeading ref={inViewRef} className="serviceHeading">
            A Wide Variety Of <br />
            Medical Services
          </ServicesHeading>
          <ServicesDescription className="serviceDescription">
            Whatever medical test you’d like to get done, we’d love to hear about it - come talk to us.
            <br />
            <br />
            Here are the most popular ones:
          </ServicesDescription>
          <ServicesMobileVideoPlayer ref={videoRef} autoPlay muted playsInline>
            <source src={ServicesVideoMov} type="video/quicktime" />
            <source src={ServicesVideo} type="video/webm" />
          </ServicesMobileVideoPlayer>

          <ScrollDownBadgeWrapper>
            <ScrollDownBadge>
              Scroll
              <br /> down
            </ScrollDownBadge>
          </ScrollDownBadgeWrapper>
        </ServicesMobileStickyWrapper>
        <ServicesMobileBoxesWrapper>
          {services.map((service, index) => (
            <ServiceBox
              key={index}
              heading={service.heading}
              description={service.description}
              imageType={service.imageType}
              borderAnimationDelay={index}
            />
          ))}
        </ServicesMobileBoxesWrapper>
      </ServicesSectionMobileWrapper>
      <div style={{ flex: 1 }} className="timeMobileWrapper">
        <TimeSectionMobileWrapper stickToTop={true}>
          <TimeSectionHeader className="timeMobileHeader">Any Time, Any Place</TimeSectionHeader>
          <TimeMobileContentWrapper>
            <TimeSectionDescription className="timeMobileDescription">
              Book an appointment at a time and a place that suits you best, and the rest is taken care of. &nbsp;
              {!isMobileLargeDown && (
                <>
                  <br /> <br />
                </>
              )}
              Our team of medical specialists will go <span>wherever you are</span> to take samples and perform tests,
              quickly and hassle-free.
            </TimeSectionDescription>
            <TimeMobileVideoPlayer key={`mobileVideo`} className={`mobileVideoPlayer`} autoPlay loop muted playsInline>
              <source src={mobileVideos[1].mov} type="video/quicktime" />
              <source src={mobileVideos[1].webm} type="video/webm" />
            </TimeMobileVideoPlayer>
            <TimeInformationMobileContentWrapper id="timeInformationContentWrapper">
              <TimeInformationMobileOuterWrapper className="timeInformationWrapper">
                {timeInformations.map((information, index) => (
                  <TimeInformationWrapper key={index}>
                    {information.icon}
                    <TimeInformationHeading className={`${information.className}Heading`}>
                      {information.heading}
                    </TimeInformationHeading>
                    <TimeInformationDescription className={`${information.className}Description`}>
                      {information.description}
                    </TimeInformationDescription>
                  </TimeInformationWrapper>
                ))}
              </TimeInformationMobileOuterWrapper>
            </TimeInformationMobileContentWrapper>
          </TimeMobileContentWrapper>
        </TimeSectionMobileWrapper>
      </div>

      <BouncedShapeWithTransparency
        trigger="servicesMobileContainer"
        topShapeBackground="transparent"
        bottomShapeBackground="#d8e3e3"
      />
    </ServicesMobileContainer>
  );
};

export default ServicesMobile;
