import React, { useEffect } from "react";
import { OurTeamTextSubtitle } from "./OurTeam.components";
import {
  MissionDescription,
  OurTeamTitleVideo,
  PlayerContainer,
  Plus50Badge,
  SeparatorLine,
  TextContentContainer,
  UpperParagraph,
  VideoSectionContainer,
  VideoSectionWrapper,
} from "./OurTeamVideoSection.components";
import Plus50Anim from "./assets/CircleBadgePlus50.svg";
import ReactPlayer from "react-player/lazy";

// import TeamVideo from "./assets/teamvideo.mp4";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";

const OurTeamVideoSection = () => {
  // const videoRef = useRef<HTMLVideoElement>();

  const [inViewRef, inView, entry] = useInView();

  useEffect(() => {
    if (entry && entry.boundingClientRect.top > 0) {
      if (inView) {
        gsap.to(".videoText", { y: -80, duration: 1 });
      } else {
        gsap.to(".videoText", { y: 80, duration: 1 });
      }
    }
  }, [inView]);

  return (
    <VideoSectionContainer>
      <OurTeamTitleVideo>
        Our
        <br />
        Team
      </OurTeamTitleVideo>
      <SeparatorLine />
      <VideoSectionWrapper>
        {inView && (
          <Plus50Badge>
            <object type="image/svg+xml" data={Plus50Anim}></object>
          </Plus50Badge>
        )}
        <TextContentContainer ref={inViewRef} className="videoText">
          <OurTeamTextSubtitle style={{ marginTop: 80 }}>Mission</OurTeamTextSubtitle>
          <MissionDescription>Always with care & on time</MissionDescription>
          <UpperParagraph>
            With the whole team taking care of day-to-day medical services for you. Medical appointments, quick and
            convenient testing - blood, COVID, and others as you need.
          </UpperParagraph>
          <UpperParagraph>
            With the whole couriers crew taking care of safely placing your shipments anywhere in London and the whole
            UK if specially requested. GMP certified and dedicated - exclusively medical shipments.
          </UpperParagraph>
        </TextContentContainer>
        <PlayerContainer>
          <ReactPlayer
            url="https://vimeo.com/643455124/b6e1348506"
            playing
            // autoPlay
            muted
            loop
            playsinline
            allowFullScreen
            controls={false}
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              pointerEvents: "none",
            }}
          />
        </PlayerContainer>
      </VideoSectionWrapper>
    </VideoSectionContainer>
  );
};

export default OurTeamVideoSection;
