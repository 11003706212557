import React, { FunctionComponent, useEffect } from "react";
import MedicalDirector from "../MedicalDirector/MedicalDirector";
import Services from "../Services/Services";
import { Wrapper } from "./MedicalLandingLayout.components";
import Introduction from "../Introduction/Introduction";
import { ClientsStory } from "../ClientsStory/ClientsStory";
import GetInTouch from "../GetInTouch/GetInTouch";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";
import Footer from "../Footer/Footer";
import CookiesBox from "../../shared/components/CookiesBox/CookiesBox";
import InNumbersMedical from "../InNumbersMedical/InNumbersMedical";
import { allRoutes } from "../../../config/routes";
import NavbarMedical from "../NavbarMedical/NavbarMedical";
import { useInView } from "react-intersection-observer";

export interface PageComponentProps {
  index: number;
}

const MedicalLandingLayout: FunctionComponent = () => {
  const [lightSectionsInViewRef, lightSectionsInView] = useInView();

  const getSectionToScroll = () => {
    const path = window.location.pathname;
    let className;
    const routes = allRoutes();

    Object.keys(routes.MEDICAL).map((key: string) => {
      if (routes.MEDICAL[key].url === path.substring(1)) className = routes.MEDICAL[key].className;
    });
    return `seoMarker ${className}`;
  };
  const orientationHandler = () => {
    // const path = window.location.pathname
    const el = document.getElementsByClassName(getSectionToScroll());

    setTimeout(() => el[0].scrollIntoView(true), 200);
  };

  useEffect(() => {
    window.addEventListener("orientationchange", orientationHandler);
    return () => window.removeEventListener("orientationchange", orientationHandler);
  }, []);

  return (
    <Wrapper id="layout">
      <NavbarMedical />
      <Introduction />
      <Services />
      <div className="darkSectionsMedical">
        <ClientsStory />
        <WhyChooseUs />

        <MedicalDirector />
      </div>
      <div ref={lightSectionsInViewRef}>
        <InNumbersMedical inView={lightSectionsInView} />
        <GetInTouch />
        <Footer isLightFooter={false} />
      </div>
      <CookiesBox />
    </Wrapper>
  );
};

export default MedicalLandingLayout;
