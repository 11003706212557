import styled from "styled-components";

export const BouncedBoxWrapper = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;

  height: 100;
  width: 100%;
  z-index: 999;
`;

export const BouncedBoxInsideWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BouncedBox = styled.div`
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  //background-color: #d8e3e3;
  border: solid 0px;
  border-color: #d8e3e3 transparent transparent transparent;
  border-radius: 50% 50% 0 0;
  box-sizing: border-box;
  z-index: -1;
  //background-color: red;
  //animation: animationBorder 2s infinite;

  @keyframes animationBorder {
    0% {
      border-radius: 0px;
      height: 0;
    }
    40% {
      border-radius: 50%/50px 50px 0 0;
      height: 30px;
    }
    100% {
      border-radius: 0px;
      height: 0;
    }
  }
`;

export const BouncedBoxTop = styled(BouncedBox)`
  background-color: #181a1b;
  border-radius: 0 0 50% 50%;
  //background-color: green;
  bottom: 0px;
  display: block;
  z-index: 99999;
`;
