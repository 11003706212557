import styled from "styled-components";
import { UkasContainer } from "../../../CompanyLanding/Ukas/Ukas.components";
import { colors } from "../../styles/colors";
import { media } from "../../styles/media";

export const PrivacyAndTermsWrapper = styled(UkasContainer)`
  width: 100%;

  ${media.down("TABLET")`
    margin-top: 50px;
  `}
`;

export const PrivacyHeader = styled.p`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;

  color: #f0f5f5;
  margin-bottom: 20px;
`;

export const PrivacySubheader = styled.p`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;

  color: #f0f5f5;
  margin-bottom: 20px;
`;
export const PrivacyContent = styled.p`
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 20px;

  color: #f0f5f5;

  > a {
    pointer-events: all;
    color: ${colors.mint._400};
    :hover {
      text-decoration: none;
    }
    :visited {
      color: ${colors.mint._600};
    }
  }
`;
