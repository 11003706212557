import React, { createContext, Dispatch, SetStateAction } from "react";
import { Animation } from "../Package/animations/animation";

export interface PackageContextType {
  pathRendered: boolean;
  setPathRendered: Dispatch<SetStateAction<boolean>>;
  animationState: Animation;
  setAnimationState: Dispatch<SetStateAction<Animation>>;
  timeCriticalAnimation: React.MutableRefObject<Animation>;
  setTimeCriticalAnimation: (animation: Animation) => void;
}

const PackageContext: React.Context<PackageContextType> = createContext<PackageContextType>({} as PackageContextType);

export default PackageContext;
