import { BREAKPOINTS } from "../../shared/styles/const";

export const getImagesHeight = (width: number) => {
  if (BREAKPOINTS.MOBILE_LARGE.max && width < BREAKPOINTS.MOBILE_LARGE.max) {
    //45vw * image ratio
    return width * 0.45 * 1.2;
  }
  if (BREAKPOINTS.TABLET_LARGE.max && width < BREAKPOINTS.TABLET_LARGE.max) {
    return 304;
  }
  if (BREAKPOINTS.LAPTOP.max && width < BREAKPOINTS.LAPTOP.max) {
    return 336;
  } else {
    return 456;
  }
};
export const getImagesFaisalHeight = (width: number) => {
  if (BREAKPOINTS.MOBILE_LARGE.max && width < BREAKPOINTS.MOBILE_LARGE.max) {
    return (width - 50) * 1.24;
  }
  if (BREAKPOINTS.TABLET_LARGE.max && width < BREAKPOINTS.TABLET_LARGE.max) {
    return 380;
  }
  if (BREAKPOINTS.LAPTOP.max && width < BREAKPOINTS.LAPTOP.max) {
    return 336;
  } else {
    return 456;
  }
};

export const getImagesDirectorsHeight = (width: number) => {
  if (BREAKPOINTS.MOBILE_LARGE.max && width < BREAKPOINTS.MOBILE_LARGE.max) {
    return (width - 48) * 0.7 * 1.25;
  }
  if (BREAKPOINTS.TABLET_LARGE.max && width < BREAKPOINTS.TABLET_LARGE.max) {
    return 380;
  }
  if (BREAKPOINTS.LAPTOP.max && width < BREAKPOINTS.LAPTOP.max) {
    return 336;
  } else {
    return 456;
  }
};
