import React, { FunctionComponent } from "react";
import { FormikProps, Form } from "formik";
import { GetInTouchFormValues, Subject, subjects } from "./form";
import {
  ButtonInnerWrapper,
  ButtonWrapper,
  GetInTouchFormContainer,
  GetInTouchInputsContainer,
  GetInTouchInputsWrapper,
  HorizontalInputsWrapper,
} from "./GetInTouchForm.components";
import { FormGroup } from "../../../shared/components/Form/FormGroup";
import InputField from "../../../shared/components/Form/Input/InputField";
import Label from "../../../shared/components/Form/Input/Label";
import RadioButton from "../../../shared/components/Form/RadioButton";
import { RadioButtonContainer, RadioLabel } from "../../../shared/components/Form/RadioButton/RadioButton.components";
import TextAreaField from "../../../shared/components/Form/Textarea/TextareaField";
import { QuoteButton } from "../../Introduction/Introduction";
import { isMobile } from "react-device-detect";

type Props = FormikProps<GetInTouchFormValues>;

const GetInTouchFormContent: FunctionComponent<Props> = ({
  values,
  submitForm,
  setFieldValue,
  resetForm,
  isValid,
  errors,
  touched,
  setFieldTouched,
}) => {
  return (
    <GetInTouchFormContainer>
      <Form autoCapitalize="off">
        <RadioButtonContainer
          onChange={async e => {
            const target = e.target as HTMLInputElement;
            await setFieldValue(target.name, target.value as Subject);
            setTimeout(() => setFieldTouched(target.name, true));
          }}
        >
          {subjects.map(subject => (
            <RadioButton
              key={subject.cardName}
              name="subject"
              checked={values.subject ? values.subject === subject.cardName : false}
              value={subject.cardName}
            >
              <RadioLabel>{subject.label}</RadioLabel>
            </RadioButton>
          ))}
        </RadioButtonContainer>
        <GetInTouchInputsContainer>
          <FormGroup>
            <Label>NAME</Label>
            <InputField name="name" placeholder="Enter your name" autoComplete="off" sizeType="normal" tabIndex={1} />
          </FormGroup>
          <GetInTouchInputsWrapper>
            <HorizontalInputsWrapper>
              <FormGroup>
                <Label>EMAIL</Label>
                <InputField
                  name="email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  sizeType="normal"
                  tabIndex={2}
                />
              </FormGroup>
              <FormGroup>
                <Label>PHONE</Label>
                <InputField
                  name="phone"
                  placeholder="Enter your phone"
                  autoComplete="off"
                  sizeType="normal"
                  type={isMobile ? "tel" : "number"}
                  tabIndex={3}
                />
              </FormGroup>
            </HorizontalInputsWrapper>
          </GetInTouchInputsWrapper>
          <GetInTouchInputsWrapper>
            <FormGroup>
              <Label>MESSAGE</Label>
              <TextAreaField
                placeholder="Explain what you need"
                name="message"
                tabIndex={4}
                style={{ borderColor: touched.message && errors.message ? "#FB7480" : "" }}
              />
            </FormGroup>
          </GetInTouchInputsWrapper>
        </GetInTouchInputsContainer>
        <ButtonWrapper>
          <ButtonInnerWrapper
            onClick={async () => {
              await submitForm();
              if (isValid) {
                document.getElementById("formSentWrapper")?.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => resetForm(), 500);
              }
            }}
          >
            <QuoteButton />
          </ButtonInnerWrapper>
        </ButtonWrapper>
      </Form>
    </GetInTouchFormContainer>
  );
};
export default GetInTouchFormContent;
