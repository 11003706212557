import { ScrollDownContainer, ScrollDownText } from "./../../shared/components/ScrollDown/ScrollDown.components";
import styled, { css } from "styled-components";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import { media } from "../../shared/styles/media";
import GradientBackground from "../Introduction/Background-Eclipse-Introduction.svg";
import { ButtonWrapper } from "../Introduction/Introduction.components";
import GradientCourierBackground from "./Background-Eclipse-Couriers.svg";

export const WhyChooseUsSection = styled(SectionWrapper)<{ courierLanding?: boolean }>`
  background: rgb(72, 199, 195);
  padding-bottom: 100px;
  overflow: unset;
  background: url(${p => (p.courierLanding ? GradientCourierBackground : GradientBackground)});
  background-size: 100% 100%;
  background-position: 0 0%;
  background-repeat: no-repeat;
  height: 2000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: ${p => (p.courierLanding ? "80px" : 0)};
  @media all and (max-height: 800px) {
    height: 1600px;
  }

  @media all and (max-height: 700px) {
    background-size: 100% 120%;
  }

  @media all and (max-height: 650px) {
    height: ${p => (p.courierLanding ? 1600 : 1200)}px;
  }
  ${media.up("NEW_LARGE_WEB")`
    padding-top: 200px;
  `}
`;

export const WhyChooseUsStickyWrapper = styled.div`
  position: sticky;
  width: 100%;
  top: 20%;
  @media all and (max-height: 700px) {
    top: 50px;
  }
`;

export const WhyChooseUsScrollDownContainer = styled(ScrollDownContainer)`
  margin: 0;
  position: absolute;
  bottom: -140px;
  ${media.up("NEW_LARGE_WEB")`
    bottom: -190px;
  `}
`;

export const WhyChooseUsScrollText = styled(ScrollDownText)`
  margin-top: 20px;
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`;

export const WhyChooseUsImage = styled.img`
  position: absolute;
  z-index: 9;
  top: -60px;
  right: 15px;
  width: 11vw;
  max-width: 180px;
  @media all and (max-width: 950px) {
    top: -40px;
  }
  @media all and (max-width: 850px) {
    top: -40px;
  }
`;

export const BoxMobileBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object {
    width: 100%;
    height: 100%;
  }
`;

export const WhyChooseUsTitle = styled.div`
  font-family: Barlow Semi Condensed;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -0.02em;
  text-align: center;
  position: relative;
  margin: 60px auto 120px;
  color: #f0f5f5;
  display: flex;
  justify-content: center;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 72px;
  `}
`;

export const WhyChooseUsBoxesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 40px;

  ${media.down("TABLET_LARGE")`
    gap: 24px;
  `}
  ${media.down("TABLET")`
    gap: 16px;
  `}
  ${media.down("NEW_TABLET")`
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
    > div {
    margin-bottom: 32px;
  }
  `}
`;

export const WhyChooseUsBoxBackground = styled.div<{ courierLanding?: boolean }>`
  flex: 1;
  box-sizing: border-box;
  width: 25%;
  max-width: 440px;

  max-height: 430px;
  position: relative;
  padding: 48px;
  padding-top: 8.5vw;
  transform: translateY(50px);

  ${media.down("NEW_SMALL_WEB")`
    padding: 8.5vw 36px 0 52px;
  `}
  ${p =>
    p.courierLanding &&
    css`
      @media all and (max-width: 1240px) {
        object {
          height: 300px;
        }
      }
    `};
  ${media.down("TABLET_LARGE")`
    padding: 9.5vw 22px 0 32px;
     object {
          height: 270px;
        }
  `}
  ${media.down("TABLET")`
    padding: 8.5vw 22px 0 26px;
    object {
      height: 240px;
    }
    
  `}

  ${p =>
    p.courierLanding &&
    css`
      ${media.down("TABLET")`
    padding: 8.5vw 22px 0 26px;
    object {
      height: 240px;
    }
    
  `}
    `}
  @media all and (max-width: 950px) {
    padding: 10vw 22px 0 26px;
    object {
      height: 260px;
    }
  }

  ${media.up("NEW_MAIN_WEB")`
  padding-top: 130px;
  object {
      height: 300px;
     
  }
    `}

  ${media.up("NEW_LARGE_WEB")`
    padding-top: 170px;
  object {
      height: 400px;
     
  }
  `}
`;

export const WhyChooseUsButtonWrapper = styled(ButtonWrapper)`
  margin: 50px auto 0;

  ${media.up("NEW_LARGE_WEB")`
    margin-top: 50px;
    svg {
    width: 220px;
  }
  `}
  ${media.down("MAIN_WEB")`
    margin-top: 100px;
  `}
`;

export const WhyChooseUsBoxTitle = styled.p`
  //styleName: Title/Title L;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 10px 0;
  color: #f0f5f5;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 24px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    margin-bottom: 8px;
  `}
  ${media.down("TABLET_LARGE")`
    font-size: 18px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 20px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 18px;
  `}
`;

export const WhyChooseUsBoxContent = styled.p`
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #b6c3c3;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 20px;
  `}

  ${media.down("TABLET_LARGE")`
    font-size: 14px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 16px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 14px;
  `}
`;

export const BreakLineSmallWeb = styled.br`
  display: none;
  ${media.down("NEW_SMALL_WEB")`
    display: block;
  `}
`;
