import React from "react";
import { PrivacyAndTermsWrapper, PrivacyContent, PrivacyHeader, PrivacySubheader } from "./PrivacyAndTerms.components";

const PrivacyPolicy = () => {
  return (
    <PrivacyAndTermsWrapper>
      <PrivacyHeader>Privacy and Cookies policy</PrivacyHeader>
      <PrivacyContent>
        We are Med Logistics Group Limited, company number 13698333. Our registered address is 1st Floor, 6-7 Clock Park
        Shripney Road, Bognor Regis, England, PO22 9NH.
      </PrivacyContent>
      <PrivacyContent>
        Med Logistics Group Limited (“we“, “us”) is committed to protecting and respecting your privacy. This Privacy
        and Cookies Policy (“Policy”) (together with and any other documents referred to therein) sets out the basis on
        which the personal data collected from you, or that you provide to us will be processed by us. Please read the
        following carefully to understand our views and practices regarding your personal data and how we will treat it.
      </PrivacyContent>
      <PrivacyContent>
        For the purpose of the General Data Protection Regulation (“GDPR”) and the Data Protection Act 2018
        (collectively the “Data Protection Laws”), the Data Controller is Med Logistics Group Limited.
      </PrivacyContent>
      <PrivacyHeader>Information we may collect from you</PrivacyHeader>
      <PrivacyContent>We may collect and process the following data about you:</PrivacyContent>
      <PrivacyContent>a) Information that you provide to us, including:</PrivacyContent>
      <PrivacyContent>
        (1) Information provided to us on the telephone or via employee application form or for our customers on our
        website including when you open an account with us, place orders or log on as an account holder. This will
        include your company or trading name (if any), your name or a contact name, address, telephone numbers, fax
        numbers, email address, bank details including account number. These are all details which we would require in
        order to invoice you for work carried out. If you use our on line booking service we would store usernames and
        password(s) which you have set up in order that if required we could alter/delete users as per your
        instructions. Please do not divulge user logins and passwords to other users as they will be able to use the on
        line booking on your account.
      </PrivacyContent>
      <PrivacyContent>
        (2) We would also collect data from you should you need to make a complaint or report a problem with our website
        or operational/accounts queries.
      </PrivacyContent>
      <PrivacyContent>
        (3) If you contact us we may keep a record of that correspondence and if that contact is via telephone your call
        may be recorded. The reasons for keeping these records are in order to deal with queries and for subsequent
        historical requests relating to the original requests.
      </PrivacyContent>
      <PrivacyContent>
        (4) Personal details you choose to give when corresponding with us by phone or email.
      </PrivacyContent>
      <PrivacyContent>
        (5) Details of the transactions we carry out for you either via our website include details of proof of
        delivery.
      </PrivacyContent>
      <PrivacyContent>
        Please let us know if any of this information changes so we can keep our records up to date.
      </PrivacyContent>
      <PrivacyContent>
        b) Technical information, including the Internet protocol (IP) address used to connect your computer to the
        Internet, your login information, browser type and version, time zone setting, browser plug-in types and
        versions, operating system and platform.
      </PrivacyContent>
      <PrivacyContent>
        c) We may utilise the services of credit referencing and company financial data sites in order to ascertain
        whether we are able to offer a credit facility account.
      </PrivacyContent>
      <PrivacyHeader>Uses made of your information</PrivacyHeader>
      <PrivacySubheader>Lawful basis for processing</PrivacySubheader>
      <PrivacyContent>
        We rely on legitimate interest as the lawful basis on which we collect and use your personal data. Our
        legitimate interests are performance of contracts with Customers, Couriers and our suppliers, marketing and
        business development and our internal administration and improvement.
      </PrivacyContent>
      <PrivacySubheader>Purposes of processing</PrivacySubheader>
      <PrivacyContent>We use information held about you in the following ways:</PrivacyContent>
      <div style={{ marginLeft: 25 }}>
        <PrivacyContent>
          a) To provide you or the organisation that you are engaged by with our services.
        </PrivacyContent>
        <PrivacyContent>
          b) To carry out our obligations arising from any contracts entered into between you and us. This includes:
        </PrivacyContent>
        <div style={{ marginLeft: 25 }}>
          <PrivacyContent>
            i) Where you are a Customer, to provide Couriers you have booked with the information needed for them to
            carry out their services.
          </PrivacyContent>
          <PrivacyContent>
            ii) Where you are a Courier, to provide Customers who have booked you with the information they need in
            order to receive your services.
          </PrivacyContent>
        </div>
        <PrivacyContent>
          c) To provide you with information and offers that you request from us or which we feel may interest you.
        </PrivacyContent>
        <PrivacyContent>d) To notify you about changes to our services.</PrivacyContent>
      </div>
      <PrivacyHeader>Disclosure of your information</PrivacyHeader>
      <PrivacyContent>
        We may pass your information to our third party service providers, agents, subcontractors and other associated
        organisations for the purposes of completing tasks and providing services to you on our behalf.
      </PrivacyContent>
      <PrivacyContent>
        We may disclose your personal data to any member of our corporate group, which means our subsidiaries, as
        defined in section 1159 of the UK Companies Act 2006 (where applicable).
      </PrivacyContent>
      <PrivacyContent>We may also disclose your personal data to third parties:</PrivacyContent>
      <div style={{ marginLeft: 25 }}>
        <PrivacyContent>
          a) In the event that we sell or buy any business or assets, in which case we will disclose your personal data
          to the prospective seller or buyer of such business or assets;
        </PrivacyContent>
        <PrivacyContent>
          b) If we are acquired by a third party, in which case personal data held by us about our customers will be one
          of the transferred assets; and
        </PrivacyContent>
        <PrivacyContent>
          c) If we are under a duty to disclose or share your personal data in order to comply with any legal
          obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights,
          property, or safety of our customers, our regulator, or others. This includes exchanging information with
          other companies and organisations for the purposes of fraud protection and prevention of money laundering and
          credit risk reduction.
        </PrivacyContent>
      </div>
      <PrivacyContent>
        Other than as set out above, and save insofar as is necessary in order for us to carry out our obligations
        arising from any contracts entered into between you and us, we will not share your data with third parties
        unless we have procured your express consent to do so.
      </PrivacyContent>

      <PrivacyHeader>Security of your personal data</PrivacyHeader>
      <PrivacyContent>
        We take appropriate measures to ensure that any personal data are kept secure, including security measures to
        prevent personal data from being accidentally lost, or used or accessed in an unauthorised way. We limit access
        to your personal data to those who have a genuine business need to know it. Those processing your information
        will do so only in an authorised manner and are subject to a duty of confidentiality.
      </PrivacyContent>
      <PrivacyContent>
        We also have procedures in place to deal with any suspected data security breach. We will notify you and any
        applicable regulator of a suspected data security breach where we are legally required to do so.
      </PrivacyContent>
      <PrivacyContent>
        Unfortunately, the transmission of information via the internet is not completely secure. Although we will do
        our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site,
        any transmission is at your own risk. Once we have received your information, we will use strict procedures and
        security features to try to prevent unauthorised access.
      </PrivacyContent>

      <PrivacyHeader>How long do we keep your personal data?</PrivacyHeader>
      <PrivacyContent>
        We will not keep your data for longer than necessary for the purposes set out in this Policy. Different
        retention periods apply for different types of data, however the longest we will normally hold any personal data
        is 6 years. To determine the appropriate retention period for personal data, we consider the amount, nature and
        sensitivity of the personal data, the potential risk from unauthorised use or disclosure of your personal data,
        the purposes for which we process your personal data and whether we can achieve those purposes through other
        means and the applicable legal requirements.
      </PrivacyContent>
      <PrivacyHeader>Payment information</PrivacyHeader>
      <PrivacyContent>
        Customer credit/debit card payments and other payments you make either on website or via our offices will be
        processed via our third party payment providers and the payment data you submit will be securely stored and
        encrypted by our payment service providers using up to date GDPR compliant industry standards. Please note that
        Med Logistics Group Limited do not ourselves directly process or store the debit/credit Card data that you
        submit.
      </PrivacyContent>
      <PrivacyHeader>Access to your personal data</PrivacyHeader>
      <PrivacyContent>
        Under GDPR, you are entitled to request, view, amend, or delete the personal information we hold on yourself.
        Email your requests to our data protection officer Sarah Burns at pa@medicallogistics.co.uk.
      </PrivacyContent>

      <PrivacyHeader>Your rights in respect of your personal data</PrivacyHeader>
      <PrivacyContent>
        Under the General Data Protection Regulation you have a number of important rights free of charge. In summary,
        those include rights to:
      </PrivacyContent>
      <div style={{ marginLeft: 25 }}>
        <PrivacyContent>
          a) To ask us not to process your personal data for marketing purposes. We will inform you (before collecting
          your data) if we intend to use your data for such purposes or if we intend to disclose your information to any
          third party for such purposes;
        </PrivacyContent>
        <PrivacyContent>
          b) To ask us not to process your personal data where it is processed on the basis of legitimate interests
          provided that there are no compelling reasons for that processing;
        </PrivacyContent>
        <PrivacyContent>
          c) To ask us not to process your personal data for scientific or historical research purposes, where relevant,
          unless the processing is necessary in the public interest;
        </PrivacyContent>
        <PrivacyContent>
          d) To request from us access to personal information held about you. To submit your subject access request,
          you can contact us at pa@medicallogistics.co.uk.
        </PrivacyContent>
        <PrivacyContent>
          e) To ask for the information we hold about you to be rectified if it is inaccurate or incomplete;
        </PrivacyContent>
        <PrivacyContent>
          f) To ask for data to be erased provided that the personal data is no longer necessary for the purposes for
          which it was collected, you withdraw consent (if the legal basis for processing is consent), you exercise your
          right to object, set out below, and there are no overriding legitimate ground for processing, the data is
          unlawfully processed, the data needs to be erased to comply with a legal obligation or the data is children’s
          data and was collected in relation to an offer of information society services;
        </PrivacyContent>
        <PrivacyContent>
          g) To ask for the processing of that information to be restricted if the accuracy of that data is contested,
          the processing is unlawful, the personal data is no longer necessary for the purposes for which it was
          collected or you exercise your right to object (pending verification of whether there are legitimate grounds
          for processing); and
        </PrivacyContent>
        <PrivacyContent>
          h) To ask for data portability if the processing is carried out by automated means and the legal basis for
          processing is consent or contract.
        </PrivacyContent>
      </div>
      <PrivacyContent>
        To exercise any of these rights or should you have any issues, concerns or problems in relation to your data, or
        wish to notify us of data which is inaccurate, please let us know by contacting us at pa@medicallogistics.co.uk.
      </PrivacyContent>
      <PrivacyHeader>Changes to this Privacy Policy</PrivacyHeader>
      <PrivacyContent>
        We may change this policy from time to time and without notice, so for customers please make sure you check back
        frequently to see any updates or changes to our privacy policy. If we change our Policy we will publish those
        changes on this page. For our employees any change in the policy will be communicated via notices sent from the
        payroll department. The last time our Policy was reviewed was September 2021.
      </PrivacyContent>
      <PrivacyHeader>Cookies</PrivacyHeader>
      <PrivacyContent>
        Like most websites our website uses cookies to collect information. Cookies are small data files which are
        placed on your computer or other devices (such as smart phones or tablets) as you browse our website. Our
        website uses cookies to distinguish you from other users, in order to provide you with a good customer
        experience when you browse our site and allows us to improve its features. By continuing to browse our site you
        are agreeing to our use of cookies. We do not use cookies to collect or record information on your name, address
        or other contact details.
      </PrivacyContent>
      <PrivacyContent>The cookies we use include:</PrivacyContent>
      <div style={{ marginLeft: 25 }}>
        <PrivacyContent>
          ● “Analytical” cookies. They allow us to recognise and count the number of visitors and to see how visitors
          move around the Website when they are using it. This helps us to improve the way our Website works, for
          example, by ensuring that users are finding what they are looking for easily.
        </PrivacyContent>
        <PrivacyContent>
          ● “Strictly necessary” cookies. These are cookies that are required for the operation of our Website. They
          include, for example, cookies that enable you to log into secure areas of our Website, use a shopping cart or
          make use of e-billing services.
        </PrivacyContent>
        <PrivacyContent>
          ● “Functionality” cookies. These are used to recognise you when you return to our Website. This enables us to
          personalise our content for you, greet you by name and remember your preferences (for example, your choice of
          language or region).
        </PrivacyContent>
        <PrivacyContent>
          ● “Targeting” cookies. These cookies record your visit to our Website, the pages you have visited and the
          links you have followed to our affiliates’ websites. We will use this information to make our Website, or
          location or to track effectiveness of our marketing campaigns in the search engines or the external websites.
          We may also share this information with third parties for this purpose.
        </PrivacyContent>
      </div>
      <PrivacyContent>
        Please note that third parties affiliates may also use cookies, over which we have no control.
      </PrivacyContent>
      <PrivacyContent>
        Cookies which are strictly necessary for the core functionality of the website are enabled by default, and set
        automatically at the point you access the website.
      </PrivacyContent>
      <PrivacyContent>
        You may block cookies by activating the setting on your browser that allows you to refuse the setting of all or
        some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you
        may not be able to access some or all of our website.
      </PrivacyContent>

      <PrivacyContent>We use Hotjar. Online businesses use Hotjar to create better website experiences.</PrivacyContent>

      <PrivacyContent>
        &quot;Hotjar was designed and built with privacy in mind. [...] to help our customers improve their websites and
        create better user experiences, while keeping privacy at the core of our software—and we’ve been doing it since
        2014.&quot;
      </PrivacyContent>
      <PrivacyContent>
        &quot;Today, Hotjar is used on over 900,000 websites in 180+ countries. We believe we have a responsibility to
        set the example and protect not just the privacy of our customers, but also that of the people who visit their
        websites—in other words: you.&quot;
      </PrivacyContent>
      <PrivacyContent>
        To find out more visit Hotjar website:{" "}
        <a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a>
      </PrivacyContent>
      <PrivacyHeader>Contact</PrivacyHeader>
      <PrivacyContent>
        If you have any questions about our collection, use or disclosure of your personal information, or any comments
        or requests, you can contact us at pa@medicallogistics.co.uk.
      </PrivacyContent>
    </PrivacyAndTermsWrapper>
  );
};
export default PrivacyPolicy;
