import styled from "styled-components";
import { media } from "../../shared/styles/media";
import { OurTeamPartContainer, OurTeamTomaszText, OurTeamPhoto, OurTeamText } from "./OurTeam.components";

export const OurTeamPartMagdaDinContainer = styled(OurTeamPartContainer)`
  margin-top: 160px;
  margin-left: -10%;
  padding-bottom: 320px;
  ${media.down("LAPTOP")`
  margin-left: -30%;
  margin-top: 100px;
  `}
  ${media.down("TABLET")`
    margin-left: -45%;
    margin-top: 80px;
  `}
  ${media.down("TABLET_SMALL")`
    margin-left: 0;
    padding-bottom: 64px;
  `}
`;

export const OurTeamMagdaDinText = styled.div`
  position: absolute;
  width: 230px;
  bottom: -80px;
  right: -260px;

  ${media.down("TABLET_LARGE")`
    width: 170px;
    right: -200px;
  `};
  ${media.down("TABLET_SMALL")`
    bottom: -240px;
    left: 50px;
    width: 210px;
    padding-left: 10px;
  `};
`;
export const OurTeamDilMagdaCircle = styled.div`
  position: absolute;
  width: 500px;
  bottom: 0;
  left: 85%;
  ${media.down("LAPTOP")`
  bottom: 60px;
  `}
  ${media.down("TABLET_SMALL")`
    transform: rotate(-90deg);
    width:200px;
    left: -50px;
    bottom: unset;
    top: 110%;
  `}
`;

export const OurTeamMagdaDilMobileText = styled(OurTeamTomaszText)`
  ${media.down("MOBILE_LARGE")`
    margin-top: 80px !important;
`}
`;

export const OurTeamFaisalPhoto = styled(OurTeamPhoto)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  transform: translateX(10%);
  margin-top: 80px;
  ${media.down("LAPTOP")`
  transform: translateX(20%);
  margin-top: 80px;
  `};
  ${media.down("TABLET")`
    transform: translateX(10%);
    margin-top: 70px;
    img {
      width: 300px;
      height: auto;
    }
  `}
  ${media.down("MOBILE_LARGE")`
  transform: translateX(0);
    display: block;
    min-width: 260px;
    width: 70%;
    margin-top: 130px;
    text-align: center;
    transform: translateX(0);
    img {
      min-width: 260px;
      width: 100%;
    }
  `}
  ${media.down("MOBILE_TABLE")`
    width: 80%;
    img {
      width: 100%;
    }
  `}
`;

export const OurTeamFisalText = styled(OurTeamText)`
  font-size: 16px;

  ${media.down("LAPTOP")`
    font-size: 14px;
  `};
  ${media.down("TABLET")`
    font-size: 12px;
  `};
  ${media.down("MOBILE_LARGE")`
    font-size: 14px;
  `}
`;

export const OurTeamFaisalTextContainer = styled.div`
  margin-left: 80px;
  margin-top: 90px;
  ${media.down("TABLET")`
      margin-left: 50px;
  `};
  ${media.down("TABLET_SMALL")`
      margin-left: 30px;
  `};
  ${media.down("MOBILE_LARGE")`
      margin-left: 0;;
  `};

  ${media.down("MOBILE_LARGE")`
    width: 100%;
    text-align: left;
    margin-top: 80px !important;
  `};
`;

export const OurTeamFaisalDescription = styled.div`
  position: relative;
  max-width: 364px;
  width: 415px;
  ${media.down("TABLET")`
    width: 250px;
  `};
  ${media.down("TABLET_SMALL")`
    width: 200px;
  `};

  span {
    display: block;
    max-width: 230px;
  }
`;

export const OurTeamFaisalCircle = styled.div`
  position: absolute;
  width: 100%;
  left: -30px;
  top: 0;
  ${media.down("MOBILE_LARGE")`
      width: 200px;
      top: -300px;
      left: -100px;
      transform: scaleX(-1) scale(0.4);
  `};
  @media all and (max-width: 500px) {
    right: 200px;
  }
`;
