import { Landing } from ".";

export type RouteInfo = {
  url: string;
  className?: string;
  title: string;
  description: string;
};

type RoutesMap = {
  [key in Landing]: {
    [key: string]: RouteInfo;
  };
};

export const CompanyRoutes = {
  title: {
    url: "",
    className: "seoTitle",
    title: "Dedicated medical couriers & services",
    description:
      "No mistakes, No delays, No more lost packages. Medical couriers and services in London and across the UK.",
  },
  products: {
    url: "our-services",
    className: "seoProducts",
    title: "Medical Couriers and Medical Services",
    description:
      "GMP trained couriers deliver only medical parcels for a secure and damage-free service. Our medical specialists will come wherever you are to take swabs or perform tests.",
  },
  ukas: {
    url: "ukas-certified-medical-services",
    className: "seoUkas",
    title: "UKAS Certified Medical Services",
    description: "Our services have been rigorously tested against national & international standards.",
  },
  ourStory: {
    url: "medical-logistics-story",
    className: "seoOurStory",
    title: "5 Years of Medical Logistics",
    description: "For over a decade we provide medical and courier services in London and across the UK.",
  },
  ourTeam: {
    url: "our-team",
    className: "seoOurTeam",
    title: "Medical Logistics Specialists Team",
    description: "Meet our team of trusted medical and logistics specialists.",
  },
  goGreen: {
    url: "eco-friendly-medical-couriers",
    className: "seoGoGreen",
    title: "Eco-Friendly Medical Couriers",
    description: "Let's go green! All-electric emission-free car fleet by 2023.",
  },
  laboratories: {
    url: "london-diagnostic-laboratories",
    className: "seoLaboratories",
    title: "London Diagnostic Laboratories We Work With",
    description: "For over a decade we provide medical and courier services in London and across the UK.",
  },
  inNumbers: {
    url: "medical-logistics-in-numbers",
    className: "seoInNumbers",
    title: "Medical Logistics In Numbers",
    description: "Over 100 000 shipments were delivered on time with positive feedback from all of our clients.",
  },
  clientOpinions: {
    url: "trustpilot-reviews",
    className: "seoClientOpinions",
    title: "What Our Clients Say",
    description: "Our client's rate our services as excellent. Read Trustpilot reviews.",
  },
  contactUs: {
    url: "contact-us",
    className: "seoContactUs",
    title: "Contact Us",
    description: "Chat via Whatsapp or catch us on LinkedIn. Our team will be happy to help.",
  },
};

//TODO: Add routes for Medical Landing
export const MedicalRoutes = {
  title: {
    url: "",
    className: "seoIntroduction",
    title: "Dedicated medical services across UK",
    description:
      "No mistakes, No delays. UKAS Certified medical services in London and across the UK. Blood tests. Covid tests. IV Drips. Vaccines.",
  },
  services: {
    url: "medical-services",
    className: "seoMedicalServices",
    title: "Blood and COVID Tests in UK",
    description: "A Wide Variety of Medical Services. Blood tests. Covid tests. IV Drips. Vaccines.",
  },
  clientsStory: {
    url: "client-testimonials",
    className: "seoClientsStory",
    title: "Medical Services Clients Story",
    description: "Why our clients chose to work with Medical Logistics and how it's helped their business flourish!",
  },
  whyChooseUs: {
    url: "medics-nurses-phlebotomists",
    className: "seoWhyChooseUs",
    title: "Why Choose our Medics, Nurses & Phlebotomists?",
    description:
      "Get tested by our team of fully trained medical professionals: Medics, Nurses & Phlebotomists. Tailored service available 24/7. ",
  },
  medicalDirector: {
    url: "medical-director",
    className: "seoMedicalDirector",
    title: "Hear About Our UKAS Certified Medical Services",
    description:
      "Watch a video with our Medical managing director - Faisal Sattar. He's a doctor, GP himself, still working as a doctor on his own practitionery.",
  },
  medicalInNumbers: {
    url: "in-numbers",
    className: "seoMedicalInNumbers",
    title: "Over 5000 Home Visits With a 100% Positive Feedback",
    description:
      "Over 5000 home visits and 100 000 shipments were delivered on time with positive feedback from all of our clients.",
  },
  getInTouch: {
    url: "get-in-touch",
    className: "seoGetInTouch",
    title: "Order Blood Tests and COVID Tests Online",
    description: "Contact us to order blood tests, COVID tests and medical services",
  },
};

export const CouriersRoutes = {
  title: {
    url: "",
    className: "seoIntroduction",
    title: "Book Online Dedicated Medical Couriers in London",
    description:
      "Book a courier online. A highly personalised and exclusively medical courier service you can count on. Always on-time and available 24/7.",
  },
  whatWeTransport: {
    url: "medical-items-transportation",
    className: "seoWhatWeTransport",
    title: "Medical transport of Blood, Swabs, X-Rays, Medications and Tissues",
    description: "Medical couriers in London transporting Blood, Blood Samples, Swabs, X-Rays, Medication and Tissues",
  },
  deliveryStandards: {
    url: "medical-delivery-standards",
    className: "seoDeliveryStandards",
    title: "Highest Medical Delivery Standards On The Market",
    description:
      "Exclusively medical shipments with GMP certified medical couriers and secure UN3373 insulated packaging.",
  },
  timeCriticalDeliveries: {
    url: "time-critical-deliveries",
    className: "seoTimeCriticalDeliveries",
    title: "Medical Courier for blood samples, tissues, swabs",
    description:
      "Thanks to our skilled team of exclusively medical couriers, all of your packages will be safely delivered, on time and perfectly intact.",
  },
  weAreExperts: {
    url: "expert-gmp-couriers",
    className: "seoWeAreExperts",
    title: "Experts in Time Critical Deliveries",
    description: "Our Medical Couriers are experts in Time Critical Deliveries. On-time delivery. Every. Single. Time.",
  },
  whyChooseUs: {
    url: "high-quality-medical-couriers",
    className: "seoWhyChooseUs",
    title: "Excellent Customer Service 24/7",
    description:
      "Our couriers are available 24/7, 365 days a year. Choose a time, and we'll be there! We are UKAS Certified - our services have been rigorously tested against national & international standards.",
  },
  clientsStory: {
    url: "client-testimonials",
    className: "seoClientsStory",
    title: "Medical Couriers Clients Story",
    description: "Why our clients chose to work with Medical Logistics and how it's helped their business flourish!",
  },
  ourNumbers: {
    url: "experienced-medical-couriers",
    className: "seoOurnNumbers",
    title: "98% On-time Collections",
    description: "100000 shipments delivered on time. Over 10 years of experience and 98% On-time collections.",
  },
  yourPackage: {
    url: "book-medical-couriers-online",
    className: "seoYourPackage",
    title: "Book a Courier Online for Your Medical Package",
    description: "Book online a medical courier in London. Your Medical Package Is In Good Hands.",
  },
};

export const allRoutes: () => RoutesMap = () => ({
  [Landing.COMPANY]: CompanyRoutes,
  [Landing.COURIERS]: CouriersRoutes,
  [Landing.MEDICAL]: MedicalRoutes,
});
