import React, { FunctionComponent, useEffect, useState } from "react";
import { goToSection } from "../../shared/components/Navbar/navigation.helpers";
import VideoPlayer from "../../shared/components/VideoPlayer/VideoPlayer";
import {
  Background,
  BlurredBackground,
  ClientsStoryContentContainer,
  ClientsStoryDescription,
  ClientsStoryHeading,
  PlayButtonContainer,
  QuoteBoxWrapper,
  ClientsStoryContainer,
  ClientsStoryInnerWrapper,
} from "./ClientsStory.components";
import placeholderImg from "./images/KirstyFirstFrame.jpg";
import useMedia from "use-media";
import ConditionalWrapper from "../../shared/components/ConditionalComponent/ConditionalComponent";
import { CloseButtonOverlay } from "../../shared/components/VideoPlayer/VideoPlayer.components";
import CirclePlayButton from "./images/CirclePlayButton.svg";
import ScrollTrigger from "../../../common/ScrollTrigger";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import BouncedShapeWithTransparency from "../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CouriersRoutes, MedicalRoutes } from "../../../config/routes";
const CLIENTS_STORY_VIDEO_URL = "https://vimeo.com/646870207";

export const ClientsStory: FunctionComponent<{ courierLanding?: boolean }> = ({ courierLanding }) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [shouldAnimateButton, setShouldAnimateButton] = useState(true);
  const [inViewRef, inView] = useInView();
  const isNewLargeMobile = useMedia({ maxWidth: 649 });

  const introAnimation = () => {
    const startingTl = gsap
      .timeline({ paused: true })
      .fromTo(".clientStoryHeading", { x: -30, opacity: 0, duration: 1 }, { x: 0, opacity: 1, duration: 1 })
      .fromTo(".storyDescription", { x: -30, opacity: 0, duration: 1 }, { x: 0, opacity: 1, duration: 1 }, 0.5)
      .fromTo(".quoteBox", { opacity: 0, x: 50 }, { opacity: 1, x: 0 }, 0.5);

    const trigger = ScrollTrigger.create({
      trigger: ".clientStorySection",
      start: "top center",
      end: "bottom center",
      onEnter: () => startingTl.play(),
    });

    startingTl.add(() => trigger.kill());
  };

  useEffect(() => {
    if (showPlayer) {
      gsap.to(".blurredBackgroudKirsty", { autoAlpha: 1 });
    } else {
      gsap.to(".blurredBackgroudKirsty", { autoAlpha: 0 });
      introAnimation();
    }
  }, [showPlayer]);

  useEffect(() => {
    if (inView && shouldAnimateButton) setShouldAnimateButton(false);
  }, [inView, shouldAnimateButton]);

  const openVideoPlayer = () => {
    const offset = isNewLargeMobile ? -1 : 50;
    goToSection("clientStorySection", offset);
    setShowPlayer(true);
  };

  return (
    <ClientsStoryContainer id="clientStorySection" className="clientStorySection">
      {courierLanding ? (
        <SEOMarker routeInfo={CouriersRoutes.clientsStory} />
      ) : (
        <SEOMarker routeInfo={MedicalRoutes.clientsStory} />
      )}
      <Background id="clientsStory">
        <ClientsStoryInnerWrapper style={{ visibility: showPlayer ? "hidden" : "visible" }}>
          <ClientsStoryContentContainer id="storySection">
            <ConditionalWrapper
              key="conditionalStoryHeadingWrapper"
              condition={isNewLargeMobile}
              wrapper={children => (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", width: "100%" }}>{children}</div>
              )}
            >
              <ClientsStoryHeading ref={inViewRef} className="clientStoryHeading">
                Listen To Our Client’s Story!
              </ClientsStoryHeading>
              {isNewLargeMobile && inView && (
                <PlayButtonContainer className="storyButton">
                  <CloseButtonOverlay onClick={() => openVideoPlayer()} />
                  <object type="image/svg+xml" data={CirclePlayButton}></object>
                </PlayButtonContainer>
              )}
            </ConditionalWrapper>

            <ClientsStoryDescription className="storyDescription">
              Hear from Kirsty - why she chose to work with Medical Logistics and how it’s helped her business flourish!
            </ClientsStoryDescription>
            {!isNewLargeMobile && !shouldAnimateButton && (
              <PlayButtonContainer onClick={() => openVideoPlayer()} className="storyButton">
                <CloseButtonOverlay onClick={() => openVideoPlayer()} />

                <object type="image/svg+xml" data={CirclePlayButton}></object>
              </PlayButtonContainer>
            )}
          </ClientsStoryContentContainer>
          {!isNewLargeMobile && <QuoteBoxWrapper courierLanding={courierLanding} className="quoteBox" />}
        </ClientsStoryInnerWrapper>
        <BouncedShapeWithTransparency
          trigger="clientStorySection"
          topShapeBackground={courierLanding ? "#EFE9E6" : "#181a1b"}
          bottomShapeBackground="transparent"
          inBottomOfSection={true}
        />
      </Background>
      <BlurredBackground className="blurredBackgroudKirsty">
        <VideoPlayer
          isOpened={showPlayer}
          videoUrl={CLIENTS_STORY_VIDEO_URL}
          setIsOpened={setShowPlayer}
          placeholderUrl={placeholderImg}
        />
      </BlurredBackground>
    </ClientsStoryContainer>
  );
};
