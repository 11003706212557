import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import { media } from "../../styles/media";

type ButtonSizes = "medium" | "large" | "extraLarge" | "block";

type ClippedButtonProps = {
  id?: string;
  size: ButtonSizes;
  active?: boolean;
};

export const ClipPathButton = styled.div<ClippedButtonProps>`
  width: ${p => (p.size === "extraLarge" ? 180 : 160)}px;
  height: 54px;
  clip-path: url(#${props => props.id});
  background: ${props =>
    props.active ? `linear-gradient(to right, ${colors.red._500} 50%, #181a1b 50% 100%)` : colors.gray._200};
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  white-space: nowrap;
  background-size: 200%;
  transition: background-position 0.5s;

  :active {
    background: ${props => (props.active ? colors.red._600 : colors.gray._200)};
  }

  :hover {
    background-position: -100%;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: -1.5px;
`;

type ButtonContainerProps = {
  active?: boolean;
  animated?: boolean;
  disabled?: boolean;
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
  position: relative;
  background: none;
  border: none;
  transition: transform 0.05s ease;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  overflow: hidden;

  ${props =>
    !props.disabled &&
    css`
      :active {
        transform: scale(0.95);
      }
    `}

  :focus {
    outline: none;
    ${ClipPathButton} {
      background: ${props => (props.disabled ? colors.gray._200 : colors.red._600)};
    }
  }
`;

export const ButtonContent = styled.div<ButtonContainerProps>`
  text-align: center;
  display: inline-block;
  position: relative;
  padding: 20px 0px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s linear 0s;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${props => (props.disabled ? colors.gray._600 : colors.white)};

  &:before {
    content: ">>";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 70px;
    transition: all 0.2s linear 0s;
  }

  ${props =>
    props.animated &&
    !props.disabled &&
    css`
      &:hover {
        text-indent: -20px;
        &:before {
          opacity: 1;
          text-indent: 0px;
        }
      }
    `}

  > span {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
  }

  ${media.down("LAPTOP")`
     font-size: 16px;
  `}

  ${media.down("NEW_HALF_WEB")`
     font-size: 14px;
  `}

   ${media.down("TABLET")`
     font-size: 16px;
  `}
`;

export const ButtonSvg = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
`;
