import gsap from "gsap/all";
import React, { useEffect, useState } from "react";
import useGAHandler from "../../../common/hooks/useGAHandler";
import useRouting from "../../../common/hooks/useRouting";
import { Landing } from "../../../config";
import PageLoader from "../../shared/components/PageLoader/PageLoader";
import GlobalStyle from "../../shared/styles/global";
import CompanyLandingProvider from "../CompanyLandingLayout/CompanyLandingContext/CompanyLandingProvider";
import CompanyLandingLayout from "../CompanyLandingLayout/CompanyLandingLayout";

const CompanyLandingApp = () => {
  const [showLoader, setShowLoader] = useState(true);

  const isLoading = useRouting(Landing.COMPANY);
  useGAHandler();

  useEffect(() => {
    if (!isLoading) {
      gsap
        .timeline()
        .to(".pageLoader", { autoAlpha: 0, duration: 0 })
        .to(".pageContent", { opacity: 1, duration: 0 }, 0)
        .add(() => setShowLoader(false));
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      gsap
        .timeline()
        .to(".pageLoader", { autoAlpha: 0, duration: 0.5, delay: 0.6 })
        .to(".pageContent", { opacity: 1, duration: 0.5 }, 0.6)
        .add(() => setShowLoader(false));
    }
  }, [isLoading]);

  return (
    <CompanyLandingProvider>
      {showLoader && <PageLoader />}
      <div className="pageContent" style={{ opacity: 0 }}>
        <CompanyLandingLayout />
      </div>
      <GlobalStyle />
    </CompanyLandingProvider>
  );
};

export default CompanyLandingApp;
