import styled, { css } from "styled-components";
import { media } from "../../shared/styles/media";
import ShineImage from "./Shine.png";

export const NoMistakesContainer = styled.section`
  position: relative;
  padding-bottom: 140px;
  overflow: hidden;
  margin-top: -220px;
  ${media.down("NEW_LARGE_MOBILE")`
    padding-bottom: 100px;
    margin-top: -250px;
  `}
`;

export const NoMistakesBanner = styled.div<{ isBottom?: boolean }>`
  position: relative;
  white-space: nowrap;
  overflow: visible;
  width: 100%;
  background: transparent;

  border: 1px solid rgba(164, 152, 152, 0.32);
  transform: rotate(-3deg);

  ${p =>
    p.isBottom &&
    css`
      margin-top: 100px;
      z-index: 9999;
      position: relative;
    `}

  ::after {
    content: "";
    width: 130px;
    height: 35px;
    background-size: contain;
    //background-color: red;
    background-image: url(${ShineImage});
    position: absolute;
    bottom: -16px;
    left: 0;
    animation: moveShine 12s linear infinite;
    @keyframes moveShine {
      0% {
        transform: translateX(-171px) rotate(2.5deg);
      }
      100% {
        transform: translateX(100vw) rotate(2.5deg);
      }
    }
  }
`;

export const NoMistakesText = styled.h2<{ isBottom?: boolean }>`
  margin: 0;
  padding: 0;
  color: #000;
  position: relative;

  ${p =>
    p.isBottom
      ? css`
          animation: banner 20s linear infinite;
        `
      : css`
          animation: bannerInverted 20s linear infinite;
        `}

  width: -moz-fit-content;
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 90px;
  line-height: 112%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #efe9e5;
  text-shadow: -1px -1px 0 #d1c6c5, 1px -1px 0 #d1c6c5, -1px 1px 0 #d1c6c5, 1px 1px 0 #d1c6c5;
  @keyframes banner {
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes bannerInverted {
    100% {
      transform: translateX(100%);
    }
  }
  ${media.down("NEW_MAIN_WEB")`
  font-size: 82px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  font-size: 80px;
  `}

  ${media.down("NEW_HALF_WEB")`
  font-size: 76px;
  `}

  ${media.down("NEW_TABLET")`
  font-size: 72px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  font-size: 64px;
  `}

  ${media.down("NEW_MOBILE")`
  font-size: 56px;
  `}
`;

export const FakeSpan = styled.span<{ isBottom?: boolean }>`
  width: 100%;
  position: absolute;
  left: ${p => (p.isBottom ? "100%" : "-100%")};
`;
