// @ts-nocheck
import React, { FunctionComponent, useRef, useMemo } from "react";
import { useGLTF, useTexture } from "@react-three/drei";

import FooterModel from "./FooterFinalNew.glb";
import * as THREE from "three";
import FooterTexture from "./FooterFinalNew.jpg";
import { useFrame } from "@react-three/fiber";

const ContactUsScene: FunctionComponent<{ isInView: boolean }> = props => {
  const group = useRef();
  const texture = useTexture(FooterTexture);
  texture.flipY = false;
  const model = useGLTF(FooterModel, "https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
  const copiedScene = useMemo(() => model.scene.clone(), [model.scene]);

  const bakedMaterial = new THREE.MeshBasicMaterial({ map: texture });

  copiedScene.traverse(child => {
    child.material = bakedMaterial;
  });
  const DisableRender = () => useFrame(() => null, 1000);

  const AbleRender = () =>
    useFrame((state, delta) => {
      group.current.rotation.y -= 0.5 * delta;
    });

  return (
    <>
      {props.isInView ? <AbleRender /> : <DisableRender />}
      <group ref={group} {...props}>
        <primitive object={copiedScene} />
      </group>
    </>
  );
};

useGLTF.preload(FooterModel, "https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
useTexture.preload(FooterTexture);

export default ContactUsScene;
