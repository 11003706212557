import { Field } from "formik";
import React, { FunctionComponent } from "react";
import TextareaFieldComponent, { TextareaFieldComponentProps } from "./TextareaFieldComponent";

export type TextareaFieldProps = TextareaFieldComponentProps;

const TextareaField: FunctionComponent<TextareaFieldProps> = ({ onFocus, ...fieldProps }) => (
  <Field {...fieldProps} onFocus={onFocus} component={TextareaFieldComponent} />
);

export default TextareaField;
