import styled from "styled-components";
import { media } from "../../shared/styles/media";
import { OurTeamTextSubtitle, OurTeamTextTitle } from "./OurTeam.components";
import { OurTeamFisalText } from "./OurTeamMiddlePart.components";

export const VideoSectionContainer = styled.div`
  padding-top: 0;
  position: relative;
  //margin-top: -170px;
  margin-top: 220px;
  ${media.up("FULL")`
    margin-top: 200px;
  `}

  ${media.down("LAPTOP")`
    margin-top: 250px;
  `}
  ${media.down("TABLET")`
    margin-top: 200px;
  `}

  ${media.down("TABLET_SMALL")`
    display: flex;
    justify-content: center;
    margin-top: 100px;
  `};

  ${media.down("MOBILE_LARGE")`
    margin-top: 0;
    padding-top: 30px;
    display: block;
  `}
`;

export const OurTeamTitleVideo = styled.div`
  position: absolute;
  top: 0;
  left: -25px;
  font-family: Barlow Semi Condensed;
  font-weight: 500;
  font-size: 136px;
  line-height: 96%;
  letter-spacing: -0.02em;
  transform: translateY(-70%);
  ${media.up("FULL")`
  transform: translateY(-80%);
    `};
  ${media.down("LAPTOP")`
     font-size: 104px;
     transform: translateY(-80%);
    `};

  ${media.down("TABLET_LARGE")`
    transform: translateY(-100%);
    `};
  ${media.down("TABLET")`
  font-size: 84px;
    `};

  ${media.down("TABLET_SMALL")`
  display: none;

    `};
`;

export const VideoSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-bottom: 80px;

  ${media.down("MAIN_WEB")`
  justify-content: space-between;
  `};

  ${media.down("TABLET_LARGE")`
    align-items: flex-end;
    
  `};

  ${media.down("TABLET_SMALL")`
  flex-direction: column-reverse;
  justify-content: flex-end;
  `};

  ${media.down("MOBILE_LARGE")`
    justify-content: center;  
    padding-bottom: 0; 
`};
`;

export const TextContentContainer = styled.div`
  margin-top: auto;
  margin-bottom: -80px;

  ${media.down("FULL")`
  margin-top: auto;
  flex: 1;
  margin-right: 51px;
  `};

  ${media.down("MAIN_WEB")`
  margin-top: auto;
  flex: 1;
  margin-right: 26px;

  `};

  ${media.down("TABLET_LARGE")`
  margin-top: 26px;
min-width: 258px;
margin-right: 16px;

  `};

  ${media.down("TABLET_SMALL")`
  margin-left: auto;
  margin-top: 16px;
  max-width: 427px;
  margin-right: 0;
  `};

  ${media.down("MOBILE_LARGE")`
  max-width: 400px;
  margin: 0 auto;
  margin-top: 26px;

  `};

  ${media.down("MOBILE_LARGE")`
  width: 100%;

  `};

  ${media.down("MOBILE")`
  margin: 0 auto;
  margin-top: 26px;


  `};

  ${media.down("MOBILE_TABLE")`
  margin: 0 auto;
  margin-top: 26px;

  `};

  ${media.down("MOBILE_SMALL")`
  margin: 0 auto;
  margin-top: 26px;


  `};
`;

export const TextSubtitle = styled(OurTeamTextSubtitle)`
  ${media.down("FULL")`
  font-size: 19px;
  `}

  ${media.down("MAIN_WEB")`
  font-size: 16px;

  `}

  ${media.down("LAPTOP")`
  font-size: 14px;

  `}

  ${media.down("TABLET")`
  font-size: 12px;

  `}

  ${media.down("MOBILE_LARGE")`
  font-size: 14px;

  `}

  ${media.down("MOBILE_SMALL")`
  font-size: 14px;
  `}
`;

export const MissionDescription = styled(OurTeamTextTitle)`
  margin-bottom: 16px;
  max-width: unset;

  ${media.down("FULL")`
  font-size: 24px;
  `}

  ${media.down("MAIN_WEB")`
  font-size: 20px;

  `}

  ${media.down("LAPTOP")`
  font-size: 18px;

  `}

  ${media.down("TABLET")`
  font-size: 14px;

  `}

  ${media.down("MOBILE_LARGE")`
  font-size: 18px;

  `}

  ${media.down("MOBILE_SMALL")`
  font-size: 16px;
  `}
`;

export const UpperParagraph = styled(OurTeamFisalText)`
  ${media.down("FULL")`
  font-size: 19px;
  `};

  ${media.down("MAIN_WEB")`
  font-size: 16px;

  `};

  ${media.down("LAPTOP")`
  font-size: 14px;

  `};

  ${media.down("TABLET")`
  font-size: 12px;

  `};

  ${media.down("TABLET_SMALL")`
  font-size: 12px;

  `};

  ${media.down("MOBILE_LARGE")`
  font-size: 14px;

  `};

  ${media.down("MOBILE_SMALL")`
  font-size: 14px;
  `};

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const SeparatorLine = styled.div`
  position: absolute;
  width: 200px;
  right: 0;
  margin-bottom: 48px;
  margin-top: -50px;
  border-top: 1px solid rgba(164, 152, 152, 0.32);

  ${media.down("MAIN_WEB")`
  margin-top: -48px;
  margin-right: 64px;

  width: 736px;
  `};

  ${media.down("LAPTOP")`
  margin-top: -24px;
  margin-right: 56px;

  width: 600px;
  `};

  ${media.down("TABLET_LARGE")`
  margin-bottom: 48px;
  margin-right: 56px;

  width: 466px;
  `};

  ${media.down("TABLET")`
  margin-right: 44px;

  width: 466px;
  `};

  ${media.down("TABLET_SMALL")`
  margin-top: -16px;
  margin-right: 40px;

  width: 482px;
  `};
  ${media.down("MOBILE_LARGE")`
  margin-right: 24px;

  width: 512px;
  `};

  ${media.down("MOBILE_MLARGE")`
  margin-right: 24px;
  width: 80%;
  `};

  ${media.down("MOBILE")`
  margin-top: -12px;
  margin-right: 24px;

  width: 80%;
  `};
`;

export const PlayerContainer = styled.div`
  position: relative;
  padding: 24.25% 0 0 0;
  overflow: hidden;
  display: flex;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  -khtml-border-radius: 10px;
  height: 344px;
  width: 600px;
  overflow: visible;

  ${media.down("FULL")`
  padding: 31.25% 0 0 0;
  height: 100%;
  width: 666px;
  `}

  ${media.down("MAIN_WEB")`
  padding: 339.906px 0 0 0;

  height: 100%;
  width: 600px;
  `}

  ${media.down("LAPTOP")`
  padding: 284.562px 0 0 0;

  width: 512px;
  `}

  ${media.down("TABLET_LARGE")`
  padding: 243.266px 0 0 0;

  width: 418px;
  `}

  ${media.down("TABLET_SMALL")`
  padding: 236.266px 0 0 0;

  height:100%;
  width: 427px;
  margin-left: auto;
  `}

  ${media.down("MOBILE_LARGE")`
  height: auto;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  `}
  ${media.down("MOBILE_MLARGE")`
  padding: 233.266px 0 0 0;

  `}
  

  ${media.down("MOBILE")`
  padding: 58.25% 0 0 0;

  `}

  ${media.down("MOBILE_TABLE")`
  `}

  ${media.down("MOBILE_SMALL")`
  padding: 163px 0 0 0;

  `}

  

  ${media.down("MOBILE_SMALLEST")`
  padding: 16px 0 0 0;

  height: 156px;
  margin: 0 auto;
  `}
`;

export const Plus50Badge = styled.div`
  width: 160px;
  position: absolute;
  top: 0;
  right: 30%;
  z-index: 999;
  /* height: 200px; */
  /* width: 200px; */
  /* right: 40%; */
  margin-top: -90px;
  ${media.down("FULL")`
  width: 184px;
  `};

  ${media.down("MAIN_WEB")`
  width: 160px;
 
 `};
  ${media.down("LAPTOP")`
  width: 136px;
  margin-top: -90px;

  `};
  ${media.down("TABLET_LARGE")`
  width: 112px;
  margin-top: -80px;
  `};

  ${media.down("TABLET_SMALL")`
  right: 15%;
  padding-bottom: 328px;
  `};

  ${media.down("MOBILE_LARGE")`
  padding-bottom: 310px;
  `};

  ${media.down("MOBILE")`
  padding-bottom: 360px;
  `};

  ${media.down("MOBILE_SMALL")`
  margin-bottom: 410px;
  

  `};
`;

export const OurTeamVideo = styled.video`
  width: 100%;
  height: 100%;
  /* margin-top: -6px;

  ${media.down("FULL")`
  width: 110%;

  `}
  ${media.down("MAIN_WEB")`
  margin-top: -4px;

  width: 103%;

  `}

  ${media.down("LAPTOP")`
  width: 105%;

  margin-top: -3px;

  `}

  ${media.down("TABLET_LARGE")`
  width: 105%;
  margin-top: 1px;


  `}

  ${media.down("MOBILE_LARGE")`
  width: 103%;
  margin-top: 1px;
  `}



  ${media.down("MOBILE_SMALL")`
  margin-top: -3px;

  `} */
`;
