import styled from "styled-components";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import { media } from "../../shared/styles/media";

export const HeroVideoContainer = styled.section`
  position: relative;
  width: 100vw;
  overflow: hidden;
  ${media.down("NEW_TABLET")`
   padding-bottom:  48px;
  `};

  ${media.down("NEW_MOBILE")`
   /* padding-bottom:  unset; */
  `};
`;

export const VideoOuterWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;

  video {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;

export const VideoInnerWrapper = styled.div<{ landscapeOrientation?: boolean }>`
  left: 0%;
  min-height: 43.75%;
  padding-top: 56.25%;
  position: absolute;
  top: ${p => (p.landscapeOrientation ? "-12%" : "0%")};
  width: 100%;
`;

export const HeroContainer = styled(SectionWrapper)`
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: visible;
  top: 15vh;
  left: 0px;
  z-index: 9999;
  > :last-child {
    margin-top: 8px;
    margin-left: -46px;
  }

  ${media.up("NEW_LARGE_WEB")`
   padding:0;
   max-width: 1200px;
   left: 50%;
   transform: translateX(-50%);
  `}
  ${media.down("NEW_TABLET")`
   top:  170px;
  `};
  ${media.down("NEW_LARGE_MOBILE")`
    top: 110px;
  `};
  ${media.down("NEW_MOBILE")`
    top: 90px;

    > :last-child {
     margin-top: 1px;
    }
  `};
`;

export const HeroHeading = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 72px;
  line-height: 104%;
  letter-spacing: -0.03em;
  color: #18191b;
  font-weight: normal;
  max-width: 820px;

  ${media.down("NEW_MAIN_WEB")`
    font-size: 72px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    font-size: 64px;
  `}

  ${media.down("NEW_HALF_WEB")`
    display: flex;
    flex-direction: column;
    font-size: 56px;
    max-width: 470px;
  `}


  ${media.down("NEW_LARGE_MOBILE")`
  max-width: 440px;

    font-size: 48px;
  `}

  ${media.down("NEW_MOBILE")`
    font-size: 40px;
  `}
`;

export const HeroDescription = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
  color: rgba(24, 25, 27, 0.8);
  max-width: 420px;
  margin-top: 16px;

  ${media.down("NEW_MAIN_WEB")`
    font-size: 18px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    margin-top: 12px;
    max-width: 350px;
  `}

  ${media.down("NEW_HALF_WEB")`
    max-width: 300px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 16px;
    max-width: 260px;
  `}

  ${media.down("NEW_MOBILE")`
  max-width: 215px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  `}
`;

export const HeroButtonWrapper = styled.div`
  position: relative;
  padding: 40px;
  width: 200px;
`;

export const ButtonLightBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 130px;
  background: #efe9e6;
  background: -webkit-radial-gradient(circle farthest-corner at center center, #efe9e6 0%, #ffffff00 50%);
  background: -moz-radial-gradient(circle farthest-corner at center center, #efe9e6 0%, #ffffff00 50%);
  background: radial-gradient(circle farthest-corner at center center, #efe9e6 0%, #ffffff00 50%);
`;

export const HeroVideoPlayer = styled.video`
  ${media.down("HERO_VIDEO_MOBILE_OFFSET")`
   margin-bottom: -70px
  `}

  ${media.down("NEW_MOBILE")`
   /* margin-bottom: -80px; */
   margin-bottom: unset;
  `}
`;
