import useMedia from "use-media";
import { BREAKPOINTS } from "../../modules/shared/styles/const";

export const useIsMobile = () => useMedia({ maxWidth: "880px" });

export const useIsTablet = () =>
  useMedia({
    minWidth: BREAKPOINTS.TABLET.min!,
    maxWidth: BREAKPOINTS.TABLET.max!,
  });

export const useIsTabletDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.TABLET.max!,
  });
export const useIsSmallTabletDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.TABLET_SMALL.max!,
  });

export const useIsMobileDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.MOBILE.max!,
  });

export const useIsSmallMobileDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.MOBILE_SMALL.max!,
  });

export const useIsMobileLargeDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.MOBILE_LARGE.max!,
  });

export const useIsHeroVideoMobileDown = () =>
  useMedia({
    maxWidth: 850,
  });

export const useIsTabletSmallDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.TABLET_SMALL.max!,
  });

export const useIsServicesSectionLayoutChange = () =>
  useMedia({
    maxWidth: BREAKPOINTS.MEDICAL_SERVICES_LAYOUT_CHANGE.max!,
  });

export const isNewLargeMobileDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.NEW_LARGE_MOBILE.max!,
  });
