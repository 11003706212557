import React, { FunctionComponent, useEffect, useState } from "react";
import {
  TimeSectionHeader,
  TimeSectionDescription,
  LineWrapper,
  TimeInformationDescription,
  TimeInformationHeading,
  TimeInformationOuterWrapper,
  TimeInformationWrapper,
  TimeSectionContainer,
  TimeSectionOuterContainer,
} from "./TimeSection.components";
import MovieIcon from "./images/MovieIcon.svg";
import HomeIcon from "./images/HomeIcon.svg";
import SportIcon from "./images/SportIcon.svg";
import { ReactComponent as Line } from "./images/services-line.svg";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import { VideoContainer, VideoPlayer, VideoWrapper } from "../Services.components";
import { desktopVideos } from "../videos.config";

export const timeInformations = [
  {
    className: "movie",
    heading: "Movie Production Set",
    icon: <object data={MovieIcon} className="movieIcon" />,
    description: (
      <>
        Book Covid tests for your <span>entire production</span> crew to ensure maximum safety.
      </>
    ),
  },
  {
    className: "sport",
    heading: "Sports Facilities",
    icon: <object data={SportIcon} className="sportIcon" />,
    description: (
      <>
        Have your team’s <span>annual health check</span> done wherever is most convenient for them.
      </>
    ),
  },
  {
    className: "home",
    heading: "At Home",
    icon: <object data={HomeIcon} className="homeIcon" />,
    description: (
      <>
        Book a <span>home service</span> for a completely stress and hassle-free experience.
      </>
    ),
  },
];

const TimeSection: FunctionComponent = () => {
  const [inViewRef, inView] = useInView({ threshold: 0.3 });
  const [isFirstAnimation, setIsFirstAnimation] = useState(true);
  useEffect(() => {
    if (inView && isFirstAnimation) {
      gsap.from(".timeInformation", { x: -100, autoAlpha: 0, stagger: 0.5, duration: 1 });
      gsap.to(".timeInformation", { autoAlpha: 1, stagger: 0.5, duration: 1 });
      setIsFirstAnimation(false);
    }
  }, [inView]);
  return (
    <TimeSectionOuterContainer>
      <TimeSectionContainer className="timeContainer">
        <TimeSectionHeader className="timeHeading">Any Time, Any Place</TimeSectionHeader>
        <TimeSectionDescription className="timeDescription">
          Book an appointment at a time and a place that suits you best, and the rest is taken care of. Our team of
          medical specialists will go wherever you are to take samples and perform tests, quickly and hassle-free.
        </TimeSectionDescription>
        <TimeInformationOuterWrapper ref={inViewRef}>
          {timeInformations.map((information, index) => (
            <TimeInformationWrapper style={{ opacity: 0 }} className="timeInformation" key={index}>
              {information.icon}
              <LineWrapper className={`${information.className}Line`}>
                <Line />
              </LineWrapper>
              <TimeInformationHeading className={`${information.className}Heading`}>
                {information.heading}
              </TimeInformationHeading>
              <TimeInformationDescription className={`${information.className}Description`}>
                {information.description}
              </TimeInformationDescription>
            </TimeInformationWrapper>
          ))}
        </TimeInformationOuterWrapper>
      </TimeSectionContainer>
      <VideoContainer>
        <VideoWrapper>
          <VideoPlayer timeSection={true} key={`videoPlayer2`} className={`videoPlayer2`} loop autoPlay muted>
            <source src={desktopVideos[1].mov} type="video/quicktime" />
            <source src={desktopVideos[1].webm} type="video/webm" />
          </VideoPlayer>
        </VideoWrapper>
      </VideoContainer>
    </TimeSectionOuterContainer>
  );
};

export default TimeSection;
