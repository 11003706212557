import React, { useRef, useState, useEffect } from "react";
import gsap from "gsap";
import {
  OurStoryMobileTextContent,
  OurStoryMobileContainer,
  PhotosContainerMobile,
  OurStoryMobileCloseBadge,
  MobileClickToReadContainer,
  CircledText,
  OurStoryMobileCloseBadgeContainer,
} from "./OurStoryMobile.components";
import CourierPhoto from "../assets/CourierMobile.png";
import LabPhoto from "../assets/labMobile.png";
import { ReactComponent as BadgeIcon } from "../assets/ClickToRead.svg";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import { BREAKPOINTS } from "../../../shared/styles/const";
import { goToSection } from "../../../shared/components/Navbar/navigation.helpers";

const OurStoryMobile = () => {
  const prevShowMobileView = useRef<boolean | null>(null);
  const [showMobileView, setShowMobileView] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    if (BREAKPOINTS.TABLET_SMALL.max && width > BREAKPOINTS.TABLET_SMALL.max) {
      setShowMobileView(false);
      prevShowMobileView.current = false;
    }
  }, [width]);
  useEffect(() => {
    if (showMobileView) {
      gsap
        .timeline()
        .to(".OurStoryMobileContainer", { height: "auto", autoAlpha: 1, duration: 0.5 })
        .from(".ourStoryMobileClose", { autoAlpha: 0, duration: 0.5 });
      goToSection("ourStoryfirstParagraph");
    } else if (prevShowMobileView.current) {
      gsap.to(".OurStoryMobileContainer", { height: 0, autoAlpha: 0 });
      goToSection("ourStory");
    }
    prevShowMobileView.current = showMobileView;
  }, [showMobileView]);

  return (
    <div style={{ position: "relative", marginTop: 0 }}>
      {!showMobileView && (
        <>
          <div
            onClick={() => setShowMobileView(true)}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <MobileClickToReadContainer
              as="button"
              title="Click to read"
              isButton={true}
              className="ourStoryBadgeMobile"
            >
              <BadgeIcon />
            </MobileClickToReadContainer>
          </div>
        </>
      )}
      <OurStoryMobileContainer className="OurStoryMobileContainer">
        <OurStoryMobileTextContent>
          <p className="ourStoryfirstParagraph">
            In 2016, after 10+ years of working in the medical courier industry (including one of the biggest courier
            companies in the UK), Alex & Tomasz decided it was time to start their own medical courier company. Along
            the way, their now co-partner Tommo joined them in the adventure.
          </p>
          <PhotosContainerMobile>
            <img loading="lazy" src={CourierPhoto} alt="Courier" className="photoCourier" />
          </PhotosContainerMobile>
          <OurStoryMobileCloseBadgeContainer>
            <OurStoryMobileCloseBadge
              className="ourStoryMobileClose"
              title="Close"
              onClick={() => setShowMobileView(false)}
            >
              CLOSE
            </OurStoryMobileCloseBadge>
          </OurStoryMobileCloseBadgeContainer>
          <p>
            Working in the industry for such a long time made them realize that there was a serious need for{" "}
            <b>higher standards</b> and a <CircledText>higher&nbsp;quality</CircledText> service in the delivery of
            medical parcels.
            <br />
            <br />
            Having understood the crucial importance of timeliness, secure deliveries and good customer service in this
            industry, they embarked on a journey to become the only dedicated medical courier company in London.
          </p>
          <PhotosContainerMobile>
            <img loading="lazy" src={LabPhoto} alt="Lab" className="photoLab" />
          </PhotosContainerMobile>
          <p>
            Throughout the pandemic, the business grew tremendously thanks to new services such as at-home{" "}
            <b>PCR tests & blood samples</b>. The clients appreciated the quality of service and began recommending
            Medical Logistics to those around them.
            <br />
            <br />
            In the space of one year Medical Logistics had hit its 5 year growth plan and obtained its{" "}
            <CircledText>UKAS&nbsp;accreditation!</CircledText>
          </p>
        </OurStoryMobileTextContent>
      </OurStoryMobileContainer>
    </div>
  );
};

export default OurStoryMobile;
