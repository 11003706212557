import { useEffect, useRef } from "react";
import { Animation } from "./animation";
import * as THREE from "three";

const useAnimationController = (
  actions: {
    [x: string]: THREE.AnimationAction;
  },
  animationState: Animation
) => {
  const prevAnim = useRef("");

  const playAnimation = (animationState: string) => {
    actions[animationState].setLoop(THREE.LoopOnce, 1);
    actions[animationState].clampWhenFinished = true;
    Object.keys(actions).forEach(name => actions[name].stop());
    actions[animationState].setEffectiveTimeScale(0.8);
    actions[animationState].play();
  };

  const playReverse = () => {
    if (prevAnim.current.length > 0) {
      const reverseName = prevAnim.current.replace("In", "Out");
      playAnimation(reverseName);
      const timeout = actions[reverseName].getEffectiveTimeScale() * 300;
      return timeout;
    } else return 0;
  };
  useEffect(() => {
    if (animationState !== Animation.IDLE && animationState !== undefined) {
      let timeout = 200;
      if (animationState !== Animation.BOX_OPEN) {
        timeout = playReverse();
      }
      setTimeout(() => {
        playAnimation(animationState);
      }, timeout);
      prevAnim.current = animationState;
    }
  }, [animationState]);
};

export default useAnimationController;
