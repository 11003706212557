import React, { ComponentPropsWithoutRef, forwardRef, FunctionComponent, Ref } from "react";
import { StyledTextarea } from "./Textarea.components";

export interface TextareaProps extends ComponentPropsWithoutRef<"textarea"> {
  error?: string;
}

export const Textarea: FunctionComponent<
  TextareaProps & {
    forwardedRef?: Ref<HTMLTextAreaElement>;
  }
> = ({ forwardedRef, rows = 2, ...textareaProps }) => (
  <StyledTextarea {...textareaProps} ref={forwardedRef} rows={rows} />
);

export default forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => (
  <Textarea {...props} forwardedRef={ref} />
));
