import React, { FunctionComponent } from "react";
import { Field, FieldProps, FieldAttributes } from "formik";
import Input, { InputProps } from "./Input";

export type InputFieldProps = FieldAttributes<{
  hasDescription?: boolean;
  description?: JSX.Element;
}> &
  Pick<InputProps, "placeholder" | "type" | "isDisabled" | "sizeType" | "showError">;

const InputField: FunctionComponent<InputFieldProps> = ({
  placeholder,
  type,
  isDisabled,
  sizeType,
  showError,
  hasDescription,
  description,
  ...fieldProps
}) => (
  <Field {...fieldProps}>
    {({ field, meta }: FieldProps) => (
      <>
        <Input
          {...field}
          placeholder={placeholder}
          type={type}
          isDisabled={isDisabled}
          sizeType={sizeType}
          showError={showError}
          error={meta.touched && meta.error ? meta.error : undefined}
          hasDescription={hasDescription}
          description={description}
        />
      </>
    )}
  </Field>
);

export default InputField;
