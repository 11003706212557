import styled from "styled-components";
import { media } from "../../../shared/styles/media";
import { WhyChooseUsImage, WhyChooseUsTitle } from "../WhyChooseUs.components";

export const WhyChooseUsBoxBackgroundMobile = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 32px 24px 80px;
  justify-content: center;
  ${media.down("NEW_LARGE_MOBILE")`
    margin-left: -30px;
  `}
  ${media.down("MOBILE_MLARGE")`
    padding-left: 72px;
  `}
  ${media.down("MOBILE")`
    padding-left:80px;
    margin-left: -80px;
  `}
`;
export const WhyChooseUsBoxWrapperMobile = styled.div`
  width: 100%;
  max-width: unset;
  display: flex;
  align-items: center;
  position: relative;
`;

export const WhyChooseUsImageMobile = styled(WhyChooseUsImage)<{ courierLanding?: boolean }>`
  position: static;
  margin-right: 20px;
  width: 13vw;
  min-width: 80px;
  ${media.down("NEW_LARGE_MOBILE")`
  width: 14vw;
  `}
`;

export const WhyChooseUsImageMobileMain = styled.img`
  position: absolute;
  width: 108px;
  height: 111px;
`;

export const WhyChooseUsTitleMobile = styled(WhyChooseUsTitle)`
  padding: 20px 0 30px;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 104%;
  font-size: 48px;
`;

export const BreakLine = styled.br`
  display: none;
  ${media.down("MOBILE_MLARGE")`
        display: block;
    `}
`;

export const BreakLineMobileTitle = styled.br`
  display: none;
  @media all and (max-width: 510px) {
    display: block;
  }
`;
