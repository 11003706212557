import React, { useState, FunctionComponent } from "react";
import CompanyLandingContext from "./CompanyLandingContext";
// import Scrollbar from "smooth-scrollbar";
// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

const CompanyLandingProvider: FunctionComponent = ({ children }) => {
  const [scrollerProxyCreated, setScrollerProxyCreated] = useState(true);

  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);
  //   const scroller = document.querySelector(".scroller");
  //   // @ts-ignore
  //   const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.08 });

  //   ScrollTrigger.scrollerProxy(".scroller", {
  //     scrollTop(value: any) {
  //       if (arguments.length) {
  //         // @ts-ignore
  //         bodyScrollBar.scrollTop = value;
  //       }
  //       return bodyScrollBar.scrollTop;
  //     },
  //   });

  //   bodyScrollBar.addListener(ScrollTrigger.update);
  //   ScrollTrigger.defaults({ scroller: scroller });

  //   setScrollerProxyCreated(true);

  //   if (document.querySelector(".gsap-marker-scroller-start")) {
  //     const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
  //     bodyScrollBar.addListener(({ offset }) => {
  //       gsap.set(markers, { marginTop: -offset.y });
  //     });
  //   }
  // }, []);
  return (
    <CompanyLandingContext.Provider value={{ scrollerProxyCreated, setScrollerProxyCreated }}>
      {children}
    </CompanyLandingContext.Provider>
  );
};

export default CompanyLandingProvider;
