import React, { FunctionComponent, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BioniqLogo from "../assets/bioniq.png";
import DoctorsLogo from "../assets/doctors-laboratory.png";
import EurofinsLogo from "../assets/eurofins.png";
import MedicalLaboratoriesLogo from "../assets/medical-laboratories.png";
import CpsLogo from "../assets/cps.png";
import GenovaLogo from "../assets/genova.png";
import InhealthLogo from "../assets/inhealth.png";
import LdpathLogo from "../assets/ldpath.png";
import MediiLogo from "../assets/medii.png";
import { LaboratoriesCarouselElements } from "../Laboratories.components";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplaySpeed: 0,
  easing: "linear",
  speed: 50000,
  slidesToShow: 7,
  slidesToScroll: 9,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};
const LogoCarousel: FunctionComponent<{ play: boolean }> = ({ play }) => {
  const sliderRef = useRef<Slider>(null);
  useEffect(() => {
    if (play && sliderRef?.current) sliderRef.current.slickPlay();
  }, [play]);
  return (
    <Slider ref={sliderRef} {...settings}>
      <LaboratoriesCarouselElements src={DoctorsLogo} />
      <LaboratoriesCarouselElements src={MedicalLaboratoriesLogo} />
      <LaboratoriesCarouselElements src={EurofinsLogo} />
      <LaboratoriesCarouselElements src={InhealthLogo} />
      <LaboratoriesCarouselElements src={BioniqLogo} />
      <LaboratoriesCarouselElements src={CpsLogo} />
      <LaboratoriesCarouselElements src={GenovaLogo} />
      <LaboratoriesCarouselElements src={MediiLogo} />
      <LaboratoriesCarouselElements src={LdpathLogo} />
    </Slider>
  );
};

export default LogoCarousel;
