import React from "react";
import { CouriersRoutes } from "../../../config/routes";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import {
  DeliveriesDescription,
  DeliveriesSubTitle,
  DeliveriesTitle,
  PackageShadowImg,
  PackageVideoContainerMobile,
  DeliveriesMobileWrapper,
  DeliveriesMobileTextContent,
} from "./TimeCriticalDeliveries.components";
import Button from "../../shared/components/Button";
//@ts-ignore
import TCDVideoMov from "./videos/tcd.mov";
//@ts-ignore
import TCDVideoWebm from "./videos/tcd.webm";

import PackageShadow from "./DeliveryOnlyShadow.png";
import useIsSamsungInternet from "../../../common/hooks/useIsSamsungInternet";
const TimeCriticalDeliveriesMobile = () => {
  const isSamsungInternet = useIsSamsungInternet();
  return (
    <DeliveriesMobileWrapper hideBackground={isSamsungInternet}>
      <DeliveriesMobileTextContent>
        <SEOMarker routeInfo={CouriersRoutes.timeCriticalDeliveries} />
        <DeliveriesTitle className="deliveriesTitle">
          Time-<span>Critical</span> Deliveries
        </DeliveriesTitle>
        <DeliveriesSubTitle>
          Need help <span>transporting</span> blood samples, tissues or swabs?
        </DeliveriesSubTitle>
        <DeliveriesDescription>
          <span>We are here for you. </span>Thanks to our skilled team of exclusively medical couriers, all of your
          packages will be safely delivered, on time and perfectly intact.
        </DeliveriesDescription>
      </DeliveriesMobileTextContent>
      <PackageVideoContainerMobile>
        <video style={{ height: "100%", width: "100%" }} autoPlay muted playsInline loop>
          <source src={TCDVideoMov} type="video/quicktime" />
          <source src={TCDVideoWebm} type="video/webm" />
        </video>
        <PackageShadowImg src={PackageShadow} />
      </PackageVideoContainerMobile>
      <div style={{ display: "flex", margin: "24px auto" }} key={"conditionalButtonWrapper"}>
        <Button onClick={() => window.open("https://booking.medicallogistics.co.uk/", "_self")} size="extraLarge">
          Check availability
        </Button>
      </div>
    </DeliveriesMobileWrapper>
  );
};

export default TimeCriticalDeliveriesMobile;
