export enum Landing {
  MEDICAL = "MEDICAL",
  COMPANY = "COMPANY",
  COURIERS = "COURIERS",
}

export const config = {
  selectedLanding: process.env.REACT_APP_SELECTED_LANDING as Landing | undefined,
  development: process.env.REACT_APP_DEVELOPMENT,
  gaTrackingCode: process.env.REACT_APP_GA_TRACKING_CODE,
  websiteUrl: process.env.REACT_APP_WEBSITE_URL,
  infoMail: "info@medicallogistics.co.uk",
};
