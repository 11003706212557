import styled from "styled-components";
import { media } from "../../styles/media";

export const SectionWrapper = styled.section`
  width: 100%;
  height: auto;
  max-width: 1920px;
  box-sizing: border-box;
  ${media.down("FULL")`
    padding:  0 360px;
    margin: auto;
  `}

  ${media.down("MAIN_WEB")`
    padding: 0 200px;
  `}

  ${media.down("LAPTOP")`
    padding: 0 144px ;
  `}
  ${media.down("TABLET")`
    padding:0 104px;
  `}
  ${media.down("TABLET_SMALL")`
    padding: 0 96px;
  `}

  ${media.down("MOBILE_LARGE")`
      padding: 0 48px;
  `}
  ${media.down("MOBILE")`
      padding: 0 48px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding: 0 40px;
  `}
`;
