import styled from "styled-components";
import { media } from "../../shared/styles/media";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import GradientBackground from "./Background-Eclipse-Introduction.svg";

export const IntroductionOuterContainer = styled(SectionWrapper)`
  display: flex;
  background: #181a1b;
  position: relative;
  flex-direction: column;
  overflow: visible;
  padding-top: 25vh;
  background: url(${GradientBackground});
  background-size: 60% 70%;
  background-position: 70% 15%;
  background-repeat: no-repeat;

  ${media.down("NEW_TABLET")`
    padding-top:  170px;
    background-size: 80% 50%;
  `};
  ${media.down("NEW_LARGE_MOBILE")`
    padding-top: 260px;
    background-size: 100% 40%;
  `};
  ${media.down("NEW_MOBILE")`
    padding-top: 250px;
    background-position: 60% 0%;
  `};
`;

export const GradientBackgroundWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: url(${GradientBackground});
  background-size: 80% 100%;
  background-position: 100% 5%;
  background-repeat: no-repeat; */
`;

export const ButtonWrapper = styled.div`
  width: 190px;
  height: 54px;
  margin-top: 34px;
  margin-left: -14px;
  position: relative;
  ${media.up("NEW_LARGE_WEB")`
    width: 220px;
  `}
`;

export const IntroductionContainer = styled.div`
  //min-height: 100vh;
  overflow: visible;
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-direction: row;
`;

export const IntroductionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
`;

export const CrownCanvasWrapper = styled.video`
  position: absolute;
  right: -70px;
  top: 50px;
  width: 50%;
  max-width: 650px;
  margin-top: -20px;
  ${media.down("NEW_TABLET")`
    margin-top: -50px;
    top:0px;
    right:0px;
    width: 60%;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    margin-top: -150px;
    width: 65%;
  `}
  ${media.down("NEW_MOBILE")`
    margin-top: -100px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
  margin-top: -100px;
    right: -20px;
  `}
`;

export const IntroductionHeading = styled.h1`
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 84px;
  line-height: 104%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #f0f5f5;
  margin: 0px;
  margin-top: 30px;
  padding: 0px;
  max-width: 580px;
  pointer-events: none;
  will-change: transform, opacity;

  ${media.up("NEW_LARGE_WEB")`
      font-size: 104px;
      max-width: 708px;
  `}

  ${media.down("NEW_SMALL_WEB")`
      font-size: 64px;
  `}

  ${media.down("NEW_HALF_WEB")`
      font-size: 56px;
      max-width:400px;
      margin-top: 24px;
  `}
  ${media.down("NEW_TABLET")`
      font-size: 56px;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
      font-size: 48px;
  `}
  ${media.down("NEW_MOBILE")`
      font-size: 44px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
      font-size: 36px;
  `}
`;

export const IntroductionDescription = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
  color: #b6c3c3;
  max-width: 420px;
  margin-top: 12px;
  margin-left: 2px;
  pointer-events: none;
  will-change: transform, opacity;
  ${media.up("NEW_LARGE_WEB")`
      font-size: 24px;
      max-width: 420px;
     
  `}

  ${media.down("NEW_SMALL_WEB")`
      max-width: 340px;
  `}

  ${media.down("NEW_HALF_WEB")`
      font-size: 18px;
      max-width:300px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
      font-size: 16px;
  `}
`;

export const TabletBreakLine = styled.br`
  display: none;
  ${media.down("NEW_TABLET")`
    display: block;
  `}
  ${media.down("NEW_LARGE_MOBILE")`
    display: none;
  `}
`;

export const FeedbackOuterContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 156px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: end;
  scroll-snap-stop: always;
  ${media.down("TABLET")`
      margin-top:0px;
  `}
`;

export const FeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  > :nth-child(2) {
    margin-left: 24px;
    margin-right: 24px;
  }

  ${media.down("TABLET")`
    flex-direction:column;
    align-items:center;

    > :nth-child(2) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top:24px;
    margin-bottom:24px;
    }
  `}
`;

export const ReadMoreButton = styled.a`
  margin-top: 30px;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #f0f5f5;
  text-decoration: none;
  position: relative;
  transition: transform 500ms ease;
  opacity: 0;

  :hover {
    transform: scale(1.2);
  }

  ::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 1px;
    background: #48c7c3;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
