import React, { useState, FunctionComponent, useRef } from "react";
import { Animation } from "../Package/animations/animation";
import PackageContext from "./PackageContext";

export const PackageProvider: FunctionComponent = ({ children }) => {
  const [pathRendered, setPathRendered] = useState(false);
  const [animationState, setAnimationState] = useState(Animation.IDLE);
  const timeCriticalAnimation = useRef(Animation.SAMPLES_IN);

  const setTimeCriticalAnimation = (animation: Animation) => {
    if (animation !== timeCriticalAnimation.current) timeCriticalAnimation.current = animation;
  };

  return (
    <PackageContext.Provider
      value={{
        pathRendered,
        setPathRendered,
        animationState,
        setAnimationState,
        timeCriticalAnimation,
        setTimeCriticalAnimation,
      }}
    >
      {children}
    </PackageContext.Provider>
  );
};
