import React from "react";
import { PageLoaderWrapper } from "./PageLoader.components";
import Loader from "./Loader.svg";

const PageLoader = () => {
  return (
    <PageLoaderWrapper className="pageLoader">
      <div>
        <object data={Loader} />
      </div>
    </PageLoaderWrapper>
  );
};

export default PageLoader;
