import styled from "styled-components";
import { media } from "../../shared/styles/media";

export const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #efe9e6;
`;

export const SectionPlaceholder = styled.section<{ bgColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: ${p => p.bgColor};
  color: black;
  text-align: center;
  font-size: 64px;
`;

export const PathAbsoluteContainer = styled.div<{ offsetTop: number }>`
  position: absolute;
  top: ${p => p.offsetTop}px;
  right: 0;

  ${media.down("NEW_HALF_WEB")`
  `}

  ${p => media.down("NEW_TABLET")`
    top: ${p.offsetTop + 64}px;
  `}

  ${p => media.down("NEW_LARGE_MOBILE")`
    top: ${p.offsetTop + 460}px;
  `}

  ${media.down("NEW_MOBILE")`
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  `}
`;
