import React from "react";
import { PrivacyAndTermsWrapper, PrivacyContent, PrivacyHeader } from "./PrivacyAndTerms.components";

const ModernSlaveryPolicy = () => {
  return (
    <PrivacyAndTermsWrapper>
      <PrivacyHeader>MODERN SLAVERY AND HUMAN TRAFFICKING STATEMENT</PrivacyHeader>

      <PrivacyHeader style={{ marginLeft: 0 }}>Introduction</PrivacyHeader>
      <PrivacyContent>
        This statement is relating to the section 54(1) of the Modern Slavery Act 2015 and comprises the slavery and
        human trafficking statement for Med Logistics Limited.
      </PrivacyContent>
      <PrivacyContent>
        This statement outlines the steps we have taken as a business to identify and prevent slavery and human
        trafficking in our operations. We understand our responsibilities and are committed to improving our practices
        to combat slavery and human trafficking.
      </PrivacyContent>

      <PrivacyHeader>Our Business</PrivacyHeader>
      <PrivacyContent>
        We are a medical courier business offering collection and delivery of medical samples across UK. We employ staff
        within our company as well as outsourcing some roles.
      </PrivacyContent>

      <PrivacyHeader>Our Supply Chains</PrivacyHeader>
      <PrivacyContent>The company has direct relationships with a few UK based suppliers</PrivacyContent>

      <PrivacyHeader>Our Policies On Slavery And Human Trafficking</PrivacyHeader>
      <PrivacyContent>
        We are committed to ensuring that there is no modern slavery or human trafficking in any part of our business or
        supply chains. Our Anti-Slavery and Human Trafficking Policy reflects our commitment to acting ethically and
        with integrity to ensure slavery and human trafficking is not taking place anywhere within our business
      </PrivacyContent>

      <PrivacyHeader>Due Diligence Processes For Slavery And Human Trafficking</PrivacyHeader>
      <PrivacyContent>
        As part of our initiative to identify, monitor and mitigate risk, we nominate senior leaders within our business
        with the purpose of:
      </PrivacyContent>

      <div style={{ marginLeft: 25 }}>
        <PrivacyContent>● Identifying inappropriate employment practices</PrivacyContent>
        <PrivacyContent>● Identifying, assessing and monitoring other potential risk areas</PrivacyContent>
        <PrivacyContent>● Mitigating the risk of slavery and human trafficking occurring</PrivacyContent>
        <PrivacyContent>● Protecting whistleblowers</PrivacyContent>
        <PrivacyContent>● Investigating reports of Modern Slavery</PrivacyContent>
      </div>

      <PrivacyHeader>Supplier Adherence To Our Values And Ethics</PrivacyHeader>
      <PrivacyContent>
        We have zero tolerance to slavery and human trafficking. To ensure all people within our business - both
        employees and contractors comply with the values we operate in line with principles of responsible sourcing.
        This includes paying employees no less than the minimum wage applicable in the UK.
      </PrivacyContent>

      <PrivacyHeader>Closing Statement</PrivacyHeader>
      <PrivacyContent>
        This statement is made pursuant to section 54(1) of the Modern Slavery Act 2015 and constitutes our company’s
        slavery and human trafficking statement for the year of 2022.
      </PrivacyContent>

      <PrivacyContent>
        Alex Landowski
        <br />
        Managing Director
        <br />
        Med Logistics Limited
        <br />
        April 2022
        <br />
      </PrivacyContent>
      <PrivacyContent>
        <a href="./modern_slavery_statement.pdf">Modern Slavery Statement Report for 2022/2023</a>
      </PrivacyContent>
    </PrivacyAndTermsWrapper>
  );
};
export default ModernSlaveryPolicy;
