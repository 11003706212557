import React, { FunctionComponent, useEffect, useState } from "react";
import { AnimatedTextContainer, AnimatedTextContent } from "./AnimatedText.components";
import gsap from "gsap";

interface AnimatedTextProps {
  texts: string[];
  currentIndex: number;
}

const AnimatedText: FunctionComponent<AnimatedTextProps> = ({ texts, currentIndex }) => {
  const [currentText, setCurrentText] = useState(texts[0]);

  useEffect(() => {
    gsap.timeline().to(".animatedTextLetter", { y: -80, duration: 0.4, stagger: 0.025 });
    setTimeout(() => {
      setCurrentText(texts[currentIndex]);
      gsap
        .timeline()
        //.from(".animatedTextLetter", { y: 80, duration: 0 })
        .to(".animatedTextLetter", { opacity: 1, y: 80, duration: 0 })
        .to(".animatedTextLetter", { y: 0, duration: 0.4, stagger: 0.025 });
    }, currentText.length * 50);
  }, [currentIndex]);

  return (
    <AnimatedTextContainer>
      <AnimatedTextContent
        dangerouslySetInnerHTML={{
          __html: currentText.replace(/(?![^<]*>)[^<]/g, c => `<span class="animatedTextLetter">${c}</span>`),
        }}
      />
    </AnimatedTextContainer>
  );
};

export default AnimatedText;
