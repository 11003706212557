import styled, { css } from "styled-components";
import { media } from "../../../styles/media";

export type InputSize = "normal" | "unset";

interface StyledInputProps {
  isError?: boolean;
}
interface WrapperProps {
  isError?: boolean;
  sizeType?: InputSize;
}

export const StyledInput = styled.input<StyledInputProps>`
  font-family: "Barlow";
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
  color: #f0f5f5;
  padding: 13px 0px 10px 0px;
  background: none;
  border: none;
  border-bottom: 1.5px solid rgba(148, 168, 167, 0.32);
  transition: border-bottom 0.4s ease;
  ${media.up("NEW_LARGE_WEB")`
      font-size: 26px;
    `}
  ${p => p.isError && "border-bottom: 1.5px solid #FB7480;"}

  &::placeholder {
    font-weight: 300;
    font-size: 20px;
    line-height: 145%;
    color: #b6c3c3;

    ${media.up("NEW_LARGE_WEB")`
      font-size: 26px;
    `}
  }

  &:focus,
  &:active {
    outline: none;
    border-bottom: 1.5px solid #35aca8;
    ${p => p.isError && "border-color:#FB7480;"}
  }

  ${media.down("NEW_HALF_WEB")`
    font-size: 18px;

    &::placeholder {
      font-size: 18px;
    }
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 20px;

    &::placeholder {
      font-size: 20px;
    }
  `}


  :hover {
    ${p => !p.isError && "border-bottom: 1.5px solid #35aca8;"}
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputComponentWrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;

  ${p =>
    p.sizeType === "normal" &&
    css`
      width: 336px;

      ${media.down("NEW_HALF_WEB")`
        width: 274px;
      `}
      ${media.down("NEW_TABLET")`
        width: 222px;
      `}
      ${media.down("NEW_LARGE_MOBILE")`
        width: 324px;
      `}
      ${media.down("NEW_MOBILE")`
        width: 264px;
      `}
    `}
`;

export const ErrorWrapper = styled.span`
  position: absolute;
  margin-top: 8px;
  color: #fb7480;
  right: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  ${media.down("NEW_HALF_WEB")`
    font-size: 14px;
  `}
`;
