import styled from "styled-components";
import { media } from "../../../styles/media";

const Label = styled.label`
  font-family: "Barlow";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #f0f5f5;
  z-index: 2000;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 20px;
  `}
`;
export default Label;
