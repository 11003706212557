import React, { FunctionComponent, forwardRef, Ref, ComponentPropsWithoutRef } from "react";
import { InputComponentWrapper, InputWrapper, StyledInput, InputSize, ErrorWrapper } from "./Input.components";

export interface InputProps extends ComponentPropsWithoutRef<"input"> {
  error?: string;
  type?: string;
  isDisabled?: boolean;
  sizeType?: InputSize;
  showError?: boolean;
  hasDescription?: boolean;
  description?: JSX.Element;
}

export const Input: FunctionComponent<
  InputProps & {
    forwardedRef?: Ref<HTMLInputElement>;
  }
> = ({
  error,
  type = "text",
  forwardedRef,
  isDisabled,
  sizeType,
  showError = true,
  hasDescription,
  description,
  ...inputProps
}) => (
  <InputComponentWrapper isError={!!error} sizeType={sizeType}>
    <InputWrapper>
      <StyledInput {...inputProps} ref={forwardedRef} isError={!!error} type={type} disabled={isDisabled} />
    </InputWrapper>
    {hasDescription && description && description}
    {showError && error && <ErrorWrapper>{error}</ErrorWrapper>}
  </InputComponentWrapper>
);

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => <Input {...props} forwardedRef={ref} />);
