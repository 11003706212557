import { config } from "../../../../config";
export const COOKIES_ACCEPTED_KEY = "COOKIES_ACCEPTED";

const useCookiesAccepted = () => {
  const cookieArr = document.cookie.split(";");
  // to hide box in development env
  if (config.development === "true") return true;

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");
    if (COOKIES_ACCEPTED_KEY == cookiePair[0].trim()) return true;
  }
  return false;
};

export default useCookiesAccepted;
