import styled, { css } from "styled-components";
import ClickToReadIcon from "./assets/ClickToRead.svg";
import ArrowIcon from "./assets/arrow.svg";
import EllipseIcon from "./assets/ellipse.png";
import CourierImageSmall from "./assets/CourierMobile.png";
import AlexAndTomaszMobile from "./assets/alexAndTomaszMobile.png";
import { media } from "../../shared/styles/media";
import { BadgeWrapper } from "../Ukas/Ukas.components";

export const OurStorySection = styled.section`
  background-color: #181a1b;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  padding: 120px 200px 80px 200px;
  box-sizing: border-box;
  width: 100%;
  height: auto;

  ${media.up("FULL")`
    padding-top: 160px;
    max-width: 1920px;
    margin: 0 auto;
    padding: 160px 360px 100px 360px;
  `}

  ${media.down("LAPTOP")`
    padding: 100px 144px 80px;
  `}
  ${media.down("TABLET")`
    padding: 80px 96px 50px;
  `}
  ${media.down("TABLET_SMALL")`
  overflow: unset;
    padding: 80px 104px 80px;
  `}
  ${media.down("MOBILE_LARGE")`

      padding: 80px;
  `}

  ${media.down("MOBILE")`
      font-size: 68px;
      padding:  48px;
      padding-top: 0;
  `}
  ${media.down("MOBILE_SMALL")`
      font-size: 44px;
      padding: 40px ;
      padding-top: 0;
  `}
`;

export const OurStoryContent = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  padding-bottom: 44px;
  position: relative;
`;

export const OurStoryPageContainer = styled.div`
  display: flex;
  ${media.down("TABLET_SMALL")`
      flex-direction: column-reverse;
  `}
`;

export const OurStoryNextPageContent = styled.div`
  position: relative;
  ${media.down("TABLET_LARGE")`
    margin-right: 30px;
    `}
  ${media.down("TABLET_SMALL")`
    margin-right: 0px;
    `}
`;

export const OurStoryLeftSection = styled.div`
  flex: 6;
  display: flex;
  position: relative;
  align-items: center;
  ${media.down("TABLET")`
    padding-top: 100px;
  `}
  ${media.down("TABLET_SMALL")`
    padding-top: 0;
  `}
`;
export const OurStoryRightSection = styled.div`
  box-sizing: border-box;
  flex: 4;
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  position: relative;
  ${media.down("TABLET_SMALL")`
    padding-left: 0;
    align-items: end;
  `}
  ${media.down("TABLET")`
    padding-left:40px;
  `}
  ${media.down("TABLET_SMALL")`
    padding-bottom: 60px;
    padding-top: 250px;
    margin-top: -150px;
    align-items: center;
  `}
  ${media.down("MOBILE_LARGE")`
    padding-left: 0;
    padding-bottom: 80px;
  `}
  ${media.down("MOBILE")`
    padding-bottom: 40px;
  `}
`;

export const OurStoryTitle = styled.div`
  position: absolute;
  top: -20%;
  right: -10%;
  ${media.down("LAPTOP")`
    right: -20%;
  `};
  //transform: translate(0, -50%);
  ${media.down("TABLET_LARGE")`
    top: -15%;
  `};
  ${media.down("TABLET_SMALL")`
  position: static;
  `}

  svg {
    font: bold 70px Century Gothic, Arial;
    width: 438px;
    height: 105px;
    overflow: visible;
    ${media.down("LAPTOP")`
      width: 500px;
      height: 104px;
  `}
    ${media.down("TABLET_LARGE")`
      width: 450px;
  `}
    ${media.down("TABLET_SMALL")`
      width: 90%;
      height: 115px;
  `}

    ${media.down("MOBILE")`
      width: 100%;
      height: 65px;
  `}
  }

  text {
    fill: none;
    stroke: rgb(209, 220, 220, 0.16);
    stroke-width: 1.5px;
    stroke-linejoin: round;
    font-family: Barlow Semi Condensed;
    font-size: 104px;
    font-style: normal;
    font-weight: 500;
    line-height: 111px;
    letter-spacing: -0.02em;
    text-align: left;

    ${media.down("LAPTOP")`
      font-size: 96px;
  `}
    ${media.down("TABLET_SMALL")`
      font-size: 76px;
  `}

  ${media.down("MOBILE")`
      font-size: 64px;
      left: 25%;
  `}
  ${media.down("MOBILE_TABLE")`
      font-size: 74px;
  `}
  }
`;
export const OurStoryDescription = styled.p`
  margin: 0%;
  font-family: Barlow Semi Condensed;
  font-size: 72px;
  font-style: italic;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.02em;

  ${media.down("LAPTOP")`
      font-size: 64px;
  `}
  ${media.down("TABLET")`
      font-size: 54px;
  `}
  ${media.down("TABLET_SMALL")`
      font-size: 72px;
  `}

  ${media.down("MOBILE")`
      font-size: 44px;
      width: 95%;
  `}
  ${media.down("MOBILE_SMALL")`
      font-size: 40px;
  `}

  b {
    font-weight: 500;
  }
  u {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;

export const PhotosContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  ${media.down("TABLET_SMALL")`
    width: 80%;
      
  `}
  ${media.down("MOBILE_LARGE")`
    width: 100%;
    margin-top: 0;
    content: url(${AlexAndTomaszMobile});
  `}
`;

export const PhotosContainerSection2 = styled(PhotosContainer)`
  height: 100%;
  img {
    width: auto;
    position: absolute;
    right: 0;
    height: 80%;
    display: flex;
    align-items: flex-end;

    ${media.down("TABLET_SMALL")`
      display: block;
  `}
  }
  img:last-of-type {
    opacity: 0;
  }

  ${media.down("TABLET_SMALL")`
      width: 80%;
      height: auto;
      border-top-left-radius: 200px;
      content: url(${CourierImageSmall})
  `}
`;

export const OurStoryFullDescription = styled.p`
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(182, 195, 195, 1);

  ${media.down("TABLET")`
      font-size: 14px;
  `}
  ${media.down("TABLET_SMALL")`
      display: none;
  `}
`;

export const OurStoryFooter = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${media.down("TABLET_SMALL")`
  height: 120px;
  `}
`;

export const ClickToReadContainer = styled(BadgeWrapper)`
  width: 120px;
  height: 120px;
  padding: 0;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  margin-right: 8px;
  top: -180px;
  right: 45%;
  background-color: transparent;
  outline: none;
  border: none;
  animation: rotate 4s linear infinite;
  ${media.down("MOBILE")`
  width: 90px;
  height: 90px;
  
  `}
  @media all and (max-width: 1100px) {
    top: -100px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ClickToRead = styled.button`
  /* background: url(${ClickToReadIcon});
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: contain;
  border: none;
  outline: none;
  cursor: pointer; */
`;

export const OurStoryFooterLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: rgba(164, 152, 152, 0.32);
`;

export const OurStoryFooterNumber = styled.p`
  margin-left: 8px;
  font-size: 24px;
  ${media.down("MOBILE")`
  font-size: 20px;
  `}
`;
interface OurStoryFooterArrowProps {
  leftArrow?: boolean;
}
export const OurStoryFooterArrow = styled.button<OurStoryFooterArrowProps>`
  border: none;
  outline: none;
  padding: 20px 0;
  background-color: transparent;
  cursor: pointer;
  background-image: url(${ArrowIcon});
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 18px;
  margin-left: 8px;
  ${props =>
    props.leftArrow &&
    css`
      transform: rotate(180deg);
      margin: 0 8px 0 0;
    `}
`;

export const OurStoryNexPageContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  ${media.down("TABLET_SMALL")`
      flex-direction: column-reverse;
  `}
`;

export const OurStoryLeftContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0 30px 0;
  font-family: Barlow Semi Condensed;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #b6c3c3;
  p {
    margin: 0;
  }

  b {
    font-weight: 500;
    color: #f1f1f1;
  }

  ${media.down("LAPTOP")`
      font-size: 24px;
      margin-right: 30px;
  `}
  ${media.down("LAPTOP")`
      font-size: 20px;
  `}
  ${media.down("TABLET_SMALL")`
      margin: 0;
  `}
  ${media.down("MOBILE_SMALL")`
      font-size: 18px;
  `}
`;

export const OurStoryHiddenText = styled.p`
  position: absolute;
  top: 0px;
  ${media.down("TABLET_SMALL")`
      top: 0;
  `}
`;

export const CircledText = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 50px;
  transition: top 0.5s linear;
  transition: left 0.5s linear, top 0.5s linear;
  background-image: url(${EllipseIcon});
  background-size: 100% 100%;
  padding: 0 8px;
`;
