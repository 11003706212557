import React, { FunctionComponent, useState, useEffect } from "react";
import gsap from "gsap";
import {
  BlurredMedicalDirectorBackground,
  MedicalDirectorBackground,
  MedicalDirectorContainer,
  MedicalDirectorContent,
  MedicalDirectorDescripton,
  MedicalDirectorHeading,
  MedicalDirectorSection,
  TextContentContainer,
  TextSectionWrapper,
} from "./MedicalDirector.components";
import { PlayButtonContainer } from "../ClientsStory/ClientsStory.components";
import VideoPlayer from "../../shared/components/VideoPlayer/VideoPlayer";
import { CloseButtonOverlay } from "../../shared/components/VideoPlayer/VideoPlayer.components";
import CirclePlayButton from "../ClientsStory/images/CirclePlayButton.svg";
import { goToSection } from "../../shared/components/Navbar/navigation.helpers";
import useMedia from "use-media";
import BouncedShapeWithTransparency from "../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import placeholderImg from "./FaisalFirstFrame.jpg";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { MedicalRoutes } from "../../../config/routes";
import { useInView } from "react-intersection-observer";

const MEDICAL_DIRECTOR_VIDEO_URL = "https://vimeo.com/646871108";

const MedicalDirector: FunctionComponent = () => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [shouldAnimateButton, setShouldAnimateButton] = useState(true);
  const isNewLargeMobile = useMedia({ maxWidth: 649 });
  const [inViewRef, inView] = useInView();

  useEffect(() => {
    gsap.to(".directorBG", {
      scale: 1.1,
      scrollTrigger: {
        trigger: ".directorBG",
        start: "top top+=150",
        end: "center-=200 top",
        scrub: true,
      },
      ease: "linear",
    });
  }, []);

  useEffect(() => {
    if (inView && shouldAnimateButton) setShouldAnimateButton(false);
  }, [inView, shouldAnimateButton]);

  const introAnimation = () => {
    return gsap
      .timeline({ paused: true })
      .fromTo(".directorHeading", { x: -30, opacity: 0, duration: 1 }, { x: 0, opacity: 1, duration: 1 })
      .fromTo(".directorDescription", { x: -30, opacity: 0, duration: 1 }, { x: 0, opacity: 1, duration: 1 }, 0.5);
  };

  useEffect(() => {
    if (showPlayer) {
      gsap.to(".blurredBackgroudDirector", { autoAlpha: 1 });
    } else {
      gsap.to(".blurredBackgroudDirector", { autoAlpha: 0 });
      introAnimation().play();
    }
  }, [showPlayer]);

  const openVideoPlayer = () => {
    setShowPlayer(true);
    goToSection("medicalDirector", 50);
  };

  return (
    <MedicalDirectorSection id="medicalDirector" className="medicalDirector">
      <SEOMarker routeInfo={MedicalRoutes.medicalDirector} />

      <MedicalDirectorContainer>
        <MedicalDirectorContent>
          <MedicalDirectorBackground className="directorBG" />
          <TextSectionWrapper style={{ visibility: showPlayer ? "hidden" : "visible" }}>
            <TextContentContainer>
              {isNewLargeMobile && inView && (
                <PlayButtonContainer className="storyButton">
                  <CloseButtonOverlay onClick={() => openVideoPlayer()} />
                  <object type="image/svg+xml" data={CirclePlayButton}></object>
                </PlayButtonContainer>
              )}
              <MedicalDirectorHeading ref={inViewRef} className="directorHeading">
                Meet Our Medical Director
              </MedicalDirectorHeading>
              <MedicalDirectorDescripton className="directorDescription">
                Hear about Medical Logistics’ remarkable journey straight from our medical director himself!
              </MedicalDirectorDescripton>
              {!isNewLargeMobile && !shouldAnimateButton && (
                <PlayButtonContainer className="storyButton">
                  <CloseButtonOverlay onClick={() => openVideoPlayer()} />
                  <object type="image/svg+xml" data={CirclePlayButton}></object>
                </PlayButtonContainer>
              )}
            </TextContentContainer>
          </TextSectionWrapper>
          <BlurredMedicalDirectorBackground className="blurredBackgroudDirector">
            <VideoPlayer
              placeholderUrl={placeholderImg}
              isOpened={showPlayer}
              setIsOpened={setShowPlayer}
              videoUrl={MEDICAL_DIRECTOR_VIDEO_URL}
            />
          </BlurredMedicalDirectorBackground>

          <BouncedShapeWithTransparency
            trigger="medicalDirector"
            topShapeBackground="#D8E3E3"
            bottomShapeBackground="transparent"
            inBottomOfSection={true}
          />
        </MedicalDirectorContent>
      </MedicalDirectorContainer>
    </MedicalDirectorSection>
  );
};

export default MedicalDirector;
