import styled from "styled-components";
import { media } from "../../styles/media";

export const ScrollDownContainer = styled.div`
  /* margin-top: 108px; */
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.down("FULL")`
  margin-top: 150px;
  `}

  ${media.down("MAIN_WEB")`
  margin-top: 100px;
  `}

  ${media.down("LAPTOP")`
  margin-top: 70px;

  `}

  ${media.down("TABLET")`
  margin-top: 60px;

  `}

  ${media.down("MOBILE")`
  margin-top: 50px;

  `}


  ${media.down("MOBILE_SMALL")`
  margin-top: 12px;
  `}
`;
export const ScrollDownText = styled.p`
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  color: #b6c3c3;
  margin: 0 auto;
  margin-top: 12px;

  ${media.down("MAIN_WEB")`
  font-size: 16px;
  `}

  ${media.down("TABLET")`
  font-size: 14px;
  `}
`;
export const ScrollDownImg = styled.div`
  margin: 0 auto;
  width: 40px;
  height: 72px;

  ${media.down("MAIN_WEB")`
  width: 28px;
  height: 48px;
  `}
`;
