import { uniqueId } from "lodash";
import React, { FunctionComponent, ComponentPropsWithoutRef, useState } from "react";
import { ButtonContainer, ButtonSvg, ClipPathButton, ButtonContent } from "./Button.components";

type ButtonProps = ComponentPropsWithoutRef<"button"> & {
  size?: "medium" | "large" | "extraLarge" | "block";
  active?: boolean;
  animated?: boolean;
  loading?: boolean;
};

const Button: FunctionComponent<ButtonProps> = ({
  active = true,
  size = "large",
  animated,
  children,
  loading,
  ...buttonProps
}: ButtonProps) => {
  //Generate random id to avoid styles overlapping.
  const [id] = useState(uniqueId("button-clip-path-"));
  return (
    <ButtonContainer {...buttonProps} active={active} disabled={loading || !active}>
      <ButtonSvg>
        <clipPath id={id} clipPathUnits="objectBoundingBox">
          <path d="M0.039,0.22 C0.049,0.089,0.09,-0.003,0.136,0 L0.855,0.052 C0.891,0.055,0.924,0.117,0.94,0.213 L0.989,0.537 C1,0.721,0.987,0.897,0.917,0.904 L0.1,1 C0.037,1,-0.012,0.836,0.002,0.655 L0.039,0.22" />
        </clipPath>
      </ButtonSvg>
      <ClipPathButton size={size} active={active} id={id}>
        <ButtonContent animated={animated} active={active}>
          <span>{children}</span>
        </ButtonContent>
      </ClipPathButton>
    </ButtonContainer>
  );
};

export default Button;
