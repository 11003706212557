import styled from "styled-components";
import { media } from "../../../shared/styles/media";
import { ClickToReadContainer, OurStoryLeftContent } from "../OurStory.components";
import EllipseIcon from "../assets/ellipse.png";

export const OurStoryMobileTextContent = styled(OurStoryLeftContent)`
  position: relative;
  display: flex;
  flex-direction: column;

  p {
    margin: 20px 0;
  }
`;

export const CircledText = styled.b`
  display: inline-block;
  background-image: url(${EllipseIcon});
  background-size: 100% 100%;
`;

export const MobileClickToReadContainer = styled(ClickToReadContainer)`
  position: absolute;
  top: -120px;
  right: 0;
  ${media.down("TABLET_SMALL")`
    position: static;
  width: 160px;
  height: 160px;
  svg {
    width: 160px;
  height: 160px;
  }
  `}

  ${media.down("MOBILE_TABLE")`
  width: 140px;
  height: 140px;
  svg {
    width: 140px;
  height: 140px;
  }
  `}
`;

export const OurStoryMobileCloseBadgeContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: -170px;
  margin-bottom: 70px;
  margin-left: 40px;
  position: sticky;
  top: 80px;
  display: flex;
  justify-content: flex-end;
`;

export const OurStoryMobileCloseBadge = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0.5px solid rgba(164, 152, 152, 0.32);
  outline: none;
  background-color: rgba(209, 220, 220, 0.16);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  ${media.down("MOBILE_SMALL")`
  width: 80px;
  height: 80px;

  `}
`;
export const OurStoryMobileContainer = styled.div`
  height: 0;
  opacity: 0;
`;

export const PhotosContainerMobile = styled.div`
  height: 100%;
  max-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;

  img {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 250px;
    object-position: center top;
    object-fit: cover;
    border-top-left-radius: 80px;
  }
`;
