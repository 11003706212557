import * as Yup from "yup";

export type Subject = "BLOOD_TEST" | "COVID19" | "OTHER" | undefined;

export interface GetInTouchFormValues {
  subject: Subject | string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

export const getInTouchInitialValues: GetInTouchFormValues = {
  subject: "BLOOD_TEST",
  name: "",
  email: "",
  phone: "",
  message: "",
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const getInTouchValidationSchema = Yup.object().shape({
  subject: Yup.string(),
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Email must be valid").required("Email is required"),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  message: Yup.string().required("Message is required"),
});

export const subjects = [
  {
    cardName: "BLOOD_TEST",
    label: "Blood tests",
  },
  {
    cardName: "COVID19",
    label: "Covid-19",
  },
  {
    cardName: "OTHER",
    label: "Other",
  },
];
