// @ts-ignore
import ServicesVideo from "./videos/services.webm";
// @ts-ignore
import ServicesVideoMov from "./videos/services.mov";
// @ts-ignore
import AnyTimeVideo from "./videos/anytime.webm";
// @ts-ignore
import AnyTimeVideoMov from "./videos/anytime.mov";

export const desktopVideos = [
  {
    mov: ServicesVideoMov,
    webm: ServicesVideo,
  },
  {
    mov: AnyTimeVideoMov,
    webm: AnyTimeVideo,
  },
];

export const mobileVideos = desktopVideos;
