import React, { useEffect } from "react";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import { OurTeamPartContainer, OurTeamPhotoCurtain, OurTeamTextSubtitle, OurTeamTextTitle } from "./OurTeam.components";
import {
  OurTeamDirectorsText,
  DirectorsContainer,
  AlexContainer,
  TommasoContainer,
  TimContainer,
  OutTeamMottoContainer,
  OurTeamPersonsContainer,
  OurTeamDirectorNameImage,
  OurTeamDirectorNameContainer,
  OurTeamDirectorNameImageContainer,
  OurTeamDirectorNames,
  OurTeamDirectorTextContainer,
  OurTeamAlexDirectorNameContainer,
  OutTeamMobileMottoContainer,
} from "./OurTeamThirdPart.components";
import Tim from "./assets/tim.png";
import Tommaso from "./assets/tommaso.png";

enum Directors {
  TOMMASO,
  TIM,
  ALEX,
}

const MottoContent = (
  <>
    <OurTeamTextSubtitle>Mission</OurTeamTextSubtitle>
    <OurTeamTextTitle>Keep it professional & dedicated to your needs </OurTeamTextTitle>
  </>
);

const DirectorText = ({ director }: any) => (
  <OurTeamDirectorsText>
    {director === Directors.TIM
      ? `Leads set of accounts, within the company. Managing relationships with clients and guiding account personnel.`
      : director === Directors.TOMMASO
      ? `Managing relationships with our customers and the day to day operations activity.`
      : `Leads set of accounts, within the company. managing relationships with clients and guiding account personnel.`}
  </OurTeamDirectorsText>
);

const OurTeamThirdPart = () => {
  const [inViewRef, inView] = useInView({ threshold: 0.25 });

  useEffect(() => {
    if (inView) {
      gsap
        .timeline()
        .to(".curtainTommaso", { height: 0, duration: 0.5 })
        .to(".OurTeamTimText", { y: -30, duration: 0.7 }, 0.5)
        .to(".OurTeamTimPhoto", { height: "auto", duration: 0.5 }, 0)
        .to(".OurTeamTommasoText", { y: -30, duration: 0.7 }, 0.5)
        .to(".curtainMartin", { height: 0, duration: 0.5 }, 0)
        .to(".OurTeamMartinText", { y: -30, duration: 0.7 }, 0.5);
    } else {
      gsap.to(".curtainTommaso", { height: "100%", duration: 0.5 });
      gsap.to(".OurTeamTimText", { y: 30, duration: 0.5 });
      gsap.to(".OurTeamTimPhoto", { height: 0, duration: 0.5 });
      gsap.to(".OurTeamTommasoText", { y: 30, duration: 0.5 });
      gsap.to(".curtainMartin", { height: "100%", duration: 0.5 });
      gsap.to(".OurTeamMartinText", { y: 30, duration: 0.5 });
    }
  }, [inView]);

  return (
    <OurTeamPartContainer>
      <DirectorsContainer ref={inViewRef}>
        <OurTeamPersonsContainer>
          {/* For easier */}
          <OutTeamMobileMottoContainer>{MottoContent}</OutTeamMobileMottoContainer>

          <AlexContainer>
            <OutTeamMottoContainer>{MottoContent}</OutTeamMottoContainer>

            <OurTeamAlexDirectorNameContainer>
              <OurTeamDirectorNames>
                <h4>Alex</h4>
                <h5>Operations/Courier controller</h5>
              </OurTeamDirectorNames>
            </OurTeamAlexDirectorNameContainer>

            <OurTeamDirectorTextContainer noImage>
              <DirectorText director={Directors.ALEX} />
            </OurTeamDirectorTextContainer>
          </AlexContainer>

          <TommasoContainer>
            <OurTeamDirectorNameImageContainer>
              <OurTeamDirectorNameImage src={Tommaso} />
              <OurTeamDirectorNameContainer>
                <OurTeamDirectorNames>
                  <h4>Tommaso</h4>
                  <h5>Account Director</h5>
                </OurTeamDirectorNames>
              </OurTeamDirectorNameContainer>
            </OurTeamDirectorNameImageContainer>
            <OurTeamDirectorTextContainer>
              <DirectorText director={Directors.TOMMASO} />
            </OurTeamDirectorTextContainer>
            <OurTeamPhotoCurtain className="curtainTommaso" />
          </TommasoContainer>

          <TimContainer>
            <OurTeamDirectorNameImageContainer>
              <OurTeamDirectorNameImage src={Tim} />
              <OurTeamDirectorNameContainer>
                <OurTeamDirectorNames>
                  <h4>Tim</h4>
                  <h5>Client Account Supervisor</h5>
                </OurTeamDirectorNames>
              </OurTeamDirectorNameContainer>
            </OurTeamDirectorNameImageContainer>
            <OurTeamDirectorTextContainer>
              <DirectorText director={Directors.TIM} />
            </OurTeamDirectorTextContainer>
            <OurTeamPhotoCurtain className="curtainTommaso" />
          </TimContainer>
        </OurTeamPersonsContainer>
      </DirectorsContainer>
    </OurTeamPartContainer>
  );
};

export default OurTeamThirdPart;
