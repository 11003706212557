import styled from "styled-components";
import MedicalDirectorImage from "./MaskedPhoto/visible-image.jpg";
import MedicalDirectorBlurImage from "./FaisalBlurWeb.jpg";
import MedicalDirectorImageTablet from "./FaisalPhotoMobile.png";
import MedicalDirectorBlurImageTablet from "./FaisalTabletBlur.jpg";
import MedicalDirectorSpot from "./MaskedPhoto/spot.svg";
import { ClientsStoryDescription, PlayButtonContainer } from "../ClientsStory/ClientsStory.components";
import { media } from "../../shared/styles/media";

export const MedicalDirectorSection = styled.div`
  width: 100%;
  height: 1400px;
  position: relative;
  z-index: 45;
  will-change: transform;
  overflow: hidden;
  @media all and (max-height: 950px) {
    height: 1100px;
  }
  @media all and (max-height: 800px) {
    height: 900px;
  }

  @media all and (max-height: 680px) {
    height: 800px;
  }
`;
export const MedicalDirectorContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 1400px;
  /* scroll-snap-align: start;
  scroll-snap-stop: always; */
  @media all and (max-height: 950px) {
    height: 1100px;
  }
  @media all and (max-height: 800px) {
    height: 900px;
  }
  @media all and (max-height: 680px) {
    height: 800px;
  }
`;
export const MedicalDirectorContent = styled.div`
  height: 100%;
  position: relative;
`;

export const ImageWithMask = styled.div<{ isPlayerVisible: boolean }>`
  width: 100%;
  height: 100%;
  background-image: url(${p => (p.isPlayerVisible ? MedicalDirectorBlurImage : MedicalDirectorImage)});
  background-size: cover;
  background-position: top;
  mask-image: url(${MedicalDirectorSpot});
  mask-size: 400px 400px;
  mask-repeat: no-repeat;

  ${p => media.down("NEW_TABLET")`
  background-image: url(${p.isPlayerVisible ? MedicalDirectorBlurImageTablet : MedicalDirectorImageTablet});
  background-position: top;
  mask-image: url(${MedicalDirectorSpot});
  mask-position: 30%;
  `}
`;

export const MedicalDirectorBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(${MedicalDirectorImage});
  background-size: cover;
  background-position: top;
  ${media.down("NEW_TABLET")`
  background-image: url(${MedicalDirectorImageTablet});
  `};
`;

export const BlurredMedicalDirectorBackground = styled(MedicalDirectorBackground)`
  position: absolute;
  background-image: url(${MedicalDirectorBlurImage});
  left: 0;
  width: 100%;
  ${media.down("NEW_TABLET")`
  background-image: url(${MedicalDirectorBlurImageTablet});
  `}
  z-index: 200;
`;

export const MedicalDirectorSpotShape = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const MedicalDirectorHeading = styled.h1`
  margin: 0;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 112%;
  text-align: right;
  letter-spacing: -0.02em;
  color: #f0f5f5;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 84px;
    margin-bottom: 24px;
  `}

  ${media.down("NEW_MAIN_WEB")`
  font-size: 64px;

  `}


  ${media.down("NEW_HALF_WEB")`
  font-size: 56px;

  `}

  ${media.down("NEW_LARGE_MOBILE")`
  font-size: 48px;
max-width: 290px;
  `}

  ${media.down("NEW_MOBILE")`
  width: 100%;
  font-size: 44px;
max-width: 268px;
margin-left: auto;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  font-size: 40px;
  max-width: 240px;

  `}
`;

export const TextContentContainer = styled.div`
  width: 501px;
  margin-left: auto;
  margin-top: 196px;

  ${PlayButtonContainer} {
    margin: 56px auto 0 auto;

    ${media.down("NEW_TABLET")`
    margin: 0;
    margin-top: 30px;
    margin-left: auto;
  `}

    ${media.down("NEW_LARGE_MOBILE")`
    margin: 0;
    margin-right: auto;
  `}
  }

  ${media.up("NEW_LARGE_WEB")`
  margin-top: 196px;
  width: 542px;
  `}

  ${media.down("NEW_MAIN_WEB")`
  width: 398px;

  `}

  ${media.down("NEW_SMALL_WEB")`
  width: 383px;


  `}

  ${media.down("NEW_HALF_WEB")`
  margin-top: 164px;
  width: 343px;

  `}

  ${media.down("NEW_TABLET")`

  `}

  ${media.down("NEW_LARGE_MOBILE")`
  margin-top: auto;
margin-bottom: 156px;
width: 100%;

  `}

  ${media.down("NEW_MOBILE")`
  `}

  ${media.down("NEW_SMALL_MOBILE")`
margin-left: -16px;
  `}
`;

export const TextSectionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  height: 100%;
  padding: 0 250px;

  /* width: 50%; */
  /* margin-left: auto; */
  /* margin-right: 9.375em; */
  /* margin-top: 168px; */

  ${media.up("NEW_LARGE_WEB")`
    padding: 0 350px;
  `}

  ${media.down("NEW_MAIN_WEB")`
    padding: 0 200px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  padding: 0 144px;

  `}

  ${media.down("NEW_HALF_WEB")`

  padding: 0 96px;

  `}

  ${media.down("NEW_TABLET")`
  padding: 0 80px;

  `}

  ${media.down("NEW_LARGE_MOBILE")`
  padding: 0 64px;

  `}

  ${media.down("NEW_MOBILE")`
  padding: 0 48px;

  `}

  ${media.down("NEW_SMALL_MOBILE")`
  padding: 0 24px;

  `}
`;

export const MedicalDirectorDescripton = styled(ClientsStoryDescription)`
  text-align: right;
  margin-left: auto;
  ${media.down("NEW_SMALL_MOBILE")`
  `}
`;
