import React, { FunctionComponent } from "react";
import {
  RadioButtonContent,
  InvisibleCheckbox,
  FakeInput,
  RadioButtonCard,
  SelectedFakeInput,
  WhiteDot,
} from "./RadioButton.components";

interface Props {
  name: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
}

const RadioButton: FunctionComponent<Props> = ({ name, value, checked, disabled, ...props }) => {
  return (
    <RadioButtonContent>
      <InvisibleCheckbox
        type="radio"
        value={value}
        disabled={disabled}
        name={name || "radio"}
        checked={checked}
        onChange={() => null}
      />
      <RadioButtonCard>
        <FakeInput>
          <SelectedFakeInput>
            <WhiteDot />
          </SelectedFakeInput>
        </FakeInput>
        {props.children}
      </RadioButtonCard>
    </RadioButtonContent>
  );
};
export default RadioButton;
