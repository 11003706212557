import styled from "styled-components";
import { media } from "../../../styles/media";

export const StyledTextarea = styled.textarea`
  font-family: "Barlow";
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
  color: #f0f5f5;
  padding: 13px 0px 10px 0px;
  background: none;
  border: none;
  border-bottom: 1.5px solid rgba(148, 168, 167, 0.32);
  resize: none;
  transition: border-bottom 0.4s ease;
  ${media.up("NEW_LARGE_WEB")`
      font-size: 26px;
    `}

  &::placeholder {
    font-weight: 300;
    font-size: 20px;
    line-height: 145%;
    color: #b6c3c3;
    ${media.up("NEW_LARGE_WEB")`
      font-size: 26px;
    `}
  }

  &:focus,
  &:active {
    outline: none;
    border-bottom: 1.5px solid #35aca8;
  }

  ${media.down("NEW_HALF_WEB")`
    font-size: 18px;
    width: 564px;

    &::placeholder {
      font-size: 18px;
    }
  `}

  ${media.down("NEW_TABLET")`
    width: 460px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 20px;
    width: 324px;

    &::placeholder {
      font-size: 20px;
    }
  `}

  ${media.down("NEW_MOBILE")`
    width: 264px;
  `}

  :hover {
    border-bottom: 1.5px solid #35aca8;
  }
`;
