import styled from "styled-components";
export const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
`;

export const Scroller = styled.div`
  height: 100vh;
`;

export const LightSectionsContainer = styled.div`
  position: relative;
  background-color: rgb(239, 233, 230);
`;
