import React, { FunctionComponent, useEffect, useState, Dispatch, SetStateAction } from "react";
import ReactPlayer from "react-player/lazy";
import {
  Background,
  BorderElement,
  CloseButtonOverlay,
  CloseButtonWrapper,
  PlaceholderContainer,
  PlaceholderImage,
  VideoContainer,
} from "./VideoPlayer.components";
import gsap from "gsap";
import CircleCloseButton from "./CircleCloseButton.svg";

export interface VideoPlayerProps {
  videoUrl: string;
  placeholderUrl?: string;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({ videoUrl, placeholderUrl, isOpened, setIsOpened }) => {
  const [isVideoReady, setIsVideoReady] = useState(false);
  useEffect(() => {
    if (isOpened) gsap.to(".playerWrapper", { scale: 1, duration: 0.5 });
    else gsap.to(".playerWrapper", { scale: 0, duration: 0.4 });
  }, [isOpened]);

  return (
    <Background className="playerBg">
      <VideoContainer className="playerWrapper">
        <BorderElement />
        <PlaceholderContainer>
          <PlaceholderImage src={placeholderUrl} loading="lazy" />
        </PlaceholderContainer>
        <ReactPlayer
          url={videoUrl}
          playing={isOpened}
          onReady={() => setIsVideoReady(true)}
          playsinline
          controls={true}
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2000,
          }}
        />
        {isVideoReady && (
          <CloseButtonWrapper id="closeButtonWrapper" className="closeBtn">
            <CloseButtonOverlay onClick={() => setIsOpened(false)} />
            <object type="image/svg+xml" data={CircleCloseButton}></object>
          </CloseButtonWrapper>
        )}
      </VideoContainer>
    </Background>
  );
};

export default VideoPlayer;
