import styled from "styled-components";
import { media } from "../../styles/media";

export const AnimatedTextContainer = styled.div`
  overflow: hidden;
`;

export const AnimatedTextContent = styled.p`
  margin: 5px auto;
  width: fit-content;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: #0e1b1b;
  span {
    display: inline-block;
    opacity: 0;
    min-width: 12px;
  }

  ${media.down("NEW_SMALL_WEB")`
    font-size: 56px;
   `}
  ${media.down("NEW_HALF_WEB")`
    font-size: 56px;
  `}
  ${media.down("NEW_TABLET")`
    font-size: 64px;
  `} 
  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 56px;
  `}
  ${media.down("NEW_MOBILE")`
    font-size: 52px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 44px;
  `}
`;
