import React, { FunctionComponent } from "react";
import { ServiceAndTimeSectionContainer, ServiceAndTimeSectionWrapper } from "../Services.components";
import ServicesSection from "../ServicesSection/ServicesSection";
import TimeSection from "../TimeSection/TimeSection";
import BouncedShapeWithTransparency from "../../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import SEOMarker from "../../../shared/components/SEO/SEOMarker";
import { MedicalRoutes } from "../../../../config/routes";

const ServicesDesktop: FunctionComponent = () => {
  //useEffect(() => {
  //const triggers: ScrollTrigger[] = [];
  //const initTl = getDesktopTimeline();

  // triggers.push(
  //   ScrollTrigger.create({
  //     trigger: ".servicesContainer",
  //     start: "center center",
  //     end: "center center",
  //     onEnter: () => {
  //       initTl.tweenTo("secondVideoShown");
  //     },
  //     onEnterBack: () => {
  //       initTl.tweenTo("firstVideoShown");
  //     },
  //   })
  // );

  //   triggers.push(
  //     ScrollTrigger.create({
  //       trigger: ".servicesContainer",
  //       start: "top+=50% center",
  //       end: "top+=50% center",
  //       onEnter: () => {
  //         initTl.tweenTo("thirdVideoShown");
  //       },
  //       onEnterBack: () => {
  //         initTl.tweenTo("secondVideoShown");
  //       },
  //     })
  //   );

  //   triggers.push(
  //     ScrollTrigger.create({
  //       trigger: ".servicesContainer",
  //       start: "top+=70% center",
  //       end: "top+=70% center",
  //       onEnter: () => {
  //         initTl.tweenTo("fourthVideoShown");
  //       },
  //       onEnterBack: () => {
  //         initTl.tweenTo("thirdVideoShown");
  //       },
  //     })
  //   );

  //initTl.tweenTo("firstVideoShown");

  //return () => triggers.forEach(trigger => trigger.kill());
  //}, []);

  return (
    <div style={{ position: "relative", backgroundColor: "#d8e3e3" }}>
      <SEOMarker routeInfo={MedicalRoutes.services} />
      <ServiceAndTimeSectionContainer className="servicesContainer">
        <ServiceAndTimeSectionWrapper>
          <ServicesSection />
          <TimeSection />
        </ServiceAndTimeSectionWrapper>
      </ServiceAndTimeSectionContainer>
      <BouncedShapeWithTransparency
        trigger="servicesContainer"
        topShapeBackground="transparent"
        bottomShapeBackground="#d8e3e3"
      />
    </div>
  );
};

export default ServicesDesktop;
