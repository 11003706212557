import React from "react";
import { isNewLargeMobileDown } from "../../../common/hooks/useMedia";
import TimeCriticalDeliveriesMobile from "./TimeCriticalDeliveriesMobile";
import TimeCriticalDeliveriesWeb from "./TimeCriticalDeliveriesWeb";

const TimeCriticalDeliveries = () => {
  const isLargeMobileDown = isNewLargeMobileDown();

  return isLargeMobileDown ? <TimeCriticalDeliveriesMobile /> : <TimeCriticalDeliveriesWeb />;
};

export default TimeCriticalDeliveries;
