interface Breakpoint {
  min?: number;
  max?: number;
}

type Breakpoints = {
  [key in
    | "MOBILE_TABLE"
    | "MOBILE_SMALL"
    | "MOBILE_SMALLEST"
    | "MOBILE"
    | "MOBILE_LARGE"
    | "TABLET_SMALL"
    | "TABLET"
    | "TABLET_LARGE"
    | "PACKAGE_SMALL_WEB"
    | "LAPTOP"
    | "MAIN_WEB"
    | "FULL"
    | "MEDICAL_SERVICES_LAYOUT_CHANGE"
    | "HERO_VIDEO_MOBILE_OFFSET"
    | "MOBILE_MLARGE"
    | "NEW_LARGE_WEB"
    | "NEW_MAIN_WEB"
    | "NEW_SMALL_WEB"
    | "NEW_HALF_WEB"
    | "NEW_TABLET"
    | "NEW_LARGE_MOBILE"
    | "NEW_MOBILE"
    | "NEW_SMALL_MOBILE"]: Breakpoint;
};

export const BREAKPOINTS: Breakpoints = {
  MOBILE_TABLE: {
    max: 420,
  },
  MOBILE_SMALLEST: {
    max: 365,
  },
  MOBILE_SMALL: {
    max: 375.98,
  },
  MOBILE: {
    min: 414,
    max: 475.98,
  },
  MOBILE_LARGE: {
    min: 476,
    max: 699.98,
  },
  MOBILE_MLARGE: {
    max: 599.98,
  },
  TABLET_SMALL: {
    min: 768,
    max: 899.98,
  },
  TABLET: {
    min: 768,
    max: 1024,
  },
  TABLET_LARGE: {
    min: 900,
    max: 1199.98,
  },
  PACKAGE_SMALL_WEB: {
    max: 1099.98,
  },
  LAPTOP: {
    min: 1200,
    max: 1439.98,
  },
  MAIN_WEB: {
    min: 1440,
    max: 1919.98,
  },
  FULL: {
    min: 1920,
  },
  MEDICAL_SERVICES_LAYOUT_CHANGE: {
    max: 650,
  },
  HERO_VIDEO_MOBILE_OFFSET: {
    max: 550,
  },
  NEW_LARGE_WEB: {
    min: 1600,
    max: 1919.98,
  },
  NEW_MAIN_WEB: {
    min: 1300,
    max: 1599.98,
  },
  NEW_SMALL_WEB: {
    min: 1024,
    max: 1299.98,
  },
  NEW_HALF_WEB: {
    min: 850,
    max: 1023.98,
  },
  NEW_TABLET: {
    min: 650,
    max: 849.98,
  },
  NEW_LARGE_MOBILE: {
    min: 430,
    max: 649.98,
  },
  NEW_MOBILE: {
    min: 380,
    max: 429.98,
  },
  NEW_SMALL_MOBILE: {
    max: 379.98,
  },
} as const;
