import styled from "styled-components";
import { media } from "../../../shared/styles/media";

export const GoGreenMobileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 80px 0 160px 0;
  width: 100%;
  box-sizing: border-box;
  ${media.down("MOBILE_LARGE")`
      padding: 0px 48px 160px;
  `}
  ${media.down("MOBILE")`
      padding: 0px 48px 160px;
  `}
  ${media.down("MOBILE_SMALL")`
      padding: 0 40px 120px;
  `}
`;

export const GoGreenMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GoGreenMobileHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 96%;
  letter-spacing: -0.02em;
  color: #ffffff;
  max-width: 208px;
`;

export const GoGreenMobileDescription = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #b6c3c3;
  margin-left: 4px;
  margin-top: 8px;
`;

export const GoGreenMobileImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 420px;
  height: auto;
  margin-top: 60px;

  ${media.down("MOBILE_TABLE")`
      margin-top: 20px;
    `}
`;

export const GoGreenMobileCircleWrapper = styled.div`
  > svg {
    z-index: 1000;
  }

  ${media.down("MOBILE_LARGE")`
    > svg {
      width: 56px;
      height: 56px;
    }
  `}
`;

export const GoGreenTopArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 27px;
  bottom: 44px;

  > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    white-space: nowrap;
    color: #b6c3c3;
    margin-bottom: 10px;
    ${media.down("MOBILE_TABLE")`
      font-size: 18px;
    `}
  }
`;

export const GoGreenTopDescriptionWrapper = styled.div`
  position: absolute;
  top: 90px;
  left: 170px;
  ${media.down("MOBILE_TABLE")`
      top: 50%;
      left: 45%;
    `}
`;

export const GoGreenBottomArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30px;
  top: 20px;

  > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;

    text-align: left;
    text-transform: uppercase;
    color: #b6c3c3;
    margin-top: 16px;
    max-width: 30px;
  }
  ${media.down("MOBILE_TABLE")`
  top: 10px;
  > span {
      font-size: 18px;
      margin-top: 10px;
  }
    `}
`;

export const GoGreenBottomDescriptionWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 120px;

  ${GoGreenMobileCircleWrapper} {
    transform: translateY(-30px);
  }
`;
