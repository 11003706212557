import { useEffect } from "react";
import AppEventTarget from "../events/AppEventTarget";
import { EventDataTypes } from "../events/EventDataTypes";

const useEventHandler = <K extends keyof EventDataTypes>(
  eventType: K,
  eventHandler: (event: CustomEvent<EventDataTypes[K]>) => void
) => {
  useEffect(() => {
    AppEventTarget.addEventListener(eventType, eventHandler as EventListener);
    return () => {
      AppEventTarget.removeEventListener(eventType, eventHandler as EventListener);
    };
  }, [eventType, eventHandler]);
};

export default useEventHandler;
