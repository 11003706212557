import React from "react";
import {
  OurStoryLeftSection,
  OurStoryDescription,
  OurStoryRightSection,
  PhotosContainer,
  OurStoryFullDescription,
  OurStoryTitle,
} from "./OurStory.components";
import AlexAndTomaszPhoto from "./assets/alexAndTomasz.png";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { BREAKPOINTS } from "../../shared/styles/const";

const getDescription = (text: string) => {
  return text.split("").map((char, index) => (
    <span key={index} className="ourStoryDescription">
      {char}
    </span>
  ));
};
const OurStoryPage1 = () => {
  const [width] = useWindowSize();

  const isTablet = () => BREAKPOINTS.TABLET_SMALL.max && width < BREAKPOINTS.TABLET_SMALL.max;
  return (
    <>
      <OurStoryLeftSection>
        <div style={{ width: "100%", position: "relative" }}>
          {!isTablet() && (
            <OurStoryTitle className="ourStoryTitle">
              <svg viewBox="0 0 500 50">
                <text className="svg-title" y="50">
                  OUR STORY
                </text>
              </svg>
            </OurStoryTitle>
          )}
          <OurStoryDescription>
            {getDescription("In 2016, after ")}
            <u>{getDescription("10+ ")}</u>
            {getDescription("years of working in ")}
            <b>{getDescription("the medical ")}</b>
            {getDescription("courier industry...")}
          </OurStoryDescription>
        </div>
      </OurStoryLeftSection>
      <OurStoryRightSection>
        {isTablet() && (
          <OurStoryTitle className="ourStoryTitle">
            <svg width="100%" height="100%" viewBox="0 0 300 24">
              <text className="svg-title" textLength="290" x="5" y="14">
                OUR STORY
              </text>
            </svg>
          </OurStoryTitle>
        )}
        <PhotosContainer className="alexAndTomaszPhoto">
          <img loading="lazy" src={AlexAndTomaszPhoto} alt="Alex and Tomasz" />
        </PhotosContainer>
        <OurStoryFullDescription className="ourStoryFullDescription">
          In 2016, after 10+ years of working in the medical courier industry (including one of the biggest courier
          companies in the UK), Alex & Tomasz decided it was time to start their own medical courier company. Along the
          way, their now co-partner Tommo joined them in the adventure.
        </OurStoryFullDescription>
      </OurStoryRightSection>
    </>
  );
};

export default OurStoryPage1;
