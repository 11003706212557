import React, { useEffect, useState, FunctionComponent } from "react";
import ScrollTrigger from "../../../common/ScrollTrigger";
import { Navbar } from "@purpledeerstudio/logistics-components";
import { Lines } from "@purpledeerstudio/logistics-components";
import { FooterUrls } from "../../../config/urls";

const NavbarMedical: FunctionComponent = () => {
  const [isDarkMenu, setIsDarkMenu] = useState(false);

  const setTriggerForSection = (
    triggerSection: string,
    onEnterFunction: () => void,
    onLeaveFunction?: () => void,
    startTrigger?: string,
    endTrigger?: string
  ) => {
    const trigger = ScrollTrigger.getById(`${triggerSection}Trigger`);
    if (trigger) trigger.kill(true);
    ScrollTrigger.create({
      trigger: `.${triggerSection}`,
      start: startTrigger || "top-=100 top",
      end: endTrigger || "bottom-=100 top",
      id: `${triggerSection}Trigger`,
      onEnter: () => {
        onEnterFunction();
      },
      onEnterBack: () => {
        onEnterFunction();
      },
      onLeave: () => {
        onLeaveFunction && onLeaveFunction();
      },
      onLeaveBack: () => {
        onLeaveFunction && onLeaveFunction();
      },
    });
  };

  useEffect(() => {
    if (ScrollTrigger.length > 0) {
      ScrollTrigger.refresh();
    }
    setTimeout(() => {
      setTriggerForSection("introduction", () => setIsDarkMenu(false));
      if (document.getElementsByClassName("servicesContainer")[0]) {
        setTriggerForSection("servicesContainer", () => setIsDarkMenu(true));
      }
      if (document.getElementsByClassName("servicesMobileContainer")[0]) {
        setTriggerForSection("servicesMobileContainer", () => setIsDarkMenu(true));
      }
      if (document.getElementsByClassName("darkSectionsMedical")[0])
        setTriggerForSection(
          "darkSectionsMedical",
          () => {
            setIsDarkMenu(false);
          },
          () => setIsDarkMenu(true)
        );
      if (document.getElementsByClassName("in-numbers")[0]) {
        setTriggerForSection(
          "in-numbers",
          () => {
            setIsDarkMenu(true);
          },
          undefined,
          "bottom-=160 top"
        );
      }

      if (document.getElementsByClassName("getInTouch")[0])
        setTriggerForSection("getInTouch", () => setIsDarkMenu(false), undefined, "top-=150 top");
      if (document.getElementsByClassName("footer")[0])
        setTriggerForSection("footer", () => setIsDarkMenu(false), undefined, "top-=150 top");
    }, 1000);
  }, []);

  return (
    <>
      <div style={{ zIndex: 99999999999, position: "fixed" }}>
        <Navbar variant={!isDarkMenu ? "dark" : "mint"} selection="services" urls={FooterUrls} />
        {/* 
            Add lines here to avoid duplicating big z-index.
            This could be anywhere just remember to add z-index.
          */}
        <Lines variant="light" />
      </div>
    </>
  );
};
export default NavbarMedical;
