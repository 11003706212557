import React, { useEffect, useState, FunctionComponent } from "react";
import ScrollTrigger from "../../../../common/ScrollTrigger";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import { Navbar, Lines } from "@purpledeerstudio/logistics-components";
import { FooterUrls } from "../../../../config/urls";

const CouriersNavbar: FunctionComponent = () => {
  const [isDarkMenu, setIsDarkMenu] = useState(true);
  const [width] = useWindowSize();

  const setTriggerForSection = (
    triggerSection: string,
    onEnterFunction: () => void,
    onLeaveFunction?: () => void,
    startTrigger?: string,
    endTrigger?: string
  ) => {
    const trigger = ScrollTrigger.getById(`${triggerSection}Trigger`);
    if (trigger) trigger.kill(true);
    ScrollTrigger.create({
      trigger: `.${triggerSection}`,
      start: startTrigger || "top-=100 top",
      end: endTrigger || "bottom-=100 top",
      id: `${triggerSection}Trigger`,
      onEnter: () => {
        onEnterFunction();
      },
      onEnterBack: () => {
        onEnterFunction();
      },
      onLeave: () => {
        onLeaveFunction && onLeaveFunction();
      },
      onLeaveBack: () => {
        onLeaveFunction && onLeaveFunction();
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setTriggerForSection("heroVideo", () => {
        setIsDarkMenu(true);
      });
      if (document.getElementsByClassName("lightSections")[0]) {
        setTriggerForSection(
          "lightSections",
          () => {
            setIsDarkMenu(true);
          },
          undefined,
          "bottom-=200 top"
        );
      }

      if (document.getElementsByClassName("darkSections")[0])
        setTriggerForSection(
          "darkSections",
          () => {
            setIsDarkMenu(false);
          },
          undefined,
          "top-=100 top"
        );
      if (document.getElementsByClassName("bottomLightSections")[0]) {
        setTriggerForSection("bottomLightSections", () => {
          setIsDarkMenu(true);
        });
      }
    }, 1000);
  }, [width]);

  return (
    <div style={{ zIndex: 99999999999, position: "fixed" }}>
      <Navbar variant={!isDarkMenu ? "dark" : "light"} selection="couriers" urls={FooterUrls} />
      {/* 
            Add lines here to avoid duplicating big z-index.
            This could be anywhere just remember to add z-index.
          */}
      <Lines variant="light" />
    </div>
  );
};
export default CouriersNavbar;
