import React from "react";
import { CouriersRoutes } from "../../../config/routes";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import {
  DeliveriesSectionContainer,
  DeliveriesSectionInnerContainer,
  DeliveriesContainer,
  DeliveriesDescription,
  DeliveriesSubTitle,
  DeliveriesTitle,
  DeliveriesWrapper,
  PackageVideo,
  PackageShadowImg,
  TCDSeparatorLine,
} from "./TimeCriticalDeliveries.components";
import Button from "../../shared/components/Button";
//@ts-ignore
import TCDVideoMov from "./videos/tcd.mov";
//@ts-ignore
import TCDVideoWebm from "./videos/tcd.webm";

import PackageShadow from "./DeliveryOnlyShadow.png";
const TimeCriticalDeliveriesWeb = () => {
  return (
    <DeliveriesSectionContainer>
      <TCDSeparatorLine></TCDSeparatorLine>
      <DeliveriesSectionInnerContainer>
        <DeliveriesContainer className="deliveriesContainer">
          <DeliveriesWrapper>
            <SEOMarker routeInfo={CouriersRoutes.timeCriticalDeliveries} />
            <DeliveriesTitle className="deliveriesTitle">
              Time-<span>Critical</span> Deliveries
            </DeliveriesTitle>
            <DeliveriesSubTitle>
              Need help <span>transporting</span> blood samples, tissues or swabs?
            </DeliveriesSubTitle>
            <DeliveriesDescription>
              <span>We are here for you. </span>Thanks to our skilled team of exclusively medical couriers, all of your
              packages will be safely delivered, on time and perfectly intact.
            </DeliveriesDescription>
            <Button onClick={() => window.open("https://booking.medicallogistics.co.uk/", "_self")} size="extraLarge">
              Check availability
            </Button>
          </DeliveriesWrapper>
          <PackageVideo style={{ zIndex: 10 }}>
            <video style={{ height: "100%", width: "100%" }} muted playsInline autoPlay loop>
              <source src={TCDVideoMov} type="video/quicktime" />
              <source src={TCDVideoWebm} type="video/webm" />
            </video>
            <PackageShadowImg src={PackageShadow} />
          </PackageVideo>
        </DeliveriesContainer>
      </DeliveriesSectionInnerContainer>
    </DeliveriesSectionContainer>
  );
};

export default TimeCriticalDeliveriesWeb;
