import React, { useRef, useState } from "react";
import { useIsMobileDown } from "../../../../common/hooks/useMedia";
import { TitleSectionImage, TitleSectionVideo } from "./BackgroundVideo.components";

import VideoMobileThumb from "./video_mobile_thumb.png";
import VideoWebThumb from "./video_web_thumb.png";

// @ts-ignore
import TitleVideoWeb from "./video_desktop.mp4";
// @ts-ignore
import TitleVideoMobile from "./video_mobile.mp4";

const BackgroundVideo = () => {
  const isMobileDown = useIsMobileDown();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoError, setVideoError] = useState(false);

  return videoError ? (
    <TitleSectionImage src={isMobileDown ? VideoMobileThumb : VideoWebThumb} />
  ) : (
    <TitleSectionVideo
      ref={videoRef}
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline={true}
      onError={() => setVideoError(true)}
      key={isMobileDown ? "vertical" : "horizontal"}
    >
      <source src={isMobileDown ? TitleVideoMobile : TitleVideoWeb} type="video/mp4" />
    </TitleSectionVideo>
  );
};

export default BackgroundVideo;
