import React, { FunctionComponent, useEffect, useRef } from "react";
import { VideoContainer, VideoPlayer } from "../Services.components";
import ServiceBox from "./ServiceBox/ServiceBox";
import { desktopVideos } from "../videos.config";
import {
  ServiceBoxesWrapper,
  ServicesSectionContainer,
  ServicesHeading,
  ServicesDescription,
  ServicesSectionOuterContainer,
  ServicesVideoWrapper,
} from "./ServicesSection.components";
import { useInView } from "react-intersection-observer";

export enum ServicesImageType {
  BLOOD_TEST,
  COVID_TEST,
  OTHER,
}

export const services = [
  {
    heading: "Blood Test",
    description: "Panels, Allergies and more",
    imageType: ServicesImageType.BLOOD_TEST,
  },
  {
    heading: "Covid Test",
    description: "PCR, Antibody and more",
    imageType: ServicesImageType.COVID_TEST,
  },
  {
    heading: "Other",
    description: "IV Drips, Vaccines and more",
    imageType: ServicesImageType.OTHER,
  },
];

const ServicesSection: FunctionComponent = () => {
  const [inViewRef, inView] = useInView();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        if (videoRef.current) videoRef.current.play();
      }, 800);
    } else {
      setTimeout(() => {
        if (videoRef.current) videoRef.current.currentTime = 0;
      }, 200);
    }
  }, [inView]);

  return (
    <ServicesSectionOuterContainer>
      <ServicesSectionContainer ref={inViewRef}>
        <ServicesHeading className="serviceHeading">
          A Wide Variety <br />
          Of Medical Services
        </ServicesHeading>
        <ServicesDescription className="serviceDescription">
          Whatever medical test you’d like to get done, we’d love to hear about it - come talk to us. Here are the most
          popular ones:
        </ServicesDescription>
        <ServiceBoxesWrapper>
          {services.map((service, index) => (
            <ServiceBox
              key={index}
              heading={service.heading}
              description={service.description}
              imageType={service.imageType}
              borderAnimationDelay={index}
            />
          ))}
        </ServiceBoxesWrapper>
      </ServicesSectionContainer>
      <VideoContainer>
        <ServicesVideoWrapper>
          <VideoPlayer ref={videoRef} key={`videoPlayer1`} className={`videoPlayer1`} muted>
            <source src={desktopVideos[0].mov} type="video/quicktime" />
            <source src={desktopVideos[0].webm} type="video/webm" />
          </VideoPlayer>
        </ServicesVideoWrapper>
      </VideoContainer>
    </ServicesSectionOuterContainer>
  );
};

export default ServicesSection;
