import React from "react";
import CookiesBox from "../../shared/components/CookiesBox/CookiesBox";
import { Wrapper } from "./CouriersLandingLayout.components";
import TimeCriticalDeliveries from "../TimeCriticalDeliveries/TimeCriticalDeliveries";
import HeroVideo from "../HeroVideo/HeroVideo";
import CouriersClientOpinions from "../../CompanyLanding/CouriersClientOpinions/CouriersClientOpinions";
import InNumbers from "../../CompanyLanding/InNumbers/InNumbers";
import { ClientsStory } from "../../MedicalLanding/ClientsStory/ClientsStory";
import WhyChooseUs from "../../MedicalLanding/WhyChooseUs/WhyChooseUs";
import InGoodHands from "../InGoodHands/InGoodHands";
import CouriersNavbar from "./CouriersNavbar/CouriersNavbar";
import Footer from "../../MedicalLanding/Footer/Footer";
import { useInView } from "react-intersection-observer";
import Transport from "../Transport/Transport";
import WeAreExperts from "../WeAreExperts/WeAreExperts";
import HighestStandards from "../HighestStandards/HighestStandards";
import NoMistakes from "../NoMistakes/NoMistakes";

const CouriersLandingLayout = () => {
  const [inViewBottomLightSectionsRef, inViewBottomLightSections] = useInView();

  return (
    <Wrapper id="layoutcourier">
      <CouriersNavbar />
      <CookiesBox couriersLanding={true} />
      <HeroVideo />

      <div className="lightSections">
        <CouriersClientOpinions />
        <Transport />
        <TimeCriticalDeliveries />
        <HighestStandards />
        <NoMistakes />
        <WeAreExperts />
      </div>
      <div className="darkSections">
        <div style={{ position: "relative", overflow: "unset", zIndex: 99, background: "#181A1B" }}>
          <WhyChooseUs courierLanding={true} />
        </div>

        <ClientsStory courierLanding={true} />
      </div>
      <div style={{ zIndex: 9999, background: "#EFE9E6" }}>
        <div className="bottomLightSections" ref={inViewBottomLightSectionsRef}>
          <InNumbers lightSectionInView={inViewBottomLightSections} courierLanding={true} />
          <InGoodHands inView={inViewBottomLightSections} />
          <Footer isLightFooter />
        </div>
      </div>
    </Wrapper>
  );
};

export default CouriersLandingLayout;
