import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { BREAKPOINTS } from "../../shared/styles/const";
import {
  changeToPage1From2,
  changeToPage1Mobile,
  changeToPage1MobileFrom2,
  changeToPage2,
  changeToPage2From1Mobile,
  changeToPage2From3,
  changeToPage3,
  ourStoryEnterAnimation,
} from "./OurStory.animations";
import {
  OurStorySection,
  OurStoryContent,
  OurStoryFooter,
  OurStoryFooterArrow,
  OurStoryFooterLine,
  OurStoryFooterNumber,
  OurStoryNexPageContainer,
  OurStoryPageContainer,
  ClickToReadContainer,
} from "./OurStory.components";
import OurStoryPage1 from "./OurStoryPage1";
import OurStoryPage2and3 from "./OurStoryPage2and3";
import { ReactComponent as BadgeIcon } from "./assets/ClickToRead.svg";
import OurStoryMobile from "./OurStoryMobile/OurStoryMobile";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CompanyRoutes } from "../../../config/routes";

const isTabletOrMobileView = () => {
  return BREAKPOINTS.TABLET_SMALL.max && window.innerWidth <= BREAKPOINTS.TABLET_SMALL.max;
};

const OurStory = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesNumber, setPagesNumber] = useState<number>(isTabletOrMobileView() ? 4 : 3);
  const prevState = useRef<number>(1);
  const [width] = useWindowSize();
  const [blockAnimation, setBlockAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => ourStoryEnterAnimation(), 500);
  }, []);

  useEffect(() => {
    if (pagesNumber === 3 && isTabletOrMobileView()) {
      setPagesNumber(4);
      setCurrentPage(1);
      prevState.current = 1;
    } else if (pagesNumber === 4 && !isTabletOrMobileView()) {
      setPagesNumber(3);
      setCurrentPage(1);
      prevState.current = 1;
    }
  }, [width]);

  useEffect(() => {
    switch (currentPage) {
      case 2:
        if (isTabletOrMobileView()) {
          prevState.current === 1 ? changeToPage1Mobile() : changeToPage1MobileFrom2();
        } else {
          if (prevState.current === 1) {
            setBlockAnimation(true);
            changeToPage2();

            setTimeout(() => setBlockAnimation(false), 1000);
          } else changeToPage2From3();
        }
        break;
      case 3:
        if (isTabletOrMobileView()) {
          prevState.current === 2 ? changeToPage2From1Mobile() : changeToPage2From3();
        } else {
          if (blockAnimation) {
            setTimeout(() => {
              changeToPage3();
            }, 1000);
          } else {
            changeToPage3();
          }
        }
        break;
      case 4:
        changeToPage3();
        break;
      case 1:
        changeToPage1From2();
        break;
      default:
        break;
    }
    prevState.current = currentPage;
  }, [currentPage]);

  const changePage = (direction: number) => {
    if (direction > 0 && currentPage <= pagesNumber - 1) {
      setCurrentPage(currentPage + 1);
    } else if (direction < 0 && currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <OurStorySection id="ourStory" className="ourStoryContainer">
      <SEOMarker routeInfo={CompanyRoutes.ourStory} offset={-100} />
      <OurStoryContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <OurStoryPageContainer className="ourStoryFirst">
            <OurStoryPage1 />
          </OurStoryPageContainer>

          <OurStoryNexPageContainer className="ourStoryNext">
            <OurStoryPage2and3 currentPage={currentPage} pagesNumber={pagesNumber} />
          </OurStoryNexPageContainer>
        </div>
      </OurStoryContent>
      {width < 900 ? (
        <OurStoryMobile />
      ) : (
        <OurStoryFooter className="ourStoryFooter">
          {currentPage > 1 ? (
            <OurStoryFooterArrow leftArrow={true} onClick={() => changePage(-1)} title="Previous section" />
          ) : (
            isTabletOrMobileView() && (
              <ClickToReadContainer
                as="button"
                title="Click to read"
                onClick={() => changePage(1)}
                style={{
                  display: currentPage === 1 ? "block" : "none",
                  position: "static",
                }}
                className="ourStoryBadge"
              >
                <BadgeIcon />
              </ClickToReadContainer>
            )
          )}
          <OurStoryFooterLine />
          <OurStoryFooterNumber className="ourStoryFooterNumber">
            {currentPage}/{pagesNumber}
          </OurStoryFooterNumber>
          {currentPage < pagesNumber && (
            <OurStoryFooterArrow className="ourStoryFooterNumber" onClick={() => changePage(1)} title="Next section" />
          )}
          {!isTabletOrMobileView() && (
            <ClickToReadContainer
              as="button"
              title="Click to read"
              isButton={true}
              onClick={() => changePage(1)}
              style={{
                display: currentPage === pagesNumber ? "none" : "block",
              }}
              className="ourStoryBadge"
            >
              <BadgeIcon />
            </ClickToReadContainer>
          )}
        </OurStoryFooter>
      )}
    </OurStorySection>
  );
};

export default React.memo(OurStory);
