import styled from "styled-components";
import { media } from "../../shared/styles/media";
import { OurTeamText } from "./OurTeam.components";

export const OutTeamMottoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 264px;
  margin-bottom: 40px;

  ${media.down("MOBILE_LARGE")`
    display: none;
  `}
`;

export const OutTeamMobileMottoContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  max-width: 264px;

  ${media.down("MOBILE_LARGE")`
    display: flex;
  `}
`;

export const OurTeamPersonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 56px;
  justify-content: center;

  ${media.down("TABLET_SMALL")`
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0px;
  `}

  ${media.down("MOBILE_LARGE")`
    flex-direction: column;
  `}
`;

export const OurTeamDirectorNameImageContainer = styled.div`
  position: relative;
`;

export const OurTeamDirectorNameImage = styled.img`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0.9;
  background: #181a1b;
`;

export const OurTeamAlexDirectorNameContainer = styled.div`
  margin-top: 87px;

  ${media.down("LAPTOP")`
    margin-top: 56px;
  `}

  ${media.down("TABLET_SMALL")`
    margin-top: 0px;
  `}
`;

export const OurTeamDirectorNameContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 24px;

  ${media.down("TABLET_SMALL")`
    bottom: 14px;
    left: 12px;
  `}
`;

export const OurTeamDirectorNames = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h4,
  h5 {
    margin: 0px;
    text-align: left;
  }

  h4 {
    font-family: "Barlow Semi Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: #f0f5f5;

    ${media.down("TABLET_SMALL")`
      font-size: 20px;
    `}
  }

  h5 {
    font-family: "Barlow";
    font-family: "Barlow";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #f0f5f5;

    ${media.down("TABLET_SMALL")`
      font-size: 12px;
    `}
  }
`;

export const DirectorsContainer = styled.div`
  display: flex;
  margin-top: -280px;
  width: 100%;

  ${media.down("TABLET")`
    margin-top: -180px;
  `}

  ${media.down("TABLET_SMALL")`
    margin-top: 0px;
  `}

  ${media.down("MOBILE_LARGE")` 
    padding-bottom: 150px;
    margin-top: -50px;
  `};

  ${media.down("MOBILE")`
    margin-top: 0;
    display: block;
    width: 100%;
    padding-bottom: 100px;
  `}
`;

export const AlexContainer = styled.div`
  max-width: 288px;
  width: 100%;
  flex: 1;
  position: relative;

  ${media.down("TABLET_SMALL")`
    width: 45%;  
    flex: unset;
  `}

  ${media.down("MOBILE_LARGE")`
    width: 100%;
    order: 2;
    margin: 32px 0px 32px 12px;
  `}
`;

export const TommasoContainer = styled.div`
  max-width: 288px;
  position: relative;
  width: 100%;
  flex: 1;
  margin-top: 16px;

  ${media.down("TABLET_SMALL")`
    width: 45%;
    flex: unset;
    margin-left: 32px;
    margin-top: 72px;
  `}

  ${media.down("MOBILE_LARGE")`
    width: 100%;
    order: 3;
    margin: 0px;
  `}

  img {
    width: 100%;
  }
`;

export const TimContainer = styled.div`
  max-width: 288px;
  position: relative;
  flex: 1;
  width: 100%;
  margin-top: 128px;

  ${media.down("TABLET_SMALL")`
    width: 45%;  
    flex: unset;
    margin-top: -128px;
  `}

  ${media.down("MOBILE_LARGE")`
    width: 100%;
    margin-top: 32px;
    order: 1;
  `}

  img {
    width: 100%;
  }
`;

export const DirectorPhotoContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  z-index: 99;
  flex-direction: column;
  max-width: 288px;

  img {
    object-fit: cover;
    object-position: center bottom;
    width: 100%;
    max-width: 288px;
    margin-bottom: 0;
  }
  ${media.down("LAPTOP")`
  `}

  ${media.down("TABLET_SMALL")`

  `}

  ${media.down("MOBILE")`

  `}
 
  p {
  }
`;

export const OurTeamDirectorTextContainer = styled.div<{ noImage?: boolean }>`
  margin-left: ${p => (p.noImage ? 0 : 24)}px;
  margin-top: 10px;

  ${p => media.down("TABLET_SMALL")`
    margin-left: ${p.noImage ? 0 : 12}px;
    margin-top: 8px;
`}

  ${media.down("MOBILE_LARGE")`
      margin-top: 4px;
  `}
`;

export const OurTeamDirectorsText = styled(OurTeamText)`
  background-color: transparent;
  ${media.down("LAPTOP")`
    font-size: 14px;
  `}
  ${media.down("TABLET")`
    font-size: 12px;
  `};
  ${media.down("MOBILE_LARGE")`
    font-size: 14px;
  `}
`;
