import styled from "styled-components";

export const PageLoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #181a1b;
  position: fixed;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  object {
    width: 170px;
    height: 270px;
  }
`;
