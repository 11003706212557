import React, { useEffect } from "react";
import {
  AlphaBottomCell,
  AlphaTopLeftCell,
  AlphaTopRightCell,
  BottomLeftCell,
  BottomRightCell,
  LabSceneContainer,
  LabTestimonial,
  TopRightCell,
} from "./LaboratoriesScene.components";
import LogoCarousel from "../Laboratories/Carousel/Carousel";
import { LaboratoriesCarouselWrapper } from "../Laboratories/Laboratories.components";
import TopRight from "./topright.png";
import BotomRight from "./bottomright.png";
import BottomLeft from "./bottomleft.png";
import AlphaTopLeft from "./alphatopleft.png";
import AlphaTopRight from "./alphatopright.png";
import AlphaBottom from "./alphabottom.png";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";

const LaboratoriesScene = () => {
  const [inViewRef, inView] = useInView();

  useEffect(() => {
    gsap.to(".c1", {
      yPercent: 200,
      scrollTrigger: {
        trigger: ".aaa",
        start: "top center",
        // end: "top center",
        scrub: true,
      },
    });

    gsap.to(".c3", {
      yPercent: 150,
      scrollTrigger: {
        trigger: ".aaa",
        start: "top center",
        // end: "top center",
        scrub: true,
      },
    });
  }, []);

  return (
    <LabSceneContainer className="aaa" ref={inViewRef}>
      <TopRightCell className="c1" src={TopRight} />
      <BottomRightCell className="c2" src={BotomRight} />
      <BottomLeftCell className="c3" src={BottomLeft} />
      <AlphaTopRightCell src={AlphaTopRight} />
      <AlphaTopLeftCell src={AlphaTopLeft} />
      <AlphaBottomCell src={AlphaBottom} />
      <LabTestimonial>
        We work with the most <span>respected </span>
        <span>laboratories</span> in London
      </LabTestimonial>
      <LaboratoriesCarouselWrapper>
        <LogoCarousel play={inView} />
      </LaboratoriesCarouselWrapper>
    </LabSceneContainer>
  );
};

export default React.memo(LaboratoriesScene);
