import styled from "styled-components";
import { media } from "../../../shared/styles/media";
import {
  ServicesBaseContentWrapper,
  ServicesBaseHeader,
  ServicesBaseDescription,
  VideoWrapper,
} from "../Services.components";

export const ServicesSectionOuterContainer = styled.div`
  display: flex;
`;

export const ServicesSectionContainer = styled(ServicesBaseContentWrapper)`
  justify-content: center;
  width: 100%;
  display: flex;
`;

export const ServicesHeading = styled(ServicesBaseHeader)`
  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
   white-space: nowrap;
  `}
`;

export const ServicesDescription = styled(ServicesBaseDescription)`
  max-width: 360px;

  ${media.up("NEW_LARGE_WEB")`
    max-width: 470px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    max-width: 400px;
  `}
  ${media.down("NEW_HALF_WEB")`
    max-width: 320px;
  `}

  ${media.down("NEW_TABLET")`
   max-width: 270px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
   max-width: 400px;
  `}
`;

export const ServicesVideoWrapper = styled(VideoWrapper)`
  position: static;
  ${media.down("TABLET")`
    position: sticky;
  `}
`;

export const ServiceBoxesWrapper = styled.div`
  display: flex;
  margin-top: 44px;

  > :nth-child(2) {
    margin: 32px 24px 0px 24px;
  }

  ${media.down("NEW_SMALL_WEB")`
    margin-top: 54px;
    > :nth-child(2) {
      margin: 32px 16px 0px 16px;
    }
  `}

  ${media.down("NEW_HALF_WEB")`
    flex-direction: column;
    margin-top: 32px;
    > :nth-child(2) {
      margin: 12px 0px;
    }
  `}
`;
