import React, { useEffect } from "react";
import AutoDrawingSVGOnScroll from "../../shared/components/AutoDrawingSVGOnScroll/AutoDrawingSVGOnScroll";

import { ReactComponent as CertifiedIcon } from "./icons/TrainedIcon.svg";
import { ReactComponent as MedicalIcon } from "./icons/MedicalIcon.svg";
import { ReactComponent as SecureIcon } from "./icons/SecureIcon.svg";
import { ReactComponent as TransportIcon } from "./icons/TransportIcon.svg";
import {
  HighestStandardsDrawingLine,
  HighestStandardSection,
  HighestStandardTitle,
  HighestStandardTitleRed,
  HighestStandardTitleWrapper,
  StandardContentWrapper,
  StandardContentDescription,
  StandardContentHeader,
  IconWrapper,
  StandardWrapper,
  MedicalStandardsWrapper,
  MedicalStandardsInnerWrapper,
  TheMarketLine,
  LineOnIconWrapper,
  HighestStandardVideoWrapper,
  HighestStandardVideoOuterWrapper,
  MedicalStandardsBackground,
  HighestStandardVideoGradient,
} from "./HighestStandards.components";
import AutoDrawingSVG from "../../shared/components/AutoDrawingSVG/AutoDrawingSVG";
import { useInView } from "react-intersection-observer";
// @ts-ignore
import StandardsVideo from "./videos/scooterVideo.mp4";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CouriersRoutes } from "../../../config/routes";
import useIsSamsungInternet from "../../../common/hooks/useIsSamsungInternet";

const HighestStandards = () => {
  const [inViewRef, inView] = useInView();
  const isSamsungInternet = useIsSamsungInternet();

  useEffect(() => {
    if (inView) {
      //gsap.to(".highestStandardsIcon", {y:});
    }
  }, [inView]);
  return (
    <HighestStandardSection hideBackground={isSamsungInternet}>
      <div style={{ maxWidth: 1200, width: "100%", height: "100%", margin: "auto" }}>
        <MedicalStandardsBackground />
        <HighestStandardTitleWrapper className="highestStandardsTitle">
          <HighestStandardTitle>Highest</HighestStandardTitle>
          <HighestStandardTitleRed>Medical Delivery</HighestStandardTitleRed>
          <SEOMarker routeInfo={CouriersRoutes.deliveryStandards} />

          <HighestStandardTitle>
            Standards On<TheMarketLine> The Market</TheMarketLine>
          </HighestStandardTitle>
          <HighestStandardsDrawingLine>
            <AutoDrawingSVGOnScroll
              trigger=".highestStandardsTitle"
              pathOptions={{
                d: "M438.293,1C478.53,1 515.99,26.8097 486.341,46.7838C468.233,58.9828 444.058,63.1333 423.044,65.7137C323.056,77.9915 217.521,72.6168 117.106,72.6168C73.5389,73.7748 16.0549,69.0392 3.64787,103.309C-4.25701,121.253 6.0738,140.043 27.4893,148",
                stroke: "#0E1B1B",
                strokeWidth: "1.5",
                strokeLinecap: "round",
              }}
            />
          </HighestStandardsDrawingLine>
        </HighestStandardTitleWrapper>
        <HighestStandardVideoOuterWrapper>
          <HighestStandardVideoWrapper isSamsungInternet={isSamsungInternet}>
            <div style={{ height: "100%", width: "100%", position: "relative" }}>
              {!isSamsungInternet && <HighestStandardVideoGradient />}
              <video src={StandardsVideo} autoPlay muted playsInline loop />
              {!isSamsungInternet && <HighestStandardVideoGradient bottom />}
            </div>
          </HighestStandardVideoWrapper>
        </HighestStandardVideoOuterWrapper>

        <MedicalStandardsWrapper
          ref={inViewRef}
          className="medicalStandardsWrapper"
          isSamsungInternet={isSamsungInternet}
        >
          <MedicalStandardsInnerWrapper>
            <StandardWrapper>
              <IconWrapper animate={inView} index={2} className="highestStandardsIcon">
                <MedicalIcon />
                <LineOnIconWrapper index={1}>
                  <AutoDrawingSVG
                    pathOptions={{
                      d: "M8.69646 0.971956C-3.21733 2.72638 -3.25056 5.81422 25.8813 11.7795C50.8892 16.9004 88.1651 10.9965 61.5731 0.969865",
                      stroke: "#0E1B1B",
                    }}
                    shouldDraw={inView}
                  />
                </LineOnIconWrapper>
              </IconWrapper>
              <StandardContentWrapper>
                <StandardContentHeader>Exclusively Medical Shipments</StandardContentHeader>
                <StandardContentDescription>
                  Dedicated <span> medical deliveries </span>means your precious samples won’t be shipped next to a bag
                  of groceries or a bicycle - our courriers carry medical shipments only.
                </StandardContentDescription>
              </StandardContentWrapper>
            </StandardWrapper>

            <StandardWrapper>
              <IconWrapper animate={inView} index={1} className="highestStandardsIcon">
                <CertifiedIcon />
                <LineOnIconWrapper index={2}>
                  <AutoDrawingSVG
                    pathOptions={{
                      d: "M8.69646 0.971956C-3.21733 2.72638 -3.25056 5.81422 25.8813 11.7795C50.8892 16.9004 88.1651 10.9965 61.5731 0.969865",
                      stroke: "#0E1B1B",
                    }}
                    shouldDraw={inView}
                  />
                </LineOnIconWrapper>
              </IconWrapper>
              <StandardContentWrapper smallerBox>
                <StandardContentHeader>Trained Medical Couriers</StandardContentHeader>
                <StandardContentDescription>
                  Rest assured, our <span>GMP certified</span> couriers will find the right door in your building and
                  leave the parcel safely in the right hands.
                </StandardContentDescription>
              </StandardContentWrapper>
            </StandardWrapper>
          </MedicalStandardsInnerWrapper>

          <MedicalStandardsInnerWrapper>
            <StandardWrapper>
              <IconWrapper animate={inView} index={4} className="highestStandardsIcon">
                <SecureIcon />
                <LineOnIconWrapper index={3}>
                  <AutoDrawingSVG
                    pathOptions={{
                      d: "M8.69646 0.971956C-3.21733 2.72638 -3.25056 5.81422 25.8813 11.7795C50.8892 16.9004 88.1651 10.9965 61.5731 0.969865",
                      stroke: "#0E1B1B",
                    }}
                    shouldDraw={inView}
                  />
                </LineOnIconWrapper>
              </IconWrapper>
              <StandardContentWrapper>
                <StandardContentHeader>Secure Packaging</StandardContentHeader>
                <StandardContentDescription>
                  For extra protection, all parcels are transported using <span>UN3373</span> insulated packaging to
                  avoid any leakages or damages.
                </StandardContentDescription>
              </StandardContentWrapper>
            </StandardWrapper>

            <StandardWrapper>
              <IconWrapper animate={inView} index={3} className="highestStandardsIcon">
                <TransportIcon />
                <LineOnIconWrapper index={4}>
                  <AutoDrawingSVG
                    pathOptions={{
                      d: "M8.69646 0.971956C-3.21733 2.72638 -3.25056 5.81422 25.8813 11.7795C50.8892 16.9004 88.1651 10.9965 61.5731 0.969865",
                      stroke: "#0E1B1B",
                    }}
                    shouldDraw={inView}
                  />
                </LineOnIconWrapper>
              </IconWrapper>
              <StandardContentWrapper smallerBox>
                <StandardContentHeader>Secure Transport</StandardContentHeader>
                <StandardContentDescription>
                  No more <span>damaged or half-opened</span> parcels. GMP Trained Couriers delivering a consistently
                  high quality service.
                </StandardContentDescription>
              </StandardContentWrapper>
            </StandardWrapper>
          </MedicalStandardsInnerWrapper>
        </MedicalStandardsWrapper>
      </div>
    </HighestStandardSection>
  );
};

export default HighestStandards;
