import gsap from "gsap";
import React, { useEffect, useState } from "react";
import useGAHandler from "../../../common/hooks/useGAHandler";
import useRouting from "../../../common/hooks/useRouting";
import { Landing } from "../../../config";
import PageLoader from "../../shared/components/PageLoader/PageLoader";
import GlobalStyle from "../../shared/styles/global";
import CouriersLandingLayout from "../CouriersLandingLayout/CouriersLandingLayout";
import { PackageProvider } from "../PackageContext/PackageProvider";

const CouriersLandingApp = () => {
  const [showLoader, setShowLoader] = useState(true);
  const isLoading = useRouting(Landing.COURIERS);
  useGAHandler();

  useEffect(() => {
    if (!isLoading) {
      gsap
        .timeline()
        .to(".pageLoader", { autoAlpha: 0, duration: 0 })
        .to(".pageContent", { opacity: 1, duration: 0 }, 0)
        .add(() => setShowLoader(false));
    }

    setTimeout(() => {
      if (isLoading) {
        gsap
          .timeline()
          .to(".pageLoader", { autoAlpha: 0, duration: 0.5, delay: 0.6 })
          .to(".pageContent", { opacity: 1, duration: 0.5 }, 0.6)
          .add(() => setShowLoader(false));
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      gsap
        .timeline()
        .to(".pageLoader", { autoAlpha: 0, duration: 0.5, delay: 1.6 })
        .to(".pageContent", { opacity: 1, duration: 0.5 }, 1.6)
        .add(() => setShowLoader(false));
    }
  }, [isLoading]);

  return (
    <>
      {showLoader && <PageLoader />}
      <div className="pageContent" style={{ opacity: 0 }}>
        <PackageProvider>
          <CouriersLandingLayout />
          <GlobalStyle />
        </PackageProvider>
      </div>
    </>
  );
};

export default CouriersLandingApp;
