import React, { FunctionComponent } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/dist/MotionPathPlugin";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { BREAKPOINTS } from "../../shared/styles/const";
import WhyChooseUsMobile from "./WhyChooseUsMobile/WhyChooseUsMobile";
import WhyChooseUsDesktop from "./WhyChooseUsDesktop";

const WhyChooseUs: FunctionComponent<{ courierLanding?: boolean }> = ({ courierLanding }) => {
  const [width] = useWindowSize();

  const isTabletView = () => {
    return BREAKPOINTS.NEW_TABLET.max && width <= BREAKPOINTS.NEW_TABLET.max;
  };
  gsap.registerPlugin(MotionPathPlugin);
  if (!width) return null;
  return (
    <>
      {isTabletView() ? (
        <WhyChooseUsMobile courierLanding={courierLanding} />
      ) : (
        <WhyChooseUsDesktop courierLanding={courierLanding} />
      )}
    </>
  );
};

export default WhyChooseUs;
