import styled, { css } from "styled-components";
import { SectionWrapper } from "../../shared/components/SectionWrapper/SectionWrapper";
import { media } from "../../shared/styles/media";

export const InGoodHandsContainer = styled(SectionWrapper)`
  position: relative;
  max-width: 1920px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 170px;
  overflow: hidden;
  margin-bottom: 64px;

  ${media.down("TABLET")`
  padding-top: 150px;
  margin-top: -100px;
    `}

  ${media.down("MOBILE_LARGE")`
  margin-top: -80px;
  padding-top: 100px;
    `}
  ${media.down("MOBILE_MLARGE")`
  margin-top: -50px;
    `}
`;

export const InGoodHandsInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 10;
`;

export const CanvasWrapper = styled.div<{ left?: boolean }>`
  position: absolute;
  width: 400px;
  height: 400px;
  ${media.up("NEW_MAIN_WEB")`
    width: 500px;
    height: 500px;
  `}
  ${media.down("MOBILE_LARGE")`
  width: 300px;
  height: 300px;
  `};
  ${p =>
    p.left &&
    css`
      right: unset;
      left: -100px;
      top: 0;
      ${media.down("NEW_HALF_WEB")`
        top: 50px;
        left: -130px;
      `}
      ${media.down("MOBILE_LARGE")`
        top: 100px;
      `};
      ${media.down("MOBILE")`
        display: none;
      `};
    `}

  ${p =>
    !p.left &&
    css`
      right: -120px;
      left: unset;
      top: -70px;
      ${media.down("TABLET")`
        top: -40px;
      `}
      ${media.down("NEW_HALF_WEB")`
        top: -250px;
      `}
      ${media.down("MOBILE_LARGE")`
        top: -150px;
      `};
      ${media.down("MOBILE")`
        top: -140px;
        right: -20px;
      `};
    `}
`;

export const InGoodHandsTitle = styled.h2`
  margin: 0;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: #0e1b1b;
  width: 590px;
  text-align: center;
  position: relative;
  z-index: 2;

  ${media.down("TABLET")`
    font-size: 56px;
    margin-bottom: 20px;
  `}

  ${media.down("TABLET_LARGE")`
    font-size: 56px;
    //width: 381px;
  `}

  ${media.down("NEW_HALF_WEB")`
    width: 481px;
  `}

  ${media.down("MOBILE_LARGE")`
    display: flex;
    justify-content:center;
    text-align: center;
    align-self: center;
    margin-top: 40px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 48px;
    margin-bottom: 12px;
    width: 340px;
  `}

  ${media.down("MOBILE")`
    font-size: 48px;
    //width: 220px;
  `}
  ${media.down("NEW_SMALL_MOBILE")`
    font-size: 44px;
  `}
`;

export const InGoodHandsSubtitle = styled.p`
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 145%;
  color: #514d4d;
  margin-bottom: 56px;
  position: relative;
  z-index: 2;

  ${media.down("NEW_LARGE_MOBILE")`
    font-size: 20px;
  `}
`;

export const InGoodHandsImage = styled.img`
  position: absolute;
  bottom: 0;
`;
