import React, {useState, useEffect, useRef} from "react";
import {
  OurStoryLeftSection,
  OurStoryRightSection,
  OurStoryLeftContent,
  PhotosContainerSection2,
  OurStoryHiddenText,
  OurStoryTitle,
  OurStoryNextPageContent,
  CircledText,
} from "./OurStory.components";
import CourierPhoto from "./assets/courier.png";
import LabPhoto from "./assets/lab.png";
import {useWindowSize} from "../../../common/hooks/useWindowSize";

interface OurStoryPage2and3Props {
  currentPage: number;
  pagesNumber: number;
}

const OurStoryPage2and3 = ({currentPage, pagesNumber}: OurStoryPage2and3Props) => {
  const circledTextPage2 = useRef<HTMLElement>(null);
  const circledTextPage3 = useRef<HTMLElement>(null);
  const [width] = useWindowSize();

  const [ellipsePosition, setEllipsePosition] = useState({x: 0, y: 0, width: 0});
  const setCircledTextPosition = () => {
    setTimeout(() => {
      if (currentPage === pagesNumber - 1 && circledTextPage2?.current) {
        const position = {
          x: circledTextPage2.current.offsetLeft,
          y: circledTextPage2.current.offsetTop - (window.innerWidth <= 1200 ? 10 : 0),
          width: circledTextPage2.current.offsetWidth,
        };
        setEllipsePosition(position);
      }

      if (currentPage === pagesNumber && circledTextPage3?.current) {
        const position = {
          x: circledTextPage3.current.offsetLeft,
          y: circledTextPage3.current.offsetTop - (window.innerWidth <= 1200 ? 10 : 0),
          width: circledTextPage3.current.offsetWidth,
        };
        setEllipsePosition(position);
      }
    }, 50);
  };

  useEffect(() => {
    setCircledTextPosition();
  }, [currentPage, width]);

  return (
    <>
      <OurStoryLeftSection style={{paddingTop: 0}}>
        <OurStoryLeftContent>
          <OurStoryNextPageContent style={{position: "relative"}}>
            <OurStoryTitle className="ourStoryTitle">
              <svg viewBox="0 0 500 50">
                <text className="svg-title" y="50">
                  OUR STORY
                </text>
              </svg>
            </OurStoryTitle>
            <OurStoryHiddenText className="ourStoryLeftContentPage1Mobile">
              In 2016, after 10+ years of working in the medical courier industry (including one of the biggest courier
              companies in the UK), Alex & Tomasz decided it was time to start their own medical courier company. Along
              the way, their now co-partner Tommo joined them in the adventure.
            </OurStoryHiddenText>
            <p className="ourStoryLeftContentPage2">
              Working in the industry for such a long time made them realize that there was a serious need for{" "}
              <b>higher standards</b> and a <b ref={circledTextPage2}>higher&nbsp;quality</b> service in the delivery of
              medical parcels.
              <br />
              <br />
              Having understood the crucial importance of timeliness, secure deliveries and good customer service in
              this industry, they embarked on a journey to become the only dedicated medical courier company in London.
            </p>
            <OurStoryHiddenText className="ourStoryLeftContentPage3">
              Throughout the pandemic, the business grew tremendously thanks to new services such as at-home{" "}
              <b>PCR tests & blood samples</b>. The clients appreciated the quality of service and began recommending
              Medical Logistics to those around them.
              <br />
              <br />
              In the space of one year Medical Logistics had hit its 5 year growth plan and obtained its{" "}
              <b ref={circledTextPage3}>UKAS&nbsp;accreditation!</b>
            </OurStoryHiddenText>
            <CircledText
              style={{
                display: currentPage >= pagesNumber - 1 ? "block" : "none",
                width: ellipsePosition.width,
                left: ellipsePosition.x,
                top: ellipsePosition.y,
              }}
            />
          </OurStoryNextPageContent>
        </OurStoryLeftContent>
      </OurStoryLeftSection>
      <OurStoryRightSection style={{paddingLeft: 0}}>
        <PhotosContainerSection2>
          <img loading="lazy" src={CourierPhoto} alt="Courier" className="photoCourier" />
          <img loading="lazy" src={LabPhoto} alt="Lab" className="photoLab" />
        </PhotosContainerSection2>
      </OurStoryRightSection>
    </>
  );
};

export default OurStoryPage2and3;
