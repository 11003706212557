export const colors = {
  white: "#fff",
  linesOnWhite: "#D8D5D5",
  opaqueWhite: {
    _08: "rgba(255, 255, 255, 0.08)",
    _6: "rgba(255, 255, 255, 0.6)",
    _8: "rgba(255, 255, 255, 0.8)",
  },
  red: {
    _50: "#FFE6E8",
    _300: "#FB7480",
    _500: "#DD2736",
    _600: "#CA1B2A",
  },
  gray: {
    _100: "#F2F5F8",
    _200: "#EAEEF0",
    _300: "#CFD4D8",
    _400: "#A9B3BC",
    _500: "#84919A",
    _600: "#697781",
    _700: "#48535B",
    _800: "#2C343A",
    _900: "#101819",
  },
  greyscale: {
    black: "#040c0b",
    white: "#E6E6E6",
    _100: "#F2F5F8",
    _800: "#2c343a",
  },
  mint: {
    _100: "#E4F7F7",
    _150: "#D1F1F0",
    _400: "#6FD3D1",
    _500: "#48C7C5",
    _600: "#35A8AC",
    _700: "#2A8189",
    _800: "#215969",
  },
  orange: {
    SANDY_BEACH: "#FAE2D1",
    BEEF_BOURGUIGNON: "#B54B00",
  },
};
