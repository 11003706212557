import React, { FunctionComponent, Suspense, useRef } from "react";
import {
  GoGreenMobileContainer,
  GoGreenMobileHeading,
  GoGreenMobileWrapper,
  GoGreenMobileDescription,
  GoGreenMobileImageWrapper,
  GoGreenMobileCircleWrapper,
  GoGreenTopDescriptionWrapper,
  GoGreenTopArrowWrapper,
  GoGreenBottomDescriptionWrapper,
  GoGreenBottomArrowWrapper,
} from "./GoGreenMobile.components";
import { ReactComponent as Circle } from "../icons/CirclePointer.svg";
import { ReactComponent as ArrowTop } from "./icons/ArrowTop.svg";
import { ReactComponent as ArrowBottom } from "./icons/ArrowBottom.svg";

import EcoBadgeImage from "./EcoBadgeMobile.svg";

import { BGBottom, EcoBadge } from "../GoGreen.components";
import { Canvas } from "@react-three/fiber";
import WaveEffect from "../WaveEffect";
import { Html } from "@react-three/drei";
import { useInView } from "react-intersection-observer";
import SEOMarker from "../../../shared/components/SEO/SEOMarker";
import { CompanyRoutes } from "../../../../config/routes";

const GoGreenMobile: FunctionComponent = () => {
  const [inViewRef, inView] = useInView();
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const getHeight = () => {
    let height = 300;
    if (canvasContainerRef && canvasContainerRef.current) {
      height = canvasContainerRef.current.clientWidth / 0.8;
    }
    return height;
  };
  return (
    <GoGreenMobileContainer ref={inViewRef}>
      <SEOMarker routeInfo={CompanyRoutes.goGreen} offset={-100} />
      <GoGreenMobileWrapper>
        <GoGreenMobileHeading>Go Green!</GoGreenMobileHeading>
        <GoGreenMobileDescription>By 2023 all of our cars will be: </GoGreenMobileDescription>
      </GoGreenMobileWrapper>
      <GoGreenMobileImageWrapper ref={canvasContainerRef} style={{ height: getHeight() }}>
        <EcoBadge src={EcoBadgeImage} />

        <Canvas dpr={1}>
          <Suspense
            fallback={
              <Html>
                <h3>Loading...</h3>
              </Html>
            }
          >
            <WaveEffect inView={inView} mobile />
          </Suspense>
        </Canvas>
        {/* <img style={{ width: "100%" }} src={GoGreenMobileImage} /> */}
        <GoGreenTopDescriptionWrapper>
          <GoGreenTopArrowWrapper>
            <span>Emission-free</span>
            <ArrowTop />
          </GoGreenTopArrowWrapper>
          <GoGreenMobileCircleWrapper>
            <Circle />
          </GoGreenMobileCircleWrapper>
        </GoGreenTopDescriptionWrapper>
        <GoGreenBottomDescriptionWrapper>
          <GoGreenBottomArrowWrapper>
            <ArrowBottom />
            <span>100% ELECTRIC</span>
          </GoGreenBottomArrowWrapper>
          <GoGreenMobileCircleWrapper>
            <Circle />
          </GoGreenMobileCircleWrapper>
        </GoGreenBottomDescriptionWrapper>
      </GoGreenMobileImageWrapper>
      <BGBottom />
      <SEOMarker routeInfo={CompanyRoutes.laboratories} offset={0} />
    </GoGreenMobileContainer>
  );
};

export default GoGreenMobile;
