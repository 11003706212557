import styled, { css } from "styled-components";
import { media } from "../../../shared/styles/media";

export const ServicesMobileContainer = styled.section`
  display: flex;
  flex-direction: column;
  background: #d8e3e3;
  height: 4000px;
  padding: 105px 96px;
  box-sizing: border-box;
  position: relative;

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    max-width: unset;
    white-space: nowrap;
  `}
  ${media.down("MOBILE_LARGE")`
    padding: 105px 48px 48px;
  `}
`;

export const ServicesMobileStickyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 105px;
  padding-bottom: 75px;
  @media all and (min-width: 430px) {
    padding-bottom: 25px;
  }
`;

export const ServicesSectionMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 64px;
`;

export const ServicesMobileVideoPlayer = styled.video`
  display: flex;
  align-self: center;
  width: 360px;
  margin-top: 18px;

  ${media.down("NEW_MOBILE")`
      width: 272px;
  `}
`;

export const ServicesMobileBoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  background: #d8e3e3;
  width: 100%;
  z-index: 9999;

  @media all and (min-width: 430px) {
    margin-top: 0;
  }

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
  > :nth-child(2) {
      margin: 12px 0px;
    }
  `}
`;

export const TimeSectionMobileWrapper = styled.div<{ stickToTop: boolean }>`
  display: flex;
  flex-direction: column;
  position: sticky;
  transition: top 1s ease;
  top: ${p => (p.stickToTop ? 115 : 300)}px;
  //margin-top: -351px;

  ${media.down("NEW_MOBILE")`
    //margin-top: -331px;
  `}

  ${p =>
    p.stickToTop
      ? css`
          ${media.down("NEW_MOBILE")`
            top: 85px;
          `}
          @media all and (max-height: 750px) {
            top: -145px;
          }
        `
      : css`
          ${media.down("NEW_MOBILE")`
            top: 300px;
          `}
        `}
`;

export const TimeMobileVideoWrapper = styled.div`
  display: flex;
  position: sticky;
  margin-bottom: 10px;
  height: 300px;
  align-items: center;
  justify-content: center;
`;

export const TimeMobileVideoPlayer = styled.video`
  //position: absolute;
  width: 390px;
  margin-top: 32px;
  ${media.down("NEW_MOBILE")`
      width: 300px;
  `};
`;

export const TimeInformationMobileContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
          width: 100vw;
  `}
`;

export const TimeInformationMobileOuterWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;
  margin-top: 24px;
`;

export const TimeMobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScrollDownBadgeWrapper = styled.div`
  position: absolute;
  bottom: 250px;
  left: 80px;
  border: 1px solid rgba(240, 245, 245, 0.8);
  padding: 10px;
  border-radius: 50%;
  ${media.down("NEW_LARGE_MOBILE")`
    bottom: 200px;
    left: 20vw;
    padding: 5px;
  `}

  ${media.down("MOBILE_MLARGE")`
    left: 15vw;
  `}
  ${media.down("MOBILE")`
    left: 10vw;
  `}
  ${media.down("MOBILE_TABLE")`
    left: 6vw;
  `}
`;

export const ScrollDownBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 81px;
  height: 81px;
  white-space: pre-wrap;
  background: rgba(240, 245, 245, 0.6);
  border: 0.855263px solid #ffffff;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 104%;
  letter-spacing: 0.2px;
  color: #0e1b1b;
  animation: pulse linear 2s infinite;

  ${media.down("NEW_LARGE_MOBILE")`
    width: 61px;
    height: 61px;
    font-size: 12px;
  `}

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
