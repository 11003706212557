import { FieldProps } from "formik";
import React, { ComponentProps } from "react";

import { Textarea } from "./Textarea";

export type TextareaFieldComponentProps = Omit<ComponentProps<typeof Textarea>, "error">;

type Props<TValueType> = TextareaFieldComponentProps & Pick<FieldProps<TValueType>, "field" | "form">;

const TextareaFieldComponent = ({ field, ...fieldProps }: Props<any>) => {
  return <Textarea {...fieldProps} {...field} />;
};

export default TextareaFieldComponent;
