export interface IOpinion {
  content: string;
  author: string;
}

export interface IOpinionsConfig {
  cardBackground: string;
  lineGradient: string;
  contentColor: string;
  borderColor: string;
  footerTextColor: string;
  readMoreColor: string;
  readMoreBackground: string;
  readMoreSeparatorColor: string;
  opinions: IOpinion[];
}

export enum LandingOpinions {
  COMPANY,
  MEDICAL,
  COURIERS,
}

const medicalLandingOpinionsConfig: IOpinionsConfig = {
  cardBackground: "rgba(23, 26, 27, 1)",
  contentColor: "#B6C3C3",
  footerTextColor: "#F0F5F5",
  lineGradient: "linear-gradient(270deg, rgba(198, 235, 234, 0) 0%,#35ACA8  96.95%)",
  readMoreColor: "#F0F5F5",
  readMoreBackground: "#181a1b",
  readMoreSeparatorColor: "rgba(148, 168, 167, 0.32)",
  borderColor: "rgba(148, 168, 167, 0.32)",
  opinions: [
    {
      content: "“Very professional at home service with quick same day results. Have already recommended.”",
      author: "Frederika van Hagen",
    },
    {
      content: "“Proffesional service. Accommodating on the phone. On time for blood draw and friendly!”",
      author: "Helene",
    },
    {
      content: "“Great service and team - punctual & efficient. Highly recommended for all phlebotomy requirements.”",
      author: "Al Patel",
    },
  ],
};

const companyLandingOpinionsConfig: IOpinionsConfig = {
  cardBackground: "rgba(239, 233, 230, 1);",
  contentColor: "#506262",
  footerTextColor: "#0e1b1b",
  lineGradient: "linear-gradient(270deg, rgba(241, 167, 173, 0) 0%, #dd2736 96.95%)",
  readMoreColor: "#0e1b1b",
  readMoreBackground: "#EFE9E5",
  readMoreSeparatorColor: "rgba(164, 152, 152, 0.32)",
  borderColor: "rgba(164, 152, 152, 0.32)",
  opinions: [
    {
      content: "“Amazing, professional service, properly trainted team! Highly reccomend!”",
      author: "Magdalena",
    },
    {
      content: "“I could have only given my experience a 5 star experience because it was exactly that.”",
      author: "Pat Crew",
    },
    {
      content: "“Incredibly efficient service, good communication. Would definitely use you again.”",
      author: "Hodan",
    },
  ],
};

const couriersLandingOpinionsConfig: IOpinionsConfig = {
  cardBackground: "#EFE9E6",
  contentColor: "#514D4D",
  footerTextColor: "#514D4D",
  lineGradient: "linear-gradient(270deg, rgba(241, 167, 173, 0) 0%, #dd2736 96.95%)",
  readMoreColor: "#514D4D",
  readMoreBackground: "#EFE9E6",
  readMoreSeparatorColor: "rgba(164, 152, 152, 0.32)",
  borderColor: "rgba(164, 152, 152, 0.32)",
  opinions: [
    {
      content: "“Great service and team - punctual & efficient. Keep up the good work!”",
      author: "Al Patel",
    },
    {
      content: "“Great service, friendly staff. Would highly recommend.”",
      author: "SJ",
    },
    {
      content: "“Tomasz came to my place of work and was very professional, clean and efficient.”",
      author: "Alexandra",
    },
  ],
};

export const getOpinionsConfig = (type: LandingOpinions) => {
  switch (type) {
    case LandingOpinions.COMPANY:
      return companyLandingOpinionsConfig;
    case LandingOpinions.MEDICAL:
      return medicalLandingOpinionsConfig;
    case LandingOpinions.COURIERS:
      return couriersLandingOpinionsConfig;
    default:
      return medicalLandingOpinionsConfig;
  }
};
