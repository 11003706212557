// @ts-nocheck
import React, { useRef, useEffect, FunctionComponent } from "react";
import { shaderMaterial, useAspect } from "@react-three/drei";
import { extend, useLoader, useFrame } from "@react-three/fiber";
import glsl from "babel-plugin-glsl/macro";
import * as THREE from "three";
import gsap from "gsap";
import Car from "./ML-car.webp";
import MobileCar from "./GoGreenMobile/MobileCar.webp";

const WaveShaderMaterial = shaderMaterial(
  // Uniform
  {
    uTime: { value: 1.0 },
    uTexture: new THREE.Texture(),
    uProg: 0,
  },
  // Vertex Shader
  glsl`
    precision highp float;
      #pragma glslify: snoise3 = require(glsl-noise/simplex/3d);
      varying vec2 vUv;
      varying float vWave;
      uniform float uTime;
      uniform float uProg;
      
      void main() {
        vUv = uv;
        vec3 pos = position;
        float noiseFreq = 3.5;
        float noiseAmp = 0.15; 
        vec3 noisePos = vec3(pos.x * noiseFreq + uTime, pos.y, pos.z);
        pos.z += snoise3(noisePos) * noiseAmp * uProg;
        vWave = pos.z;
      
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
}
    `,
  // Fragment Shader
  glsl`
    varying vec2 vUv;
    varying float vWave;
    uniform sampler2D uTexture;
    
    void main() {
        float wave = vWave * 0.2;
        // Split each texture color vector
        float r = texture2D(uTexture, vUv).r;
        float g = texture2D(uTexture, vUv).g;
        float b = texture2D(uTexture, vUv + wave).b;
        // Put them back together
        vec3 texture = vec3(r, g, b);
        gl_FragColor = vec4(texture, 1.);
    }
    `
);

extend({ WaveShaderMaterial });

const DisableRender = () => useFrame(() => null, 1000);

const WaveEffect: FunctionComponent<{ mobile?: boolean; inView?: boolean }> = ({ mobile, inView }) => {
  const ref = useRef();

  const AbleRender = () =>
    useFrame(({ clock }) => {
      ref.current.uTime = clock.getElapsedTime();
    });

  const [image] = useLoader(THREE.TextureLoader, [Car]);
  const [imageSmall] = useLoader(THREE.TextureLoader, [MobileCar]);

  useEffect(() => {
    gsap.to(ref.current.uniforms.uProg, {
      value: 0.5,
    });
  }, []);

  const scale = useAspect(
    1024, // Pixel-width
    512, // Pixel-height
    1 // Optional scaling factor
  );

  const args = !mobile ? [0.8, 1, 8, 8] : [0.4, 1, 8, 8];

  return (
    <mesh scale={scale}>
      {inView ? <AbleRender /> : <DisableRender />}
      <planeGeometry args={args} />
      <waveShaderMaterial ref={ref} uTexture={!mobile ? image : imageSmall} />
    </mesh>
  );
};

export default WaveEffect;
