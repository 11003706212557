import React, { FunctionComponent } from "react";
import { FlowingButtonContainer, Liquid } from "./FlowingButton.components";

type FlowingButtonProps = {
  text?: string;
};

const FlowingButton: FunctionComponent<FlowingButtonProps> = ({ text }) => {
  return (
    <FlowingButtonContainer href="https://booking.medicallogistics.co.uk/">
      <span>{text || "Check availability"}</span>
      <Liquid className="liquid" />
    </FlowingButtonContainer>
  );
};

export default FlowingButton;
