import React, { FunctionComponent, useEffect, useRef } from "react";
import gsap from "gsap";
import {
  BoxMobileBackground,
  WhyChooseUsBoxBackground,
  WhyChooseUsBoxContent,
  WhyChooseUsBoxesContainer,
  WhyChooseUsBoxTitle,
  WhyChooseUsImage,
  WhyChooseUsScrollDownContainer,
  WhyChooseUsScrollText,
  WhyChooseUsSection,
  WhyChooseUsStickyWrapper,
  WhyChooseUsTitle,
  WhyChooseUsButtonWrapper,
  BreakLineSmallWeb,
} from "./WhyChooseUs.components";
import { ScrollDownImg } from "../../shared/components/ScrollDown/ScrollDown.components";
import ScrollDown from "../../shared/components/ScrollDown/ScrollDown.svg";
import BackgroundBoxMobile from "./BoxWhyChooseUsWeb.svg";
import BackgroundBoxMobileRed from "./BoxWhyChooseUsWebRed.svg";
import ScrollTrigger from "../../../common/ScrollTrigger";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import { CouriersRoutes, MedicalRoutes } from "../../../config/routes";
import { QuoteButton } from "../Introduction/Introduction";
import urls from "../../../config/urls";
import BouncedShapeWithTransparency from "../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import FlowingButton from "../../shared/components/FlowingButton/FlowingButton";
import { ClockMint, ClockRed, CrownMint, CrownRed, TubesMint, TubesRed } from "./images/images";

const SVGatorBackground: FunctionComponent<{ courierLanding?: boolean }> = ({ courierLanding }) => (
  <BoxMobileBackground style={{ height: "auto" }} className="whyChooseUsBoxBackgroundDesktop">
    <object type="image/svg+xml" data={courierLanding ? BackgroundBoxMobileRed : BackgroundBoxMobile} />
  </BoxMobileBackground>
);

const WhyChooseUsDesktop: FunctionComponent<{ courierLanding?: boolean }> = ({ courierLanding }) => {
  const isAnimating = useRef<boolean>(false);

  const animateWhyChooseUs = () => {
    gsap
      .timeline()
      .add(() => {
        isAnimating.current = true;
      })
      .to(".whyChooseUsTitle", { scale: 1, y: 0, duration: 0.8 }, 0)
      .to(".whyChooseUsTitleText", { scale: 1, duration: 1 }, 0)
      .to(".scrollDown", { autoAlpha: 0, duration: 0.2 }, 0)
      .to(".whyChooseUsBoxBackgroundDesktop", { autoAlpha: 1, duration: 0.5 }, 0)
      .to(".whyChooseUsBox", { y: 0, duration: 1 }, 0)
      .to(".whyChooseUsBoxTitle", { y: 0, autoAlpha: 1, duration: 1 }, 0)
      .to(".whyChooseUsBoxContent", { y: 0, autoAlpha: 1, duration: 1 }, 0)
      .to(".tube", { y: 0, x: 0, duration: 1 }, 0)
      .to(".clock", { y: 0, x: 0, duration: 1 }, 0)
      .to(".crown", { y: 0, x: 0, duration: 1 }, 0)
      .to(".whyChooseUsImage", { scale: 1, duration: 1 }, 0)
      .to(".whyChooseUsButton", { autoAlpha: 1, scale: 1, duration: 1 }, 1)

      .add(() => {
        isAnimating.current = false;
      });
    gsap.to(".whyChooseUsImage", {
      y: 8,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      duration: 1,
      delay: 1,
    });
  };

  const animateOnStart = (duration = 0) => {
    gsap.killTweensOf(".whyChooseUsImage");
    gsap
      .timeline()
      .add(() => {
        isAnimating.current = true;
      })
      .to(".whyChooseUsTitle", { y: 200, duration: duration / 1.5 }, 0)
      .to(".whyChooseUsTitleText", { scale: 1.5, duration: duration }, 0)
      .to(".scrollDown", { autoAlpha: 1, duration: duration / 2 }, 0)
      .to(".whyChooseUsBoxBackgroundDesktop", { autoAlpha: 0, duration: duration / 4 }, 0)
      .to(".whyChooseUsBox", { y: 50, duration: 1 }, 0)
      .to(".whyChooseUsBoxTitle", { y: 100, autoAlpha: 0, duration: duration }, 0)
      .to(".whyChooseUsBoxContent", { y: 100, autoAlpha: 0, duration: duration }, 0)
      .to(".tube", { y: courierLanding ? 150 : 70, x: -90, duration: duration }, 0)
      .to(".clock", { y: window.innerWidth > 1566 ? -240 : -190, x: -50, duration: duration }, 0)
      .to(".crown", { y: courierLanding ? 120 : 40, x: -20, duration: duration }, 0)
      .to(".whyChooseUsImage", { scale: 1.2, duration: duration }, 0)
      .to(".whyChooseUsButton", { autoAlpha: 0, scale: 0, duration: duration }, 0)
      .add(() => {
        isAnimating.current = false;
      });
  };
  useEffect(() => {
    animateOnStart();
    ScrollTrigger.create({
      trigger: ".whyChooseUs",
      start: "bottom-=600 bottom",
      end: "bottom top",
      onEnter: () => {
        const timeout = isAnimating.current ? 1000 : 0;
        setTimeout(() => {
          animateWhyChooseUs();
        }, timeout);
      },
      onLeaveBack: () => {
        const timeout = isAnimating.current ? 1000 : 0;
        setTimeout(() => {
          animateOnStart(1);
        }, timeout);
      },
    });
  }, []);

  return (
    <div style={{ position: "relative", overflow: "unset" }}>
      {courierLanding ? (
        <SEOMarker routeInfo={CouriersRoutes.whyChooseUs} />
      ) : (
        <SEOMarker routeInfo={MedicalRoutes.whyChooseUs} />
      )}
      <WhyChooseUsSection courierLanding={courierLanding} className="whyChooseUs">
        <WhyChooseUsStickyWrapper>
          <WhyChooseUsTitle className="whyChooseUsTitle">
            {courierLanding ? (
              <div style={{ margin: 0 }} className="whyChooseUsTitleText">
                Even More Reasons
                <BreakLineSmallWeb /> To Choose Us
              </div>
            ) : (
              <div style={{ margin: 0 }} className="whyChooseUsTitleText">
                Why Choose Us?
              </div>
            )}
            <WhyChooseUsScrollDownContainer className="scrollDown">
              <ScrollDownImg>
                <div
                  style={{ height: "100%", width: "100%", zIndex: 11, margin: "0 auto -15px auto", cursor: "pointer" }}
                >
                  <object type="image/svg+xml" data={ScrollDown}></object>
                </div>
              </ScrollDownImg>
              <WhyChooseUsScrollText>scroll to find out</WhyChooseUsScrollText>
            </WhyChooseUsScrollDownContainer>
          </WhyChooseUsTitle>
          <div>
            <WhyChooseUsBoxesContainer>
              <WhyChooseUsBoxBackground courierLanding={courierLanding} className="whyChooseUsBox">
                <SVGatorBackground courierLanding={courierLanding} />
                <WhyChooseUsImage
                  className="tube whyChooseUsImage"
                  alt="tube"
                  src={courierLanding ? ClockRed : TubesMint}
                  loading="lazy"
                />
                {courierLanding ? (
                  <WhyChooseUsBoxTitle className="whyChooseUsBoxTitle">Couriers Availability 24/7</WhyChooseUsBoxTitle>
                ) : (
                  <WhyChooseUsBoxTitle className="whyChooseUsBoxTitle">Medical Experts</WhyChooseUsBoxTitle>
                )}
                <WhyChooseUsBoxContent className="whyChooseUsBoxContent">
                  {courierLanding
                    ? "Our couriers are available 24/7, 365 days a year. Choose a time, and we’ll be there!"
                    : "Get tested by our team of fully trained medical professionals: Medics, Nurses & Phlebotomists."}
                </WhyChooseUsBoxContent>
              </WhyChooseUsBoxBackground>
              <WhyChooseUsBoxBackground courierLanding={courierLanding} className="whyChooseUsBox">
                <SVGatorBackground courierLanding={courierLanding} />
                <WhyChooseUsImage
                  className="clock whyChooseUsImage"
                  alt="clock"
                  src={courierLanding ? CrownRed : ClockMint}
                  loading="lazy"
                />
                {courierLanding ? (
                  <WhyChooseUsBoxTitle className="whyChooseUsBoxTitle">Excellent Customer Service</WhyChooseUsBoxTitle>
                ) : (
                  <WhyChooseUsBoxTitle className="whyChooseUsBoxTitle">Available 24/7</WhyChooseUsBoxTitle>
                )}
                <WhyChooseUsBoxContent className="whyChooseUsBoxContent">
                  {courierLanding
                    ? "Our team is available on Whatsapp - no more being on hold with call centers or speaking to a chatbot!"
                    : "Choose the time, and we’ll be there. We are available 24/7 even on Bank Holidays."}
                </WhyChooseUsBoxContent>
              </WhyChooseUsBoxBackground>
              <WhyChooseUsBoxBackground courierLanding={courierLanding} className="whyChooseUsBox">
                <SVGatorBackground courierLanding={courierLanding} />
                <WhyChooseUsImage
                  className="crown whyChooseUsImage"
                  alt="crown"
                  src={courierLanding ? TubesRed : CrownMint}
                  loading="lazy"
                />
                {courierLanding ? (
                  <WhyChooseUsBoxTitle className="whyChooseUsBoxTitle">High Quality Service</WhyChooseUsBoxTitle>
                ) : (
                  <WhyChooseUsBoxTitle className="whyChooseUsBoxTitle">Tailored Service</WhyChooseUsBoxTitle>
                )}
                <WhyChooseUsBoxContent className="whyChooseUsBoxContent">
                  {courierLanding
                    ? "We are UKAS Accredited - our services have been rigorously tested against national & international standards."
                    : "Any special request? A team away day, a sports event, or a business conference. Get in touch for more details."}
                </WhyChooseUsBoxContent>
              </WhyChooseUsBoxBackground>
            </WhyChooseUsBoxesContainer>
            <WhyChooseUsButtonWrapper>
              {courierLanding ? (
                <div className="whyChooseUsButton">
                  <FlowingButton text="Get a quote" />
                </div>
              ) : (
                <a href={urls.MEDICAL_LANDING_URL + "get-in-touch"} className="whyChooseUsButton">
                  <QuoteButton />
                </a>
              )}
            </WhyChooseUsButtonWrapper>
          </div>
        </WhyChooseUsStickyWrapper>
      </WhyChooseUsSection>
      <BouncedShapeWithTransparency
        trigger="whyChooseUs"
        topShapeBackground="transparent"
        bottomShapeBackground="#181a1b"
      />
    </div>
  );
};

export default WhyChooseUsDesktop;
