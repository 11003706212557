import React, { FunctionComponent, useEffect } from "react";
import { TrustpilotWrapper, TruspilotInnerWrapper } from "./Trustpilot.components";
import { ReactComponent as TruspilotIcon } from "./TrustpilotHeading.svg";
import { ReactComponent as TruspilotIconWhite } from "./TrustpilotHeadingWhite.svg";
import { ReactComponent as TrustpilotStar } from "./Star.svg";
import { ReactComponent as TrustpilotStarRed } from "./RedStar.svg";
import { ReactComponent as TrustpilotStarWhite } from "./WhiteStar.svg";
import { ReactComponent as TruspilotIconRed } from "./TrustpilotHeadingRed.svg";
import gsap from "gsap";
const TRUSTPILOT_STARS = 5;

type TrustpilotVariant = "red" | "white" | "mint";

type TrustpilotProps = {
  variant: TrustpilotVariant;
};

const getStar = (variant: TrustpilotVariant, key: number) => {
  switch (variant) {
    case "red":
      return <TrustpilotStarRed key={key} className="trustpilotStar" />;
    case "white":
      return <TrustpilotStarWhite key={key} className="trustpilotStar" />;
    case "mint":
      return <TrustpilotStar key={key} className="trustpilotStar" />;
  }
};

const getHeading = (variant: TrustpilotVariant) => {
  switch (variant) {
    case "red":
      return <TruspilotIconRed className="trustpilotHeading" />;
    case "white":
      return <TruspilotIconWhite className="trustpilotHeading" />;
    case "mint":
      return <TruspilotIcon className="trustpilotHeading" />;
  }
};

const Trustpilot: FunctionComponent<TrustpilotProps> = ({ variant }) => {
  useEffect(() => {
    gsap.from(".trustpilotHeading", { autoAlpha: 0, x: variant === "white" ? 0 : -100, duration: 1, delay: 1.2 });
    gsap.from(".trustpilotStar", { autoAlpha: 0, x: variant === "white" ? 0 : -100, duration: 1, delay: 1.2 });
  }, [variant]);

  return (
    <TrustpilotWrapper>
      <a
        style={{ zIndex: 199999999 }}
        href="https://uk.trustpilot.com/review/medicallogistics.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        {getHeading(variant)}

        <TruspilotInnerWrapper smallerMargin={variant === "white"}>
          {Array.from(Array(TRUSTPILOT_STARS).keys()).map(index => getStar(variant, index))}
        </TruspilotInnerWrapper>
      </a>
    </TrustpilotWrapper>
  );
};

export default Trustpilot;
