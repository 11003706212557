import React, { FunctionComponent, useEffect } from "react";
import {
  WhyChooseUsBoxContent,
  WhyChooseUsBoxesContainer,
  WhyChooseUsBoxTitle,
  WhyChooseUsSection,
  BoxMobileBackground,
} from "../WhyChooseUs.components";
import {
  BreakLine,
  BreakLineMobileTitle,
  WhyChooseUsBoxBackgroundMobile,
  WhyChooseUsBoxWrapperMobile,
  WhyChooseUsImageMobile,
  WhyChooseUsTitleMobile,
} from "./WhyChooseUsMobile.components";
import BackgroundBoxMobile from "./BoxWhyChooseUsMobile.svg";
import BackgroundBoxMobileRed from "../BoxWhyChooseUsMobileRed.svg";
import BouncedShapeWithTransparency from "../../../shared/components/BouncedShape/BouncedShapeWithTransparency/BouncedShapeWithTransparency";
import SEOMarker from "../../../shared/components/SEO/SEOMarker";
import { CouriersRoutes, MedicalRoutes } from "../../../../config/routes";
import { QuoteButton } from "../../Introduction/Introduction";
import urls from "../../../../config/urls";
import FlowingButton from "../../../shared/components/FlowingButton/FlowingButton";
import gsap from "gsap";
import { ClockMint, ClockRed, CrownMint, CrownRed, TubesMint, TubesRed } from "../images/images";
import { useInView } from "react-intersection-observer";

const SVGatorBackground: FunctionComponent<{ courierLanding?: boolean }> = ({ courierLanding }) => (
  <BoxMobileBackground className="whyChooseUsBoxBackground">
    <object type="image/svg+xml" data={courierLanding ? BackgroundBoxMobileRed : BackgroundBoxMobile} />
  </BoxMobileBackground>
);

const WhyChooseUsMobile: FunctionComponent<{ courierLanding?: boolean }> = ({ courierLanding }) => {
  const [inViewRef, inView] = useInView();

  useEffect(() => {
    gsap.to(".whyChooseUsImageMobile", {
      y: 8,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      duration: 1,
    });
  }, []);

  useEffect(() => {
    if (inView) {
      gsap.to(".whyChooseUsTitleMobileText", { scale: 1, duration: 1 });
    } else {
      gsap.to(".whyChooseUsTitleMobileText", { scale: 0.5, duration: 1 });
    }
  }, [inView]);
  return (
    <WhyChooseUsSection
      style={{ height: "auto", paddingTop: courierLanding ? 100 : 60, paddingBottom: 80 }}
      courierLanding={courierLanding}
      className="whyChooseUsMobile"
      ref={inViewRef}
    >
      {courierLanding ? (
        <SEOMarker routeInfo={CouriersRoutes.whyChooseUs} />
      ) : (
        <SEOMarker routeInfo={MedicalRoutes.whyChooseUs} />
      )}

      <WhyChooseUsTitleMobile className="whyChooseUsTitleMobile">
        {courierLanding ? (
          <div className="whyChooseUsTitleMobileText">
            Even More Reasons
            <br />
            To Choose Us
          </div>
        ) : (
          <div className="whyChooseUsTitleMobileText">
            Why <BreakLine />
            Choose&nbsp;Us?
          </div>
        )}
      </WhyChooseUsTitleMobile>

      <WhyChooseUsBoxesContainer>
        <WhyChooseUsBoxWrapperMobile>
          <WhyChooseUsImageMobile
            courierLanding={courierLanding}
            className="tubeMobile whyChooseUsImageMobile"
            alt="tube"
            src={courierLanding ? ClockRed : TubesMint}
            loading="lazy"
          />
          <WhyChooseUsBoxBackgroundMobile className="whyChooseUsBoxMobile">
            <SVGatorBackground courierLanding={courierLanding} />
            {courierLanding ? (
              <WhyChooseUsBoxTitle className="whyChooseUsBoxTitleMobile">
                Couriers
                <BreakLineMobileTitle /> Availability 24/7
              </WhyChooseUsBoxTitle>
            ) : (
              <WhyChooseUsBoxTitle className="whyChooseUsBoxTitleMobile">Medical Experts</WhyChooseUsBoxTitle>
            )}
            <WhyChooseUsBoxContent className="whyChooseUsBoxContentMobile">
              {courierLanding
                ? "Our couriers are available 24/7, 365 days a year. Choose a time, and we’ll be there!"
                : "Get tested by our team of fully trained medical professionals: Medics, Nurses & Phlebotomists."}
            </WhyChooseUsBoxContent>
          </WhyChooseUsBoxBackgroundMobile>
        </WhyChooseUsBoxWrapperMobile>
        <WhyChooseUsBoxWrapperMobile>
          <WhyChooseUsImageMobile
            courierLanding={courierLanding}
            className="clockMobile whyChooseUsImageMobile"
            alt="clock"
            src={courierLanding ? CrownRed : ClockMint}
            loading="lazy"
          />
          <WhyChooseUsBoxBackgroundMobile className="whyChooseUsBoxMobile">
            <SVGatorBackground courierLanding={courierLanding} />
            {courierLanding ? (
              <WhyChooseUsBoxTitle className="whyChooseUsBoxTitleMobile">
                Excellent
                <BreakLineMobileTitle /> Customer Service
              </WhyChooseUsBoxTitle>
            ) : (
              <WhyChooseUsBoxTitle className="whyChooseUsBoxTitleMobile">Available 24/7</WhyChooseUsBoxTitle>
            )}
            <WhyChooseUsBoxContent className="whyChooseUsBoxContentMobile">
              {courierLanding
                ? "Our team is available on Whatsapp - no more being on hold with call centers or speaking to a chatbot!"
                : "Choose the time, and we’ll be there. We are available 24/7 even on Bank Holidays."}
            </WhyChooseUsBoxContent>
          </WhyChooseUsBoxBackgroundMobile>
        </WhyChooseUsBoxWrapperMobile>
        <WhyChooseUsBoxWrapperMobile>
          <WhyChooseUsImageMobile
            courierLanding={courierLanding}
            className="crownMobile whyChooseUsImageMobile"
            alt="crown"
            src={courierLanding ? TubesRed : CrownMint}
            loading="lazy"
          />
          <WhyChooseUsBoxBackgroundMobile className="whyChooseUsBoxMobile">
            <SVGatorBackground courierLanding={courierLanding} />
            {courierLanding ? (
              <WhyChooseUsBoxTitle className="whyChooseUsBoxTitleMobile">High Quality Service</WhyChooseUsBoxTitle>
            ) : (
              <WhyChooseUsBoxTitle className="whyChooseUsBoxTitleMobile">Tailored Service</WhyChooseUsBoxTitle>
            )}
            <WhyChooseUsBoxContent className="whyChooseUsBoxContentMobile">
              {courierLanding
                ? "We are UKAS Accredited - our services have been rigorously tested against national & international standards."
                : "Any special request? A team away day, a sports event, or a business conference. Get in touch for more details."}
            </WhyChooseUsBoxContent>
          </WhyChooseUsBoxBackgroundMobile>
        </WhyChooseUsBoxWrapperMobile>
        {courierLanding ? (
          <div className="whyChooseUsButtonMobile" style={{ margin: "20px auto 0", width: 160 }}>
            <FlowingButton text="Get a quote" />
          </div>
        ) : (
          <a
            className="whyChooseUsButtonMobile"
            style={{ margin: "20px auto 0", width: 160 }}
            href={urls.MEDICAL_LANDING_URL + "get-in-touch"}
          >
            <QuoteButton />
          </a>
        )}
      </WhyChooseUsBoxesContainer>
      <BouncedShapeWithTransparency
        trigger="whyChooseUsMobile"
        topShapeBackground="transparent"
        bottomShapeBackground="#181A1B"
      />
    </WhyChooseUsSection>
  );
};

export default WhyChooseUsMobile;
