import styled, { css } from "styled-components";
import { media } from "../../../shared/styles/media";

type CounterWrapperProps = {
  inverted?: boolean;
  additionalMarginLeft?: boolean;
};

export const CounterWrapper = styled.div<CounterWrapperProps>`
  display: flex;
  box-sizing: border-box;
  ${p =>
    p.inverted
      ? css`
          flex-direction: row-reverse;
          margin-top: -30px;
          @media all and (max-height: 700px) {
            margin-top: -60px;
          }
          ${media.down("TABLET")`
          @media all and (min-height: 900px) {
    > :first-child {
      margin-top: 30px;
    }
          `};

          ${media.down("MOBILE_LARGE")`
          margin-top: -64px !important;
          > :first-child {
            transform: rotate(180deg) !important;
          }
          > :last-child {
            justify-content: flex-end;

            ${media.down("MOBILE_SMALL")`
              margin-bottom:40px;
            `}
          }
        `}
          ${media.down("MOBILE")`
        margin-top: -145px !important;

          `}
        `
      : css`
          > :first-child {
            transform: rotate(0deg) !important;
          }
        `}
`;

export const CounterTextWrapper = styled.div<CounterWrapperProps>`
  display: flex;
  flex-direction: column;

  ${p => p.inverted && "align-items: flex-end;"}

  > h3 {
    margin: 0;
    font-weight: 600;
    font-size: 96px;
    line-height: 104%;
    letter-spacing: -0.02em;
    color: #0e1b1b;
    white-space: nowrap;
    ${p =>
      p.inverted &&
      css`
        text-align: right;
      `}

    ${media.down("LAPTOP")`
      font-size: 84px;
    `}

    ${media.down("TABLET_SMALL")`
      font-size: 64px;
    `}

    ${media.down("MOBILE")`
      font-size: 56px;
    `}


    ${media.down("MOBILE_SMALL")`
      font-size: 48px;
    `}
  }

  > span {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    color: #0e1b1b;
    max-width: 209px;

    ${p =>
      p.inverted &&
      css`
        text-align: right;
      `}

    ${p =>
      p.additionalMarginLeft &&
      css`
        margin-left: 40px;
        ${media.down("TABLET_SMALL")`
          margin-left: 30px;
        `}

        ${media.down("MOBILE_SMALL")`
          margin-left: 28px;
        `}
      `}

    ${media.down("LAPTOP")`
      max-width: 309px;
    `}
    ${media.down("TABLET_SMALL")`
      font-size: 20px;
    `}

    ${media.down("MOBILE_LARGE")`
          max-width: 140px;
    `}

    ${media.down("MOBILE")`
      font-size: 18px;
    `}

    ${media.down("MOBILE_SMALL")`
      font-size: 16px;
    `}
  }
`;
