import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { CompanyRoutes } from "../../../config/routes";
import SEOMarker from "../../shared/components/SEO/SEOMarker";
import ProductCard from "../TitleSection/ProductCard/ProductCard";
import { ProductCardsContainer } from "../TitleSection/TitleSection.components";
import { ProdSectionContainer } from "./ProductsSection.components";
import { isMacOs, isSafari, isIOS } from "react-device-detect";
import urls from "../../../config/urls";

const ProductsSection = () => {
  const [inViewRef, inView] = useInView();

  useEffect(() => {
    // reload on safari and its derivatives because of svgator animations
    // becoming transparent after navigating back
    if (isIOS || (isMacOs && isSafari)) {
      window.addEventListener(
        "pageshow",
        function (evt) {
          if (evt.persisted) {
            setTimeout(function () {
              window.location.reload();
            }, 10);
          }
        },
        false
      );
    }
  }, []);

  return (
    <ProdSectionContainer ref={inViewRef}>
      <ProductCardsContainer className="products">
        <SEOMarker routeInfo={CompanyRoutes.products} offset={-340} />
        <ProductCard
          title="medical couriers"
          description="Our GMP trained couriers deliver only medical parcels for a secure and damage-free service."
          footer="No mistakes, No delays, No more lost packages."
          isInView={inView}
          goTo={urls.COURIERS_LANDING_URL}
          isCouriersService
          gaEvent={{ category: "services", action: "medical-couriers-click" }}
        />
        <ProductCard
          title="medical services"
          description="Our team of medical specialists will come wherever you are to take swabs or perform tests. No need to travel!"
          isInView={inView}
          goTo={urls.MEDICAL_LANDING_URL}
          gaEvent={{ category: "services", action: "medical-services-click" }}
        />
      </ProductCardsContainer>
    </ProdSectionContainer>
  );
};

export default ProductsSection;
