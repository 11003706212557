//@ts-nocheck
import React, { useRef, useState, useEffect, FunctionComponent } from "react";
import * as THREE from "three";
import { useLoader, useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { useIsMobileLargeDown } from "../../../common/hooks/useMedia";
import ScrollTrigger from "../../../common/ScrollTrigger";
import pointsData from "./points.json";
import virusPointsData from "./virusPoints.json";

import VirusTexture from "./VirusTexture.jpg";
import BloodTexture2 from "./BloodCell - Texture2.jpg";

const tempObject = new THREE.Object3D();

// const getRandomNumber = (min: number, max: number) => Math.random() * (max - min) + min;
interface Props {
  isInView: boolean;
  isMedical?: boolean;
}
const CellsMesh: FunctionComponent<Props> = ({ isInView, isMedical }) => {
  const [points, setPoints] = useState([]);
  // const [progress, setProgress] = useState(0);
  const globalProgress = useRef(0);
  const meshRef = useRef();
  const isMobile = useIsMobileLargeDown();
  const { nodes } = useGLTF(
    isMedical ? "/VirusTest2.glb" : "/BloodCellNew.glb",
    "https://www.gstatic.com/draco/versioned/decoders/1.4.3/"
  );
  const [texture] = isMedical
    ? useLoader(THREE.TextureLoader, [VirusTexture])
    : useLoader(THREE.TextureLoader, [BloodTexture2]);
  //test texture performance
  // const [texture] = useLoader(THREE.TextureLoader, [BloodTexture2]);
  texture.flipY = false;

  useEffect(() => {
    // Uncomment to generate new points
    // const temp = [];
    // for (let i = -16; i < 16; i += 0.2) {
    //   temp.push({
    //     x: i < 0 ? i : i * 4,
    //     y: 0.6 * Math.sin(1.5 * i),
    //     z: getRandomNumber(-1, 1),
    //     rotX: getRandomNumber(0, 360),
    //     rotY: getRandomNumber(0, 360),
    //     rotZ: getRandomNumber(0, 360),
    //   });
    //   console.log(temp);
    //   setPoints(temp);
    // }
    setPoints(isMedical ? virusPointsData : pointsData);

    ScrollTrigger.create({
      trigger: ".in-numbers",
      start: "top top",
      end: "bottom-=200px top",
      onUpdate: self => {
        globalProgress.current = self.progress;
      },
    });
  }, []);

  useFrame(() => {
    const progress = globalProgress.current;
    if (isInView) {
      let id = 0;
      for (const point of points) {
        tempObject.position.set(point.x - progress, point.y + progress / 2, point.z + Math.sin(progress) - 0.5);
        tempObject.rotation.set(point.rotX, point.rotY + progress * 2, point.rotZ);
        tempObject.scale.setScalar(isMedical ? 7.5 : 0.3);

        tempObject.updateMatrix();
        meshRef.current.setMatrixAt(id, tempObject.matrix);
        id++;
      }
      meshRef.current.instanceMatrix.needsUpdate = true;
    }
  });

  return isInView ? (
    <instancedMesh
      ref={meshRef}
      args={[isMedical ? nodes.Icosphere004.geometry : nodes.Torus.geometry, null, !isMobile ? 160 : 80]}
      position={[!isMobile ? 6 : 5.5, !isMobile ? -0.5 : 1.6, !isMobile ? -3 : -3]}
      rotation={[!isMobile ? 0 : 0.6, 0.5, 0]}
    >
      <meshStandardMaterial attach="material" map={texture} />
    </instancedMesh>
  ) : (
    <group />
  );
};

export default CellsMesh;
