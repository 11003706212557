import styled, { keyframes } from "styled-components";
import { BGTop } from "../../CompanyLanding/GoGreen/GoGreen.components";
import { media } from "../../shared/styles/media";
import BackgroundImage from "./backgrounds/KirstyBackground.jpg";
import BackgroundImageBlur from "./backgrounds/KirstyBlurWeb.jpg";
import BackgroundImgTablet from "./backgrounds/KirstyPhotoTablet.jpg";
import BackgroundImageBlurTablet from "./backgrounds/KirstyTabletBlur.jpg";
import Quote from "./images/KirstyQuoteAll.svg";
import QuoteCourier from "./images//KirstyQuoteRed.svg";

export const ClientsStoryContainer = styled.section`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  background: linear-gradient(219.68deg, #f9fbfb 13.42%, #e9eded 137.94%);
`;

export const Background = styled.div`
  background-image: url(${BackgroundImage});
  display: flex;
  width: 100%;
  height: 1000px;
  background-position: center;
  background-position-y: 18%;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: center;
  padding: 150px 250px;
  position: relative;

  @media all and (max-height: 800px) {
    height: 800px;
  }

  @media all and (max-height: 680px) {
    height: 650px;
  }

  @media all and (min-height: 1200px) {
    height: 1500px;
  }

  ${media.down("NEW_LARGE_WEB")`
    padding: 150px 200px;
  `}

  ${media.down("NEW_MAIN_WEB")`
    padding: 150px 200px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  background-image: url(${BackgroundImgTablet});
  padding: 150px 144px;
  `}

  ${media.down("NEW_HALF_WEB")`
  padding: 150px 96px;
  `}

  ${media.down("NEW_TABLET")`
  min-height: 600px;
  background-position-y: 14%;
  padding: 150px 80px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  padding: 150px 64px;
  `}

  ${media.down("NEW_MOBILE")`
  padding: 150px 48px;
  box-sizing: border-box;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  padding: 150px 24px;
  `}
`;

export const BlurredBackground = styled(Background)`
  position: absolute;
  background-image: url(${BackgroundImageBlur});

  ${media.down("NEW_SMALL_WEB")`
    background-image: url(${BackgroundImageBlurTablet});
  `}
`;

export const ClientsStoryInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${media.up("FULL")`
  max-width: 1200px;
  margin: 0 auto;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
    padding: 0 16px;
  `}
`;

export const ClientsStoryHeading = styled.h1`
  max-width: 384px;
  margin: 0;
  font-family: Barlow Semi Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 84px;
  line-height: 104%;
  letter-spacing: -0.03em;
  color: #f0f5f5;

  ${media.up("NEW_LARGE_WEB")`
  font-size: 92px;
  margin-bottom: 24px;
  `}

  ${media.down("NEW_MAIN_WEB")`
  font-size: 84px;
  margin-bottom: 16px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  font-size: 72px;
  `}

  ${media.down("NEW_HALF_WEB")`
  font-size: 64px;
  `}

  ${media.down("NEW_TABLET")`
  font-size: 56px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  font-size: 48px;
  width: 192px;
  margin-bottom: 8px;
  `}

  ${media.down("NEW_MOBILE")`
  font-size: 44px;
  max-width: 192px;

  `}

  ${media.down("NEW_SMALL_MOBILE")`
  font-size: 40px;
  max-width: 168px;

  `}
`;

export const ClientsStoryDescription = styled.h3`
  margin: 0;
  max-width: 288px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 145%;
  color: #f0f5f5;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.2);

  ${media.up("NEW_LARGE_WEB")`
  max-width: 350px;
  font-size: 24px;
  `}

  ${media.down("NEW_MAIN_WEB")`
  font-size: 20px;
  `}

  ${media.down("NEW_HALF_WEB")`
  font-size: 18px;
  `}
 
  ${media.down("NEW_LARGE_MOBILE")`
  font-weight: 300;
  `}

  ${media.down("NEW_MOBILE")`
  font-size: 16px;
  `}
`;

export const ClientsStoryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 385px;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 145%;
  color: #f0f5f5;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.2);
  margin-top: 180px;

  ${media.down("NEW_LARGE_WEB")`
  margin-top: 180px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  margin-top: 160px;
  `}

  ${media.down("NEW_HALF_WEB")`
  margin-top: 140px;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  margin-top: auto;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 50px;
  `}

  ${media.down("NEW_MOBILE")`
  font-size: 16px;
  `}

  ${media.down("NEW_SMALL_MOBILE")`
  margin-bottom: -16px;
  `}
`;

const spin = keyframes`
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const PlayButtonContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-top: -30px;
  margin-right: -40px;
  width: 168px;
  height: 168px;
  -webkit-animation: ${spin} 2.5s infinite ease-in-out;
  animation: ${spin} 2.5s infinite ease-in-out;

  ${media.down("NEW_HALF_WEB")`
  margin: 30px auto;
  `}

  ${media.down("NEW_LARGE_MOBILE")`
  margin: 0 auto;
  width: 136px;
  height: 136px;
  `}
`;

export const QuoteBoxWrapper = styled.div<{ courierLanding?: boolean }>`
  background-image: url(${p => (p.courierLanding ? QuoteCourier : Quote)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 378px;
  height: 245px;
  position: absolute;
  right: 157px;
  bottom: 150px;

  ${media.up("NEW_LARGE_WEB")`
  width: 378px;
  height: 245px;
  right: 300px;
  `}

  ${media.down("NEW_SMALL_WEB")`
  width: 320px;
  height: 202px;
  right: 123px;

  `}

  ${media.down("NEW_HALF_WEB")`
  right: 50px;
  `}

  ${media.down("NEW_TABLET")`
  width: 265px;
  height: 166px;
  right: 65px;
  `}


  ${media.down("NEW_LARGE_MOBILE")`
    display: none;;
  `}
`;

export const BackgroundTopElement = styled(BGTop)`
  height: 76.5px;
`;
