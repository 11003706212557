import gsap from "gsap";
import ScrollTrigger from "../../../common/ScrollTrigger";

const ourStoryEnterAnimation = () => {
  gsap.to(".ourStoryNext", { autoAlpha: 0, duration: 0 });
  gsap.to(".ourStoryLeftContentPage3", { autoAlpha: 0, duration: 0 });
  gsap.to(".ourStoryLeftContentPage1Mobile", { autoAlpha: 0, duration: 0 });
  const ourStoryTimeline = gsap
    .timeline({ paused: true })
    .from(".ourStoryTitle", {
      x: window.innerWidth < 900 ? 0 : window.innerWidth / 9,
      y: window.innerWidth < 900 ? -60 : 100,
      scale: window.innerWidth < 900 ? 1 : 1.3,
      duration: 0.9,
    })
    .from(".svg-title", { fill: "rgb(209, 220, 220, 0.16)", duration: 0.5 })
    .from(".alexAndTomaszPhoto", { yPercent: 100, autoAlpha: 0, duration: 0.5 }, 0.5)
    .from(".ourStoryFullDescription", { yPercent: 100, autoAlpha: 0, duration: 0.5 }, 1)

    .from(".ourStoryDescription", { x: -200, autoAlpha: 0, rotate: 15, stagger: 0.015 }, 1);

  if (window.innerWidth < 900) {
    ourStoryTimeline.from(".ourStoryBadgeMobile", { autoAlpha: 0 }, 1);
  } else {
    ourStoryTimeline.from(".ourStoryFooter", { autoAlpha: 0, y: 50, duration: 1 }, 2);
  }
  // Trigger for text animation
  const trigger = ScrollTrigger.create({
    trigger: ".ourStoryContainer",
    start: "top center",
    end: "bottom center",
    onEnter: () => {
      ourStoryTimeline.play();
    },
  });
  ourStoryTimeline.add(() => trigger.kill());
};

const changeToPage1Mobile = () => {
  gsap.to(".ourStoryLeftContentPage1Mobile", { autoAlpha: 1, duration: 0 });
  gsap.to(".ourStoryLeftContentPage2", { autoAlpha: 0, duration: 0 });
  gsap.to(".ourStoryLeftContentPage3", { autoAlpha: 0, duration: 0 });
  gsap.timeline().to(".ourStoryFirst", { autoAlpha: 0, duration: 0.5 }).to(".ourStoryNext", { autoAlpha: 1 });
  gsap
    .timeline()
    .to(".photoLab", { autoAlpha: 0, duration: 0 })
    .from(".photoCourier", { autoAlpha: 0, scale: 0, duration: 0.5 })
    .to(".photoCourier", { autoAlpha: 1 });
};

const changeToPage2 = () => {
  gsap
    .timeline()
    .to(".ourStoryFirst", { autoAlpha: 0, duration: 0.5 })
    .to(".ourStoryNext", { autoAlpha: 1 })
    .to(".ourStoryLeftContentPage2", { autoAlpha: 1 });

  //.from(".ourStoryLeftContentPage2", {autoAlpha: 0, duration: 0.5});
  gsap
    .timeline()
    .to(".photoLab", { autoAlpha: 0, duration: 0 })
    .from(".photoCourier", { autoAlpha: 0, scale: 0, duration: 0.5 })
    .to(".photoCourier", { autoAlpha: 1 });
  gsap.to(".ourStoryBadge", { right: -50, top: -110, autoAlpha: 1, duration: 1 });
};

const changeToPage2From1Mobile = () => {
  gsap
    .timeline()
    .to(".ourStoryLeftContentPage2", { autoAlpha: 1, duration: 0 })
    .to(".ourStoryLeftContentPage1Mobile", { opacity: 0, duration: 0.5 });
};

const changeToPage2From3 = () => {
  gsap
    .timeline()
    .to(".ourStoryLeftContentPage3", { autoAlpha: 0, duration: 0 })
    .to(".ourStoryLeftContentPage2", { autoAlpha: 1, duration: 0.2 });
  gsap.timeline().to(".photoLab", { autoAlpha: 0, duration: 1 });
};

const changeToPage1MobileFrom2 = () => {
  gsap.to(".ourStoryLeftContentPage3", { autoAlpha: 0, duration: 0 });
  gsap.to(".ourStoryLeftContentPage2", { autoAlpha: 0, duration: 0 });
  gsap.timeline().to(".ourStoryLeftContentPage1Mobile", { autoAlpha: 1 });

  gsap.timeline().to(".photoLab", { autoAlpha: 0, duration: 1 });
};

const changeToPage1From2 = () => {
  gsap.timeline().to(".ourStoryLeftContentPage1Mobile", { autoAlpha: 0 });
  gsap.timeline().to(".ourStoryNext", { autoAlpha: 0, duration: 0.5 }).to(".ourStoryFirst", { autoAlpha: 1 });
  gsap.timeline().to(".ourStoryBadge", { right: "45%", top: window.innerWidth > 1100 ? -180 : -100 });
};

const changeToPage3 = () => {
  gsap.timeline().to(".photoLab", { autoAlpha: 1, duration: 1 });
  gsap
    .timeline()
    .to(".ourStoryLeftContentPage3", { autoAlpha: 1, duration: 0 })
    .to(".ourStoryLeftContentPage2", { opacity: 0, duration: 0.5 });
  gsap.to(".ourStoryBadge", { right: -50, autoAlpha: 1, duration: 1 });
};

export {
  ourStoryEnterAnimation,
  changeToPage2,
  changeToPage3,
  changeToPage2From3,
  changeToPage1From2,
  changeToPage1MobileFrom2,
  changeToPage1Mobile,
  changeToPage2From1Mobile,
};
