import React, { FunctionComponent, useEffect } from "react";
import { CookiesBoxButton, CookiesBoxWrapper } from "./CookiesBox.components";
import { ReactComponent as CookiesIcon } from "./CookiesIcon.svg";
import gsap from "gsap";
import { useIsSmallTabletDown } from "../../../../common/hooks/useMedia";
import useCookiesAccepted, { COOKIES_ACCEPTED_KEY } from "./useCookiesAccepted";
import urls from "../../../../config/urls";

const CookiesBox: FunctionComponent<{ couriersLanding?: boolean }> = ({ couriersLanding }) => {
  const isTabletSmallDown = useIsSmallTabletDown();
  const cookiesAccepted = useCookiesAccepted();
  useEffect(() => {
    if (!cookiesAccepted) gsap.from(".cookiesBoxWrapper", { yPercent: 150, duration: 1, delay: 2 });
  }, [cookiesAccepted]);

  const acceptCookiePolicy = () => {
    document.cookie = `${COOKIES_ACCEPTED_KEY}=true; domain=medicallogistics.co.uk; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    gsap.to(".cookiesBoxWrapper", { yPercent: 150, duration: 1, delay: 0.2 });
  };

  return !cookiesAccepted ? (
    <CookiesBoxWrapper couriersLanding={couriersLanding} className="cookiesBoxWrapper">
      <CookiesIcon />
      <span>
        {!isTabletSmallDown && <>We use cookies.</>} By using our website you agree to use of our cookies.{" "}
        <a href={`${urls.COMPANY_LANDING_URL}privacy-and-cookies-policy`}>Read cookies policies.</a>
      </span>
      <CookiesBoxButton onClick={acceptCookiePolicy}>Accept</CookiesBoxButton>
    </CookiesBoxWrapper>
  ) : (
    <></>
  );
};

export default CookiesBox;
