import React, { FunctionComponent } from "react";
import { RouteInfo } from "../../../../config/routes";

type SEOMarkerProps = {
  routeInfo: RouteInfo;
  offset?: number;
};

const MARKER_DEFAULT_OFFSET = -200;

const SEOMarker: FunctionComponent<SEOMarkerProps> = ({ routeInfo, offset }) => {
  if (offset === undefined) offset = MARKER_DEFAULT_OFFSET;

  return (
    <span style={{ position: "relative" }}>
      <span
        style={{
          position: "absolute",
          top: `${offset}px`,
        }}
        className={`seoMarker ${routeInfo.className}`}
      ></span>
    </span>
  );
};

export default SEOMarker;
