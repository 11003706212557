import React from "react";
import { PrivacyAndTermsWrapper, PrivacyContent, PrivacyHeader } from "./PrivacyAndTerms.components";

const Terms = () => {
  return (
    <PrivacyAndTermsWrapper>
      <PrivacyHeader>Terms & Conditions</PrivacyHeader>
      <PrivacyContent>
        Please read these terms carefully. By engaging in works with Med Logistics Group Limited in any way, you agree
        to be bound by these terms and every transaction between the Carrier and the Client will be governed by their
        Terms and Conditions of Trade unless otherwise agreed in writing by the Carrier before the commencement of any
        such transactions.
      </PrivacyContent>
      <PrivacyContent>
        Services performed by the Carrier for the Client will be charged for on the basis and at the prices quoted on
        the Carrier’s Website current at the time such services are performed unless special contract rates have been
        agreed between the parties. The services performed by the Carrier at the rates agreed will be subject to a
        surcharge when requested or carried out outside normal working hours. Normal working hours will be taken as
        08:00 am to 18:00 pm, Monday to Friday unless a special agreement has been entered into between the parties in
        writing.
      </PrivacyContent>
      <PrivacyContent>
        The client will be liable for all additional charges, reasonably incurred by the Carrier, in the performance of
        the deliveries on the client’s behalf. Additional charges may be incurred where a client request a collection
        from a remote address as opposed to the clients account address. Additional charges may also be incurred as a
        result of Bridge & tunnel charges, congestion charges, parking charges and similar when incurred in the course
        of the collection or delivery.
      </PrivacyContent>
      <PrivacyContent>
        At the time the Client orders any such services the Carrier will record the necessary details of collection and
        delivery. The date and time of the order will be displayed on the subsequent invoice with any order or special
        reference stated by the Client. Exact details of addresses will be available on request at a later date up to
        three months after the completion of delivery. Signature or equivalent proof of delivery will be obtained from
        the consignee unless alternative arrangements are made with the Client at the time of booking or because of
        prevailing circumstances at the point of delivery. As all orders are communicated by the Client to the Carrier
        by telephone or web access, the quotation of the Client’s account number and/or the use of his logon and
        password and references will be accepted by the Client as proof that the order was made by him.
      </PrivacyContent>
      <PrivacyContent>
        An invoice for services rendered will be issued at month end (or at more frequent intervals on request).
      </PrivacyContent>
      <PrivacyContent>
        Statements of Account will be issued by the Carrier monthly on request. Any query as to the correctness of
        stated charges, payments, or the calculation thereof must be made within fourteen (14) days of the receipt of
        invoice which will be sent electronically to the e-mail address requested by the client. All invoices shall be
        settled within 30 days from invoice date. The carrier reserves the right to apply a monthly interest surcharge
        to all overdue account calculated at 8% over the Bank of England base rate in accordance with the Late Payment
        of Commercial Debts (interest) Act 1998 Regulations 2002 until the debt is settled in full.
      </PrivacyContent>
      <PrivacyContent>
        The above Terms and Conditions of Trade refer only to the conduct of Credit Accounts and the documentation
        arising from transactions on such Accounts. They shall not be construed to alter in any way the effects of the
        Standard Conditions of Carriage that appear below.
      </PrivacyContent>
      <PrivacyHeader>Standard Conditions of Carriage</PrivacyHeader>
      <PrivacyContent>
        Med Logistics Group Limited accepts goods for carriage subject to the conditions (hereinafter referred to as
        “the Conditions”) set out below. No agent or employee of the Carrier is permitted to alter or vary these
        conditions in any way unless he is expressly authorised to do so. Once a contract is in operation any deviation
        or special arrangements requested by the client shall be expressly documented and agreed in writing between the
        client and a Director of the carrier’s company within 21 days of the deviation having been agreed. No verbal
        agreement or hearsay will be considered as binding or part of the contract.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>1. Definitions</PrivacyHeader>
      <PrivacyContent>
        In these Conditions the following expressions shall have the meanings hereby respectively assigned to them, that
        is to say “Client” shall mean the Customer who contracts the services of the Carrier.
      </PrivacyContent>
      <PrivacyContent>
        “Consignment” shall mean goods in bulk or contained in one parcel or package, as the case may be, or any number
        of separate parcels or packages sent at one time in one load by or for the Client from one address to one
        address.
      </PrivacyContent>
      <PrivacyContent>
        “Dangerous Goods” shall mean (i) goods, which are specified in special classification of dangerous goods issued
        by British Railways Board and the International Air Transport Association, or which, although not specified
        therein, are not acceptable to British Railways Board or the International Air Transport Association for
        conveyance on the grounds of their dangerous or hazardous nature, or (ii) goods’ which although not indicated in
        (i) above are of a kindred nature.
      </PrivacyContent>
      <PrivacyContent>
        “Contract” shall mean the contract of carriage between the Client and the Carrier.
      </PrivacyContent>
      <PrivacyContent>
        “Subcontracting parties” include all persons (other than the Carrier and the Client) referred to in Clause 3(3).
      </PrivacyContent>
      <PrivacyContent>
        “Carrier” save in the expression of Carrier/Contractor includes subcontracting parties in Clauses 4(2), 5(2) and
        (3) and 11 (proviso).
      </PrivacyContent>
      <PrivacyContent>“Carrier/Contractor” means the Carrier and any other carrier within Clause 3(2).</PrivacyContent>
      <PrivacyContent>
        “The Shipper” means the person who contracts either as principal or as agent for the services of Med Logistics
        Group Limited both on his behalf and on behalf of any other person having interest in the Consignment.
      </PrivacyContent>
      <PrivacyContent>(c) “The excepted risks” mean:-</PrivacyContent>
      <PrivacyContent>
        i) War, invasion, act of foreign enemy, hostilities (whether war is declared or not), civil war, rebellion,
        revolution, insurrection or military or usurped power, or loot, sack or pillage in connection therewith, and/or
        ii) Ionising radiations or contaminations by radioactivity from any nuclear fuel or from any nuclear waste from
        the combustion of nuclear fuel, and/or
      </PrivacyContent>
      <PrivacyContent>
        iii) Radioactive, toxic, explosive or other hazardous properties of any explosive nuclear component thereof,
        and/or
      </PrivacyContent>
      <PrivacyContent>
        iv) Pressure waves caused by aircraft and other aerial devices travelling at sonic or supersonic speeds, and/or
      </PrivacyContent>
      <PrivacyContent>v) The absence, failure or inadequacy of packing and packaging</PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>2. Carrier is not a Common Carrier</PrivacyHeader>
      <PrivacyContent>
        The Carrier is not a common carrier and will accept goods for carriage only on the conditions set out in this
        document.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>3. Parties and Subcontract</PrivacyHeader>
      <PrivacyContent>
        (1) Where the Client is not the owner of some or all of the goods in any consignment, he shall be deemed for all
        purposes to be the agent of the owner or owners.
      </PrivacyContent>
      <PrivacyContent>
        (2) The Carrier enters into the Contract for and on behalf of himself and servants, agents and subcontractors
        and his subcontractors’ servants, agents and subcontractors, all of whom shall be entitled to the benefit of the
        Contract and shall be under no liability whatsoever to the Client or anyone claiming through him in respect of
        the goods in addition to or separate from that of the Carrier under the Contract.
      </PrivacyContent>
      <PrivacyContent>
        (3) The Client shall save harmless and keep the Carrier indemnified against all claims and demand whatsoever by
        whomsoever made in excess of the liability of the Carrier under these Conditions in respect of any loss, damage
        or injury, except if caused by the negligence of the Carrier, his servants, agents or subcontractors.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>4. Dangerous Goods</PrivacyHeader>
      <PrivacyContent>
        (1) If the Carrier agrees to accept dangerous goods for carriage such goods must be accompanied by a full
        declaration of their nature and contents and be properly and safely packed in accordance with any statutory
        regulations for the time being in force for transport by road or air.
      </PrivacyContent>
      <PrivacyContent>
        (2) The client shall indemnify the Carrier against all loss, damage or injury, however caused arising out of any
        dangerous goods whether declared as such or not.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>4b. Acceptable condition of goods</PrivacyHeader>
      <PrivacyContent>
        All goods must be suitably and safely packaged. The carrier accepts no liability for any damage howsoever caused
        in the event of this condition not being complied with.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}> 5. Loading or Unloading</PrivacyHeader>
      <PrivacyContent>
        (1) When collection or delivery takes place at the Client’s premises, the Carrier/Contractor shall not be under
        any obligations to provide any plant, power or labour which, in additions to the Carrier/Contractor’s driver or
        dispatch rider, is required for loading or unloading at such premises.
      </PrivacyContent>
      <PrivacyContent>
        2) Any assistance given by the Carrier beyond the usual place of collection or delivery shall be at the sole
        risk of the Client who will save harmless and keep the Carrier indemnified against any claim or demand, which
        could not have been made if such assistance had not been given.
      </PrivacyContent>
      <PrivacyContent>
        (3) Goods requiring special appliances for unloading from the vehicle are accepted only on condition that the
        sender had duly ascertained from the consignee that such appliances are available at the destination, Where the
        Carrier/Contractor is without prior arrangement in writing with the Client, called upon to load or unload such
        goods, the Carrier shall be under no liability whatsoever to the Client for any damage however caused, whether
        or not by the negligence of the Carrier and the Client shall save harmless and keep the Carrier indemnified
        against any claim or demand which could not have been made if such assistance had not been given.
      </PrivacyContent>
      <PrivacyContent>
        (4) The Carrier will allow up to 15 minutes for waiting when picking up or delivering a consignment. Thereafter,
        the Carrier will charge the Client for the total waiting time (including the first 15 minutes) at the operative
        rate. If the Carrier has to leave the collection premises and re-attend because the items for collection are not
        available or cannot be collected because of their size or contents then the charge for the booking is payable in
        full. Subsequent visits are chargeable in addition to the initial visit.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>6. Consignment Notes</PrivacyHeader>
      <PrivacyContent>
        The Carrier/Contractor shall, if so required, sign a document prepared by the sender acknowledging the receipt
        of the consignment, but no such document shall be evidence of the condition of the correctness of the declared
        nature, quantity or weight of the consignment at the time it is received by the Carrier/Contractor.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}> 7. Transit</PrivacyHeader>
      <PrivacyContent>
        (1) The maximum value of any one domestic consignment shall not exceed £250.00. Should the client wish to send a
        consignment of value in excess of this £250.00 he may do so but the liability of the carrier shall remain
        £250.00. Should the client wish to arrange increased insurance cover for a consignment of value in excess of
        £250.00, he must provide written details to the carrier of the value of the consignment and the cover he
        requires. The liability value for International consignments is £250. The Carrier will levy a charge for
        supplementary insurance.
      </PrivacyContent>
      <PrivacyContent>
        (2) Transit shall commence when the consignment is handed to the Carrier/Contractor whether at the point of
        collection or at the Carrier/Contractor’s premises.
      </PrivacyContent>
      <PrivacyContent>
        (3) Transit shall (unless otherwise previously determined) end when the consignment is tendered at the specified
        place of delivery or returned to the consignee’s address within the customary cartage hours of the district.
        Provided (i) that no safe and adequate access or no adequate loading facilities there exist, then transit shall
        be deemed to end at the expiry of one clear hour after notice by telephone of the arrival of the consignment at
        the Carrier/Contractor’s premises has been communicated to the consignee (or if the consignee is unavailable,
        the consignor): and (ii) that when for any other reason whatsoever a consignment is held by the
        Carrier/Contractor “to await order” or “to be kept till called for” or upon any like instructions and such
        instructions are not given or the consignment is not called for and removed within a reasonable time, then the
        transit shall be deemed to end.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>8. Proof of Delivery and Undeliverable consignments</PrivacyHeader>
      <PrivacyContent>
        Where the Carrier/Contractor is unable for whatsoever reason to deliver a consignment to the consignee he will
        take all reasonable steps to advise the client and obtain revised delivery instructions. The carrier will always
        seek to obtain a signature or other proof of delivery. In the absence of specific instructions to the contrary
        by the Client the Carrier will not leave consignments where they cannot obtain proof of delivery. Consignments
        may be returned to the Carriers premises if the Client cannot be contacted to obtain the necessary permission.
        Increased delivery charges may be incurred in this way and the consignment will be delayed.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>9. Contract Couriers</PrivacyHeader>
      <PrivacyContent>
        Where the client chooses to enter into a separate agreement for the provision of courier services with a
        messenger who has been introduced by Med Logisitcs Group Limited then the client will be liable for an
        introduction fee of one month’s sub-contracted employment of the aforementioned messenger through Courier
        Systems.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}> 10. Carrier’s Charges</PrivacyHeader>
      <PrivacyContent>
        (1) The Carrier’s charges for carriage shall be payable by the Client without prejudice to the Carrier’s rights
        against the consignee or any other person. Provided that when goods are consigned “carriage forward” the Client
        shall not be liable to pay such charges unless the consignee fails to pay after a reasonable demand has been
        made by the Carrier/Contractor for payment thereof./
      </PrivacyContent>
      <PrivacyContent></PrivacyContent>
      <PrivacyContent>
        (2) Except where the quotation states otherwise all quotation given based on a weight charge shall apply to the
        gross weight of the goods.
      </PrivacyContent>
      <PrivacyContent>
        (3) A claim or counterclaim shall not be made by reason for differing or withholding payment of monies payable,
        or liabilities incurred, to the Carrier.
      </PrivacyContent>
      <PrivacyContent>
        (4) Unless otherwise indicated, all charges are quoted exclusive of any Value Added Tax, import and any other
        duties or taxes which may be payable.
      </PrivacyContent>
      <PrivacyContent>
        (5) For charging purposes, the Carrier will charge the greater of the actual weight or the volumetric weight
        where applicable. Volumetric weight is calculated by multiplying the length x height x breadth and dividing the
        resulting figure by 5000cc for Courier and Airfreight, or 3000cc for Seafreight and Roadfreight.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>11. The Limit for Claims</PrivacyHeader>
      <PrivacyContent>
        (1) (i) for loss from a package or from an unpacked consignment: or (ii) for damage, deviation, mis-delivery,
        delay of detention, unless the client is advised thereof in writing (otherwise than on a consignment note or
        delivery document) within fourteen days and the claim be made in writing within 21 days of the commencement of
        the transit. 21 days of the problem having occurred giving full description of the incident including cost, the
        carrier, will not accept liability. Please refer to clause 13 “Limitation of Liability”
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}> 12. Liability for Loss and Damage</PrivacyHeader>
      <PrivacyContent>
        Subject to these Conditions the Carrier shall be liable for any loss, or mis-delivery of or damage to goods
        occasioned during transit unless the Carrier shall prove that such loss, mis-delivery or damage has arisen from
        (i) Act of God; (ii) any consequence of war, invasion, act of foreign enemy, hostilities (whether war be
        declared or not), civil war, rebellion, insurrection, military or usurped power of confiscation, requisition,
        destruction of, or damage to property by or under the order of any government or public or local authority;
        (iii) seizure under legal process; (iv) act or emission of the Client or owner of the goods or of the servants
        or agents of either; (v) inherent liability to wastage in bulk or weight, latent defect or inherent defect vice
        or natural deterioration of the goods; (vi) insufficient or improper packaging; (vii) insufficient or improper
        labelling or addressing; (viii) riots, civil commotion, lockouts, general or partial stoppage or restraint of
        labour from whatever cause; (ix) consignee not taking or accepting delivery within a reasonable time.
      </PrivacyContent>
      <PrivacyContent>
        The Carrier shall not incur liability of any kind in respect of a consignment where there has been fraud on the
        part of the Client or the owner of the goods or the servants or agent of either in respect of that consignment.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}> 13. Limitation of Liability </PrivacyHeader>
      <PrivacyContent>
        Subject to these Conditions the liability of the Carrier in respect of any one consignment shall in any case be
        limited.
      </PrivacyContent>
      <PrivacyContent>
        (1) Where the loss or damage however sustained is in respect of the whole of the consignment to a sum of
        £250.00. (Special insurance can be arranged immediately on request).
      </PrivacyContent>
      <PrivacyContent>
        (2) Motorcycles have secure panniers and artwork bags for the safe transit of goods. Where the client requests a
        bike to carry a consignment too large to be carried in the aforementioned containers the client does so at his
        own risk. (Sizes of carrying capacity are available on request.)
      </PrivacyContent>
      <PrivacyContent>
        (3) Where loss or damage however sustained is in respect of part of a consignment to the proportion of the sum
        ascertained in accordance with (1) of this condition which the actual value of the whole of the consignment.
      </PrivacyContent>
      <PrivacyContent>
        (4) The Carrier shall not in any case be liable for indirect or consequential damages or for loss of a
        particular market whether held daily or at intervals;
      </PrivacyContent>
      <PrivacyContent>
        (i) The Carrier shall be entitled to require proof of the value of the whole consignment; (ii) Any liability
        incurred hereunder shall be subject to Clause 7(1) hereof.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>14. Items excluded from liability </PrivacyHeader>
      <PrivacyContent>
        The carrier does not accept any liability for the following items when in transit unless special arrangements
        have been agreed in writing in advance between the carrier and the client : cash, currency, stamps, glass,
        jewellery, precious metals, works of art including antiques.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>15. General Lien</PrivacyHeader>
      <PrivacyContent>
        The Carrier shall have a general lien against the owner of any goods for any monies whatsoever due from such
        owner to the Carrier. If any lien is not satisfied within a reasonable time the Carrier may at his absolute
        discretion sell the goods as agent for the owner and apply the proceeds towards the monies due and the expenses
        of the sale, and shall upon accounting to the Client for the balance remaining, if any, be discharged from all
        liability whatsoever in respect of the goods.
      </PrivacyContent>
      <PrivacyHeader style={{ marginLeft: 25 }}>16. Counterclaims</PrivacyHeader>
      <PrivacyContent>
        If you claim or counterclaim against us it will not be the reason for deferring (putting off), or withholding
        payment or for refusing to repay any monies due under our credit terms.
      </PrivacyContent>
    </PrivacyAndTermsWrapper>
  );
};
export default Terms;
