import styled from "styled-components";

export const DrawingSVGOnScrollWrapper = styled.div<{ pathLength: number; dashOffset: number }>`
  svg {
    max-width: 100%;
    height: 100%;
  }
  path {
    stroke-dasharray: ${p => p.pathLength};
    stroke-dashoffset: ${p => p.dashOffset};
  }
`;
