import styled from "styled-components";
import { media } from "../../../shared/styles/media";

import { ServicesBaseContentWrapper, ServicesBaseHeader, ServicesBaseDescription } from "../Services.components";

export const TimeSectionOuterContainer = styled.div`
  display: flex;
`;

export const TimeSectionContainer = styled(ServicesBaseContentWrapper)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;
`;

export const TimeSectionHeader = styled(ServicesBaseHeader)`
  white-space: nowrap;

  ${media.down("NEW_TABLET")`
   white-space: unset;
   max-width: 350px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    max-width: unset;
    white-space: nowrap;
  `}

  ${media.down("MOBILE")`
   max-width: 250px;
   white-space: wrap;
  `}
`;

export const TimeSectionDescription = styled(ServicesBaseDescription)`
  max-width: 433px;
  ${media.up("NEW_LARGE_WEB")`
    max-width: 580px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    max-width: 350px;
    align-self: flex-start;
  `}

  > span {
    color: #268b84;
  }
`;

export const TimeInformationOuterWrapper = styled.div`
  margin-top: 52px;
  margin-left: 88px;
  position: relative;
  ${media.down("NEW_SMALL_WEB")`
    margin-left: 77px;
  `}
  ${media.down("NEW_HALF_WEB")`
    margin-left: 36px;
  `}
  ${media.down("NEW_TABLET")`
    margin-left: 0px;
  `}
`;

export const TimeInformationWrapper = styled.div`
  position: relative;
  display: flex;
  width: 350px;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 36px;

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    padding: 16px 32px;
    border-radius: 24px;
    z-index: 99999999999999999;
    background: linear-gradient(
      94.1deg,
      rgba(255, 255, 255, 0) 13.17%,
      rgba(255, 255, 255, 0.22) 42.44%,
      rgba(255, 255, 255, 0) 87.5%
      ),
    rgba(255, 255, 255, 0.2); ;
    margin-left: 50px;
  
    `}

  ${media.down("MOBILE")`
      width: 350px;
      margin-left: 40px;
    `}
    ${media.down("MOBILE_SMALL")`
      width: 300px;
    `}
    object {
    width: 32px;
    height: 32px;
  }
`;

export const TimeInformationHeading = styled.h5`
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: rgb(14, 27, 27);
  margin-top: 24px;

  ${media.up("NEW_LARGE_WEB")`
    font-size: 30px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    font-size: 18px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    font-size: 24px;
    margin-top: 10px;
  `}
`;

export const TimeInformationDescription = styled.p`
  margin: 0;
  margin-top: 8px;
  font-weight: 300;
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
  color: rgb(55, 67, 67);
  max-width: 275px;
  > span {
    color: #d30e29;
  }

  ${media.up("NEW_LARGE_WEB")`
    font-size: 24px;
    max-width: 360px;
  `}

  ${media.down("NEW_SMALL_WEB")`
    font-size: 16px;
  `}

  ${media.down("NEW_HALF_WEB")`
   max-width: 200px;
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    font-size: 18px;
    max-width: 260px;
    white-space: pre-line;
  `}

  ${media.down("NEW_MOBILE")`
    max-width: 220px;
  `}
`;

export const LineWrapper = styled.div`
  position: absolute;
  top: 11px;
  left: 31px;
  overflow: hidden;
  transform: translateY(-45%);
  width: 270px;

  ${media.down("NEW_SMALL_WEB")`
  left: 21px;
    > svg {
      width: 250px;
    }
  `}

  ${media.down("NEW_HALF_WEB")`
    top: 11px;
    > svg {
      width: 250px;
    }
  `}

  ${media.down("MEDICAL_SERVICES_LAYOUT_CHANGE")`
    top: 15px;
    > svg {
      width: 400px;
    }
  `}

  ${media.down("NEW_MOBILE")`
    top: 17px;
    left: 40px;
    width: 100%;
    > svg {
      width: 600px;
    }
  `}
`;
