import styled from "styled-components";
import { media } from "../../shared/styles/media";
import { UkasContainer } from "../Ukas/Ukas.components";

export const LaboratoriesSection = styled(UkasContainer)`
  background-color: rgba(234, 238, 238, 1);
  height: 170vh;
  overflow: hidden;
`;

export const LaboratoriesCarouselWrapper = styled.div`
  margin-top: 120px;
  width: 100vw;
  background: rgba(239, 233, 230, 0.2);
  /* margin-left: -200px; */
  padding: 30px 0;
  border-top: 1px solid #d8d5d5;
  border-bottom: 1px solid #d8d5d5;
  /* ${media.up("FULL")`
    margin-left: -360px;
  `}
  ${media.down("TABLET_LARGE")`
    margin-left: -144px;
  `}
  ${media.down("TABLET")`
    margin-left: -104px;
  `}
  ${media.down("TABLET_SMALL")`
    margin-left: -96px;
  `}
  ${media.down("MOBILE_LARGE")`
    margin-left: -48px;
    padding: 20px 0;
  `} */
  ${media.down("MOBILE")`
    /* margin-left: -75px; */
    margin-top: 70px;
    transform: rotate(-15deg);
    width: 120vw;
    padding: 14px 0;
    margin-right: 0 important;
  `}
  ${media.down("MOBILE_SMALL")`
    /* margin-left: -60px; */
  `}
  .slick-track {
    display: flex;
    align-items: center;
    transition-timing-function: linear !important;
  }
  .slick-slide {
    margin-right: 64px;
    ${media.down("TABLET")`
    
      margin-right: 48px;
    `}
  }
`;

export const LaboratoriesCarouselElements = styled.img`
  max-height: 90px;
  max-height: 70px;
`;
