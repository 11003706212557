import Blood from "./images/Blood.png";
import BloodSamples from "./images/BloodSamples.png";
import Medications from "./images/Medications.png";
import Swabs from "./images/Swabs.png";
import Tissues from "./images/Tissues.png";
import XRays from "./images/XRays.png";

const sliderElements = [
  {
    caption: "Tissues",
    image: Tissues,
  },
  {
    caption: "Swabs",
    image: Swabs,
  },
  {
    caption: "Blood",
    image: Blood,
  },
  {
    caption: "Blood samples",
    image: BloodSamples,
  },
  {
    caption: "X-rays",
    image: XRays,
  },
  {
    caption: "Medications",
    image: Medications,
  },
];

export default sliderElements;
