import React, { FunctionComponent } from "react";
import { BrowserRouter } from "react-router-dom";
import { config, Landing } from "../../../config";

import CompanyLandingApp from "../../../modules/CompanyLanding/CompanyLandingApp/CompanyLandingApp";
import CouriersLandingApp from "../../../modules/CouriersLanding/CouriersLandingApp/CouriersLandingApp";
import MedicalLandingApp from "../../../modules/MedicalLanding/MedicalLandingApp/MedicalLandingApp";
import "@purpledeerstudio/logistics-components/dist/style.css";

const renderSelectedLanding = (landing: Landing) => {
  switch (landing) {
    case Landing.MEDICAL:
      return <MedicalLandingApp />;
    case Landing.COMPANY:
      return <CompanyLandingApp />;
    case Landing.COURIERS:
      return <CouriersLandingApp />;
    default:
      throw "No landing selected in config file.";
  }
};

const Root: FunctionComponent = () => {
  // Change expression after ? to select different landing during development
  const landing = config.development === "true" ? Landing.MEDICAL : config.selectedLanding;
  if (!landing) throw new Error("No landing selected");

  return <BrowserRouter>{renderSelectedLanding(landing)}</BrowserRouter>;
};

export default Root;
