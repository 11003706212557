import styled from "styled-components";
import { media } from "../../shared/styles/media";
import { ClientOpinionsProps } from "./ClientOpinions";
import { getOpinionsConfig, LandingOpinions } from "./opinionsConfig";

export const ClientOpinionsContainer = styled.section<ClientOpinionsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  // padding dependant on wrapper
  padding: ${p => (p.opinionsConfig === LandingOpinions.COMPANY ? "0 200px 200px;" : "0 0 200px")};
  box-sizing: border-box;

  ${p => media.down("LAPTOP")`
    padding: ${p.opinionsConfig === LandingOpinions.COMPANY ? "0 144px 200px;" : "0 0 200px"};
  `}

  ${p => media.down("TABLET_LARGE")`
    padding: ${p.opinionsConfig === LandingOpinions.COMPANY ? "0 96px 200px" : "0 0 200px"};
  `}

  ${p => media.down("MOBILE_LARGE")`
    padding: ${p.opinionsConfig === LandingOpinions.COMPANY ? "0 90px 100px" : "0 0 100px"};
  `}

  ${p => media.down("MOBILE")`
    padding: ${p.opinionsConfig === LandingOpinions.COMPANY ? " 0 48px 100px;" : "0 0 100px"};
  `}
  
  ${media.up("FULL")`
    padding-bottom: 300px;
  `}
`;

export const ClientOpinionsHeader = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 112%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0e1b1b;

  ${media.down("LAPTOP")`
    font-size: 64px;
  `}

  ${media.down("TABLET_LARGE")`
    font-size: 56px;
  `}

  ${media.down("MOBILE_LARGE")`
    font-size: 44px;
  `}
`;

export const ClientOpinionsWrapper = styled.div`
  display: flex;
  margin-top: 150px;

  > :nth-child(odd) {
    margin-top: 55px;
  }

  > :nth-child(even) {
    margin: 0 16px;
    ${media.up("NEW_LARGE_WEB")`
      margin: 0 40px;
    `}
  }
  ${media.down("TABLET_LARGE")`
    margin-top: 148px;
    > :nth-child(odd) {
        margin-top: 0px;
    }
    > :last-child {
        display: none;
    }
    > :nth-child(even) {
        margin:0;
        margin-left: 32px;
    }
  `}

  ${media.down("MOBILE_LARGE")`
    flex-direction: column;
    > :nth-child(even) {
        margin:28px 0;
    }
    > :last-child {
        display: flex;
    }
  `}
`;

export const ClientOpinionBoxWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 3px;
  overflow: hidden;
  border-radius: 20px;
  height: 100%;
`;

export const ClientOpinionInsideWrapper = styled.div`
  ${media.down("MOBILE_LARGE")`
    position: relative;
  `}
`;

export const ClientOpinionBox = styled.div<ClientOpinionsProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* background: rgba(239, 233, 230, 1); */
  background: ${p => getOpinionsConfig(p.opinionsConfig).cardBackground};

  border: 1px solid ${p => getOpinionsConfig(p.opinionsConfig).borderColor};
  border-radius: 20px;
  padding: 78px 48px 33px 48px;
  max-height: 240px;

  ${media.up("NEW_LARGE_WEB")`
    max-height: 276px;
  `}

  ${media.down("LAPTOP")`
    padding: 78px 24px 33px 24px;
  `}
  ${media.down("TABLET_LARGE")`
    padding: 78px 30px 33px 30px;
  `}
  ${media.down("MOBILE_LARGE")`
   padding: 35px 42px 24px 32px;
  `}
`;

// const bg = "linear-gradient(270deg, rgba(241, 167, 173, 0) 0%, #dd2736 96.95%)";
// const bg2 = "linear-gradient(270deg, rgba(198, 235, 234, 0) 0%,#35ACA8  96.95%)";

export const AnimatedLine = styled.div<{ animationDelay: number; config: LandingOpinions }>`
  position: absolute;
  top: 50%;
  left: 48%;
  width: 50px;
  height: 400px;
  background: ${p => getOpinionsConfig(p.config).lineGradient};
  transform-origin: 50% 0;
  animation: rotateLine linear 6s infinite;
  /* animation-delay: ${props => props.animationDelay * 3 + "s"}; */
  @keyframes rotateLine {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ClientOpinionBoxContent = styled.p<ClientOpinionsProps>`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: ${p => getOpinionsConfig(p.opinionsConfig).contentColor};
  max-width: 240px;
  ${media.up("NEW_LARGE_WEB")`
    font-size: 19px;
    max-width: 280px;
  `}

  ${media.down("MOBILE_LARGE")`
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    text-align: left;
  `}
`;

export const ClientOpinionBoxAuthor = styled.span<ClientOpinionsProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  font-style: italic;
  color: ${p => getOpinionsConfig(p.opinionsConfig).footerTextColor};
  margin-top: 16px;

  ${media.down("TABLET_LARGE")`
    font-size: 16px;
  `}

  ${media.down("MOBILE_LARGE")`
    text-align: right;
  `}
`;

export const ClientOpinionStarsWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  top: 32px;

  ${media.down("MOBILE_LARGE")`
   top: -10px;
   left: 30px;
   transform: translateX(0);
   z-index: 9999;
  `}
`;

export const ReadMoreButtonWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`;
export const ReadMoreButton = styled.a<ClientOpinionsProps>`
  position: relative;
  background: none;
  border: none;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  color: ${p => getOpinionsConfig(p.opinionsConfig).readMoreColor};
  text-decoration: none;
  transition: font-weight 0.3s ease;
  padding: 0 8px;
  z-index: 10;
  ::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
    width: 25px;
    height: 1.5px;
    background: #48c7c3;
  }

  :hover {
    font-weight: 500;
  }

  ${media.down("TABLET_LARGE")`
    margin-top: 24px;
  `}
  ${p => media.down("MOBILE_LARGE")`
    background: ${getOpinionsConfig(p.opinionsConfig).readMoreBackground};
  `}

  ${media.up("NEW_LARGE_WEB")`
    font-size: 19px;
  `}
`;

export const ReadMoreButtonLine = styled.span<ClientOpinionsProps>`
  position: absolute;
  height: 1px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  top: 37px;
  background-color: ${p => getOpinionsConfig(p.opinionsConfig).readMoreSeparatorColor};
  display: none;
  ${media.down("MOBILE_LARGE")`
    display: block;
  `}
`;
