import React, { useCallback } from "react";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import { ScaledContentWrapper } from "./ScaledContent.components";

const ScaledContent = (props: any) => {
  const [width] = useWindowSize();
  const calculateZoom = useCallback(() => {
    if (width >= 1920) {
      return (width - 1920) / 2000 + 1;
    } else {
      return 1;
    }
  }, [width]);

  return <ScaledContentWrapper zoom={calculateZoom()}>{props.children}</ScaledContentWrapper>;
};

export default ScaledContent;
